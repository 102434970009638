async function doFetch(pageNumber) {
  let url = process.env.REACT_APP_API_ENTRYPOINT + `/offers?page=${pageNumber}`;
  return await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  }).then((response) => response.json());
}

export default async function getOffers(pageNumber) {
  return doFetch(pageNumber)
    .then((res) => {
      if (res.code === 401) {
        let url = process.env.REACT_APP_API_ENTRYPOINT + "/login/admin/refresh";

        return fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            refresh_token: `${localStorage.getItem("refresh_token")}`,
          }),
        })
          .then((res) => res.json())
          .then((r) => localStorage.setItem("token", r.token))
          .then(() => doFetch(pageNumber));
      }
      return res;
    })
    .then((data1) => {
      let list = [];
      data1["hydra:member"].forEach((item) => {
        list.push(item);
      });
      return list;
    });
}

export function getDetails(id) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + `${id}`;
  const res = fetch(URL, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => response.json())
    .then((data1) => {
      return data1;
    });
  return res;
}

export function createOffer(
  product,
  startDate,
  endDate,
  newPrice,
  priority,
  createdAt,
  editedAt
) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + "/offers";
  const res = fetch(URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({
      product,
      startDate,
      endDate,
      newPrice,
      priority,
      createdAt,
      editedAt,
    }),
  });
  return res;
}

export async function deleteOffer(id) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + id;
  let res = await fetch(URL, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return res;
}

export async function editOffer(
  id,
  startDate,
  endDate,
  newPrice,
  priority,
  editedAt
) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + id;
  let res = await fetch(URL, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({
      startDate,
      endDate,
      newPrice,
      priority,
      editedAt,
    }),
  });
  return res;
}

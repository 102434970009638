async function doFetch(url) {
  return await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

export async function getInvoices(url) {
  return doFetch(url)
    .then((res) => {
      if (res.status === 401) {
        let link =
          process.env.REACT_APP_API_ENTRYPOINT + "/login/admin/refresh";

        return fetch(link, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            refresh_token: `${localStorage.getItem("refresh_token")}`,
          }),
        })
          .then((res) => res.json())
          .then((r) => localStorage.setItem("token", r.token))
          .then(() => doFetch(url));
      }
      return res;
    })
    .then((data1) => {
      return data1;
    });
}

export async function getManufacturerInvoice(id) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + `${id}`;
  const res = fetch(URL, {
    method: "GET",
    headers: {
      "X-User-Auth": `${localStorage.getItem("token")}`,
    },
  });
  return res;
}

export async function getAdminInvoice(id) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + `${id}`;
  const res = fetch(URL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return res;
}

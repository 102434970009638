import React, { useState, useEffect } from "react";
import { Button, Card, Container, Col } from "reactstrap";
import SimpleHeader from "../../componentsTheme/Headers/SimpleHeader.js";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import getTags, { getTagDetailsByName } from "../../services/tags";
import Loader from "../../utils/loader";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import ErrorAlert from "../../utils/errorAlert";
import IconButton from "@material-ui/core/IconButton";
import Header from "../../utils/header";

function TagList(props) {
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [pageNumber, setPageNumber] = useState("1");
  const [requests, setRequests] = useState([1]);
  const [totalNumberOfTags, setTotalNumberOfTags] = useState(1);
  const [filterApplied, setFilterApplied] = useState(false);
  const [filters, setFilters] = useState("");

  const THEME = createMuiTheme({
    typography: {
      fontFamily: "Poppins",
    },
  });

  useEffect(() => {
    if (!filterApplied && tags.length < totalNumberOfTags) {
      setLoading(true);
      let tagsList = [...tags];
      getTags(pageNumber)
        .then((data) => {
          setTotalNumberOfTags(data["hydra:totalItems"]);
          let list = [];
          data["hydra:member"].forEach((tag) => {
            list.push(tag);
          });
          list.forEach((item) => {
            tagsList.push(item);
          });
          setTags(tagsList);
          setLoading(false);
        })
        .catch(() => {
          setError(true);
          setLoading(false);
          window.scrollTo(0, 0);
        });
    }
  }, [pageNumber, filterApplied]);

  const columns = [
    {
      name: "@id",
      label: "TAG ID",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value.slice(6, 10)}</>;
        },
      },
    },
    {
      name: "name",
      label: "NAME",
      options: {
        filter: true,
        sort: true,
        filterType: "textField",
      },
    },
    {
      name: "products",
      label: "PRODUCTS",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value.length}</>;
        },
      },
    },
    {
      name: "DETAILS",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ width: "30px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IconButton
                  size="small"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={() =>
                    props.history.push(
                      `show/${encodeURIComponent(tableMeta.rowData[0])}`,
                      {
                        tag: tableMeta.rowData[0],
                      }
                    )
                  }
                >
                  <MoreVertIcon fontSize="small" />
                </IconButton>
              </div>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    confirmFilters: true,
    textLabels: {
      body: {
        noMatch: loading ? <Loader /> : "Sorry, no matching records found",
      },
    },
    count: totalNumberOfTags,
    rowsPerPage: 10,
    selectableRows: "none",
    responsive: "vertical",
    rowsPerPageOptions: [],
    download: false,
    print: false,
    search: false,
    onChangePage: (currentPage) => {
      let req = [...requests];
      let lastRequest = req.slice(-1)[0];
      let newRequest = lastRequest + 1;
      if (tags.length < totalNumberOfTags) {
        if (!filterApplied) {
          if (loading) {
            return <Loader />;
          } else {
            if (requests.includes(newRequest)) {
              return;
            } else {
              req.push(newRequest);
              setRequests(req);
              setPageNumber(newRequest);
            }
            window.scrollTo(0, 0);
          }
        } else {
          if (loading) {
            return <Loader />;
          } else {
            setError(false);
            if (requests.includes(newRequest)) {
              return;
            } else {
              req.push(newRequest);
              setRequests(req);
              setPageNumber(newRequest);
            }
            window.scrollTo(0, 0);
          }
        }
      }
    },
    onFilterChipClose: (index, removedFilter, filterList) => {
      setError(false);
      let filter = filterList.some((item) => item.length > 0);
      if (filter) {
        applyFilters(filterList);
      } else {
        setPageNumber(1);
        setFilterApplied(false);
        setRequests([1]);
        setTags([]);
      }
    },
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div style={{ marginTop: "40px" }}>
          <Button
            variant="contained"
            onClick={() => handleFilterSubmit(applyNewFilters)}
          >
            Apply filters
          </Button>
        </div>
      );
    },
  };

  function applyFilters(filterList) {
    let list = [];
    setError(false);
    setLoading(true);
    setTags([]);
    setFilterApplied(true);
    setFilters(filterList);
    setRequests([1]);
    setPageNumber(1);
    let searchByName = filterList[1][0];
    getTagDetailsByName(searchByName)
      .then((item) => setTags(item))
      .catch((error) => {
        setTags([]);
        setTotalNumberOfTags(1);
        setLoading(false);
      });
  }

  const handleFilterSubmit = (applyNewFilters) => {
    setError(false);
    let filterList = applyNewFilters();
    applyFilters(filterList);
  };

  return (
    <>
      <SimpleHeader name="All tags" parentName="Tags" />
      <Container className="mt--6" fluid>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Card>
            <Header
              title={"Tag list"}
              edit={true}
              del
              delText
              loading={false}
              showIcons={false}
            />
            {error && (
              <ErrorAlert
                text={
                  "There was an error while loading the list, please try again"
                }
              />
            )}
            <MuiThemeProvider theme={THEME}>
              <MUIDataTable
                title={""}
                data={tags}
                columns={columns}
                options={options}
              />
            </MuiThemeProvider>
          </Card>
        </Col>
      </Container>
    </>
  );
}

export default TagList;

import { getIn } from "formik";
import React from "react";
import { Label, Col, FormGroup, FormFeedback } from "reactstrap";
import Select from "react-select";
import COLORS from "../utils/colors";

export default function SelectField({ field, form, ...props }) {
  const errorText =
    getIn(form.touched, field.name) && getIn(form.errors, field.name);

  const options = props.options;
  return (
    <FormGroup className="row">
      <Label className="form-control-label" htmlFor="example-text-input" md="2">
        {props.label}
      </Label>
      <Col md="6">
        <Select
          {...field}
          {...props}
          onChange={(option) => form.setFieldValue(field.name, option.value)}
          onBlur={field.onBlur}
          value={
            options
              ? options.find((option) => option.value === field.value)
              : ""
          }
          styles={{
            control: (base, state) => ({
              ...base,
              backgroundColor: COLORS.WHITE,
              fontSize: 14,
              border: !!errorText ? "1px solid red" : "1px solid #dee2e6",
              height: "calc(1.5em + 1.25rem + 5px)",
            }),
          }}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              neutral50: "#8898aa",
            },
          })}
        />
        <FormFeedback>{errorText}</FormFeedback>
      </Col>
    </FormGroup>
  );
}

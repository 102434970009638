import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import SimpleHeader from "../../componentsTheme/Headers/SimpleHeader.js";

class Form extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string,
  };

  renderField = (data) => {
    data.input.className = "form-control";

    const isInvalid = data.meta.touched && !!data.meta.error;
    if (isInvalid) {
      data.input.className += " is-invalid";
      data.input["aria-invalid"] = true;
    }

    if (this.props.error && data.meta.touched && !data.meta.error) {
      data.input.className += " is-valid";
    }

    return (
      <div className={`form-group`}>
        <input
          {...data.input}
          type={data.type}
          step={data.step}
          required={data.required}
          placeholder={data.placeholder}
          id={`brand_${data.input.name}`}
        />
        {isInvalid && <div className="invalid-feedback">{data.meta.error}</div>}
      </div>
    );
  };

  render() {
    return (
      <>
        <SimpleHeader name="New brand" parentName="Brands" />
        <Container className="mt--6" fluid>
          <Row>
            <Col lg="8">
              <div className="card-wrapper">
                <Card>
                  <CardHeader>
                    <h3 className="mb-0">Brand details</h3>
                  </CardHeader>
                  <CardBody>
                    <form onSubmit={this.props.handleSubmit}>
                      <FormGroup className="row">
                        <Label
                          className="form-control-label"
                          htmlFor="example-text-input"
                          md="2"
                        >
                          Brand name
                        </Label>
                        <Col md="10">
                          <Field
                            component={this.renderField}
                            name="name"
                            type="text"
                            placeholder="The name of the brand"
                            required={true}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup className="row">
                        <Label
                          className="form-control-label"
                          htmlFor="example-text-input"
                          md="2"
                        >
                          Brand logo
                        </Label>
                        <Col md="10">
                          <Field
                            component={this.renderField}
                            name="logo"
                            type="text"
                            placeholder="An associated logo"
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup className="row">
                        <Label
                          className="form-control-label"
                          htmlFor="example-text-input"
                          md="2"
                        >
                          External Ids
                        </Label>
                        <Col md="10">
                          <Field
                            component={this.renderField}
                            name="externalIds"
                            type="text"
                            placeholder="The ids that represent this brand in external platforms."
                            // required={true}
                          />
                        </Col>
                      </FormGroup>
                      <button type="submit" className="btn btn-success">
                        Save
                      </button>
                    </form>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default reduxForm({
  form: "brand",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(Form);

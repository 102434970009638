import React from "react";
import ReactDOM from "react-dom";
import { createStore, combineReducers, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { reducer as form } from "redux-form";
import { Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import {
  ConnectedRouter,
  connectRouter,
  routerMiddleware,
} from "connected-react-router";
import "bootstrap/dist/css/bootstrap.css";
import "@fortawesome/fontawesome-free/css/all.css";
import * as serviceWorker from "./serviceWorker";
import "./assets/vendor/bootstrap-rtl/bootstrap-rtl.scss";
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./assets/vendor/fullcalendar/dist/fullcalendar.min.css";
import "./assets/vendor/sweetalert2/dist/sweetalert2.min.css";
import "./assets/vendor/select2/dist/css/select2.min.css";
import "./assets/vendor/quill/dist/quill.core.css";
import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-pro-react.scss?v1.1.0";
import AdminLayout from "./layouts/Admin.js";
import AuthLayout from "./layouts/Auth.js";
// Import your reducers and routes here
import user from "./reducers/user/";
import userRoutes from "./routes/user";
import bulkuploadrequest from "./reducers/bulkuploadrequest/";
import bulkuploadrequestRoutes from "./routes/bulkuploadrequest";
import category from "./reducers/category/";
import categoryRoutes from "./routes/category";
import seller from "./reducers/seller/";
import sellerRoutes from "./routes/seller";
import product from "./reducers/product/";
import productRoutes from "./routes/product";
import order from "./reducers/order/";
import orderRoutes from "./routes/order";
import imageobject from "./reducers/imageobject/";
import imageobjectRoutes from "./routes/imageobject";
import gdnentry from "./reducers/gdnentry/";
import gdnentryRoutes from "./routes/gdnentry";
import customer from "./reducers/customer/";
import customerRoutes from "./routes/customer";
import manufacturer from "./reducers/manufacturer/";
import manufacturerRoutes from "./routes/manufacturer";
import variation from "./reducers/variation/";
import variationRoutes from "./routes/variation";
import goodsdeliverednote from "./reducers/goodsdeliverednote/";
import goodsdeliverednoteRoutes from "./routes/goodsdeliverednote";
import woocommerceorderrequest from "./reducers/woocommerceorderrequest/";
import woocommerceorderrequestRoutes from "./routes/woocommerceorderrequest";
import brand from "./reducers/brand/";
import brandRoutes from "./routes/brand";
import customerwish from "./reducers/customerwish";
import customerwishRoutes from "./routes/customerwish";
import offer from "./reducers/offer";
import offerRoutes from "./routes/offer";
import productreview from "./reducers/productreview";
import productreviewRoutes from "./routes/productreview";
import provider from "./reducers/provider";
import providerRoutes from "./routes/provider";
import tag from "./reducers/tag";
import tagRoutes from "./routes/tag";
import ProviderLayout from "./layouts/Provider";
import ManufacturerLayout from "./layouts/Manufacturer";
import PrivateRoute from "./utils/privateRoute";
import ErrorPage from "./components/error/errorPage";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://015667ece20a4d91b4c18388dc075e86@sentry.kodo.ro/4",
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

const history = createBrowserHistory();
const store = createStore(
  combineReducers({
    router: connectRouter(history),
    form,
    user,
    bulkuploadrequest,
    category,
    seller,
    product,
    order,
    imageobject,
    gdnentry,
    customer,
    manufacturer,
    variation,
    goodsdeliverednote,
    woocommerceorderrequest,
    brand,
    customerwish,
    offer,
    productreview,
    provider,
    tag,
    /* Add your reducers here */
  }),
  applyMiddleware(routerMiddleware(history), thunk)
);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        <Route
          path="/provider"
          component={PrivateRoute(ProviderLayout, "provider")}
        />
        <Route
          path="/manufacturer"
          component={PrivateRoute(ManufacturerLayout, "manufacturer")}
        />
        <Route path="/admin" component={PrivateRoute(AdminLayout, "admin")} />
        <Route
          exact
          path="/"
          component={
            localStorage.getItem("role") === "provider"
              ? PrivateRoute(ProviderLayout, "provider")
              : localStorage.getItem("role") === "manufacturer"
              ? PrivateRoute(ManufacturerLayout, "manufacturer")
              : PrivateRoute(AdminLayout, "admin")
          }
        />
        {userRoutes}
        {bulkuploadrequestRoutes}
        {categoryRoutes}
        {sellerRoutes}
        {productRoutes}
        {orderRoutes}
        {imageobjectRoutes}
        {gdnentryRoutes}
        {customerRoutes}
        {manufacturerRoutes}
        {variationRoutes}
        {goodsdeliverednoteRoutes}
        {woocommerceorderrequestRoutes}
        {brandRoutes}
        {customerwishRoutes}
        {offerRoutes}
        {productreviewRoutes}
        {providerRoutes}
        {tagRoutes}
        <Route render={(props) => <ErrorPage {...props} />} />
      </Switch>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

export const PROVIDER_VEHICLE_OPTIONS = [
  {
    value: "foot",
    label: "Foot",
  },
  { value: "car", label: "Car" },
  { value: "bike", label: "Bike" },
  { value: "motorbike", label: "Motorbike" },
  { value: "public_transport ", label: "Public transport" },
];

async function doFetch(page) {
  let url = process.env.REACT_APP_API_ENTRYPOINT + `/providers?page=${page}`;
  return await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  }).then((response) => response.json());
}

export default async function getProviders(page) {
  return doFetch(page)
    .then((res) => {
      if (res.code === 401) {
        let url = process.env.REACT_APP_API_ENTRYPOINT + "/login/admin/refresh";

        return fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            refresh_token: `${localStorage.getItem("refresh_token")}`,
          }),
        })
          .then((res) => res.json())
          .then((r) => localStorage.setItem("token", r.token))
          .then(() => doFetch(page));
      }
      return res;
    })
    .then((data1) => {
      let list = [];
      data1["hydra:member"].forEach((item) => {
        list.push(item);
      });
      return list;
    });
}

export async function getProviderDetails(id) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + `${id}`;
  const res = fetch(URL, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => response.json())
    .then((data1) => data1);
  return res;
}

export async function getOrdersFromProvider(prId) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + prId + "/orders/";
  const res = fetch(URL, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => response.json())
    .then((data1) => {
      let list = [];
      data1["hydra:member"].forEach((item) => {
        list.push(item);
      });
      return list;
    });
  return res;
}

export async function createProvider(
  firstName,
  lastName,
  phoneNumber,
  email,
  password,
  licensePlate,
  vehicleType,
  additionalInfo
) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + "/provider/register";
  const res = fetch(URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({
      firstName,
      lastName,
      phoneNumber,
      email,
      password,
      licensePlate,
      vehicleType,
      additionalInfo,
    }),
  });
  return res;
}

export async function deleteProvider(id) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + "" + id;
  let res = await fetch(URL, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return res;
}

export async function updateProvider(
  id,
  firstName,
  lastName,
  phoneNumber,
  email,
  licensePlate,
  vehicleType,
  additionalInfo
) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + "" + id;
  let res = await fetch(URL, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({
      firstName,
      lastName,
      phoneNumber,
      email,
      licensePlate,
      vehicleType,
      additionalInfo,
    }),
  });
  return res;
}

export async function updatePassword(email) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + `/login/forgot`;
  let res = await fetch(URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-User-Auth": `${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({
      email: email,
    }),
  });
  return res;
}

//provider view

async function doFetchProviderName() {
  const urlProviderId = process.env.REACT_APP_API_ENTRYPOINT + "/customers/me";
  const id = await fetch(urlProviderId, {
    method: "GET",
    headers: {
      "X-User-Auth": `${localStorage.getItem("token")}`,
    },
  })
    .then((response) => response.json())
    .then((data1) => data1);

  let url = process.env.REACT_APP_API_ENTRYPOINT + `/providers/${id}`;

  return await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-User-Auth": `${localStorage.getItem("token")}`,
    },
  }).then((response) => response.json());
}

export async function getProviderName() {
  return doFetchProviderName()
    .then((res) => {
      if (res.code === 401) {
        let url = process.env.REACT_APP_API_ENTRYPOINT + "/login/refresh";
        return fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            refreshToken: `${localStorage.getItem("refresh_token")}`,
          }),
        })
          .then((res) => res.json())
          .then((r) => localStorage.setItem("token", r.token))
          .then(() => doFetchProviderName());
      }
      return res;
    })
    .then((data1) => data1);
}

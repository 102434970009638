import React, { useMemo, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { Label, FormGroup, Col } from "reactstrap";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px 0px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#BEBEBE",
  borderStyle: "dashed",
  backgroundColor: "#FFF",
  color: "#000",
  fontSize: "14px",
  fontWeight: 500,
  outline: "none",
  transition: "border .24s ease-in-out",
  marginBottom: "20px",
  height: "100%",
  width: "100%",
  fontFamily: "poppins",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const useStyles = makeStyles((theme) => ({
  loadingWraper: {
    textAlign: "center",
    marginTop: 50,
  },
  loader: {
    color: "#FBD400",
  },
  uploadSucces: {
    color: "green",
    fontSize: "50px",
  },
  uploadError: {
    color: "red",
    fontSize: "50px",
  },
  appBarSpacer: theme.mixins.toolbar,
}));

export default function BulkImgContainer({
  links,
  setLinks,
  newProduct,
  setNewProduct,
  productList,
  setProductList,
}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [showUploadText, setShowUploadText] = useState(true);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const uploadBulkProductImage = (acceptedFiles) => {
        acceptedFiles.forEach((file) => {
          let product = { ...newProduct };
          let list = [...productList];
          list.forEach((img) => {
            img.images = [];
            img.link = [];
          });
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function () {
            product.images.push({
              contentUrl: reader.result,
            });
            product.link.push(reader.result);
            links.push(0);

            list.map((pr) => {
              pr.images.push({
                contentUrl: reader.result,
              });
              pr.link.push(reader.result);
            });
            setProductList(list);
            setNewProduct(product);
            setError(false);
            setSuccess(true);
            setShowUploadText(false);
          };
          reader.onerror = function (error) {
            setError(true);
            setSuccess(false);
            setShowUploadText(true);
          };
        });
      };

      uploadBulkProductImage(acceptedFiles);
    },
    [newProduct, productList, setNewProduct, setProductList, links]
  );

  const {
    acceptedFiles,
    rejectedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, accept: "image/jpeg, image/png" });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <div>
      <FormGroup className="row">
        <Label
          className="form-control-label"
          htmlFor="example-datetime-local-input"
          md="2"
        >
          Or
        </Label>

        <Col md="6">
          <div className="container">
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />

              <div>Drag and drop your files here</div>

              {loading && (
                <div className={classes.loadingWraper}>
                  <CircularProgress
                    className={classes.loader}
                  ></CircularProgress>
                </div>
              )}

              {success && (
                <span>
                  <CheckCircleIcon className={classes.uploadSucces} />
                </span>
              )}
              {error && (
                <span>
                  <HighlightOffIcon className={classes.uploadError} />
                </span>
              )}
            </div>
          </div>
        </Col>
      </FormGroup>
    </div>
  );
}

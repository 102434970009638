import React, { useState, useEffect } from "react";
import { Card, CardHeader, Container, Row, Col } from "reactstrap";
import SimpleHeader from "../../componentsTheme/Headers/SimpleHeader.js";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import getBanners from "../../services/banners";
import Loader from "../../utils/loader";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import ErrorAlert from "../../utils/errorAlert";
import MUIDataTable from "mui-datatables";
import IconButton from "@material-ui/core/IconButton";
import Header from "../../utils/header";

function BannerList(props) {
  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState("1");
  const [error, setError] = useState(false);

  const THEME = createMuiTheme({
    typography: {
      fontFamily: "Poppins",
    },
    overrides: {
      MUIDataTableHeadCell: {
        root: {
          "&:nth-child(1)": {
            width: 100,
          },
        },
      },
    },
  });

  useEffect(() => {
    setLoading(true);
    getBanners(pageNumber)
      .then((item) => {
        setBanners(item);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  }, [pageNumber]);

  const columns = [
    {
      name: "image",
      label: "IMAGE",
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <img alt="Banner image" height="100px" src={value.contentUrl} />
            </>
          );
        },
      },
    },
    {
      name: "@id",
      label: "BANNER ID",
      options: {
        filter: true,
        sort: true,
        filterType: "textField",
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value.slice(9, 20)}</>;
        },
      },
    },
    {
      name: "location",
      label: "LOCATION",
      options: {
        filter: true,
        sort: true,
        filterType: "dropdown",
      },
    },
    {
      name: "startDate",
      label: "START DATE",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return `${new Date(value).toLocaleDateString()}`;
        },
      },
    },
    {
      name: "endDate",
      label: "END DATE",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return `${new Date(value).toLocaleDateString()}`;
        },
      },
    },
    {
      name: "DETAILS",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ width: "30px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IconButton
                  size="small"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={() =>
                    props.history.push(
                      `show/${encodeURIComponent(tableMeta.rowData[1])}`,
                      {
                        banner: tableMeta.rowData[1],
                      }
                    )
                  }
                >
                  <MoreVertIcon fontSize="small" />
                </IconButton>
              </div>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    rowsPerPage: 10,
    selectableRows: "none",
    responsive: "vertical",
    rowsPerPageOptions: [],
    download: false,
    print: false,
    search: false,
  };

  return (
    <>
      <SimpleHeader name="All banners" parentName="Banners" />
      <Container className="mt--6" fluid>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Card>
            <Header
              title={"Banner list"}
              edit={true}
              del
              delText
              loading={false}
              showIcons={false}
            />

            {loading && <Loader />}
            {error && (
              <ErrorAlert
                text={
                  "There was an error while loading the banners, please try again"
                }
              />
            )}
            {banners.length > 0 && (
              <>
                <MuiThemeProvider theme={THEME}>
                  <MUIDataTable
                    title={""}
                    data={banners}
                    columns={columns}
                    options={options}
                  />
                </MuiThemeProvider>
              </>
            )}
          </Card>
        </Col>
      </Container>
    </>
  );
}

export default BannerList;

import React from "react";
// react library for routing
import { Route, Switch, Redirect } from "react-router-dom";

// core components
import AuthNavbar from "../componentsTheme/Navbars/AuthNavbar.js";
import AuthFooter from "../componentsTheme/Footers/AuthFooter.js";

import routes from "../routes.js";

class Auth extends React.Component {
  componentDidMount() {

    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
    document.body.classList.add("bg-default");


    if (window.location.href.startsWith("https://provider") || window.location.href.startsWith("https://livrator")) {
      return (
        <Redirect from="*" to="/auth/login/provider/" />)
    } else {
      return (
        <Redirect from="*" to="/auth/login/admin/" />)
    }

  }
  componentWillUnmount() {
    document.body.classList.remove("bg-default");
  }
  componentDidUpdate(e) {
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainContent.scrollTop = 0;
    }
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  render() {
    return (
      <>
        <div className="main-content" ref="mainContent" >

          <Switch>
            {this.getRoutes(routes)}
            {window.location.href.startsWith("https://provider") || window.location.href.startsWith("https://livrator") ?
              <Redirect from="*" to="/auth/login/provider" />
              :
              <Redirect from="*" to="/auth/login/admin" />
            }

          </Switch>
        </div>

      </>
    );
  }
}

export default Auth;

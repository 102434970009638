export const IMAGE_PRODUCT = "PRODUCT";
export const IMAGE_CLOUDINARY_UPLOAD =
  "https://res.cloudinary.com/kodingtech/image/upload";
export const IMAGE_CLOUDINARY_UPLOAD_W_H = "/w_512,h_512";
export const PRODUCT_VISIBILITY_OPTIONS = [
  {
    value: "visible",
    label: "visible",
  },
  {
    value: "hidden",
    label: "hidden",
  },
  {
    value: "gone",
    label: "gone",
  },
];

export const PRODUCT_UM_OPTIONS = [
  {
    value: "kg",
    label: "kg",
  },
  {
    value: "100 grame",
    label: "100 grame",
  },
  {
    value: "150 grame",
    label: "150 grame",
  },
  {
    value: "200 grame",
    label: "200 grame",
  },
  {
    value: "250 grame",
    label: "250 grame",
  },
  {
    value: "300 grame",
    label: "300 grame",
  },
  {
    value: "330 ml",
    label: "330 ml",
  },
  {
    value: "330 ml",
    label: "330 ml",
  },
  {
    value: "400 grame",
    label: "400 grame",
  },
  {
    value: "5 buc.",
    label: "5 buc.",
  },
  {
    value: "5 kg",
    label: "5 kg",
  },
  {
    value: "500 grame",
    label: "500 grame",
  },
  {
    value: "500 ml",
    label: "500 ml",
  },
  {
    value: "750 grame",
    label: "750 grame",
  },
  {
    value: "750 ml",
    label: "750 ml",
  },
  {
    value: "850 grame",
    label: "850 grame",
  },
  {
    value: "850 grame",
    label: "850 grame",
  },
  {
    value: "borcan 314 grame",
    label: "borcan 314 grame",
  },
  {
    value: "buc",
    label: "buc",
  },
  {
    value: "casoleta (400 g)",
    label: "casoleta (400 g)",
  },
  {
    value: "casoleta (400 g)",
    label: "casoleta (400 g)",
  },
  {
    value: "legatura",
    label: "legatura",
  },
  {
    value: "litru",
    label: "litru",
  },
];

export const PRODUCT_CURRENCY_OPTIONS = [
  {
    value: "RON",
    label: "RON",
  },
  {
    value: "EUR",
    label: "EUR",
  },
  {
    value: "USD",
    label: "USD",
  },
];

export const PRODUCT_TVA = [
  {
    value: "19",
    label: "19%",
  },
  {
    value: "9",
    label: "9%",
  },
  {
    value: "5",
    label: "5%",
  },
  {
    value: "0",
    label: "0%",
  },
];

export const COUNT_OF_TRANSPORT_PRODUCTS = 3;

async function doFetch(url) {
  return await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

export async function getProducts(url) {
  return doFetch(url)
    .then((res) => {
      if (res.status === 401) {
        let link =
          process.env.REACT_APP_API_ENTRYPOINT + "/login/admin/refresh";

        return fetch(link, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            refresh_token: `${localStorage.getItem("refresh_token")}`,
          }),
        })
          .then((res) => res.json())
          .then((r) => localStorage.setItem("token", r.token))
          .then(() => doFetch(url));
      }
      return res;
    })
    .then((data1) => {
      return data1;
    });
}

export async function getAllProducts() {
  let url = process.env.REACT_APP_API_ENTRYPOINT + `/products?pagination=false`;
  return await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

export async function getProductDetails(id, role) {
  let url = process.env.REACT_APP_API_ENTRYPOINT + `${id}`;
  let header =
    role === "admin"
      ? {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
      : {
          "Content-Type": "application/json",
          "X-User-Auth": `${localStorage.getItem("token")}`,
        };

  return await fetch(url, {
    method: "GET",
    headers: header,
  })
    .then((response) => response.json())
    .then((data1) => {
      return data1;
    });
}

export async function deleteProduct(id, props, role) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + id;
  let header =
    role === "admin"
      ? {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
      : {
          "Content-Type": "application/json",
          "X-User-Auth": `${localStorage.getItem("token")}`,
        };

  return fetch(URL, {
    method: "DELETE",
    headers: header,
  }).then((response) => {
    props.history.push(`/${role}/products/`);
  });
}

export async function createProducts(
  name,
  slogan,
  unitOfMeasurement,
  manufacturer,
  categories,
  subcategories,
  sku,
  stockCount,
  addPrice,
  images,
  visibility,
  tags,
  description,
  deliveryTime,
  role
) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + "/products";

  let header =
    role === "admin"
      ? {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
      : {
          "Content-Type": "application/json",
          "X-User-Auth": `${localStorage.getItem("token")}`,
        };

  return fetch(URL, {
    method: "POST",
    headers: header,
    body: JSON.stringify({
      name: name,
      slogan: slogan,
      unitOfMeasurement: unitOfMeasurement,
      tenant: `${manufacturer}`,
      categories: [`${categories}`],
      subcategory: `${subcategories}`,
      sku: sku,
      stockCount: stockCount,
      price: addPrice,
      images: images,
      visibility: visibility,
      tags: tags,
      description: description,
      deliveryTime: deliveryTime,
      formattedDescription: description,
      isVariable: false,
    }),
  }).then((response) => response);
}

export async function getProductsByManufacturer(id, page) {
  const URL =
    process.env.REACT_APP_API_ENTRYPOINT + `${id}/products?page=${page}`;
  const res = fetch(URL, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => response.json())
    .then((data1) => {
      return data1;
    });
  return res;
}

export async function getProductsByCategories(name, page) {
  const URL =
    process.env.REACT_APP_API_ENTRYPOINT +
    `/products?categories.name=${name}&page=${page}`;
  const res = fetch(URL, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => response.json())
    .then((data1) => {
      return data1;
    });
  return res;
}

export async function getProductById(id) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + `/products/${id}`;
  const res = fetch(URL, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => response.json())
    .then((data1) => {
      return data1;
    });
  return res;
}

export async function getProductBySku(id) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + `/products/${id}`;
  const res = fetch(URL, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => response.json())
    .then((data1) => {
      return data1;
    });
  return res;
}

export async function getProductByName(name, page) {
  const URL =
    process.env.REACT_APP_API_ENTRYPOINT +
    `/products?name=${name}&page=${page}`;
  const res = fetch(URL, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => response.json())
    .then((data1) => {
      let list = [];
      data1["hydra:member"].forEach((item) => {
        list.push(item);
      });
      return list;
    });
  return res;
}

export async function getProductsByVisibility(type, page) {
  const URL =
    process.env.REACT_APP_API_ENTRYPOINT +
    `/products?visibility=${type}&page=${page}`;
  const res = fetch(URL, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => response.json())
    .then((data1) => {
      return data1;
    });
  return res;
}

export async function getProductsByExpressDelivery(value, page) {
  const URL =
    process.env.REACT_APP_API_ENTRYPOINT +
    `/products?manufacturer.transportExpressExist=${value}&page=${page}`;

  const res = fetch(URL, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => response.json())
    .then((data1) => data1);
  return res;
}

export async function getProductsByDeliveryTime(value, page) {
  const URL =
    process.env.REACT_APP_API_ENTRYPOINT +
    `/products?deliveryTime=${value}&page=${page}`;

  const res = fetch(URL, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => response.json())
    .then((data1) => data1);
  return res;
}

export async function getProductByNameAndManufacturer(name, manufacturer) {
  const URL =
    process.env.REACT_APP_API_ENTRYPOINT +
    `/products?name=${name}&manufacturer.id=${manufacturer}`;
  const res = fetch(URL, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => response.json())
    .then((data1) => {
      let list = [];
      data1["hydra:member"].forEach((item) => {
        list.push(item);
      });
      return list;
    });
  return res;
}

import React from "react";
import { Button, CardHeader } from "reactstrap";
import COLORS from "./colors";
import QuestionAlert from "./questionAlert";

function Header(props) {
  return (
    <>
      <CardHeader>
        <h3 className="mb-0">
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {props.title}
              {!props.loading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {props.showIcons && (
                    <>
                      {!props.hideEdit && (
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="#00ff00"
                          type="submit"
                          onClick={() => props.edit(true)}
                        >
                          <span
                            className="btn-inner--icon"
                            style={{ color: COLORS.BLACK }}
                          >
                            <i className="fas fa-edit fa-lg" />
                          </span>
                        </Button>
                      )}
                      <QuestionAlert text={props.delText} del={props.del} />
                    </>
                  )}
                </div>
              )}
            </div>
          </>
        </h3>
      </CardHeader>
    </>
  );
}

export default Header;

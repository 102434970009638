import React from "react";
import { Route } from "react-router-dom";
import { List, CreateManufacturer, Show } from "../components/manufacturer/";

export default [
  <Route
    path="/manufacturers/create"
    component={CreateManufacturer}
    exact
    key="create"
  />,
  <Route path="/manufacturers/show/:id" component={Show} exact key="show" />,
  <Route path="/manufacturers/" component={List} exact strict key="list" />,
  <Route
    path="/manufacturers/:page"
    component={List}
    exact
    strict
    key="page"
  />,
  <Route path="/manufacturers" component={List} exact strict key="list" />,
];

import Login from "./components/login/Login.js";
import ProviderLogin from "./components/login/ProviderLogin.js";
import Profile from "./components/manufacturerAccountViews/profile/Profile.js";
import OrderList from "./components/manufacturerAccountViews/order/List.js";
import ProductList from "./components/manufacturerAccountViews/product/List.js";
import ShowProduct from "./components/product/Show.js";
import ShowOrder from "./components/manufacturerAccountViews/order/Show.js";
import InvoiceList from "./components/manufacturerAccountViews/invoices/List.js";
import Payments from "./components/manufacturerAccountViews/payments/Payments.js";
import CreateProduct from "./components/product/Create.js";

const manufacturerRoutes = [
  {
    collapse: false,
    name: "Orders",
    icon: "ni ni-align-left-2 text-darker",
    state: "tablesCollapse",
    path: "/orders/show/:id",
    miniName: "O",
    component: ShowOrder,
    layout: "/manufacturer",
    redirect: "/orders/show/:id",
  },
  {
    collapse: false,
    name: "All orders",
    icon: "ni ni-align-left-2 text-darker",
    state: "tables2Collapse",
    path: "/orders",
    miniName: "O",
    component: OrderList,
    layout: "/manufacturer",
  },
  {
    collapse: true,
    name: "Financial overview",
    icon: "ni ni-archive-2 text-darker",
    state: "financialOverviewCollapse",
    views: [
      {
        path: "/payments/",
        name: "Payments details",
        miniName: "C",
        component: Payments,
        layout: "/manufacturer",
      },
      {
        path: "/invoices",
        name: "Invoices",
        miniName: "I",
        component: InvoiceList,
        layout: "/manufacturer",
      },
    ],
  },

  {
    collapse: true,
    name: "Products",
    icon: "ni ni-basket text-darker",
    state: "formsCollapse",
    views: [
      {
        path: "/products/show/:id",
        name: "Product details",
        miniName: "P",
        component: ShowProduct,
        layout: "/manufacturer",
        redirect: "/products/show/:id",
      },
      {
        path: "/products/create",
        name: "Add product",
        miniName: "P",
        component: CreateProduct,
        layout: "/manufacturer",
      },
      {
        path: "/products/",
        name: "All products",
        miniName: "P",
        component: ProductList,
        layout: "/manufacturer",
      },
    ],
  },
  {
    collapse: false,
    name: "My profile",
    icon: "ni ni-single-02 text-darker",
    state: "profileCollapse",
    path: "/profile/",
    miniName: "O",
    component: Profile,
    layout: "/manufacturer",
  },
  {
    collapse: true,
    name: "Others",
    icon: "ni ni-ungroup text-orange",
    state: "examplesCollapse",
    redirect: "/login/admin/",
    views: [
      {
        path: "/login/admin",
        name: "Login",
        miniName: "L",
        component: Login,
        layout: "/auth",
      },
    ],
  },
  {
    collapse: true,
    name: "Others",
    icon: "ni ni-ungroup text-orange",
    state: "examplesCollapse",
    redirect: "/login/provider",
    views: [
      {
        path: "/login/provider",
        name: "Login",
        miniName: "L",
        component: ProviderLogin,
        layout: "/auth",
      },
    ],
  },
];

export default manufacturerRoutes;

import React, { useState } from "react";
import { Card, CardBody, Container, Row, Col } from "reactstrap";
import SimpleHeader from "../../componentsTheme/Headers/SimpleHeader.js";
import SuccessAlert, { showSaveDialog } from "../../utils/succesAlert";
import ErrorAlert, { showErrorDialog } from "../../utils/errorAlert";
import ImageLinks from "./imageLinksContainer";
import TagsInputContainer from "./tagsInputContainer";
import { createProducts } from "../../services/products";
import FormattedDescription from "./formattedDescriptionContainer";
import ImgContainer from "../../utils/imgContainer";
import Header from "../../utils/header";
import DeliveryOptionsContainer from "./deliveryOptionsContainer";
import ProductDetailsContainer from "./productDetailsContainer";
import { IMAGE_PRODUCT } from "../../services/products";
import { RequestLoader } from "../../utils/loader";

function CreateProduct() {
  const [newProduct, setNewProduct] = useState({
    name: "",
    deliveryTime: "24",
    description: "",
    slogan: "",
    manufacturer: "",
    unitOfMeasurement: "",
    brands: [],
    categories: "",
    subcategory: "",
    price: {
      price: "",
      priceCurrency: { value: "RON", label: "RON" },
      vatPercentage: { value: "19", label: "19%" },
    },
    link: [],
    externalIds: "",
    sku: "",
    stockCount: 0,
    variable: "",
    visibility: "visible",
    images: [],
    tags: [],
  });

  const [links, setLinks] = useState([]);
  const [saved, setSaved] = useState(false);
  const [loadingDeliveryOptions, setLoadingDeliveryOptions] = useState(false);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [error, setError] = useState(false);
  const [errorName, setErrorName] = useState(false);
  const [errorDescription, setErrorDescription] = useState(false);
  const [errorUm, setErrorUm] = useState(false);
  const [errorMan, setErrorMan] = useState(false);
  const [errorCateg, setErrorCateg] = useState(false);
  const [errorTax, setErrorTax] = useState(false);
  const [errorSubcateg, setErrorSubcateg] = useState(false);
  const [errorSku, setErrorSku] = useState(false);
  const [errorPrice, setErrorPrice] = useState(false);
  const [errorPriceCurrency, setErrorPriceCurrency] = useState(false);
  const [errorText, setErrorText] = useState("");
  const role = localStorage.getItem("role");

  const isFormValid =
    newProduct.name.length > 2 &&
    newProduct.name.length < 35 &&
    newProduct.description.length > 0 &&
    newProduct.unitOfMeasurement !== "" &&
    newProduct.manufacturer !== "" &&
    newProduct.categories !== "" &&
    newProduct.subcategory !== "" &&
    newProduct.sku.length > 0 &&
    newProduct.price.vatPercentage !== "" &&
    newProduct.price.price.length > 0 &&
    newProduct.price.priceCurrency !== "";

  function setErrorBorder() {
    setErrorName(
      errorName ||
        (newProduct.name.trim().length <= 2 &&
          newProduct.name.trim().length >= 34)
    );
    setErrorDescription(
      errorDescription || newProduct.description.trim().length <= 0
    );
    setErrorUm(errorUm || newProduct.unitOfMeasurement.length <= 0);
    setErrorSku(errorSku || newProduct.sku.trim().length <= 0);
    setErrorPrice(errorPrice || newProduct.price.price.length <= 0);
    setErrorMan(errorMan || newProduct.manufacturer.length <= 0);
    setErrorCateg(errorCateg || newProduct.categories.length <= 0);
    setErrorSubcateg(errorSubcateg || newProduct.subcategory.length <= 0);
  }

  async function addNewProduct(event) {
    event.preventDefault();
    if (isFormValid) {
      setLoadingRequest(true);
      let getTag = [];
      if (newProduct.tags !== "") {
        newProduct.tags.forEach((item) => {
          getTag.push(item.value);
        });
      } else {
        getTag.push({ label: "", value: "" });
      }
      let addPrice = {
        price: Number(newProduct.price.price),
        priceCurrency: newProduct.price.priceCurrency.value,
        vatPercentage: Number(newProduct.price.vatPercentage.value),
      };
      createProducts(
        newProduct.name,
        newProduct.slogan,
        newProduct.unitOfMeasurement.value,
        newProduct.manufacturer.value,
        newProduct.categories.value,
        newProduct.subcategory.value,
        newProduct.sku,
        Number(newProduct.stockCount),
        addPrice,
        newProduct.images,
        newProduct.visibility,
        getTag,
        newProduct.description,
        newProduct.deliveryTime,
        role
      )
        .then((res) => {
          if (res.ok) {
            showSaveDialog(setSaved);
            setLoadingRequest(false);
          } else {
            showErrorDialog(setError);
            setLoadingRequest(false);
          }
        })
        .catch((error) => {
          showErrorDialog(setError);
          setLoadingRequest(false);
        });
    } else {
      showErrorDialog(setError);
      setErrorBorder();
      setLoadingRequest(false);
      if (
        newProduct.name.trim().length < 3 ||
        newProduct.name.trim().length >= 34
      ) {
        setErrorName(true);
        setErrorText(
          "The product name must be between 3 and 34 charancters long."
        );
      } else {
        setErrorText("Please enter all of the required informations");
      }
    }
  }

  return (
    <>
      <SimpleHeader name="New product" parentName="Products" />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="6">
            <div className="card-wrapper">
              <Card>
                <Header
                  title={"Product details"}
                  edit={true}
                  del
                  delText
                  loading={false}
                  showIcons={false}
                />
                <CardBody>
                  <ProductDetailsContainer
                    product={newProduct}
                    setProduct={setNewProduct}
                    editProduct={true}
                    setLoadingDeliveryOptions={setLoadingDeliveryOptions}
                    errorName={errorName}
                    setErrorName={setErrorName}
                    errorUm={errorUm}
                    setErrorUm={setErrorUm}
                    errorSku={errorSku}
                    setErrorSku={setErrorSku}
                    errorPrice={errorPrice}
                    setErrorPrice={setErrorPrice}
                    errorPriceCurrency={errorPriceCurrency}
                    setErrorPriceCurrency={setErrorPriceCurrency}
                    errorMan={errorMan}
                    setErrorMan={setErrorMan}
                    errorCateg={errorCateg}
                    setErrorCateg={setErrorCateg}
                    errorSubcateg={errorSubcateg}
                    setErrorSubcateg={setErrorSubcateg}
                    errorTax={errorTax}
                    setErrorTax={setErrorTax}
                    role={role}
                  />
                </CardBody>
              </Card>
            </div>
          </Col>
          <Col lg="6">
            <div className="card-wrapper">
              <FormattedDescription
                product={newProduct}
                setProduct={setNewProduct}
                editProduct={true}
                errorDescription={errorDescription}
                setErrorDescription={setErrorDescription}
                loading={false}
              />
              <DeliveryOptionsContainer
                product={newProduct}
                setProduct={setNewProduct}
                edit={true}
                loading={loadingDeliveryOptions}
              />
              <TagsInputContainer
                newProduct={newProduct}
                setNewProduct={setNewProduct}
                editProduct={true}
                loading={false}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <Header
                  title={"Images"}
                  edit={true}
                  del
                  delText
                  loading={false}
                  showIcons={false}
                />
                <CardBody>
                  <ImageLinks
                    links={links}
                    setLinks={setLinks}
                    newProduct={newProduct}
                    setNewProduct={setNewProduct}
                  />
                  <ImgContainer
                    links={links}
                    setLinks={setLinks}
                    newProduct={newProduct}
                    setNewProduct={setNewProduct}
                    type={IMAGE_PRODUCT}
                  />
                  <button
                    disabled={loadingRequest ? true : false}
                    onClick={(event) => addNewProduct(event)}
                    className="btn btn-success"
                  >
                    Save
                  </button>
                  {loadingRequest && <RequestLoader />}
                </CardBody>
                <Col md="10">
                  {saved && <SuccessAlert text={"Your product was created"} />}
                  {error && <ErrorAlert text={errorText} />}
                </Col>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CreateProduct;

import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Container,
  Form,
  FormGroup,
  Col,
  Label,
} from "reactstrap";
import SimpleHeader from "../../../componentsTheme/Headers/SimpleHeader.js";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MaterialTable from "material-table";
import Loader from "../../../utils/loader";
import ErrorAlert from "../../../utils/errorAlert";
import Header from "../../../utils/header";
import {
  getManufacturerSuborders,
  getManufacturerName,
} from "../../../services/manufacturers";
import { CsvBuilder } from "filefy";
import ReactDatetime from "react-datetime";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import * as moment from "moment";

function OrderList(props) {
  const [manufacturer, setManufacturer] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [loading, setLoading] = useState(false);
  const [showCustomInputs, setShowCustomInputs] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [period, setPeriod] = useState({
    startDate: moment(new Date()),
    endDate: moment().add(7, "d"),
  });
  const [month, setMonth] = useState();
  const tableRef = React.createRef();
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const THEME = createMuiTheme({
    typography: {
      fontFamily: "Poppins",
    },
  });

  useEffect(() => {
    setLoading(true);
    getManufacturerName()
      .then((item) => {
        setManufacturer(item);
        setLoading(false);
      })
      .catch((error) => {
        showErrorDialog(setError);
        setErrorText(
          "There was an error while loading the informations, please try again"
        );
      });
  }, []);

  function showErrorDialog() {
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 3000);
  }

  function addStartDate(date) {
    setPeriod({
      ...period,
      startDate: date["_d"],
    });
  }

  function addEndDate(date) {
    setPeriod({
      ...period,
      endDate: date["_d"],
    });
  }

  const columns = [
    {
      field: "@id",
      title: "SUBORDER ID",
      render: (rowData) => {
        return <>{rowData["@id"].slice(11, 15)}</>;
      },
    },
    {
      field: "finalPrice",
      title: "TOTAL PRICE",
      filtering: false,
      render: (rowData) => {
        if (rowData.finalPrice) {
          return (
            <>
              {rowData.finalPrice.price} {rowData.finalPrice.priceCurrency}
            </>
          );
        } else {
          return (
            <>
              {rowData.totalPrice.price} {rowData.totalPrice.priceCurrency}
            </>
          );
        }
      },
    },
    {
      field: "createdAt",
      title: "DATE",
      filterComponent: (props) => <CustomDatePicker {...props} />,
      render: (rowData) => {
        return <>{new Date(rowData.createdAt).toLocaleDateString()}</>;
      },
    },
    {
      field: "status",
      title: "STATUS",
      render: (rowData) => {
        return <>{rowData.status}</>;
      },
    },
  ];

  function getUrl(query, hasPagination, filterByDate) {
    let url =
      process.env.REACT_APP_API_ENTRYPOINT +
      `/suborders?manufacturer.id=${manufacturer.id}`;

    url += "&page=" + (query.page + 1);
    url += "&pagination=" + hasPagination;

    if (query.orderDirection === "" && !query.orderBy) {
      query.orderDirection = "desc";
      url += `&order%5BcreatedAt%5D=${query.orderDirection}`;
    }

    if (query.orderBy) {
      url += `&order%5B${query.orderBy.field}%5D=${query.orderDirection}`;
    }
    if (query.filters) {
      query.filters.forEach((filter) => {
        switch (filter.column.title) {
          case "SUBORDER ID":
            url =
              process.env.REACT_APP_API_ENTRYPOINT +
              "/suborders/" +
              encodeURIComponent(filter.value) +
              "?";
            break;
          case "STATUS":
            url += "&status=" + encodeURIComponent(filter.value);
            break;
          default:
        }
      });
    }

    if (filterByDate) {
      switch (filterByDate) {
        case "lastMonth":
          var startMonth = moment(startMonth)
            .subtract(1, "months")
            .startOf("month")
            .format("YYYY-MM-DDTHH:mm");
          var endMonth = moment(endMonth)
            .subtract(1, "months")
            .endOf("month")
            .format("YYYY-MM-DDTHH:mm");
          url += `&createdAt%5Bafter%5D=${startMonth}&createdAt%5Bbefore%5D=${endMonth}`;
          break;
        case "currentMonth":
          var startCurrentMonth = moment(startMonth)
            .startOf("month")
            .format("YYYY-MM-DDTHH:mm");
          var endCurrentMonth = moment(endMonth)
            .endOf("month")
            .format("YYYY-MM-DDTHH:mm");
          url += `&createdAt%5Bafter%5D=${startCurrentMonth}&createdAt%5Bbefore%5D=${endCurrentMonth}`;
          break;
        case "customPeriod":
          var startCustomPeriod = moment(period.startDate).format(
            "YYYY-MM-DDTHH:mm"
          );
          var endCustomPeriod = moment(period.endDate).format(
            "YYYY-MM-DDTHH:mm"
          );
          url += `&createdAt%5Bafter%5D=${startCustomPeriod}&createdAt%5Bbefore%5D=${endCustomPeriod}`;
          break;
        default:
      }
    }
    return url;
  }

  function getTotal(or) {
    let orderedProd = or.orderProducts.filter((el) => el.status !== "removed");
    let newPrice = orderedProd.reduce(
      (sum, obj) => sum + obj.product.actualPrice.price * obj.quantity,
      0
    );
    or.totalPrice.total = `${newPrice} ${or.totalPrice.priceCurrency}`;
    return or;
  }

  const getNewItems = async (query) => {
    let url = getUrl(query, true, month);
    const fetchResult = await getManufacturerSuborders(url);
    if (fetchResult.status > 299) {
      return {
        data: [],
        page: query.page,
        totalCount: 1,
      };
    } else {
      const parsedResponse = await fetchResult.json();
      if (parsedResponse.hasOwnProperty("hydra:member")) {
        let list = [];
        parsedResponse["hydra:member"].forEach((or) => {
          or = getTotal(or);
          list.push(or);
        });
        return {
          data: list,
          page: query.page,
          totalCount: parsedResponse["hydra:totalItems"],
        };
      } else {
        let list = [];
        let updatedTotal = getTotal(parsedResponse);
        list.push(updatedTotal);
        return {
          data: list,
          page: query.page,
          totalCount: 1,
        };
      }
    }
  };

  function createCSV(list, columns) {
    let listMap = list.map((rowData) =>
      columns.map((columnDef) => rowData[columnDef.field])
    );
    listMap.forEach((row) => {
      row[0] = row[0].slice(11, 15);
      row[1] = `${row[1].price} ${row[1].priceCurrency}`;
      row[2] = row[2].slice(0, 10);
    });
    new CsvBuilder("Suborders.csv")
      .setDelimeter(",")
      .setColumns(columns.map((columnDef) => columnDef.title))
      .addRows(listMap)
      .exportFile();
  }

  const exportCsv = async (allColumns, allData) => {
    const columns = allColumns.filter(
      (columnDef) => columnDef["export"] !== false
    );
    let queryList = tableRef.current.state.query;
    let url = getUrl(queryList, false);
    const fetchResult = await getManufacturerSuborders(url);
    const parsedResponse = await fetchResult.json();
    let list = [];
    if (parsedResponse.hasOwnProperty("hydra:member")) {
      parsedResponse["hydra:member"].forEach((or) => {
        or = getTotal(or);
        list.push(or);
      });
    } else {
      let updatedTotal = getTotal(parsedResponse);
      list.push(updatedTotal);
    }
    createCSV(list, columns);
  };

  async function handleFilterBy(e) {
    switch (e) {
      case "currentMonth":
        setShowCustomInputs(false);
        tableRef.current.onQueryChange();
        setMonth("currentMonth");
        break;
      case "lastMonth":
        setShowCustomInputs(false);
        tableRef.current.onQueryChange();
        setMonth("lastMonth");
        break;
      case "customPeriod":
        setShowCustomInputs(true);
        break;
      case "reset":
        setShowCustomInputs(false);
        tableRef.current.onQueryChange();
        setMonth("");
    }
  }

  const CustomDatePicker = (props) => {
    return (
      <Dropdown
        isOpen={dropdownOpen}
        toggle={toggle}
        style={{
          textAlign: "left",
          color: "black",
          marginLeft: "0px",
          paddingLeft: "0px",
          fontFamily: "Poppins",
          cursor: "pointer",
        }}
      >
        <DropdownToggle
          tag="a"
          className="nav-link"
          style={{
            width: "100%",
            color: "black",
            marginLeft: "0px",
            paddingLeft: "10px",
            borderRadius: "4px",
            border: "none",
            maxHeight: "100%",
          }}
        >
          Filter by
        </DropdownToggle>
        <DropdownMenu onClick={(e) => handleFilterBy(e.target.value)}>
          <DropdownItem value="currentMonth">Current month</DropdownItem>
          <DropdownItem value="lastMonth">Last month</DropdownItem>
          <DropdownItem value="customPeriod">Custom period</DropdownItem>
          <DropdownItem divider />
          <DropdownItem value="reset">Reset</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  function customDateSearch(e) {
    e.preventDefault();
    tableRef.current.onQueryChange();
    setMonth("customPeriod");
  }

  const actions = [
    {
      name: "seeDetails",
      icon: () => <MoreVertIcon />,
      tooltip: "See more details",
      onClick: (event, rowData) =>
        props.history.push(
          `/manufacturer/orders/show/${encodeURIComponent(rowData["@id"])}`,
          {
            order: rowData,
          }
        ),
      disabled: false,
      position: "row",
    },
  ];

  return (
    <>
      <SimpleHeader name="All orders" parentName="Orders" />
      <Container className="mt--6" fluid>
        <Card>
          <Header
            title={"Order list"}
            edit={true}
            del
            delText
            loading={false}
            showIcons={false}
          />
          {error && <ErrorAlert text={errorText} />}
          <>
            <div>
              {showCustomInputs && (
                <Form>
                  <FormGroup className="row">
                    <Col md="2"></Col>
                    <Col md="3">
                      <ReactDatetime
                        inputProps={{
                          style: {
                            color: "black",
                          },
                        }}
                        value={moment(period.startDate).format("DD-MM-YYYY")}
                        timeFormat={false}
                        onChange={(date) => addStartDate(date)}
                      />
                    </Col>

                    <Label
                      className="form-control-label"
                      htmlFor="example-text-input"
                      md="1"
                    >
                      to
                    </Label>
                    <Col md="3">
                      <ReactDatetime
                        inputProps={{
                          style: {
                            color: "black",
                          },
                        }}
                        value={moment(period.endDate).format("DD-MM-YYYY")}
                        timeFormat={false}
                        onChange={(date) => addEndDate(date)}
                      />
                    </Col>
                    <Button
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={(e) => customDateSearch(e)}
                    >
                      Apply
                    </Button>
                  </FormGroup>
                </Form>
              )}
            </div>
            {loading && <Loader />}
            {!loading && (
              <MuiThemeProvider theme={THEME}>
                <div style={{ maxWidth: "100%" }}>
                  <MaterialTable
                    columns={columns}
                    data={getNewItems}
                    title={null}
                    tableRef={tableRef}
                    options={{
                      exportButton: true,
                      sorting: true,
                      filtering: true,
                      pageSize: 30,
                      pageSizeOptions: [30],
                      search: false,
                      exportAllData: true,
                      actionsColumnIndex: -1,
                      exportCsv,
                    }}
                    actions={actions}
                    localization={{
                      header: {
                        actions: "",
                      },
                    }}
                  />
                </div>
              </MuiThemeProvider>
            )}
          </>
        </Card>
      </Container>
    </>
  );
}

export default OrderList;

import { Field, Form, Formik } from "formik";
import React from "react";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";

import SimpleHeader from "../../componentsTheme/Headers/SimpleHeader.js";
import Header from "../../utils/header";
import {
  formAllProducts,
  formCategories,
  formManufacturer,
  formObject,
  formPlus,
  formProducts,
  formTags,
} from "./constants/formObject.js";
import { schema } from "./constants/validation.js";
import moment from "moment";
import { addVoucher } from "../../services/vouchers.js";
import SuccessAlert, { showSaveDialog } from "../../utils/succesAlert.js";
import ErrorAlert, { showErrorDialog } from "../../utils/errorAlert.js";
import { RequestLoader } from "../../utils/loader.js";

function Create(props) {
  const [loadingRequest, setLoadingRequest] = React.useState(false);
  const [errorText, setErrorText] = React.useState("");
  const [typeVoucher, setTypeVoucher] = React.useState("tags");
  const [saved, setSaved] = React.useState(false);
  const [error, setError] = React.useState(false);

  const currentTime = moment();

  function formByType(type) {
    setTimeout(() => setTypeVoucher(type), 0);
    switch (type) {
      case "tags":
        return formTags().map((snapshot, key) => {
          return <Field {...snapshot} key={key} />;
        });
      case "categories":
        return formCategories().map((snapshot, key) => {
          return <Field {...snapshot} key={key} />;
        });
      case "manufacturers":
        return formManufacturer().map((snapshot, key) => {
          return <Field {...snapshot} key={key} />;
        });
      case "products":
        return formProducts().map((snapshot, key) => {
          return <Field {...snapshot} key={key} />;
        });
      case "all":
        return formAllProducts().map((snapshot, key) => {
          return <Field {...snapshot} key={key} />;
        });
      case "plus":
        return formPlus().map((snapshot, key) => {
          return <Field {...snapshot} key={key} />;
        });
      default:
        return null;
    }
  }

  function postVoucher(val, _) {
    console.log(val);
    if (val["IDByType"]) {
      if (val["IDByType"].length > 0) {
        let list = [];
        val["IDByType"].forEach((data) => {
          list.push(data.value);
        });
        val["IDByType"] = list;
      } else {
        let list1 = [];
        list1.push(val["IDByType"].value ? val["IDByType"].value : "all");
        val["IDByType"] = list1;
      }
    } else {
      val["IDByType"] = [""];
    }
    val["Discount"] = Number(val["Discount"]);

    val["StartDate"] = moment(val["StartDate"], "YYYY-MM-DDTHH:mm:ssZ").format(
      "YYYY-MM-DDTHH:mm:ssZ"
    );
    val["ExpiredDate"] = moment(
      val["ExpiredDate"],
      "YYYY-MM-DDTHH:mm:ssZ"
    ).format("YYYY-MM-DDTHH:mm:ssZ");

    val["MaximumSpend"] = Number(val["MaximumSpend"]);
    val["MinimumSpend"] = Number(val["MinimumSpend"]);
    val["UsageLimit"] = Number(val["UsageLimit"]);
    val["UsageLimitPerUser"] = Number(val["UsageLimitPerUser"]);
    setLoadingRequest(true);
    addVoucher(val)
      .then((data) => {
        if (data.ok) {
          showSaveDialog(setSaved);
          setLoadingRequest(false);
          setTimeout(() => {
            props.history.push("/admin/vouchers");
          }, 2000);
        } else {
          setErrorText("Something went wrong!");
          showErrorDialog(setError);
          setLoadingRequest(false);
        }
      })
      .catch((error) => {
        showErrorDialog(setError);
        setLoadingRequest(false);
      });
  }

  return (
    <>
      <SimpleHeader name="Create voucher" parentName="Vouchers" />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <Header
                  title={"Create voucher"}
                  edit={true}
                  del
                  delText
                  loading={false}
                  showIcons={false}
                />
                <CardBody>
                  <Formik
                    validationSchema={schema(typeVoucher)}
                    initialValues={{
                      Name: "",
                      Description: "",
                      Code: "",
                      StartDate: moment(currentTime, "YYYY-MM-DDTHH:mm").format(
                        "YYYY-MM-DDTHH:mm"
                      ),
                      ExpiredDate: moment(currentTime, "YYYY-MM-DDTHH:mm")
                        .add(7, "days")
                        .format("YYYY-MM-DDTHH:mm"),
                      FreeShipping: true,
                      MinimumSpend: "",
                      MaximumSpend: "",
                      UsageLimit: "",
                      UsageLimitPerUser: "",
                      VoucherType: typeVoucher,
                      Discount: "",
                      IDByType: "",
                      DiscountPriceType: "relative",
                      DiscountConditionPlus: "",
                    }}
                    onSubmit={(values, actions) => {
                      postVoucher(values, actions);
                    }}
                  >
                    {({ values }) => (
                      <Form>
                        {formObject.map((snapshot, key) => {
                          return <Field {...snapshot} key={key} />;
                        })}
                        {formByType(values.VoucherType)}
                        <Col
                          md="8"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignSelf: "center",
                            alignItems: "center",
                            marginTop: 30,
                          }}
                        >
                          <Button
                            color="success"
                            outline
                            type="submit"
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "center",
                            }}
                          >
                            Add Voucher
                          </Button>
                        </Col>
                      </Form>
                    )}
                  </Formik>
                  {loadingRequest && <RequestLoader />}
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
        <Col md="10">
          {saved && <SuccessAlert text={"Your voucher was created!"} />}
          {error && <ErrorAlert text={errorText} />}
        </Col>
      </Container>
    </>
  );
}

export default Create;

import React, { useState } from "react";
import { Card, Container, Button, FormGroup, Label, Input } from "reactstrap";
import SimpleHeader from "../../componentsTheme/Headers/SimpleHeader.js";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import ErrorAlert from "../../utils/errorAlert";
import Header from "../../utils/header";
import MaterialTable from "material-table";
import * as moment from "moment";
import { deliveryProducts } from "../../services/deliveries.js";
import Checkbox from "@material-ui/core/Checkbox";
import DeleteIcon from "@material-ui/icons/Delete";

function FinancialOrderedProducts(props) {
  const [error, setError] = useState(false);
  const tableRef = React.createRef();

  const [sku, setSKU] = useState();
  const [delivery, setDelivery] = useState();
  const [manufacturerName, setManufacturerName] = useState();
  const [productName, setProductName] = useState();
  const [orderId, setOrderID] = useState();
  const [status, setStatus] = useState();
  const [titles, setTitles] = useState([]);
  const [checked, setChecked] = useState(false);
  const [createdDate, setCreatedDate] = useState();
  const [loading, setLoading] = useState(false);

  const [filter, setFilters] = useState([]);
  function handleChange() {
    let op = !checked;
    setChecked(op);
  }

  function handleButton() {
    if (sku) {
      setFilters([...filter, `&product.sku=${sku}`]);
      setTitles([...titles, sku]);
      setSKU("");
      tableRef.current.onQueryChange();
    } else if (delivery) {
      setFilters([...filter, `&forOrder.deliveryType=${delivery}`]);
      setTitles([...titles, delivery]);

      setDelivery("");
      tableRef.current.onQueryChange();
    } else if (manufacturerName) {
      setFilters([...filter, `&product.manufacturer.name=${manufacturerName}`]);
      setTitles([...titles, manufacturerName]);

      setManufacturerName("");
      tableRef.current.onQueryChange();
    } else if (productName) {
      setFilters([...filter, `&product.name=${productName}`]);
      setTitles([...titles, productName]);

      setProductName("");
      tableRef.current.onQueryChange();
    } else if (orderId) {
      setFilters([...filter, `&forOrder.id=${orderId}`]);
      setTitles([...titles, orderId]);

      setOrderID("");
      tableRef.current.onQueryChange();
    } else if (status) {
      setFilters([...filter, `&delivery.deliveryStatus=${status}`]);
      setTitles([...titles, status]);

      setStatus("");
      tableRef.current.onQueryChange();
    } else if (createdDate) {
      setFilters([...filter, `&delivery.deliveryDate[after]=${createdDate}`]);
      setTitles([...titles, createdDate]);

      setCreatedDate("");
      tableRef.current.onQueryChange();
    }
  }

  const THEME = createMuiTheme({
    typography: {
      fontFamily: "Poppins",
    },
  });

  const columns = [
    {
      field: "statusDelivery",
      title: "Status delivery time",
      render: (rowData) => {
        return rowData.forOrder.deliveryType;
      },
    },
    {
      field: "manufacturer",
      title: "Manufacturer",
      render: (rowData) => {
        return rowData.product.manufacturer.name;
      },
    },
    {
      field: "sku",
      title: "SKU",
      render: (rowData) => {
        return rowData.product.sku;
      },
    },
    {
      field: "productName",
      title: "Product Name",
      render: (rowData) => {
        return rowData.product.name;
      },
    },
    {
      field: "qty",
      title: "Quantity",
      filtering: false,
      sorting: false,

      render: (rowData) => {
        return rowData.quantity;
      },
    },
    {
      field: "um",
      title: "UM",
      filtering: false,
      sorting: false,

      render: (rowData) => {
        return rowData.product.unitOfMeasurement;
      },
    },
    {
      field: "price",
      title: "Price",

      render: (rowData) => {
        return rowData.product.actualPrice.price;
      },
    },
    {
      field: "orderId",
      title: "Order Id",
      render: (rowData) => {
        return Number(rowData.forOrder["@id"].match(/[0-9]+/g)[0]);
      },
    },
    {
      field: "deliveryDate",
      title: "Delivery date",

      render: (rowData) => {
        if (rowData.delivery.deliveryDate) {
          return (
            moment(rowData.delivery.deliveryDate)

              .format("DD-MM-YYYY")
          );
        } else {
          return null;
        }
      },
    },
    {
      field: "purchaseStatus",
      title: "Purchase status",
      render: (rowData) => {
        return rowData.delivery.deliveryStatus;
      },
    },
  ];

  function getUrl(query, hasPagination) {

    let url =
      process.env.REACT_APP_API_ENTRYPOINT +
      `/order_products?delivery.deliveryStatus[]=prepared&delivery.deliveryStatus[]=new&delivery.deliveryStatus[]=on_the_way`;
    url += "&page=" + (query.page + 1);
    if (filter.length > 0) {
      filter.forEach((data) => {
        url += data;
      });
    }
    if (query.orderBy) {
      switch (query.orderBy.title) {
        case "Manufacturer":
          query.orderBy.field = "manufacturer.name";
          break;
        case "Status delivery time":
          query.orderBy.field = "forOrder.deliveryType";
          break;
        case "SKU":
          query.orderBy.field = "product.sku";
          break;
        case "Product Name":
          query.orderBy.field = "product.name";
          break;
        case "Price":
          query.orderBy.field = "product.price.price";
          break;
        case "Order Id":
          query.orderBy.field = "forOrder.id";
          break;
        case "Delivery date":
          query.orderBy.field = "delivery.deliveryDate";
          break;
        case "Purchase status":
          query.orderBy.field = "delivery.deliveryStatus";
          break;
        default:
          query.orderBy.field = "";
      }
      url += `&order%5B${query.orderBy.field}%5D=${query.orderDirection}`;
    }

    if (query.filters) {
      query.filters.forEach((filter) => {
        switch (filter.column.field) {
          case "purchaseStatus":
            url +=
              "&delivery.deliveryStatus=" + encodeURIComponent(filter.value);
            break;
          case "statusDelivery":
            url += "&forOrder.deliveryType=" + encodeURIComponent(filter.value);
            break;
          case "orderId":
            url += "&forOrder.id=" + encodeURIComponent(filter.value);
            break;
          case "deliveryDate":
            url +=
              "&delivery.deliveryDate[after]=" +
              encodeURIComponent(filter.value);
            break;
          case "productName":
            url += "&product.name=" + encodeURIComponent(filter.value);
            break;
          case "sku":
            url += "&product.sku=" + encodeURIComponent(filter.value);
            break;
          case "price":
            url += "&product.price.price=" + encodeURIComponent(filter.value);
            break;
          case "manufacturer":
            url +=
              "&product.manufacturer.name=" + encodeURIComponent(filter.value);
            break;
          default:
        }
      });
    }

    return url;
  }

  const getNewItems = async (query) => {
    let url = getUrl(query, true);
    const fetchResult = await deliveryProducts(url);
    if (fetchResult.status > 299) {
      setError(true);
      return {
        data: [],
        page: query.page,
        totalCount: 1,
      };
    }

    const parsedResponse = await fetchResult.json();
    const totalItems = parsedResponse["hydra:totalItems"];
    if (parsedResponse.hasOwnProperty("hydra:member")) {
      let list = [];

      let filtered = parsedResponse["hydra:member"].filter(
        (item) => !item.product.name.includes("Transport")
      );
      filtered.forEach((pr) => {
        list.push(pr);
      });
      return {
        data: list,
        page: query.page,
        totalCount: totalItems,
      };
    } else {
      let list = [];
      return {
        data: list,
        page: query.page,
        totalCount: 1,
      };
    }
  };

  function handleDelete() {
    setTitles([]);
    setFilters([]);
    setChecked(false);
    tableRef.current.onQueryChange();
  }

  function deleteItem(index) {
    let newF = [...filter];
    let newT = [...titles];
    newF.splice(index, 1);
    newT.splice(index, 1);
    setTitles(newT);
    tableRef.current.onQueryChange();

    setFilters(newF);
    tableRef.current.onQueryChange();


  }

  return (
    <>
      <SimpleHeader name="Ordered Products" parentName="Dashboard" />
      <Container className="mt--6" fluid>
        <Card>
          <Header
            title={"Ordered Products"}
            edit={true}
            del
            delText
            loading={false}
            showIcons={false}
          />
          {error && (
            <ErrorAlert
              text={
                "There was an error while loading the ordered products page, please try again"
              }
            />
          )}
          <div
            style={{
              margin: 10,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Checkbox
              checked={checked}
              onChange={handleChange}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
            <text>Show static filters</text>
          </div>
          {checked ? (
            <div
              style={{
                marginTop: 20,
              }}
            >
              <div
                style={{
                  marginLeft: 40,
                  marginRight: 40,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div>
                  <FormGroup>
                    <Label>Status delivery time</Label>
                    <div style={{ flexDirection: "row", display: "flex" }}>
                      <Input
                        value={delivery}
                        name="Status delivery name"
                        placeholder="Status delivery name"
                        onChange={(v) => setDelivery(v.target.value)}
                      />
                      <Button style={{ marginLeft: 15 }} onClick={handleButton}>
                        submit
                      </Button>
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <Label>Manufacturer name</Label>
                    <div style={{ flexDirection: "row", display: "flex" }}>
                      <Input
                        value={manufacturerName}
                        name="Manufacturer name"
                        placeholder="Manufacturer name"
                        onChange={(v) => setManufacturerName(v.target.value)}
                      />
                      <Button style={{ marginLeft: 15 }} onClick={handleButton}>
                        submit
                      </Button>
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <Label>SKU</Label>
                    <div style={{ flexDirection: "row", display: "flex" }}>
                      <Input
                        value={sku}
                        name="sku"
                        placeholder="SKU"
                        onChange={(v) => setSKU(v.target.value)}
                      />
                      <Button style={{ marginLeft: 15 }} onClick={handleButton}>
                        submit
                      </Button>
                    </div>
                  </FormGroup>
                </div>

                <div style={{ marginLeft: 50 }}>
                  <FormGroup>
                    <Label>Product name</Label>
                    <div style={{ flexDirection: "row", display: "flex" }}>
                      <Input
                        value={productName}
                        name="Product name"
                        placeholder="Product name"
                        onChange={(v) => setProductName(v.target.value)}
                      />
                      <Button style={{ marginLeft: 15 }} onClick={handleButton}>
                        submit
                      </Button>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label>Order Id</Label>
                    <div style={{ flexDirection: "row", display: "flex" }}>
                      <Input
                        value={orderId}
                        name="Order Id"
                        placeholder="Order Id"
                        onChange={(v) => setOrderID(v.target.value)}
                      />
                      <Button style={{ marginLeft: 15 }} onClick={handleButton}>
                        submit
                      </Button>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label>Status</Label>
                    <div style={{ flexDirection: "row", display: "flex" }}>
                      <Input
                        value={status}
                        name="Status"
                        placeholder="Status"
                        onChange={(v) => setStatus(v.target.value)}
                      />
                      <Button style={{ marginLeft: 15 }} onClick={handleButton}>
                        submit
                      </Button>
                    </div>
                  </FormGroup>
                </div>

                <div style={{ marginLeft: 50 }}>
                  <FormGroup>
                    <Label>Delivery date</Label>
                    <div style={{ flexDirection: "row", display: "flex" }}>
                      <Input
                        value={createdDate}
                        name="Delivery date"
                        placeholder="Delivery date"
                        onChange={(v) => setCreatedDate(v.target.value)}
                      />
                      <Button style={{ marginLeft: 15 }} onClick={handleButton}>
                        submit
                      </Button>
                    </div>
                  </FormGroup>
                </div>
              </div>
            </div>
          ) : null}
          <>
            <MuiThemeProvider theme={THEME}>
              {filter.length > 0
                ? titles.map((data, key) => {
                  return (
                    <Button
                      key={key}
                      onClick={() => deleteItem(key)}
                      style={{
                        borderWidth: 1,
                        borderStyle: "solid",
                        borderRadius: 15,
                        diplay: "flex",
                        flexDirection: "row",
                        padding: 5,
                        flex: 1,
                        width: 100,
                        marginLeft: 20,
                        marginTop: 5,
                        textAlign: "center",
                      }}
                    >
                      <text
                        style={{
                          display: "inline-block",
                        }}
                      >
                        {data}
                      </text>
                      <DeleteIcon />
                    </Button>
                  );
                })
                : null}

              {filter.length > 0 ? (
                <div
                  style={{
                    diplay: "flex",
                    padding: 5,
                    flex: 1,
                    marginLeft: 20,
                    marginTop: 20,
                  }}
                >
                  <Button
                    onClick={handleDelete}
                    style={{
                      backgroundColor: "rgb(226,50,90)",
                      color: "white",
                    }}
                  >
                    Delete static filters
                  </Button>
                </div>
              ) : null}

              <div style={{ alignSelf: "center" }}>
                <MaterialTable
                  columns={columns}
                  data={getNewItems}
                  title={null}
                  tableRef={tableRef}
                  options={{
                    sorting: true,
                    filtering: true,
                    pageSize: 30,
                    pageSizeOptions: [30],
                    search: false,
                    exportAllData: true,
                    actionsColumnIndex: -1,

                  }}
                  style={{ borderWidth: 0, boxShadow: "none" }}

                  localization={{
                    header: {
                      actions: "",
                    },
                    toolbar: {
                      nRowsSelected: "{0} product(s) selected",
                    },
                    body: {
                      emptyDataSourceMessage: "No records to display",
                      filterRow: {
                        filterTooltip: "Filter",
                      },
                    },
                  }}

                />
              </div>
            </MuiThemeProvider>
          </>
        </Card>
      </Container>
    </>
  );
}

export default FinancialOrderedProducts;

import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import SimpleHeader from "../../componentsTheme/Headers/SimpleHeader.js";
import ImgContainer from "../../utils/imgContainer";
import Loader from "../../utils/loader";
import SuccessAlert, { showSaveDialog } from "../../utils/succesAlert";
import ErrorAlert, { showErrorDialog } from "../../utils/errorAlert";
import { THEME } from "../../utils/colors";
import {
  IMAGE_USER_PROFILE,
  getAdminData,
  updateAdminDetails,
} from "../../services/users";

export default function Profile(props) {
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    city: "",
    country: "",
    phone: "",
    photo: {
      contentUrl: "",
    },
  });
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [errorFirstName, setErrorFirstName] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const isFormValid = user.firstName !== "" && user.email !== "";

  useEffect(() => {
    setLoading(true);
    getAdminData().then((item) => {
      setUser(item);
      setLoading(false);
    });
  }, []);

  function changeInfo(event) {
    switch (event.target.name) {
      case "firstName":
        setErrorFirstName(false);
        break;
      case "email":
        setErrorEmail(false);
        break;
    }
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    });
  }

  function setErrorBorder() {
    setErrorFirstName(errorFirstName || user.firstName.length <= 0);
    setErrorEmail(errorEmail || user.email.length <= 0);
  }

  function updateProfile(e) {
    e.preventDefault();
    if (isFormValid) {
      let userImg = user.photo?.contentUrl.includes("base64")
        ? user.photo
        : undefined;

      updateAdminDetails(
        user["@id"],
        user.email,
        user.firstName,
        user.lastName,
        userImg
      ).then((res) => {
        if (res.ok) {
          showSaveDialog(setSaved);
          setTimeout(() => {
            window.location.reload();
          }, 500);
        } else {
          showErrorDialog(setError);
          setErrorText(
            "There was an error while updating your profile, please try again"
          );
        }
      });
    } else {
      setErrorBorder();
      showErrorDialog(setError);
      setErrorText("Please enter all of the required informations");
    }
  }

  return (
    <>
      <SimpleHeader name="My profile" parentName="Profile" />
      <Container className="mt--6" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Edit profile</h3>
                  </Col>
                </Row>
              </CardHeader>
              {loading && <Loader />}
              {!loading && (
                <CardBody>
                  <Row>
                    <Col xs="4" sm="4" md="4" lg="4" xl="4">
                      <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                        <div className="d-flex justify-content-between"></div>
                      </CardHeader>
                      <img
                        width="200px"
                        alt="Profile"
                        className="rounded-circle"
                        style={{
                          display: "block",
                          marginLeft: "auto",
                          marginRight: "auto",
                          width: "50%",
                        }}
                        src={
                          user.photo && user.photo.contentUrl !== ""
                            ? user.photo?.contentUrl
                            : require("../../assets/img/theme/default-avatar.png")
                        }
                      />
                      <Row className="justify-content-center">
                        <ImgContainer
                          null
                          null
                          newProduct={user}
                          setNewProduct={setUser}
                          type={IMAGE_USER_PROFILE}
                        />
                      </Row>
                    </Col>
                    <Col xs="8" sm="8" md="8" lg="8" xl="8">
                      <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                        <div className="d-flex justify-content-between"></div>
                      </CardHeader>
                      <Form>
                        <h6 className="heading-small text-muted mb-4">
                          User information
                        </h6>
                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  First name
                                </label>
                                <Input
                                  value={user.firstName}
                                  placeholder="First name"
                                  type="text"
                                  name="firstName"
                                  onChange={(event) => changeInfo(event)}
                                  style={{
                                    borderColor: errorFirstName
                                      ? THEME.DANGER
                                      : "",
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-last-name"
                                >
                                  Last name
                                </label>
                                <Input
                                  value={user.lastName}
                                  placeholder="Last name"
                                  type="text"
                                  name="lastName"
                                  onChange={(event) => changeInfo(event)}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="8">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  Username
                                </label>
                                <Input
                                  disabled
                                  value={user.email}
                                  placeholder="email"
                                  name="email"
                                  onChange={(event) => changeInfo(event)}
                                  style={{
                                    borderColor: errorEmail ? THEME.DANGER : "",
                                    backgroundColor: "white",
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="8">
                              <button
                                onClick={(event) => updateProfile(event)}
                                className="btn btn-success"
                                style={{ display: "block", marginLeft: "auto" }}
                              >
                                Save
                              </button>
                            </Col>
                          </Row>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <div style={{ marginTop: 20 }}>
                        {saved && (
                          <SuccessAlert
                            text={"Your profile has been updated"}
                          />
                        )}
                        {error && <ErrorAlert text={errorText} />}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

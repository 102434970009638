import React, { useState } from "react";
import { Card, Container } from "reactstrap";
import SimpleHeader from "../../componentsTheme/Headers/SimpleHeader.js";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ClearIcon from "@material-ui/icons/Clear";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  getProducts,
  getAllProducts,
  IMAGE_CLOUDINARY_UPLOAD,
  IMAGE_CLOUDINARY_UPLOAD_W_H,
  COUNT_OF_TRANSPORT_PRODUCTS,
} from "../../services/products";
import ErrorAlert from "../../utils/errorAlert";
import Header from "../../utils/header";
import CustomToolbarSelect from "../../utils/customToolbarSelectProductList";
import MaterialTable, { MTableToolbar } from "material-table";
import { CsvBuilder } from "filefy";

function ProductList(props) {
  const [error, setError] = useState(false);
  const [
    selectedRowsForMultipleEdit,
    setSelectedRowsForMultipleEdit,
  ] = useState([]);
  const tableRef = React.createRef();

  const THEME = createMuiTheme({
    typography: {
      fontFamily: "Poppins",
    },
  });

  const columns = [
    {
      field: "images",
      title: "IMAGE",
      filtering: false,
      render: (rowData) => {
        return (
          <>
            <img
              alt="Product"
              className="avatar rounded-circle mr-1"
              src={
                rowData.images.length > 0
                  ? rowData.images[0].contentUrl
                  : "/no_image.png"
              }
            />
          </>
        );
      },
    },
    {
      field: "id",
      title: "ID",
      render: (rowData) => {
        return <>{Number(rowData["id"])}</>;
      },
    },
    {
      field: "sku",
      title: "SKU",
      render: (rowData) => {
        return <>{rowData["sku"]}</>;
      },
    },
    {
      field: "name",
      title: "NAME",
      render: (rowData) => {
        return <>{rowData["name"]}</>;
      },
    },
    {
      field: "manufacturer",
      title: "MANUFACTURER",
      render: (rowData) => {
        return <>{rowData["manufacturer"].name}</>;
      },
    },
    {
      field: "categories",
      title: "CATEGORY",
      render: (rowData) => {
        return <>{rowData["categories"][0].name}</>;
      },
    },
    {
      field: "subcategory",
      title: "SUBCATEGORY",
      render: (rowData) => {
        return <>{rowData["subcategory"].name}</>;
      },
    },
    {
      field: "actualPrice",
      title: "PRICE (RON)",
      filtering: false,
      render: (rowData) => {
        return <>{rowData["actualPrice"] ? rowData["actualPrice"].price : 0}</>;
      },
    },
    {
      field: "deliveryTime",
      title: "DELIVERY HOURS",
      type: "numeric",
    },
    {
      field: "manufacturer",
      title: "EXPRESS DELIVERY",
      filterPlaceholder: "y/n",
      render: (rowData) => {
        return (
          <>
            {rowData.manufacturer["transportExpressExist"] ? (
              <CheckCircleIcon style={{ color: "green" }} />
            ) : (
              <ClearIcon style={{ color: "red" }} />
            )}
          </>
        );
      },
    },
    {
      field: "visibility",
      title: "VISIBILITY",
      render: (rowData) => {
        return <>{rowData["visibility"]}</>;
      },
    },
  ];

  function getUrl(query, hasPagination) {
    let url = process.env.REACT_APP_API_ENTRYPOINT + "/products?";
    url += "&page=" + (query.page + 1);
    url += "&pagination=" + hasPagination;
    if (query.orderBy) {
      switch (query.orderBy.title) {
        case "MANUFACTURER":
          query.orderBy.field = "manufacturer.name";
          break;
        case "CATEGORY":
          query.orderBy.field = "categories.name";
          break;
        case "SUBCATEGORY":
          query.orderBy.field = "subcategory.name";
          break;
        case "PRICE (RON)":
          query.orderBy.field = "price.price";
          break;
        case "EXPRESS DELIVERY":
          query.orderBy.field = "manufacturer.transportExpressExist";
          break;
      }
      url += `&order%5B${query.orderBy.field}%5D=${query.orderDirection}`;
    }

    if (query.filters) {
      query.filters.forEach((filter) => {
        switch (filter.column.title) {
          case "ID":
            url =
              process.env.REACT_APP_API_ENTRYPOINT +
              "/products/" +
              encodeURIComponent(filter.value);
            break;
          case "SKU":
            url += "&sku=" + encodeURIComponent(filter.value);
            break;
          case "NAME":
            url += "&name=" + encodeURIComponent(filter.value);
            break;
          case "MANUFACTURER":
            url += "&manufacturer.name=" + encodeURIComponent(filter.value);
            break;
          case "CATEGORY":
            url += "&categories.name=" + encodeURIComponent(filter.value);
            break;
          case "SUBCATEGORY":
            url += "&subcategory.name=" + encodeURIComponent(filter.value);
            break;
          case "DELIVERY HOURS":
            url += "&deliveryTime=" + encodeURIComponent(filter.value);
            break;
          case "EXPRESS DELIVERY":
            let value = filter.value.includes("y") ? true : false;
            url +=
              "&manufacturer.transportExpressExist=" +
              encodeURIComponent(value);
            break;
          case "VISIBILITY":
            let valueVisibility = filter.value.includes("v")
              ? "visible"
              : filter.value.includes("h")
              ? "hidden"
              : filter.value.includes("g")
              ? "gone"
              : "";
            url += "&visibility=" + encodeURIComponent(valueVisibility);
            break;
          default:
        }
      });
    }
    return url;
  }

  const getNewItems = async (query) => {
    let url = getUrl(query, true);
    const fetchResult = await getProducts(url);
    if (fetchResult.status > 299) {
      setError(true);
      return {
        data: [],
        page: query.page,
        totalCount: 1,
      };
    }
    const parsedResponse = await fetchResult.json();
    if (parsedResponse.hasOwnProperty("hydra:member")) {
      let filtered = parsedResponse["hydra:member"].filter(
        (item) => !item.name.includes("Transport")
      );
      let list = [];
      filtered.forEach((pr) => {
        pr.images.forEach((img) => {
          if (img.contentUrl.includes(IMAGE_CLOUDINARY_UPLOAD)) {
            img.contentUrl = [
              img.contentUrl.slice(0, IMAGE_CLOUDINARY_UPLOAD.length),
              IMAGE_CLOUDINARY_UPLOAD_W_H,
              img.contentUrl.slice(IMAGE_CLOUDINARY_UPLOAD.length),
            ].join("");
          }
        });
        list.push(pr);
      });

      let lengthWithoutTransport = 0;
      if (query.filters.length > 0) {
        lengthWithoutTransport = parsedResponse["hydra:totalItems"];
      } else {
        lengthWithoutTransport =
          parsedResponse["hydra:totalItems"] - COUNT_OF_TRANSPORT_PRODUCTS;
      }
      return {
        data: list,
        page: query.page,
        totalCount: lengthWithoutTransport,
      };
    } else {
      let list = [];
      list.push(parsedResponse);
      return {
        data: list,
        page: query.page,
        totalCount: 1,
      };
    }
  };

  function createCSV(list, columns) {
    let listMap = list.map((rowData) =>
      columns.map((columnDef) => rowData[columnDef.field])
    );
    listMap.forEach((row) => {
      row[0] = row[0][0]
        ? row[0][0].contentUrl.startsWith("https://")
          ? row[0][0].contentUrl
          : "Uploaded image"
        : "No image available";
      row[4] = row[4].name;
      row[5] = row[5][0].name;
      row[6] = row[6].name;
      row[7] = row[7] ? row[7].price : 0;
      row[9] = row[9].transportExpressExist;
    });
    new CsvBuilder("Products.csv")
      .setDelimeter(",")
      .setColumns(columns.map((columnDef) => columnDef.title))
      .addRows(listMap)
      .exportFile();
  }

  const exportCsv = async (allColumns, allData) => {
    const columns = allColumns.filter(
      (columnDef) => columnDef["export"] !== false
    );
    let queryList = tableRef.current.state.query;
    if (queryList.filters.length > 0) {
      let url = getUrl(queryList, false);
      const fetchResult = await getProducts(url);
      const parsedResponse = await fetchResult.json();
      let list = [];
      if (parsedResponse.hasOwnProperty("hydra:member")) {
        list = parsedResponse["hydra:member"].filter(
          (item) => !item.name.includes("Transport")
        );
      } else {
        list.push(parsedResponse);
      }
      createCSV(list, columns);
    } else {
      const exportedData = await getAllProducts();
      const parsedResponse = await exportedData.json();
      let list = parsedResponse["hydra:member"].filter(
        (item) => !item.name.includes("Transport")
      );
      createCSV(list, columns);
    }
  };

  const actions = [
    {
      name: "seeDetails",
      icon: () => <MoreVertIcon />,
      tooltip: "See more details",
      onClick: (event, rowData) =>
        props.history.push(`show/${encodeURIComponent(rowData["@id"])}`, {
          product: rowData,
        }),
      disabled: false,
      position: "row",
    },
  ];

  const selectMultipleRows = (data) => {
    setSelectedRowsForMultipleEdit(data);
  };

  return (
    <>
      <SimpleHeader name="All products" parentName="Products" />
      <Container className="mt--6" fluid>
        <Card>
          <Header
            title={"Product list"}
            edit={true}
            del
            delText
            loading={false}
            showIcons={false}
          />
          {error && (
            <ErrorAlert
              text={
                "There was an error while loading the product list, please try again"
              }
            />
          )}
          <>
            <MuiThemeProvider theme={THEME}>
              <div style={{ maxWidth: "100%" }}>
                <MaterialTable
                  columns={columns}
                  data={getNewItems}
                  title={null}
                  tableRef={tableRef}
                  options={{
                    exportButton: true,
                    selection: true,
                    sorting: true,
                    filtering: true,
                    pageSize: 30,
                    pageSizeOptions: [30],
                    search: false,
                    exportAllData: true,
                    actionsColumnIndex: -1,
                    exportCsv,
                  }}
                  actions={actions}
                  localization={{
                    header: {
                      actions: "",
                    },
                    toolbar: {
                      nRowsSelected: "{0} product(s) selected",
                    },
                    body: {
                      emptyDataSourceMessage: "No records to display",
                      filterRow: {
                        filterTooltip: "Filter",
                      },
                    },
                  }}
                  onSelectionChange={(data) => selectMultipleRows(data)}
                  components={{
                    Toolbar: (propsToolbar) => (
                      <div>
                        <MTableToolbar {...propsToolbar} />
                        <CustomToolbarSelect
                          selectedRows={selectedRowsForMultipleEdit}
                          location={props}
                        />
                      </div>
                    ),
                  }}
                />
              </div>
            </MuiThemeProvider>
          </>
        </Card>
      </Container>
    </>
  );
}

export default ProductList;

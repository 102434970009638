import React, { useState, useEffect } from "react";
import { Badge, Card, Container } from "reactstrap";
import SimpleHeader from "../../componentsTheme/Headers/SimpleHeader.js";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import getOffers from "../../services/offers";
import Loader from "../../utils/loader";
import MUIDataTable from "mui-datatables";
import ErrorAlert from "../../utils/errorAlert";
import IconButton from "@material-ui/core/IconButton";
import Header from "../../utils/header";

function List(props) {
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState("1");
  const [count, setCount] = useState("");
  const [error, setError] = useState(false);

  const THEME = createMuiTheme({
    typography: {
      fontFamily: "Poppins",
    },
  });

  useEffect(() => {
    setLoading(true);
    getOffers(pageNumber).then((item) => {
      setOffers(item);
      setLoading(false);
    });
  }, [pageNumber]);

  const options = {
    count: count,
    rowsPerPage: 10,
    selectableRows: "none",
    responsive: "vertical",
    rowsPerPageOptions: [],
    download: false,
    print: false,
  };

  const columns = [
    {
      name: "@id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value.slice(8, 20)}</>;
        },
      },
    },
    {
      name: "product",
      label: "PRODUCT",
      options: {
        filter: true,
        sort: true,
        filterType: "textField",
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value.name}</>;
        },
      },
    },
    {
      name: "product",
      label: "MANUFACTURER",
      options: {
        filter: true,
        sort: true,
        filterType: "textField",
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value.manufacturer.name}</>;
        },
      },
    },
    {
      name: "startDate",
      label: "START DATE",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{new Date(value).toLocaleDateString()}</>;
        },
      },
    },
    {
      name: "endDate",
      label: "END DATE",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{new Date(value).toLocaleDateString()}</>;
        },
      },
    },
    {
      name: "product",
      label: "STANDARD PRICE",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {value.price.price} {value.price.priceCurrency}
            </>
          );
        },
      },
    },
    {
      name: "newPrice",
      label: "NEW PRICE",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return `${value.price} ${value.priceCurrency}`;
        },
      },
    },
    {
      name: "endDate",
      label: "STATUS",
      options: {
        filter: false,
        sort: true,
        filterOptions: {
          names: ["Active", "Ended"],
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          if (new Date(value) > new Date()) {
            return (
              <Badge color="" className="badge-dot mr-2">
                <i className={"bg-success"} />
                <span style={{ fontSize: 14 }}>Active</span>
              </Badge>
            );
          } else {
            return (
              <Badge color="" className="badge-dot mr-2">
                <i className={"bg-danger"} />
                <span style={{ fontSize: 14 }}>Ended</span>
              </Badge>
            );
          }
        },
      },
    },
    {
      name: "DETAILS",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ width: "30px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IconButton
                  size="small"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={() =>
                    props.history.push(
                      `show/${encodeURIComponent(tableMeta.rowData[0])}`,
                      {
                        offer: tableMeta.rowData[0],
                      }
                    )
                  }
                >
                  <MoreVertIcon fontSize="small" />
                </IconButton>
              </div>
            </div>
          );
        },
      },
    },
  ];

  return (
    <>
      <SimpleHeader name="All offers" parentName="Offers" />
      <Container className="mt--6" fluid>
        <Card>
          <Header
            title={"Offer list"}
            edit={true}
            del
            delText
            loading={false}
            showIcons={false}
          />
          {loading && <Loader />}
          {error && (
            <ErrorAlert
              text={
                "There was an error while loading the offer list, please try again"
              }
            />
          )}
          {!loading && (
            <>
              <MuiThemeProvider theme={THEME}>
                <MUIDataTable
                  title={""}
                  data={offers}
                  columns={columns}
                  options={options}
                />
              </MuiThemeProvider>
            </>
          )}
        </Card>
      </Container>
    </>
  );
}

export default List;

import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import SimpleHeader from "../../componentsTheme/Headers/SimpleHeader.js";

class OrderForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string,
  };

  renderField = (data) => {
    data.input.className = "form-control";

    const isInvalid = data.meta.touched && !!data.meta.error;
    if (isInvalid) {
      data.input.className += " is-invalid";
      data.input["aria-invalid"] = true;
    }

    if (this.props.error && data.meta.touched && !data.meta.error) {
      data.input.className += " is-valid";
    }

    return (
      <div className={`form-group`}>
        <input
          {...data.input}
          type={data.type}
          step={data.step}
          required={data.required}
          placeholder={data.placeholder}
          id={`order_${data.input.name}`}
        />
        {isInvalid && <div className="invalid-feedback">{data.meta.error}</div>}
      </div>
    );
  };

  render() {
    return (
      <>
        <SimpleHeader name="New order" parentName="Orders" />
        <Container className="mt--6" fluid>
          <Row>
            <Col lg="6">
              <div className="card-wrapper">
                <Card>
                  <CardHeader>
                    <h3 className="mb-0">Order details</h3>
                  </CardHeader>
                  <CardBody>
                    <Form onSubmit={this.props.handleSubmit}>
                      <FormGroup className="row">
                        <Label
                          className="form-control-label"
                          htmlFor="example-text-input"
                          md="2"
                        >
                          External Ids
                        </Label>
                        <Col md="10">
                          <Field
                            component={this.renderField}
                            name="externalIds"
                            type="text"
                            placeholder="The ids that represent this order in external platforms."
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label
                          className="form-control-label"
                          htmlFor="example-search-input"
                          md="2"
                        >
                          Customer
                        </Label>
                        <Col md="10">
                          <Field
                            component={this.renderField}
                            name="customer"
                            type="text"
                            placeholder="The customer that has placed this order"
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label
                          className="form-control-label"
                          htmlFor="example-email-input"
                          md="2"
                        >
                          Total Price
                        </Label>
                        <Col md="10">
                          <Field
                            component={this.renderField}
                            name="totalPrice"
                            type="text"
                            placeholder="The total price of the order"
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label
                          className="form-control-label"
                          htmlFor="example-url-input"
                          md="2"
                        >
                          Shipping Price
                        </Label>
                        <Col md="10">
                          <Field
                            component={this.renderField}
                            name="shippingPrice"
                            type="text"
                            placeholder="The shipping price of the order"
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label
                          className="form-control-label"
                          htmlFor="example-tel-input"
                          md="2"
                        >
                          Shipping Address
                        </Label>
                        <Col md="10">
                          <Field
                            component={this.renderField}
                            name="shippingAddress"
                            type="text"
                            placeholder="The address the items should ship to"
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label
                          className="form-control-label"
                          htmlFor="example-password-input"
                          md="2"
                        >
                          Billing Address
                        </Label>
                        <Col md="10">
                          <Field
                            component={this.renderField}
                            name="billingAddress"
                            type="text"
                            placeholder="The address the customer should be billed on"
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label
                          className="form-control-label"
                          htmlFor="example-number-input"
                          md="2"
                        >
                          Variations
                        </Label>
                        <Col md="10">
                          <Field
                            component={this.renderField}
                            name="variations"
                            type="text"
                            placeholder="The items that have been ordered"
                            normalize={(v) => (v === "" ? [] : v.split(","))}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label
                          className="form-control-label"
                          htmlFor="example-datetime-local-input"
                          md="2"
                        >
                          Products
                        </Label>
                        <Col md="10">
                          <Field
                            component={this.renderField}
                            name="products"
                            type="text"
                            placeholder=""
                            normalize={(v) => (v === "" ? [] : v.split(","))}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label
                          className="form-control-label"
                          htmlFor="example-date-input"
                          md="2"
                        >
                          Status
                        </Label>
                        <Col md="10">
                          <Field
                            component={this.renderField}
                            name="status"
                            type="text"
                            placeholder="The status of the order"
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label
                          className="form-control-label"
                          htmlFor="example-month-input"
                          md="2"
                        >
                          Payment Method
                        </Label>
                        <Col md="10">
                          <Field
                            component={this.renderField}
                            name="paymentMethod"
                            type="text"
                            placeholder="The payment method that was used"
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label
                          className="form-control-label"
                          htmlFor="example-week-input"
                          md="2"
                        >
                          Edited At
                        </Label>
                        <Col md="10">
                          <Field
                            component={this.renderField}
                            name="editedAt"
                            type="dateTime"
                            placeholder=""
                          />
                        </Col>
                      </FormGroup>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Col>
            <Col lg="6">
              <div className="card-wrapper">
                <Card>
                  <CardHeader>
                    <h3 className="mb-0">Text inputs</h3>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="exampleFormControlTextarea2"
                        >
                          Basic textarea
                        </label>
                        <Input
                          id="exampleFormControlTextarea2"
                          rows="3"
                          type="textarea"
                        />
                      </FormGroup>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="exampleFormControlTextarea3"
                        >
                          Unresizable textarea
                        </label>
                        <Input
                          id="exampleFormControlTextarea3"
                          resize="none"
                          rows="3"
                          type="textarea"
                        />
                      </FormGroup>
                    </Form>
                    <button type="submit" className="btn btn-success">
                      Submit
                    </button>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default reduxForm({
  form: "order",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(OrderForm);

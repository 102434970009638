import React, { useState, useEffect } from "react";
import { Label, FormGroup, Form, Input, Col } from "reactstrap";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@material-ui/core/";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import getManufacturers, {
  getDetails,
  getManufacturerName,
  getManufacturerCategories,
  getManufacturerSubcategories,
} from "../../services/manufacturers";
import getCategories, { getSubcategories } from "../../services/categories";
import COLORS, { THEME } from "../../utils/colors";
import {
  PRODUCT_VISIBILITY_OPTIONS,
  PRODUCT_UM_OPTIONS,
  PRODUCT_CURRENCY_OPTIONS,
  PRODUCT_TVA,
} from "../../services/products";

import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";

function ProductDetailsContainer({
  product,
  setProduct,
  editProduct,
  setLoadingDeliveryOptions,
  errorName,
  setErrorName,
  errorUm,
  setErrorUm,
  errorSku,
  setErrorSku,
  errorPrice,
  setErrorPrice,
  errorPriceCurrency,
  setErrorPriceCurrency,
  errorMan,
  setErrorMan,
  errorCateg,
  setErrorCateg,
  errorSubcateg,
  setErrorSubcateg,
  errorTax,
  setErrorTax,
  role,
}) {
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(false);
  const [manufacturerOptions, setManufacturerOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subcategoryOptions, setSubcategoryOptions] = useState([]);

  const ITooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "white",
      color: "black",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      fontFamily: "Poppins, sans-serif",
      border: "1px solid #dadde9",
    },
  }))(Tooltip);

  useEffect(() => {
    if (role === "admin") {
      getManufacturers(1)
        .then((man) => {
          let list = [];
          man["hydra:member"].forEach((item) => {
            let selectableList = {};
            selectableList.label = item["companyName"];
            selectableList.value = item["@id"];
            list.push(selectableList);
          });
          setManufacturerOptions(list);
        })
        .catch(() => showErrorAlert());
      getCategories(1)
        .then((categ) => {
          let list = [];
          categ.forEach((item) => {
            let selectableList = {};
            selectableList.label = item["name"];
            selectableList.value = item["@id"];
            list.push(selectableList);
          });
          setCategoryOptions(list);
        })
        .catch(() => showErrorAlert());
      if (product.categories.value) {
        getSubcategories(product.categories.value)
          .then((res) => {
            let list = [];
            res.forEach((item) => {
              let selectableList = {};
              selectableList.label = item["name"];
              selectableList.value = item["@id"];
              list.push(selectableList);
            });
            setSubcategoryOptions(list);
          })
          .catch(() => showErrorAlert());
      }
    }

    if (role === "manufacturer") {
      getManufacturerName().then((item) => {
        let currentManuf = {};
        currentManuf.label = item["name"];
        currentManuf.value = item["@id"];
        setProduct({
          ...product,
          manufacturer: currentManuf,
        });
      });
      getManufacturerCategories().then((categ) => {
        let list = [];
        categ.forEach((item) => {
          let selectableList = {};
          selectableList.label = item["name"];
          selectableList.value = item["@id"];
          list.push(selectableList);
        });
        setCategoryOptions(list);
      });
      if (product.categories.value) {
        getManufacturerSubcategories(product.categories.value)
          .then((res) => {
            let list = [];
            res.forEach((item) => {
              let selectableList = {};
              selectableList.label = item["name"];
              selectableList.value = item["@id"];
              list.push(selectableList);
            });
            setSubcategoryOptions(list);
          })
          .catch(() => showErrorAlert());
      }
    }
  }, []);

  function changePrice(event) {
    let newPrice = { ...product };
    newPrice.price.price = event.target.value;
    setProduct(newPrice);
    let isValid = event.target.value.length > 0;
    if (event.target.name == "price") {
      setErrorPrice(!isValid);
    }
  }

  function changePriceCurrency(selectedOption) {
    let newPrice = { ...product };
    newPrice.price.priceCurrency = selectedOption ? selectedOption : "";
    setProduct(newPrice);
    setErrorPriceCurrency(false);
  }

  function changeTVA(selectedOption) {
    let newTva = { ...product };
    newTva.price.vatPercentage = selectedOption ? selectedOption : "";
    setProduct(newTva);
    setErrorTax(false);
  }

  function handleChangeManufacturer(selectedOption) {
    setLoadingDeliveryOptions(true);
    setProduct({
      ...product,
      manufacturer: selectedOption,
    });
    getDetails(selectedOption.value).then((item) => {
      item.label = item.companyName;
      item.value = item["@id"];
      setProduct({
        ...product,
        manufacturer: item,
      });
      setLoadingDeliveryOptions(false);
      setErrorMan(false);
    });
  }

  function handleChangeCategories(selectedOption) {
    if (role === "admin") {
      setProduct({
        ...product,
        categories: selectedOption,
        subcategory: "",
      });
      setErrorCateg(false);
      let list = [];
      getSubcategories(selectedOption.value)
        .then((res) => {
          res.forEach((item) => {
            let selectableList = {};
            selectableList.label = item["name"];
            selectableList.value = item["@id"];
            list.push(selectableList);
          });
          setSubcategoryOptions(list);
        })
        .catch(() => showErrorAlert());
    }
    if (role === "manufacturer") {
      setProduct({
        ...product,
        categories: selectedOption,
        subcategory: "",
      });
      setErrorCateg(false);
      let list = [];
      getManufacturerSubcategories(selectedOption.value)
        .then((res) => {
          res.forEach((item) => {
            let selectableList = {};
            selectableList.label = item["name"];
            selectableList.value = item["@id"];
            list.push(selectableList);
          });
          setSubcategoryOptions(list);
        })
        .catch(() => showErrorAlert());
    }
  }

  function handleChangeSubcategories(selectedOption) {
    setProduct({
      ...product,
      subcategory: selectedOption,
    });
    setErrorSubcateg(false);
  }

  function handleChangeVisibility(event) {
    setProduct({
      ...product,
      visibility: event.target.value,
    });
  }

  function handleChangeUm(selectedOption) {
    setProduct({
      ...product,
      unitOfMeasurement: selectedOption ? selectedOption : "",
    });
    setErrorUm(false);
  }

  function showErrorAlert() {
    setError(true);
    setSaved(false);
  }

  function changeInfo(event) {
    setProduct({
      ...product,
      [event.target.name]: event.target.value,
    });
    let isValid = event.target.value.length > 0;
    switch (event.target.name) {
      case "name":
        setErrorName(!isValid);
        break;
      case "unitOfMeasurement":
        setErrorUm(!isValid);
        break;
      case "sku":
        setErrorSku(!isValid);
        break;
    }
  }

  return (
    <>
      <Form>
        <FormGroup className="row">
          <Label
            className="form-control-label"
            htmlFor="example-text-input"
            md="2"
          >
            Name
          </Label>
          <Col md="10">
            <Input
              disabled={editProduct ? false : true}
              style={{
                backgroundColor: COLORS.WHITE,
                borderColor: errorName ? THEME.DANGER : "",
              }}
              value={product["name"]}
              name="name"
              type="text"
              placeholder="The name of the product"
              required={true}
              onChange={(event) => changeInfo(event)}
            />
          </Col>
        </FormGroup>
        <FormGroup className="row">
          <Label
            className="form-control-label"
            htmlFor="example-week-input"
            md="2"
          >
            SKU
          </Label>
          <Col md="10">
            <Input
              disabled={editProduct ? false : true}
              onChange={(event) => changeInfo(event)}
              style={{
                backgroundColor: COLORS.WHITE,
                borderColor: errorSku ? THEME.DANGER : "",
              }}
              value={product["sku"]}
              name="sku"
              type="text"
              placeholder="SKU of the product"
            />
          </Col>
        </FormGroup>
        <FormGroup className="row">
          <Label
            className="form-control-label"
            htmlFor="example-email-input"
            md="2"
          >
            Slogan
          </Label>
          <Col md="10">
            <Input
              disabled={editProduct ? false : true}
              onChange={(event) => changeInfo(event)}
              style={{ backgroundColor: COLORS.WHITE }}
              value={product["slogan"]}
              name="slogan"
              type="textarea"
              rows="3"
              placeholder="A slogan or motto associated with the product"
            />
          </Col>
        </FormGroup>
        <FormGroup className="row">
          <Label
            className="form-control-label"
            htmlFor="example-tel-input"
            md="2"
          >
            Manufacturer
          </Label>
          <Col md="10">
            <Select
              name="manufacturer"
              styles={{
                control: (base, state) => ({
                  ...base,
                  backgroundColor: COLORS.WHITE,
                  fontSize: 14,
                  border: errorMan ? "1px solid red" : "1px solid lightgray",
                }),
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  neutral50: "#8898aa",
                },
              })}
              isDisabled={editProduct ? false : true}
              value={product.manufacturer}
              onChange={handleChangeManufacturer}
              options={manufacturerOptions}
            />
          </Col>
        </FormGroup>
        <FormGroup className="row">
          <Label
            className="form-control-label"
            htmlFor="example-number-input"
            md="2"
          >
            Category
          </Label>
          <Col md="10">
            <Select
              styles={{
                control: (base, state) => ({
                  ...base,
                  backgroundColor: COLORS.WHITE,
                  fontSize: 14,
                  border: errorCateg ? "1px solid red" : "1px solid lightgray",
                }),
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  neutral50: "#8898aa",
                },
              })}
              isDisabled={editProduct ? false : true}
              name="categories"
              value={product.categories}
              onChange={handleChangeCategories}
              options={categoryOptions}
            />
          </Col>
        </FormGroup>
        <FormGroup className="row">
          <Label
            className="form-control-label"
            htmlFor="example-text-input"
            md="2"
          >
            Subcategory
          </Label>
          <Col md="10">
            <Select
              styles={{
                control: (base, state) => ({
                  ...base,
                  backgroundColor: COLORS.WHITE,
                  fontSize: 14,
                  border: errorSubcateg
                    ? "1px solid red"
                    : "1px solid lightgray",
                }),
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  neutral50: "#8898aa",
                },
              })}
              isDisabled={editProduct ? false : true}
              name="subcategories"
              value={product.subcategory}
              onChange={handleChangeSubcategories}
              options={subcategoryOptions}
            />
          </Col>
        </FormGroup>
        <FormGroup className="row">
          <Label
            className="form-control-label"
            htmlFor="example-week-input"
            md="2"
          >
            Stock count
          </Label>
          <Col md="10">
            <Input
              disabled={editProduct ? false : true}
              onChange={(event) => changeInfo(event)}
              style={{ backgroundColor: COLORS.WHITE }}
              value={product["stockCount"]}
              name="stockCount"
              type="number"
              placeholder=""
            />
          </Col>
        </FormGroup>
        <FormGroup className="row">
          <Label
            className="form-control-label"
            htmlFor="example-url-input"
            md="2"
          >
            UM
          </Label>
          <Col md="10">
            <CreatableSelect
              isClearable
              placeholder="Select unit of measurement"
              styles={{
                control: (base, state) => ({
                  ...base,
                  fontSize: "0.875rem",
                  backgroundColor: COLORS.WHITE,
                  border: errorUm ? "1px solid red" : "1px solid lightgray",
                }),
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  neutral50: "#8898aa",
                },
              })}
              name="unitOfMeasurement"
              value={product.unitOfMeasurement}
              onChange={handleChangeUm}
              options={PRODUCT_UM_OPTIONS}
              isDisabled={editProduct ? false : true}
            />
          </Col>
        </FormGroup>
        <FormGroup className="row">
          <Label
            className="form-control-label"
            htmlFor="example-week-input"
            md="2"
          >
            Price
          </Label>
          <Col md="10">
            <Input
              disabled={editProduct ? false : true}
              onChange={(event) => changePrice(event)}
              style={{
                backgroundColor: COLORS.WHITE,
                borderColor: errorPrice ? THEME.DANGER : "",
              }}
              value={`${product.price.price}`}
              name="price"
              type="number"
              placeholder=""
            />
          </Col>
        </FormGroup>
        <FormGroup className="row">
          <Label
            className="form-control-label"
            htmlFor="example-week-input"
            md="2"
          >
            Price currency
          </Label>
          <Col md="10">
            <CreatableSelect
              placeholder="Select currency"
              styles={{
                control: (base, state) => ({
                  ...base,
                  fontSize: "0.875rem",
                  backgroundColor: COLORS.WHITE,
                  border: errorPriceCurrency
                    ? "1px solid red"
                    : "1px solid lightgray",
                }),
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  neutral50: "#8898aa",
                },
              })}
              name="priceCurrency"
              value={product.price.priceCurrency}
              onChange={changePriceCurrency}
              options={PRODUCT_CURRENCY_OPTIONS}
              isDisabled={editProduct ? false : true}
            />
          </Col>
        </FormGroup>
        <FormGroup className="row">
          <Label
            className="form-control-label"
            htmlFor="example-week-input"
            md="2"
          >
            TVA
          </Label>
          <Col md="10">
            <CreatableSelect
              placeholder="Select TVA"
              styles={{
                control: (base, state) => ({
                  ...base,
                  fontSize: "0.875rem",
                  backgroundColor: COLORS.WHITE,
                  border: errorTax ? "1px solid red" : "1px solid lightgray",
                }),
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  neutral50: "#8898aa",
                },
              })}
              name="tax"
              value={product.price.vatPercentage}
              onChange={changeTVA}
              options={PRODUCT_TVA}
              isDisabled={editProduct ? false : true}
            />
          </Col>
        </FormGroup>
        <FormGroup className="row">
          <Label
            className="form-control-label"
            htmlFor="example-week-input"
            md="2"
          >
            Priority
            <ITooltip
              title={
                <React.Fragment>
                  <Typography style={{ fontFamily: "Poppins" }} color="inherit">
                    Priority description
                  </Typography>
                  The priority value is related to the importance of the
                  product.{" "}
                </React.Fragment>
              }
            >
              <InfoIcon style={{ marginLeft: "10px" }} />
            </ITooltip>
          </Label>

          <Col md="10">
            <Input
              disabled={editProduct ? false : true}
              onChange={(event) => changeInfo(event)}
              style={{
                backgroundColor: COLORS.WHITE,
              }}
              value={product["priority"]}
              name="priority"
              type="number"
              placeholder="The priority of the product"
            />
          </Col>
        </FormGroup>

        <FormGroup className="row">
          <Label
            className="form-control-label"
            htmlFor="example-week-input"
            md="2"
          >
            Visibility
          </Label>
          <Col md="10">
            <FormControl
              component="fieldset"
              style={{ fontFamily: "Poppins" }}
              disabled={editProduct ? false : true}
            >
              <RadioGroup
                row
                value={product.visibility}
                onChange={(event) => handleChangeVisibility(event)}
              >
                {PRODUCT_VISIBILITY_OPTIONS.map((item, index) => {
                  return (
                    <div key={index}>
                      <FormControlLabel
                        value={item.value}
                        control={<Radio color="primary" />}
                        label={item.label}
                      />
                    </div>
                  );
                })}
              </RadioGroup>
            </FormControl>
          </Col>
        </FormGroup>
      </Form>
    </>
  );
}

export default ProductDetailsContainer;

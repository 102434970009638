import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Label,
  Form,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import SimpleHeader from "../../../componentsTheme/Headers/SimpleHeader.js";
import Select from "react-select";
import Header from "../../../utils/header";
import Loader from "../../../utils/loader";
import { getProviderName } from "../../../services/providers";

function Profile(props) {
  const [provider, setProvider] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    password: "",
    licensePlate: "",
    vehicleType: "",
    additionalInfo: "",
  });

  const [selectedOption, setSelectedOption] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    setLoading(true);
    getProviderName()
      .then((item) => {
        setProvider(item);
        setSelectedOption({
          value: item.vehicleType,
          label: item.vehicleType,
        });
        setLoading(false);
      })
      .catch((error) => {
        showErrorDialog(setError);
        setErrorText(
          "There was an error while loading the informations, please try again"
        );
      });
  }, []);

  function showErrorDialog() {
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 3000);
  }

  return (
    <>
      <SimpleHeader name="My profile" parentName="Profile" />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <Header
                  title={"My profile"}
                  edit={true}
                  del
                  delText
                  loading={false}
                  showIcons={false}
                />
                {loading && <Loader />}
                {!loading && (
                  <CardBody>
                    <Form>
                      <FormGroup className="row">
                        <Label
                          className="form-control-label"
                          htmlFor="example-text-input"
                          md="2"
                        >
                          First name
                        </Label>
                        <Col md="6">
                          <Input
                            disabled
                            style={{ backgroundColor: "white" }}
                            value={provider.firstName}
                            name="firstName"
                            type="text"
                            placeholder="The provider's first name"
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup className="row">
                        <Label
                          className="form-control-label"
                          htmlFor="example-text-input"
                          md="2"
                        >
                          Last name
                        </Label>
                        <Col md="6">
                          <Input
                            disabled
                            style={{ backgroundColor: "white" }}
                            value={provider.lastName}
                            name="lastName"
                            type="text"
                            placeholder="The provider's last name"
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup className="row">
                        <Label
                          className="form-control-label"
                          htmlFor="example-text-input"
                          md="2"
                        >
                          Phone number
                        </Label>
                        <Col md="6">
                          <Input
                            disabled
                            style={{ backgroundColor: "white" }}
                            value={provider.phoneNumber}
                            name="phoneNumber"
                            type="number"
                            placeholder="The provider's phone number"
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup className="row">
                        <Label
                          className="form-control-label"
                          htmlFor="example-text-input"
                          md="2"
                        >
                          Email
                        </Label>
                        <Col md="6">
                          <Input
                            disabled
                            style={{ backgroundColor: "white" }}
                            value={provider.email}
                            name="email"
                            type="text"
                            placeholder="The provider's email"
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup className="row">
                        <Label
                          className="form-control-label"
                          htmlFor="example-text-input"
                          md="2"
                        >
                          Vehicle Type
                        </Label>
                        <Col md="6">
                          <Select
                            isDisabled
                            name="vehicleType"
                            defaultValue={selectedOption}
                            placeholder="The type of the vehicle the provider uses"
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                backgroundColor: "white",
                                fontSize: 14,
                              }),
                            }}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup className="row">
                        <Label
                          className="form-control-label"
                          htmlFor="example-text-input"
                          md="2"
                        >
                          License plate
                        </Label>
                        <Col md="6">
                          <Input
                            disabled
                            style={{ backgroundColor: "white" }}
                            value={provider.licensePlate}
                            name="licensePlate"
                            type="text"
                            placeholder="The license plate if the provider owns a vehicle"
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup className="row">
                        <Label
                          className="form-control-label"
                          htmlFor="example-text-input"
                          md="2"
                        >
                          Additional informations
                        </Label>
                        <Col md="6">
                          <Input
                            disabled
                            style={{ backgroundColor: "white" }}
                            rows="3"
                            value={provider.additionalInfo}
                            name="additionalInfo"
                            type="textarea"
                            placeholder="Other informations"
                          />
                        </Col>
                      </FormGroup>
                    </Form>
                  </CardBody>
                )}
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Profile;

import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import AuthHeader from "../../componentsTheme/Headers/AuthHeader.js";
import COLORS from "../../utils/colors";
import ErrorAlert from "../../utils/errorAlert";

class Login extends React.Component {
  state = {
    email: "",
    password: "",
    error: false,
  };

  doLogin = async () => {
    const URL = process.env.REACT_APP_API_ENTRYPOINT + "/login/admin";
    const loginResponse = await fetch(URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: this.state.email,
        password: this.state.password,
      }),
    }).catch(() => {
      this.setState({
        error: true,
      });
      return;
    });
    if (!loginResponse) {
      this.setState({
        error: true,
      });
      return;
    }

    if (!loginResponse.ok) {
      this.setState({
        error: true,
      });
      return;
    }
    const loginResult = await loginResponse.json();

    if (!loginResult["token"]) {
      this.setState({
        error: true,
      });
      return;
    }
    localStorage.setItem("token", loginResult["token"]);
    localStorage.setItem("refresh_token", loginResult["refresh_token"]);
    localStorage.setItem("role", "admin");
    this.props.history.push("/admin/orders", { user: this.state.email });
  };

  showDialog = () => {
    window.location.href = "https://api.approzar.com/reset-password";
  };

  render() {
    return (
      <>
        <AuthHeader />
        <Container className="mt--1 pb-5" >
          <Row className="justify-content-center">
            <Col lg="5" md="7" style={{ marginTop: 15 }}>
              <Card className="bg-secondary border-0 mb-0">
                <CardHeader className="bg-transparent pb-5">
                  <div className="text-muted text-center mt-2 mb-3">
                    <small>
                      Sign in <b>ADMIN</b>
                    </small>
                  </div>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                  <Form role="form">
                    <FormGroup
                      className={classnames("mb-3", {
                        focused: this.state.focusedEmail,
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          onChange={(event) =>
                            this.setState({ email: event.target.value })
                          }
                          value={this.state.email}
                          placeholder="Email"
                          type="email"
                          onFocus={() => this.setState({ focusedEmail: true })}
                          onBlur={() => this.setState({ focusedEmail: false })}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup
                      className={classnames({
                        focused: this.state.focusedPassword,
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          onChange={(event) =>
                            this.setState({ password: event.target.value })
                          }
                          value={this.state.password}
                          placeholder="Password"
                          type="password"
                          onFocus={() =>
                            this.setState({ focusedPassword: true })
                          }
                          onBlur={() =>
                            this.setState({ focusedPassword: false })
                          }
                        />
                      </InputGroup>
                    </FormGroup>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        style={{ fontSize: 14, color: COLORS.BLUE }}
                        onClick={this.showDialog}
                      >
                        Forgot Password
                      </Button>
                    </div>
                    <div className="text-center">
                      <Button
                        className="my-4"
                        type="button"
                        onClick={this.doLogin}
                        style={{
                          backgroundColor: COLORS.BLUE,
                          color: COLORS.WHITE,
                        }}
                      >
                        Sign in
                      </Button>
                    </div>
                    {this.state.error && (
                      <ErrorAlert
                        text={
                          "There was an error. If you are a provider or a manufacturer, please click the link below."
                        }
                      />
                    )}
                  </Form>
                </CardBody>
              </Card>
              <Row className="mt-3">
                <Col xs="6"></Col>
                <Col className="text-right" xs="12">
                  <Link to="/auth/login/provider" className="text-light">
                    <small>Login as provider or manufacturer</small>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Login;

import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import Checkbox from "@material-ui/core/Checkbox";
import CreatableSelect from "react-select/creatable";
import SimpleHeader from "../../componentsTheme/Headers/SimpleHeader.js";
import SuccessAlert from "../../utils/succesAlert";
import ErrorAlert from "../../utils/errorAlert";
import Loader from "../../utils/loader";
import COLORS from "../../utils/colors";
import Header from "../../utils/header";
import ImgContainer from "../../utils/imgContainer";
import {
  getDetails,
  updateManufacturerInfo,
  deleteManufacturer,
  MANUFACTURER_DELIVERY_TIME,
  MANUFACTURER_LOGO,
} from "../../services/manufacturers";

function Show(props) {
  const [manufacturer, setManufacturer] = useState({
    name: "",
    legalName: "",
    email: "",
    telephone: "",
    address: {
      label: "",
      city: "",
      county: "",
      country: "",
      streetAddress: "",
      addressAdditionalInfo: "",
    },
    additionalInfo: "",
    deliveryTime: "",
    deliveryPrice: "",
    slogan: "",
    transportExpressExist: false,
    deliveryPriceExpress: "",
    deliveryTimeExpress: "",
    logo: { contentUrl: "" },
    vatId: "",
    regCom: "",
    bankName: "",
    bankAccount: "",
    vatPayer: false,
    isVerified: false,
  });

  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [errorUpdate, setErrorUpdate] = useState(false);
  const [errorName, setErrorName] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorCity, setErrorCity] = useState("");
  const [errorSlogan, setErrorSlogan] = useState("");
  const [errorPhoneNumber, setErrorPhoneNumber] = useState("");
  const [selectedDeliveryTime, setSelectedDeliveryTime] = useState("");
  const [
    selectedExpressDeliveryTime,
    setSelectedExpressDeliveryTime,
  ] = useState("");

  const isFormValid =
    manufacturer.name.trim().length > 4 &&
    manufacturer.email.trim().length > 4 &&
    manufacturer.address.city.trim().length > 0 &&
    manufacturer.telephone.length > 4 &&
    manufacturer.slogan.length < 255;

  useEffect(() => {
    setLoading(true);
    getDetails(props.location.state.manufacturer)
      .then((item) => {
        if (!item.logo) {
          item.logo = {
            contentUrl: "",
          };
        }
        if (!item.slogan) {
          item.slogan = "";
        }
        setManufacturer(item);
        setSelectedDeliveryTime({
          value: item.deliveryTime,
          label: `${item.deliveryTime} hours`,
        });
        setSelectedExpressDeliveryTime({
          value: item.deliveryTimeExpress,
          label: `${item.deliveryTimeExpress} hours`,
        });
        setLoading(false);
      })
      .catch(() => setError(true));
  }, []);

  function handleDeliveryChange(selectedDeliveryOption) {
    setSelectedDeliveryTime(selectedDeliveryOption);
    setManufacturer({
      ...manufacturer,
      deliveryTime: selectedDeliveryOption
        ? selectedDeliveryOption.value
        : null,
    });
  }

  function handleChangeDeliveryExpress(event) {
    setManufacturer({
      ...manufacturer,
      transportExpressExist: event.target.checked,
      deliveryPriceExpress: "",
      deliveryTimeExpress: "",
    });
  }

  function handleExpressDeliveryTimeChange(selectedExpressDeliveryTime) {
    setSelectedExpressDeliveryTime(selectedExpressDeliveryTime);
    setManufacturer({
      ...manufacturer,
      deliveryTimeExpress: selectedExpressDeliveryTime
        ? selectedExpressDeliveryTime.value
        : null,
    });
  }

  function handleChangeVatPayer(event) {
    setManufacturer({
      ...manufacturer,
      vatPayer: event.target.checked,
    });
  }

  function handleChangeIsVerified(event) {
    setManufacturer({
      ...manufacturer,
      isVerified: event.target.checked,
    });
  }

  function addInfo(event) {
    setManufacturer({
      ...manufacturer,
      [event.target.name]: event.target.value,
    });
  }

  function addInfoAddress(event) {
    let newAddress = { ...manufacturer.address };
    newAddress[event.target.name] = event.target.value;
    setManufacturer({
      ...manufacturer,
      address: newAddress,
    });
  }

  function showSaveDialog() {
    setSaved(true);
    setTimeout(() => {
      setSaved(false);
    }, 5000);
  }

  function showErrorDialog() {
    setErrorUpdate(true);
    setTimeout(() => {
      setErrorUpdate(false);
    }, 3000);
  }

  function setErrorBorder() {
    manufacturer.name.trim().length < 5
      ? setErrorName("error")
      : setErrorName("");
    manufacturer.email.trim().length < 5
      ? setErrorEmail("error")
      : setErrorEmail("");
    manufacturer.address.city.trim().length < 1
      ? setErrorCity("error")
      : setErrorCity("");
    manufacturer.telephone.length < 5
      ? setErrorPhoneNumber("error")
      : setErrorPhoneNumber("");
    manufacturer.slogan.length >= 255
      ? setErrorSlogan("error")
      : setErrorSlogan("");
  }

  function del() {
    let manId = manufacturer["@id"];
    deleteManufacturer(manId)
      .then((res) => {
        res.ok
          ? props.history.push(`/admin/manufacturers/`)
          : showErrorDialog();
      })
      .catch(() => {
        showErrorDialog();
      });
  }

  async function updateManufacturer(event) {
    event.preventDefault();
    if (isFormValid) {
      let manId = manufacturer["@id"];
      let address = {
        city: manufacturer.address.city,
        county: manufacturer.address.county,
        country: manufacturer.address.country,
        streetAddress: manufacturer.address.streetAddress,
        additionalInfo: manufacturer.address.addressAdditionalInfo,
      };
      let image =
        manufacturer.logo.contentUrl !== "" ? manufacturer.logo : null;
      updateManufacturerInfo(
        manId,
        manufacturer.name,
        manufacturer.legalName,
        manufacturer.telephone,
        manufacturer.email,
        address,
        manufacturer.additionalInfo,
        manufacturer.slogan,
        manufacturer.transportExpressExist,
        Number(manufacturer.deliveryTime),
        Number(manufacturer.deliveryPrice),
        Number(manufacturer.deliveryTimeExpress),
        Number(manufacturer.deliveryPriceExpress),
        manufacturer.vatId,
        manufacturer.regCom,
        manufacturer.bankAccount,
        manufacturer.bankName,
        image,
        manufacturer.vatPayer,
        manufacturer.isVerified
      ).then((res) => {
        if (res.status > 299) {
          res.json().then((text) => {
            let errorMessage = JSON.parse(text["hydra:description"]).message;
            showErrorDialog();
            setErrorText(errorMessage);
          });
        } else {
          showSaveDialog();
          setSaved(true);
          setErrorName("");
          setErrorEmail("");
          setErrorCity("");
          setErrorPhoneNumber("");
          setErrorSlogan("");
        }
      });
    } else {
      setErrorBorder();
      showErrorDialog();
      if (manufacturer.slogan.length >= 255) {
        setErrorText("The slogan must be max. 255 characters long");
      } else if (manufacturer.name.length <= 5) {
        setErrorText(
          "The manufacturer name must be at least 5 characters long"
        );
      } else {
        setErrorText("Please enter all of the required informations");
      }
    }
  }

  function removeImage() {
    setManufacturer({
      ...manufacturer,
      logo: {
        contentUrl: "",
      },
    });
  }

  return (
    <>
      <SimpleHeader name="Manufacturer details" parentName="Manufacturers" />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <Header
                  title={"Manufacturer details"}
                  edit={setEdit}
                  del={del}
                  delText={"You will not be able to recover this manufacturer."}
                  loading={loading}
                  showIcons={true}
                />

                {error && (
                  <ErrorAlert
                    text={
                      "There was an error while loading the informations, please try again"
                    }
                  />
                )}
                {loading && <Loader />}
                {!loading && (
                  <CardBody>
                    <div style={{ display: "flex", direction: "column" }}>
                      <Col md="8">
                        <Form>
                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="example-text-input"
                              md="3"
                            >
                              Manufacturer name
                            </Label>
                            <Col md="9">
                              <Input
                                disabled={edit ? false : true}
                                style={{
                                  backgroundColor: "white",
                                  borderColor:
                                    errorName === "error" ? "red" : "",
                                }}
                                value={manufacturer.name || ""}
                                name="name"
                                type="text"
                                placeholder="The manufacturer's name"
                                onChange={(event) => addInfo(event)}
                                autoComplete="off"
                              />
                            </Col>
                          </FormGroup>

                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="example-text-input"
                              md="3"
                            >
                              Legal name
                            </Label>
                            <Col md="9">
                              <Input
                                disabled={edit ? false : true}
                                style={{ backgroundColor: "white" }}
                                value={manufacturer.legalName || ""}
                                name="legalName"
                                type="text"
                                placeholder="The manufacturer's name"
                                onChange={(event) => addInfo(event)}
                                autoComplete="off"
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="example-text-input"
                              md="3"
                            >
                              VAT ID
                            </Label>
                            <Col md="9">
                              <Input
                                disabled={edit ? false : true}
                                style={{ backgroundColor: "white" }}
                                value={manufacturer.vatId || ""}
                                name="vatId"
                                type="text"
                                placeholder="The manufacturer's VAT ID"
                                onChange={(event) => addInfo(event)}
                                autoComplete="off"
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="example-text-input"
                              md="3"
                            >
                              VAT payer
                            </Label>
                            <Col md="9">
                              <Checkbox
                                checked={manufacturer.vatPayer}
                                onChange={(event) =>
                                  handleChangeVatPayer(event)
                                }
                                style={{ color: COLORS.BLUE }}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="example-text-input"
                              md="3"
                            >
                              Reg Com
                            </Label>
                            <Col md="9">
                              <Input
                                disabled={edit ? false : true}
                                style={{ backgroundColor: "white" }}
                                value={manufacturer.regCom || ""}
                                name="regCom"
                                type="text"
                                placeholder="Reg com"
                                onChange={(event) => addInfo(event)}
                                autoComplete="off"
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="example-text-input"
                              md="3"
                            >
                              Bank account
                            </Label>
                            <Col md="9">
                              <Input
                                disabled={edit ? false : true}
                                style={{ backgroundColor: "white" }}
                                value={manufacturer.bankAccount || ""}
                                name="bankAccount"
                                type="text"
                                placeholder="Bank account"
                                onChange={(event) => addInfo(event)}
                                autoComplete="off"
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="example-text-input"
                              md="3"
                            >
                              Bank name
                            </Label>
                            <Col md="9">
                              <Input
                                disabled={edit ? false : true}
                                style={{ backgroundColor: "white" }}
                                value={manufacturer.bankName || ""}
                                name="bankName"
                                type="text"
                                placeholder="Bank name"
                                onChange={(event) => addInfo(event)}
                                autoComplete="off"
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="example-text-input"
                              md="3"
                            >
                              Phone number
                            </Label>
                            <Col md="9">
                              <Input
                                disabled={edit ? false : true}
                                style={{
                                  backgroundColor: "white",
                                  borderColor:
                                    errorPhoneNumber === "error" ? "red" : "",
                                }}
                                value={manufacturer.telephone}
                                name="telephone"
                                type="number"
                                placeholder="The manufacturer's phone number"
                                onChange={(event) => addInfo(event)}
                                autoComplete="off"
                              />
                            </Col>
                          </FormGroup>

                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="example-text-input"
                              md="3"
                            >
                              Email
                            </Label>
                            <Col md="9">
                              <Input
                                disabled={true}
                                style={{
                                  backgroundColor: "white",
                                }}
                                value={manufacturer.email}
                                name="email"
                                type="text"
                                placeholder="The manufacturer's email"
                                onChange={(event) => addInfo(event)}
                                autoComplete="off"
                              />
                            </Col>
                          </FormGroup>
                          <>
                            <FormGroup className="row">
                              <Label
                                className="form-control-label"
                                htmlFor="example-text-input"
                                md="3"
                              >
                                Street
                              </Label>
                              <Col md="9">
                                <Input
                                  disabled={edit ? false : true}
                                  style={{ backgroundColor: "white" }}
                                  value={
                                    manufacturer.address
                                      ? manufacturer.address.streetAddress
                                      : ""
                                  }
                                  name="streetAddress"
                                  type="text"
                                  placeholder="Street"
                                  onChange={(event) => addInfoAddress(event)}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup className="row">
                              <Label
                                className="form-control-label"
                                htmlFor="example-text-input"
                                md="3"
                              >
                                City
                              </Label>
                              <Col md="9">
                                <Input
                                  disabled={edit ? false : true}
                                  style={{
                                    backgroundColor: "white",
                                    borderColor:
                                      errorCity === "error" ? "red" : "",
                                  }}
                                  value={
                                    manufacturer.address
                                      ? manufacturer.address.city
                                      : ""
                                  }
                                  name="city"
                                  type="text"
                                  placeholder="City"
                                  onChange={(event) => addInfoAddress(event)}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup className="row">
                              <Label
                                className="form-control-label"
                                htmlFor="example-text-input"
                                md="3"
                              >
                                County
                              </Label>
                              <Col md="9">
                                <Input
                                  disabled={edit ? false : true}
                                  style={{ backgroundColor: "white" }}
                                  value={
                                    manufacturer.address
                                      ? manufacturer.address.county
                                      : ""
                                  }
                                  name="county"
                                  type="text"
                                  placeholder="County"
                                  onChange={(event) => addInfoAddress(event)}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup className="row">
                              <Label
                                className="form-control-label"
                                htmlFor="example-text-input"
                                md="3"
                              >
                                Country
                              </Label>
                              <Col md="9">
                                <Input
                                  disabled={edit ? false : true}
                                  style={{ backgroundColor: "white" }}
                                  value={
                                    manufacturer.address
                                      ? manufacturer.address.country
                                      : ""
                                  }
                                  name="country"
                                  type="text"
                                  placeholder="Country"
                                  onChange={(event) => addInfoAddress(event)}
                                />
                              </Col>
                            </FormGroup>

                            <FormGroup className="row">
                              <Label
                                className="form-control-label"
                                htmlFor="example-text-input"
                                md="3"
                              >
                                Additional address info
                              </Label>
                              <Col md="9">
                                <Input
                                  disabled={edit ? false : true}
                                  style={{ backgroundColor: "white" }}
                                  value={
                                    manufacturer.address
                                      ? manufacturer.address
                                          .addressAdditionalInfo
                                      : ""
                                  }
                                  name="addressAdditionalInfo"
                                  type="text"
                                  placeholder=" Additional informations"
                                  onChange={(event) => addInfoAddress(event)}
                                />
                              </Col>
                            </FormGroup>
                          </>
                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="example-text-input"
                              md="3"
                            >
                              Slogan
                            </Label>
                            <Col md="9">
                              <Input
                                rows="3"
                                disabled={edit ? false : true}
                                style={{
                                  backgroundColor: "white",
                                  borderColor:
                                    errorSlogan === "error" ? "red" : "",
                                }}
                                value={manufacturer.slogan}
                                name="slogan"
                                type="textarea"
                                onChange={(event) => addInfo(event)}
                                autoComplete="off"
                              />
                            </Col>
                          </FormGroup>

                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="example-text-input"
                              md="3"
                            >
                              Additional informations
                            </Label>
                            <Col md="9">
                              <Input
                                rows="3"
                                disabled={edit ? false : true}
                                style={{ backgroundColor: "white" }}
                                value={manufacturer.additionalInfo}
                                name="additionalInfo"
                                type="textarea"
                                onChange={(event) => addInfo(event)}
                                autoComplete="off"
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="example-text-input"
                              md="3"
                            >
                              Standard delivery time
                            </Label>
                            <Col md="9">
                              <CreatableSelect
                                isDisabled={edit ? false : true}
                                isClearable
                                placeholder="Select from list or add yourself (hours only)"
                                value={selectedDeliveryTime}
                                onChange={handleDeliveryChange}
                                options={MANUFACTURER_DELIVERY_TIME}
                                styles={{
                                  control: (base, state) => ({
                                    ...base,
                                    fontSize: 14,
                                    backgroundColor: "white",
                                  }),
                                }}
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    neutral50: "#8898aa",
                                  },
                                })}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="example-text-input"
                              md="3"
                            >
                              Verified manufacturer
                            </Label>
                            <Col md="9">
                              <Checkbox
                                checked={manufacturer.isVerified}
                                onChange={(event) =>
                                  handleChangeIsVerified(event)
                                }
                                style={{ color: COLORS.BLUE }}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="example-text-input"
                              md="3"
                            >
                              Standard delivery price (RON)
                            </Label>
                            <Col md="9">
                              <Input
                                disabled={edit ? false : true}
                                placeholder="Standard delivery price (RON)"
                                style={{ backgroundColor: "white" }}
                                value={manufacturer.deliveryPrice}
                                name="deliveryPrice"
                                type="number"
                                onChange={(event) => addInfo(event)}
                                autoComplete="off"
                              />
                            </Col>
                          </FormGroup>

                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="example-text-input"
                              md="3"
                            >
                              Express delivery
                            </Label>
                            <Col md="9">
                              <Checkbox
                                disabled={edit ? false : true}
                                checked={manufacturer.transportExpressExist}
                                onChange={(event) =>
                                  handleChangeDeliveryExpress(event)
                                }
                                style={{
                                  color: COLORS.BLUE,
                                }}
                              />
                            </Col>
                          </FormGroup>
                          {manufacturer.transportExpressExist && (
                            <>
                              <FormGroup className="row">
                                <Label
                                  className="form-control-label"
                                  htmlFor="example-text-input"
                                  md="3"
                                >
                                  Express delivery time
                                </Label>
                                <Col md="9">
                                  <CreatableSelect
                                    isDisabled={edit ? false : true}
                                    isClearable
                                    placeholder="Select from list or add yourself (hours only)"
                                    value={selectedExpressDeliveryTime}
                                    onChange={handleExpressDeliveryTimeChange}
                                    options={MANUFACTURER_DELIVERY_TIME}
                                    styles={{
                                      control: (base, state) => ({
                                        ...base,
                                        fontSize: 14,
                                        backgroundColor: "white",
                                      }),
                                    }}
                                    theme={(theme) => ({
                                      ...theme,
                                      colors: {
                                        ...theme.colors,
                                        neutral50: "#8898aa",
                                      },
                                    })}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup className="row">
                                <Label
                                  className="form-control-label"
                                  htmlFor="example-text-input"
                                  md="3"
                                >
                                  Express delivery price (RON)
                                </Label>
                                <Col md="9">
                                  <Input
                                    disabled={edit ? false : true}
                                    style={{ backgroundColor: "white" }}
                                    value={manufacturer.deliveryPriceExpress}
                                    name="deliveryPriceExpress"
                                    type="number"
                                    placeholder="Express delivery price (RON)"
                                    onChange={(event) => addInfo(event)}
                                    autoComplete="off"
                                  />
                                </Col>
                              </FormGroup>
                            </>
                          )}
                        </Form>
                      </Col>

                      <Col md="4">
                        <Col md="12">
                          {manufacturer.logo.contentUrl !== "" && (
                            <>
                              {edit && (
                                <span
                                  style={{
                                    position: "absolute",
                                    top: "2px",
                                    right: "100px",
                                    zIndex: "100",
                                    color: "black",
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                  onClick={() => removeImage()}
                                >
                                  &times;
                                </span>
                              )}
                              <img
                                alt="..."
                                style={{
                                  width: "250px",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  display: "block",
                                }}
                                src={
                                  manufacturer.logo.contentUrl
                                    ? manufacturer.logo.contentUrl
                                    : ""
                                }
                              />
                            </>
                          )}
                        </Col>
                        {edit && manufacturer.logo.contentUrl === "" && (
                          <Col md="12">
                            <ImgContainer
                              links
                              setLinks
                              newProduct={manufacturer}
                              setNewProduct={setManufacturer}
                              type={MANUFACTURER_LOGO}
                            />
                          </Col>
                        )}
                      </Col>
                    </div>

                    {edit && (
                      <>
                        <button
                          type="submit"
                          className="btn btn-success"
                          onClick={(event) => updateManufacturer(event)}
                        >
                          Save
                        </button>
                      </>
                    )}
                    <div style={{ marginTop: 10 }}>
                      {errorUpdate && <ErrorAlert text={errorText} />}
                      {saved && (
                        <SuccessAlert
                          text={"The manufacturer has been updated"}
                        />
                      )}
                    </div>
                  </CardBody>
                )}
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Show;

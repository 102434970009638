import React, { useState } from "react";
import {
  Card,
  CardBody,
  Label,
  Form,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import classnames from "classnames";
import SimpleHeader from "../../componentsTheme/Headers/SimpleHeader.js";
import Select from "react-select";
import SuccessAlert from "../../utils/succesAlert";
import ErrorAlert from "../../utils/errorAlert";
import Header from "../../utils/header";
import { RequestLoader } from "../../utils/loader";
import {
  createProvider,
  PROVIDER_VEHICLE_OPTIONS,
} from "../../services/providers";

function Create(props) {
  const [provider, setProvider] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    password: "",
    licensePlate: "",
    vehicleType: "",
    additionalInfo: "",
  });

  const [selectedOption, setSelectedOption] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(false);
  const [errorFirstName, setErrorFirstName] = useState(false);
  const [errorLastName, setErrorLastName] = useState(false);
  const [errorPhoneNumber, setErrorPhoneNumber] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorVehicleType, setErrorVehicleType] = useState(false);
  const [errorText, setErrorText] = useState('');

  const isFormValid =
    provider.firstName.trim().length > 0 &&
    provider.lastName.trim().length > 0 &&
    provider.phoneNumber.trim().length > 0 &&
    provider.email.trim().length > 0 &&
    provider.password.trim().length > 8 &&
    provider.vehicleType.trim().length > 0;

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  function handleChange(selectedOption) {
    setProvider({
      ...provider,
      vehicleType: selectedOption.value,
    });
  }

  function addInfo(event) {
    setProvider({
      ...provider,
      [event.target.name]: event.target.value,
    });
  }

  function showSaveDialog() {
    setSaved(true);
    setTimeout(() => {
      setSaved(false);
    }, 5000);
  }

  function showErrorDialog() {
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 3000);
  }

  function setErrorBorder() {
    setErrorFirstName(errorFirstName || provider.firstName.trim().length <= 0);
    setErrorLastName(errorLastName || provider.lastName.trim().length <= 0);
    setErrorPhoneNumber(
      errorPhoneNumber || provider.phoneNumber.trim().length <= 0
    );
    setErrorEmail(errorEmail || provider.email.trim().length <= 0);
    setErrorPassword(errorPassword || provider.password.trim().length <= 8);
    setErrorVehicleType(errorVehicleType || provider.vehicleType.length <= 0);
  }

  async function addProvider(event) {
    event.preventDefault();
    if (isFormValid) {
      setLoadingRequest(true)
      createProvider(
        provider.firstName,
        provider.lastName,
        provider.phoneNumber,
        provider.email,
        provider.password,
        provider.licensePlate,
        provider.vehicleType,
        provider.additionalInfo
      )
        .then((res) => {
          if (res.ok) {
            showSaveDialog()
            setLoadingRequest(false)
          } else {
            showErrorDialog()
            setLoadingRequest(false)
            setErrorText('There was an error while creating the provider, please try again')
          }
        })
        .catch((res) => {
          showErrorDialog();
          setLoadingRequest(false)
          setErrorText('There was an error while creating the provider, please try again')
        });
    } else {
      setErrorBorder();
      showErrorDialog();
      setLoadingRequest(false)
      if (provider.password.length < 8) {
        setErrorText('Please enter a password with min. 8 characters')
      } else {
        setErrorText('Please enter all of the required informations')
      }
    }
  }

  return (
    <>
      <SimpleHeader name="New provider" parentName="Providers" />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <Header
                  title={"Provider details"}
                  edit={true}
                  del
                  delText
                  loading={false}
                  showIcons={false}
                />

                <CardBody>
                  <Form>
                    <FormGroup className="row">
                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                        md="2"
                      >
                        First name
                      </Label>
                      <Col md="6">
                        <Input
                          style={{ borderColor: errorFirstName ? "red" : "" }}
                          value={provider.firstName}
                          name="firstName"
                          type="text"
                          placeholder="The provider's first name"
                          onChange={(event) => addInfo(event)}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                        md="2"
                      >
                        Last name
                      </Label>
                      <Col md="6">
                        <Input
                          style={{ borderColor: errorLastName ? "red" : "" }}
                          value={provider.lastName}
                          name="lastName"
                          type="text"
                          placeholder="The provider's last name"
                          onChange={(event) => addInfo(event)}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                        md="2"
                      >
                        Phone number
                      </Label>
                      <Col md="6">
                        <Input
                          style={{ borderColor: errorPhoneNumber ? "red" : "" }}
                          value={provider.phoneNumber}
                          name="phoneNumber"
                          type="number"
                          placeholder="The provider's phone number"
                          onChange={(event) => addInfo(event)}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                        md="2"
                      >
                        Email
                      </Label>
                      <Col md="6">
                        <Input
                          style={{ borderColor: errorEmail ? "red" : "" }}
                          value={provider.email}
                          name="email"
                          type="text"
                          placeholder="The provider's email"
                          onChange={(event) => addInfo(event)}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                        md="2"
                      >
                        Password
                      </Label>
                      <Col md="6">
                        <FormGroup>
                          <InputGroup
                            className={classnames("input-group-merge", {
                              focused: showPassword,
                            })}
                          >
                            <Input
                              style={{
                                borderColor: errorPhoneNumber ? "red" : "",
                              }}
                              value={provider.password}
                              onChange={(event) => addInfo(event)}
                              name="password"
                              type={showPassword ? "text" : "password"}
                              placeholder="Min. 8 characters password"
                            />
                            <InputGroupAddon
                              addonType="append"
                              onClick={handleClickShowPassword}
                            >
                              <InputGroupText>
                                {showPassword ? (
                                  <i className="fas fa-eye-slash" />
                                ) : (
                                    <i className="fas fa-eye" />
                                  )}
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                        md="2"
                      >
                        Vehicle Type
                      </Label>
                      <Col md="6">
                        <Select
                          name="vehicleType"
                          defaultValue={selectedOption}
                          onChange={handleChange}
                          options={PROVIDER_VEHICLE_OPTIONS}
                          placeholder="The type of the vehicle the provider uses"
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              backgroundColor: "white",
                              fontSize: 14,
                              border: errorVehicleType
                                ? "1px solid red"
                                : "1px solid lightgray",
                            }),
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                        md="2"
                      >
                        License plate
                      </Label>
                      <Col md="6">
                        <Input
                          value={provider.licensePlate}
                          name="licensePlate"
                          type="text"
                          placeholder="The license plate if the provider owns a vehicle"
                          onChange={(event) => addInfo(event)}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                        md="2"
                      >
                        Additional informations
                      </Label>
                      <Col md="6">
                        <Input
                          rows="3"
                          value={provider.additionalInfo}
                          name="additionalInfo"
                          type="textarea"
                          placeholder="Other informations"
                          onChange={(event) => addInfo(event)}
                        />
                      </Col>
                    </FormGroup>

                    <button
                      type="submit"
                      className="btn btn-success"
                      onClick={(event) => addProvider(event)}
                    >
                      Save
                    </button>
                    {loadingRequest && <RequestLoader />}
                    <div style={{ marginTop: 10 }}>
                      {saved && (
                        <SuccessAlert
                          text={"The provider account has been created"}
                        />
                      )}
                      {error && (
                        <ErrorAlert
                          text={
                            errorText
                          }
                        />
                      )}
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Create;

import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Modal,
} from "reactstrap";
// core components
import AuthHeader from "../../componentsTheme/Headers/AuthHeader.js";
import COLORS from "../../utils/colors";
import ErrorAlert from "../../utils/errorAlert";
import { updatePassword } from "../../services/providers.js";
import SuccessAlert from "../../utils/succesAlert.js";

class ProviderLogin extends React.Component {
  state = {
    email: "",
    password: "",
    error: false,
    emailToSend: "",
    emailSent: false,
    errorText: "",
    changePasswordDialog: "",
  };

  sendLink = () => {
    var emailFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValidEmail = emailFormat.test(
      String(this.state.emailToSend.trim()).toLowerCase()
    );
    if (isValidEmail) {
      updatePassword(this.state.emailToSend).then((res) => {
        if (res.ok) {
          this.setState({
            error: false,
            emailSent: true,
          });
        } else {
          this.setState({
            error: true,
            errorText:
              "There was an error while sending the link, please try again",
          });
        }
      });
    } else {
      this.setState({
        error: true,
        errorText: "Please enter a valid email address",
      });
    }
  };

  showDialog = () => {
    this.setState({
      changePasswordDialog: true,
    });
  };

  toggleModal = () => {
    this.setState({
      error: false,
      errorText: "",
      changePasswordDialog: !this.state.changePasswordDialog,
      emailToSend: "",
      emailSent: false,
    });
  };

  handleChange = (event) => {
    this.setState({
      emailToSend: event.target.value,
      error: false,
      errorText: "",
    });
  };

  doLogin = async () => {
    const URL = process.env.REACT_APP_API_ENTRYPOINT + "/login";

    const loginResponse = await fetch(URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: this.state.email,
        password: this.state.password,
      }),
    }).catch(() => {
      this.setState({
        error: true,
      });
      return;
    });
    if (!loginResponse) {
      this.setState({
        error: true,
      });
      return;
    }

    if (!loginResponse.ok) {
      this.setState({
        error: true,
      });
      return;
    }
    const loginResult = await loginResponse.json();

    if (!loginResult["token"]) {
      this.setState({
        error: true,
      });
      return;
    }

    let role = loginResult["role"];
    localStorage.setItem("refresh_token", loginResult["refreshToken"]);
    localStorage.setItem("role", loginResult["role"]);
    localStorage.setItem("token", loginResult["token"]);
    this.props.history.push(`/${role}/orders`);
  };

  render() {
    return (
      <>
        <AuthHeader />
        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.changePasswordDialog}
          toggle={() => this.toggleModal("defaultModal")}
        >
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-default">
              Change password
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("defaultModal")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <p>Please enter your email address:</p>
            <Input
              id="exampleFormControlTextarea1"
              type="email"
              required={true}
              value={this.state.emailToSend}
              onChange={(event) => this.handleChange(event)}
            />
          </div>

          <div className="modal-footer">
            {!this.state.emailSent && (
              <Button
                onClick={() => this.sendLink()}
                type="button"
                style={{ backgroundColor: COLORS.BLUE, color: COLORS.WHITE }}
              >
                Send link to change password
              </Button>
            )}
            <Button
              className="ml-auto"
              style={{ color: COLORS.BLUE }}
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("defaultModal")}
            >
              Close
            </Button>
          </div>

          <div style={{ margin: "10px" }}>
            {this.state.error && <ErrorAlert text={this.state.errorText} />}
          </div>
          <div style={{ margin: "10px" }}>
            {this.state.emailSent && <SuccessAlert text={"Check your email"} />}
          </div>
        </Modal>
        <Container className="mt--1 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7" style={{ marginTop: 15 }}>
              <Card className="bg-secondary border-0 mb-0">
                <CardHeader className="bg-transparent pb-5">
                  <div className="text-muted text-center mt-2 mb-3">
                    <small>
                      Login in as <b>PROVIDER</b> or <b>MANUFACTURER</b>
                    </small>
                  </div>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">

                  <Form role="form">
                    <FormGroup
                      className={classnames("mb-3", {
                        focused: this.state.focusedEmail,
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          onChange={(event) =>
                            this.setState({ email: event.target.value })
                          }
                          value={this.state.email}
                          placeholder="Email"
                          type="email"
                          onFocus={() => this.setState({ focusedEmail: true })}
                          onBlur={() => this.setState({ focusedEmail: false })}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup
                      className={classnames({
                        focused: this.state.focusedPassword,
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          onChange={(event) =>
                            this.setState({ password: event.target.value })
                          }
                          value={this.state.password}
                          placeholder="Password"
                          type="password"
                          onFocus={() =>
                            this.setState({ focusedPassword: true })
                          }
                          onBlur={() =>
                            this.setState({ focusedPassword: false })
                          }
                        />
                      </InputGroup>
                    </FormGroup>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        style={{ fontSize: 14, color: COLORS.BLUE }}
                        onClick={this.showDialog}
                      >
                        Forgot Password
                      </Button>
                    </div>
                    <div className="text-center">
                      <Button
                        className="my-4"
                        type="button"
                        onClick={this.doLogin}
                        style={{
                          backgroundColor: COLORS.BLUE,
                          color: COLORS.WHITE,
                        }}
                      >
                        Sign in
                      </Button>
                    </div>
                    {this.state.error && (
                      <ErrorAlert
                        text={"There was an error, please try again"}
                      />
                    )}
                  </Form>
                </CardBody>
              </Card>
              <Row className="mt-3">
                <Col xs="6"></Col>
                <Col className="text-right" xs="6">
                  <Link to="/auth/login/admin" className="text-light">
                    <small>Login as an admin</small>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default ProviderLogin;

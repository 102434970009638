import React, { useState } from "react";
import {
  Card,
  CardBody,
  Label,
  Form,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import SimpleHeader from "../../componentsTheme/Headers/SimpleHeader.js";
import SuccessAlert from "../../utils/succesAlert";
import ErrorAlert from "../../utils/errorAlert";
import Header from "../../utils/header";

function CreateTag(props) {
  const [newTag, setNewTag] = useState({
    name: "",
  });

  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(false);

  function addInfo(event) {
    setNewTag({
      ...newTag,
      [event.target.name]: event.target.value,
    });
  }

  async function createTag(event) {
    event.preventDefault();
    let URL = process.env.REACT_APP_API_ENTRYPOINT + "/tags";
    if (newTag.name.length == 0) {
      setError(true);
      setSaved(false);
    } else {
      let res = await fetch(URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          name: newTag.name,
        }),
      })
        .then((response) => {
          setSaved(true);
          setError(false);
        })
        .catch(() => {
          setError(true);
          setSaved(false);
        });

      return res;
    }
  }

  return (
    <>
      <SimpleHeader name="New tag" parentName="Tags" />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <Header
                  title={"Tag details"}
                  edit={true}
                  del
                  delText
                  loading={false}
                  showIcons={false}
                />

                <CardBody>
                  <Form>
                    <FormGroup className="row">
                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                        md="2"
                      >
                        Tag
                      </Label>
                      <Col md="6">
                        <Input
                          value={newTag.name}
                          name="name"
                          type="name"
                          placeholder="Enter tag"
                          onChange={(event) => addInfo(event)}
                        />
                      </Col>
                    </FormGroup>

                    <button
                      onClick={(event) => createTag(event)}
                      className="btn btn-success"
                    >
                      Save
                    </button>
                  </Form>
                  <div style={{ marginTop: 10 }}>
                    {error && (
                      <ErrorAlert
                        text={
                          "There was an error while creating the tag, please try again"
                        }
                      />
                    )}
                    {saved && (
                      <SuccessAlert text={"Your tag has been created."} />
                    )}
                  </div>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CreateTag;

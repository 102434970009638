import React, { useState } from "react";
import { Card, Container } from "reactstrap";
import SimpleHeader from "../../componentsTheme/Headers/SimpleHeader.js";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/core/styles";
import ErrorAlert from "../../utils/errorAlert";
import Header from "../../utils/header";
import ExpressProducts from "./expressProducts";
import TodaysProducts from "./todaysProducts";
import TommorowsProducts from "./tommorowsProducts";
import OtherProducts from "./otherProducts";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  indicator: {
    display: "none",
  },
  tabItemStyles: {
    opacity: 1,
    overflow: "initial",
    color: "black",
    backgroundColor: "white",
    transition: "0.2s",
    "&:before": {
      transition: "0.2s",
    },
    "&:not(:first-of-type)": {
      "&:before": {
        content: '" "',
        position: "absolute",
        left: 0,
        display: "block",
        height: 20,
        width: 1,
        zIndex: 1,
        backgroundColor: "#c2c2c2",
      },
    },
    "& + $selected:before": {
      opacity: 0,
    },
    "&:hover": {
      "&:not($selected)": {
        backgroundColor: "#39AD4B",
        color: "white",
        opacity: 0.9,
      },
      "&::before": {
        opacity: 0,
      },
      "& + $root:before": {
        opacity: 0,
      },
    },
  },
  selectedTab: {
    backgroundColor: "#39AD4B",
    color: "white",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={4}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function OrderedProducts(props) {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <>
      <SimpleHeader name="Ordered products" parentName="Products" />
      <Container className="mt--6" fluid>
        <Card>
          <Header
            title={"Ordered products"}
            edit={true}
            del
            delText
            loading={false}
            showIcons={false}
          />
          {error && (
            <ErrorAlert
              text={
                "There was an error while loading the product list, please try again"
              }
            />
          )}
          <>
            <Tabs
              value={tabIndex}
              onChange={handleChangeTab}
              classes={{
                indicator: classes.indicator,
              }}
              style={{ marginLeft: "25px" }}
            >
              <Tab
                label="Express"
                disableRipple
                className={
                  tabIndex == 0 ? classes.selectedTab : classes.tabItemStyles
                }
              />
              <Tab
                label="Today"
                disableRipple
                className={
                  tabIndex == 1 ? classes.selectedTab : classes.tabItemStyles
                }
              />
              <Tab
                label="Tommorow"
                disableRipple
                className={
                  tabIndex == 2 ? classes.selectedTab : classes.tabItemStyles
                }
              />
              <Tab
                label="Others"
                disableRipple
                className={
                  tabIndex == 3 ? classes.selectedTab : classes.tabItemStyles
                }
              />
            </Tabs>
            <TabPanel value={tabIndex} index={0}>
              <ExpressProducts />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <TodaysProducts />
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
              <TommorowsProducts />
            </TabPanel>
            <TabPanel value={tabIndex} index={3}>
              <OtherProducts />
            </TabPanel>
          </>
        </Card>
      </Container>
    </>
  );
}

export default OrderedProducts;

import React, { useState } from "react";
import {
  Card,
  CardBody,
  Label,
  Form,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import SimpleHeader from "../../componentsTheme/Headers/SimpleHeader.js";
import Select from "react-select";
import * as moment from "moment";
import SuccessAlert, { showSaveDialog } from "../../utils/succesAlert";
import ErrorAlert, { showErrorDialog } from "../../utils/errorAlert";
import ImgContainer from "../../utils/imgContainer";
import Header from "../../utils/header";
import {
  createNewBanner,
  IMAGE_BANNER,
  BANNER_LOCATION,
} from "../../services/banners";

function CreateBanner(props) {
  const [banner, setBanner] = useState({
    startDate: moment(new Date()).format("YYYY-MM-DDTHH:mm"),
    endDate: moment().add(7, "d").format("YYYY-MM-DDTHH:mm"),
    location: "home",
    url: "",
    image: {
      contentUrl: "",
    },
  });
  const [selectedOption, setSelectedOption] = useState(BANNER_LOCATION);
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(false);
  const [errorStartDate, setErrorStartDate] = useState("");
  const [errorEndDate, setErrorEndDate] = useState("");
  const [errorLocation, setErrorLocation] = useState("");
  const [errorUrl, setErrorUrl] = useState("");
  const [errorText, setErrorText] = useState("");

  const isFormValid =
    banner.startDate.length > 0 &&
    banner.endDate.length > 0 &&
    (banner.startDate < banner.endDate) &&
    banner.location.length > 0 &&
    banner.image.contentUrl.trim().length > 0;

  function handleChange(selectedOption) {
    setBanner({
      ...banner,
      location: selectedOption.value,
    });
  }

  function addImg(event) {
    let link = {}
    link.contentUrl = event.target.value
    setBanner({
      ...banner,
      image: link,
    });
  }

  function addStartDate(event) {
    setBanner({
      ...banner,
      startDate: event.target.value,
    });
  }

  function addEndDate(event) {
    setBanner({
      ...banner,
      endDate: event.target.value,
    });
  }

  function setErrorBorder() {
    banner.startDate.length < 1
      ? setErrorStartDate("error")
      : setErrorStartDate("");
    banner.endDate.length < 1 ? setErrorEndDate("error") : setErrorEndDate("");
    banner.location.trim().length < 1
      ? setErrorLocation("error")
      : setErrorLocation("");
    banner.image.contentUrl.trim().length < 1 ? setErrorUrl("error") : setErrorUrl("");
  }

  async function createBanner(event) {
    event.preventDefault();
    if (isFormValid) {
      createNewBanner(
        banner.startDate,
        banner.endDate,
        banner.location,
        banner.image,
      )
        .then((res) => {
          if (res.ok) {
            showSaveDialog(setSaved)
          } else {
            showErrorDialog(setError)
            setErrorText('There was an error while adding the banner, please try again')
          }
        })
        .catch((error) => {
          showErrorDialog(setError);
          setErrorText('There was an error while adding the banner, please try again')
        });
    } else {
      showErrorDialog(setError);
      setErrorBorder();
      if (banner.image.contentUrl == '') {
        setErrorText('Please enter a link or upload an image')
      } else if (banner.startDate > banner.endDate) {
        setErrorText('End date must be after start date')
      } else {
        setErrorText('Please enter all of the required informations')
      }
    }
  }

  return (
    <>
      <SimpleHeader name="New banner" parentName="Banners" />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <Header
                  title={"Banner details"}
                  edit={true}
                  del
                  delText
                  loading={false}
                  showIcons={false}
                />
                <CardBody>
                  <Form>
                    <FormGroup className="row">
                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                        md="2"
                      >
                        Start date
                      </Label>
                      <Col md="6">
                        <Input
                          value={moment(banner.startDate).format(
                            "YYYY-MM-DDTHH:mm"
                          )}
                          style={{
                            borderColor:
                              errorStartDate === "error" ? "red" : "",
                          }}
                          name="startDate"
                          type="datetime-local"
                          placeholder="The starting date for the banner"
                          onChange={(event) => addStartDate(event)}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="row">
                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                        md="2"
                      >
                        End date
                      </Label>
                      <Col md="6">
                        <Input
                          value={moment(banner.endDate).format(
                            "YYYY-MM-DDTHH:mm"
                          )}
                          style={{
                            borderColor: errorEndDate === "error" ? "red" : "",
                          }}
                          onChange={(event) => addEndDate(event)}
                          name="endDate"
                          type="datetime-local"
                          placeholder="The ending date for the banner"
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                        md="2"
                      >
                        Location
                      </Label>
                      <Col md="6">
                        <Select
                          name="location"
                          defaultValue={selectedOption}
                          onChange={handleChange}
                          options={BANNER_LOCATION}
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              backgroundColor: "white",
                              fontSize: 14,
                              border:
                                errorLocation === "error"
                                  ? "1px solid red"
                                  : "1px solid lightgray",
                            }),
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                        md="2"
                      >
                        Link
                      </Label>
                      <Col md="6">
                        <Input
                          value={
                            banner.image.contentUrl.indexOf("base64") === -1
                              ? banner.image.contentUrl
                              : "Banner uploaded"
                          }
                          style={{
                            borderColor: errorUrl === "error" ? "red" : "",
                          }}
                          onChange={(event) => addImg(event)}
                        />
                      </Col>
                    </FormGroup>
                    <ImgContainer
                      links
                      setLinks
                      newProduct={banner}
                      setNewProduct={setBanner}
                      type={IMAGE_BANNER}
                    />
                    {banner.image.contentUrl !== '' && (
                      <FormGroup className="row">
                        <GridList
                          style={{
                            width: "auto",
                            height: "auto",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                          cellHeight={300}
                          cols={1}
                        >
                          <GridListTile
                            cols={1}
                            style={{
                              position: "relative",
                            }}
                          >
                            <img src={banner.url} />
                          </GridListTile>
                        </GridList>
                      </FormGroup>
                    )}
                    <button
                      onClick={(event) => createBanner(event)}
                      className="btn btn-success"
                    >
                      Save
                    </button>
                  </Form>
                  <div style={{ marginTop: 10 }}>
                    {saved && (
                      <SuccessAlert text={"Your banner has been saved."} />
                    )}
                    {error && (
                      <ErrorAlert
                        text={errorText}
                      />
                    )}
                  </div>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CreateBanner;

import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { deleteProduct } from "../services/products";

export default function CustomToolbarSelect(props) {
  const [showAlert, setShowAlert] = useState(false)

  function handleClick(e) {
    setShowAlert(true)
  }

  function handleDelete() {
    setShowAlert(false);
    let listOfArray = []
    props.selectedRows.map(item => {
      listOfArray.push(item['@id'])
    })
    listOfArray.forEach(el => {
      deleteProduct(el, props.location, 'admin')
        .then(() => {
          window.location.reload(true)
        })
    })
  }

  return (
    <div className={"custom-toolbar-select"}>
      {props.selectedRows.length > 1 &&
        <Tooltip title={"Edit selected products"}>
          <IconButton onClick={() =>
            props.location.history.push(
              `show/editBulk/`,
              {
                product: props.selectedRows,
              }
            )
          }>
            <EditIcon />
          </IconButton>
        </Tooltip>
      }
      {showAlert && <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "10%" }}
        title="Are you sure?"
        showCancel
        onConfirm={() => handleDelete()}
        onCancel={() => setShowAlert(false)}
        confirmBtnBsStyle="default"
        confirmBtnText="Ok"
        cancelBtnText="Cancel"
        btnSize=""
      >
        {'You will not be able to recover these products.'}
      </ReactBSAlert>}
      {props.selectedRows.length > 0 &&
        <Tooltip title={"Delete selected products"}>
          <IconButton onClick={(e) => handleClick(e)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      }
    </div>
  );

}

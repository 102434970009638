import React from "react";
import { UncontrolledAlert } from "reactstrap";

export default function SuccessAlert(props) {
  return (
    <div>
      <UncontrolledAlert color="success">
        <span className="alert-icon">
          <i className="ni ni-like-2" />
        </span>
        <span className="alert-text ml-1">
          <strong>Success!</strong> {props.text}.
        </span>
      </UncontrolledAlert>
    </div>
  );
}

export function showSaveDialog(setSaved) {
  setSaved(true);
  setTimeout(() => {
    setSaved(false);
  }, 5000);
}

import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, Container, Row, Col } from "reactstrap";
import SimpleHeader from "../../componentsTheme/Headers/SimpleHeader.js";
import { getOrderDetails, deleteOrder } from "../../services/orders";
import QuestionAlert from "../../utils/questionAlert";
import Loader from "../../utils/loader";
import ErrorAlert, { showErrorDialog } from "../../utils/errorAlert";
import DeliveryInformations from "./deliveryInfo";
import ProductInformations from "./productInfo";
import ClientInformations from "./clientInfo";

function ShowOrder(props) {
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoading(true);
    let linkParam = props.match.params.id.slice(12, 20);
    let orderLinkId = `/orders/${linkParam}`;

    if (props.location.state !== undefined) {
      getOrderDetails(props.location.state.order)
        .then((item) => {
          setSelectedStatus({
            label: item.status,
            value: item.status,
          });
          setOrder(item);
          setLoading(false);
        })
        .catch(() => {
          showErrorDialog(setError);
        });
    } else {
      getOrderDetails(orderLinkId)
        .then((item) => {
          setSelectedStatus({
            label: item.status,
            value: item.status,
          });
          setOrder(item);
          setLoading(false);
        })
        .catch(() => {
          showErrorDialog(setError);
        });
    }
  }, []);

  function del() {
    let orderId = order["@id"];
    deleteOrder(orderId)
      .then((res) => {
        res.ok
          ? props.history.push(`/admin/orders`)
          : showErrorDialog(setError);
      })
      .catch(() => {
        showErrorDialog(setError);
      });
  }
  return (
    <>
      <SimpleHeader name="Order details" parentName="Orders" />
      <Container className="mt--6" fluid>
        {loading && (
          <Card>
            <Loader />
          </Card>
        )}
        {error && (
          <ErrorAlert
            text={
              "There was an error while loading the order details, please try again"
            }
          />
        )}
        {order !== "" && (
          <>
            <Row>
              <Col lg="12">
                <div className="card-wrapper">
                  <Card>
                    <CardHeader>
                      <h3 className="mb-0">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          Order {""}
                          <>
                            {order["@id"].slice(8, 12)} from{" "}
                            {new Date(order["createdAt"]).toLocaleDateString()}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <QuestionAlert
                                text={
                                  "You will not be able to recover this order."
                                }
                                del={del}
                              />
                            </div>
                          </>
                        </div>
                      </h3>
                    </CardHeader>
                    <CardBody>
                      {order !== "" && selectedStatus !== "" && (
                        <ClientInformations
                          order={order}
                          setOrder={setOrder}
                          selectedStatus={selectedStatus}
                          setSelectedStatus={setSelectedStatus}
                        />
                      )}
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <ProductInformations order={order} setOrder={setOrder} />
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <DeliveryInformations
                  deliveries={order.deliveries}
                  order={order}
                  setOrder={setOrder}
                  setSelectedStatus={setSelectedStatus}
                />
              </Col>
            </Row>
          </>
        )}
      </Container>
    </>
  );
}

export default ShowOrder;

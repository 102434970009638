import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import OrderForm from "./Form";
import { create, reset } from "../../actions/order/create";

class CreateOrder extends Component {
  static propTypes = {
    error: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    created: PropTypes.object,
    create: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
  };

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    if (this.props.created)
      return (
        <Redirect
          to={`edit/${encodeURIComponent(this.props.created["@id"])}`}
        />
      );

    return (
      <div>


        {this.props.loading && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        {this.props.error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{" "}
            {this.props.error}
          </div>
        )}

        <OrderForm onSubmit={this.props.create} values={this.props.item} />

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { created, error, loading } = state.order.create;
  return { created, error, loading };
};

const mapDispatchToProps = (dispatch) => ({
  create: (values) => dispatch(create(values)),
  reset: () => dispatch(reset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrder);

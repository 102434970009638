import * as yup from "yup";

export const schema = (typeVoucher) => {
  return yup.object({
    Name: yup.string().min(4).required(),
    Description: yup.string().min(4).required(),
    Code: yup.string().min(4).required(),
    StartDate: yup.date().required(),
    ExpiredDate: yup.date().required(),
    FreeShipping: yup.string().required(),
    MinimumSpend: yup.number().min(1).required(),
    MaximumSpend: yup.number().min(1).required(),
    VoucherType: yup.string().min(3).required(),
    Discount: typeVoucher !== "plus" ? yup.number().min(1).required() : null,
    UsageLimit: yup.number().min(1).required(),
    UsageLimitPerUser: yup.number().min(1).required(),
    DiscountPriceType: typeVoucher !== "plus" ? yup.string().required() : null,
    DiscountConditionPlus:
      typeVoucher === "plus" ? yup.string().required() : null,
  });
};

import React, { useState, useEffect } from "react";
import { Card, Container } from "reactstrap";
import SimpleHeader from "../../componentsTheme/Headers/SimpleHeader.js";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import getProviders from "../../services/providers";
import Loader from "../../utils/loader";
import Header from "../../utils/header";
import IconButton from "@material-ui/core/IconButton";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";

function List(props) {
  const [providers, setProviders] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState("1");
  const THEME = createMuiTheme({
    typography: {
      fontFamily: "Poppins",
    },
  });

  useEffect(() => {
    setLoading(true);
    getProviders(pageNumber).then((item) => {
      setProviders(item);
      setLoading(false);
    });
  }, [pageNumber]);

  const columns = [
    {
      name: "@id",
      label: "PROVIDER ID",
      options: {
        filter: true,
        sort: true,
        filterType: "textField",
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value.slice(11, 20)}</>;
        },
      },
    },
    {
      name: "phoneNumber",
      label: "PHONE NUMBER",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "email",
      label: "EMAIL",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "licensePlate",
      label: "LICENSE PLATE",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      label: "VEHICLE TYPE",
      name: "vehicleType",
    },
    {
      name: "deliveries",
      label: "DELIVERIES",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value.length}</>;
        },
      },
    },
    {
      name: "DETAILS",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ width: "30px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IconButton
                  size="small"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={() =>
                    props.history.push(
                      `show/${encodeURIComponent(tableMeta.rowData[0])}`,
                      {
                        provider: tableMeta.rowData[0],
                      }
                    )
                  }
                >
                  <MoreVertIcon fontSize="small" />
                </IconButton>
              </div>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    rowsPerPage: 10,
    selectableRows: "none",
    responsive: "vertical",
    rowsPerPageOptions: [],
    download: false,
    print: false,
    search: false,
  };

  return (
    <>
      <SimpleHeader name="All providers" parentName="Providers" />
      <Container className="mt--6" fluid>
        <Card>
          <Header
            title={"Provider list"}
            edit={true}
            del
            delText
            loading={false}
            showIcons={false}
          />

          {loading && <Loader />}
          {providers !== "" && (
            <>
              <MuiThemeProvider theme={THEME}>
                <MUIDataTable
                  title={""}
                  data={providers}
                  columns={columns}
                  options={options}
                />
              </MuiThemeProvider>
            </>
          )}
        </Card>
      </Container>
    </>
  );
}

export default List;

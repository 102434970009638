import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Label,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import CreatableSelect from "react-select/creatable";
import SimpleHeader from "../../componentsTheme/Headers/SimpleHeader.js";
import SuccessAlert, { showSaveDialog } from "../../utils/succesAlert";
import ErrorAlert, { showErrorDialog } from "../../utils/errorAlert";
import Loader from "../../utils/loader";
import { getDetails, editOffer, deleteOffer } from "../../services/offers";
import * as moment from "moment";
import Header from "../../utils/header";
import COLORS from "../../utils/colors";
import { PRODUCT_CURRENCY_OPTIONS } from "../../services/products";

function Show(props) {
  const [offer, setOffer] = useState("");
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [errorStartDate, setErrorStartDate] = useState(false);
  const [errorEndDate, setErrorEndDate] = useState(false);
  const [errorPrice, setErrorPrice] = useState(false);
  const [errorPriceCurrency, setErrorPriceCurrency] = useState(false);
  const [errorPriority, setErrorPriority] = useState(false);

  const isFormValid =
    offer !== ""
      ? offer.priority !== "" &&
      offer.newPrice.price !== "" &&
      offer.startDate !== "" &&
      offer.endDate !== ""
      : "";

  useEffect(() => {
    setLoading(true);
    getDetails(props.location.state.offer)
      .then((item) => {
        item.newPrice.priceCurrency = {
          value: item.newPrice.priceCurrency,
          label: item.newPrice.priceCurrency,
        };
        setOffer(item);
        setLoading(false);
      })
      .catch(() => showErrorDialog(setError));
  }, []);

  function addInfo(event) {
    setOffer({
      ...offer,
      [event.target.name]: event.target.value,
    });
  }

  function addStartDate(event) {
    setOffer({
      ...offer,
      startDate: event.target.value,
    });
  }

  function addEndDate(event) {
    setOffer({
      ...offer,
      endDate: event.target.value,
    });
  }

  function updatePrice(event) {
    let update = { ...offer.newPrice };
    update.price = event.target.value;
    setOffer({
      ...offer,
      newPrice: update,
    });
  }

  function updatePriceCurrency(selectedOption) {
    let update = { ...offer.newPrice };
    update.priceCurrency = selectedOption ? selectedOption : "";
    setOffer({
      ...offer,
      newPrice: update,
    });
  }

  function setErrorBorder() {
    setErrorStartDate(errorStartDate || offer.startDate.length <= 0);
    setErrorEndDate(errorEndDate || offer.endDate.length <= 0);
    setErrorPrice(errorPrice || offer.newPrice.price.length <= 0);
    setErrorPriceCurrency(
      errorPriceCurrency || offer.newPrice.priceCurrency.length <= 0
    );
    setErrorPriority(errorPriority || offer.priority.length <= 0);
  }

  function del() {
    let currentOffer = offer["@id"];
    deleteOffer(currentOffer)
      .then((res) => {
        res.ok
          ? props.history.push(`/admin/offers/`)
          : showErrorDialog(setError);
      })
      .catch(() => {
        showErrorDialog(setError);
      });
  }

  function updateOffer(event) {
    let id = offer["@id"];
    event.preventDefault();
    if (isFormValid) {
      setLoadingRequest(true);
      let newOfferDate = new Date(offer.startDate);
      let endOfferDate = new Date(offer.endDate);
      let toISoNew = newOfferDate.toISOString();
      let toISoEnd = endOfferDate.toISOString();
      let price = {
        price: Number(offer.newPrice.price),
        priceCurrency: offer.newPrice.priceCurrency.value,
      };

      editOffer(
        id,
        toISoNew,
        toISoEnd,
        price,
        Number(offer.priority),
        new Date().toISOString()
      )
        .then((resp) => {
          if (resp.ok) {
            showSaveDialog(setSaved);
            setLoadingRequest(false);
          } else {
            showErrorDialog(setError);
            setErrorText(
              "There was an error while updating your offer, please try again"
            );
            setLoadingRequest(false);
          }

        })
        .catch(() => {
          showErrorDialog(setError);
          setErrorText(
            "There was an error while updating your offer, please try again"
          );
          setLoadingRequest(false);
        });
    } else {
      setErrorBorder();
      showErrorDialog(setError);
      setErrorText("Please enter all of the required informations");
    }
  }

  return (
    <>
      <SimpleHeader name="Offer details" parentName="Offers" />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <Header
                  title={"Offer details"}
                  edit={setEdit}
                  del={del}
                  delText={"You will not be able to recover this offer."}
                  loading={loading}
                  showIcons={true}
                />

                {error && (
                  <ErrorAlert
                    text={
                      "There was an error while loading the informations, please try again"
                    }
                  />
                )}
                {loading && <Loader />}

                {offer !== "" && (
                  <CardBody>
                    <Form>
                      <FormGroup className="row">
                        <Label
                          className="form-control-label"
                          htmlFor="example-text-input"
                          md="2"
                        >
                          Product
                        </Label>
                        <Col md="6">
                          <Input
                            disabled
                            style={{ backgroundColor: "white" }}
                            value={offer.product.name}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label
                          className="form-control-label"
                          htmlFor="example-text-input"
                          md="2"
                        >
                          Manufacturer
                        </Label>
                        <Col md="6">
                          <Input
                            disabled
                            style={{ backgroundColor: "white" }}
                            value={offer.product.manufacturer.name}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label
                          className="form-control-label"
                          htmlFor="example-text-input"
                          md="2"
                        >
                          Start date
                        </Label>
                        <Col md="6">
                          <Input
                            disabled={edit ? false : true}
                            defaultValue={moment(offer.startDate).format(
                              "YYYY-MM-DDTHH:mm"
                            )}
                            style={{
                              backgroundColor: "white",
                              borderColor: errorStartDate ? "red" : "",
                            }}
                            name="startDate"
                            type="datetime-local"
                            placeholder="The starting date for the offer"
                            onChange={(event) => addStartDate(event)}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup className="row">
                        <Label
                          className="form-control-label"
                          htmlFor="example-text-input"
                          md="2"
                        >
                          End date
                        </Label>
                        <Col md="6">
                          <Input
                            disabled={edit ? false : true}
                            style={{
                              backgroundColor: "white",
                              borderColor: errorEndDate ? "red" : "",
                            }}
                            defaultValue={moment(offer.endDate).format(
                              "YYYY-MM-DDTHH:mm"
                            )}
                            onChange={(event) => addEndDate(event)}
                            name="endDate"
                            type="datetime-local"
                            placeholder="The ending date for the offer"
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup className="row">
                        <Label
                          className="form-control-label"
                          htmlFor="example-text-input"
                          md="2"
                        >
                          Old price
                        </Label>
                        <Col md="6">
                          <Input
                            value={`${offer.product.price.price} `}
                            disabled
                            style={{ backgroundColor: "white" }}
                            name="currentPrice"
                            type="text"
                            placeholder="The old price of the product"
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label
                          className="form-control-label"
                          htmlFor="example-text-input"
                          md="2"
                        >
                          New price
                        </Label>
                        <Col md="6">
                          <Input
                            disabled={edit ? false : true}
                            style={{
                              backgroundColor: "white",
                              borderColor: errorPrice ? "red" : "",
                            }}
                            value={offer.newPrice.price}
                            onChange={(event) => updatePrice(event)}
                            name="newPrice"
                            type="text"
                            placeholder="The new price"
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup className="row">
                        <Label
                          className="form-control-label"
                          htmlFor="example-text-input"
                          md="2"
                        >
                          Price currency
                        </Label>
                        <Col md="6">
                          <CreatableSelect
                            isDisabled={edit ? false : true}
                            placeholder="Select currency"
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                fontSize: "0.875rem",
                                backgroundColor: COLORS.WHITE,
                                border: errorPriceCurrency
                                  ? "1px solid red"
                                  : "1px solid lightgray",
                              }),
                            }}
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                neutral50: "#8898aa",
                              },
                            })}
                            name="priceCurrency"
                            value={offer.newPrice.priceCurrency}
                            onChange={updatePriceCurrency}
                            options={PRODUCT_CURRENCY_OPTIONS}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup className="row">
                        <Label
                          className="form-control-label"
                          htmlFor="example-text-input"
                          md="2"
                        >
                          Priority
                        </Label>
                        <Col md="6">
                          <Input
                            disabled={edit ? false : true}
                            style={{
                              backgroundColor: "white",
                              borderColor: errorPriority ? "red" : "",
                            }}
                            value={offer.priority}
                            onChange={(event) => addInfo(event)}
                            name="priority"
                            type="number"
                            placeholder="Offer priority"
                          />
                        </Col>
                      </FormGroup>

                      {edit && (
                        <button
                          onClick={(event) => updateOffer(event)}
                          className="btn btn-success"
                        >
                          Save
                        </button>
                      )}
                      <div style={{ marginTop: 10 }}>
                        {error && (
                          <ErrorAlert text={errorText} />
                        )}
                        {saved && (
                          <SuccessAlert text={"Your offer has been updated"} />
                        )}
                      </div>
                    </Form>
                  </CardBody>
                )}
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Show;

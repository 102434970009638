import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardTitle,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";
import CardsHeader from "../../componentsTheme/Headers/CardsHeader.js";
import Loader from "../../utils/loader";
import {
  getTotalOrders,
  getNewOrders,
  getCanceledOrders,
  getCompletedOrders,
  getRefundedOrders,
  getPreparedOrders,
} from "../../services/orders";
import ErrorAlert from "../../utils/errorAlert";

function Dashboard(props) {
  const [totalOrders, setTotalOrders] = useState("");
  const [newOrders, setNewOrders] = useState("");
  const [completedOrders, setCompletedOrders] = useState("");
  const [refundedOrders, setRefundedOrders] = useState("");
  const [canceledOrders, setCanceledOrders] = useState("");
  const [preparedOrders, setPreparedOrders] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const ordersLoaded =
    totalOrders !== "" &&
    newOrders !== "" &&
    completedOrders !== "" &&
    refundedOrders !== "" &&
    canceledOrders !== "" &&
    preparedOrders !== "";

  useEffect(() => {


    getTotalOrders(1).then((item) => {
      setTotalOrders(item["hydra:totalItems"]);
    });


  }, []);

  useEffect(() => {
    getNewOrders()
      .then((item) => {
        setNewOrders(item["hydra:totalItems"]);
      })
      .catch(() => setError(true));
  }, []);

  useEffect(() => {
    getCompletedOrders()
      .then((item) => {
        setCompletedOrders(item["hydra:totalItems"]);
      })
      .catch(() => setError(true));
  }, []);

  useEffect(() => {
    getPreparedOrders()
      .then((item) => {
        setPreparedOrders(item["hydra:totalItems"]);
      })
      .catch(() => setError(true));
  }, []);

  useEffect(() => {
    getCanceledOrders()
      .then((item) => {
        setCanceledOrders(item["hydra:totalItems"]);
      })
      .catch(() => setError(true));
  }, []);

  useEffect(() => {
    getRefundedOrders()
      .then((item) => {
        setRefundedOrders(item["hydra:totalItems"]);
      })
      .catch(() => setError(true));
  }, []);

  useEffect(() => {
    setLoading(true);
    if (ordersLoaded) {
      setLoading(false);
      setError(false);
    }
  }, [ordersLoaded]);

  return (
    <>
      <>
        <CardsHeader name="General" parentName="Dashboards" />
        <Container className="mt--6" fluid>
          {loading && (
            <Card>
              <Loader />
            </Card>
          )}
          <div className="header-body">
            {error && (
              <ErrorAlert
                text={
                  "There was an error while loading the informations, please try again"
                }
              />
            )}
            {ordersLoaded && (
              <>
                <Row>
                  <Col md="6" xl="3">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Total orders
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {totalOrders}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                              <i className="ni ni-active-40" />
                            </div>
                          </Col>
                        </Row>
                        <p className="mt-3 mb-0 text-sm">
                          <span className="text-success mr-2">
                            <a href="/admin/orders">
                              <i className="ni ni-curved-next" /> See orders
                            </a>
                          </span>
                          {/* <span className="text-nowrap">Since last month</span> */}
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6" xl="3">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              New orders
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {newOrders}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                              <i className="ni ni-chart-pie-35" />
                            </div>
                          </Col>
                        </Row>
                        <span className="text-success mr-2">
                          <Button
                            style={{ paddingLeft: "0px" }}
                            color="link"
                            onClick={() =>
                              props.history.push(`/admin/orders`, {
                                filtered: "new",
                              })
                            }
                          >
                            <i className="ni ni-curved-next" /> See orders
                          </Button>
                        </span>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6" xl="3">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Prepared orders
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {preparedOrders}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                              <i className="ni ni-money-coins" />
                            </div>
                          </Col>
                        </Row>
                        <span className="text-success mr-2">
                          <Button
                            style={{ paddingLeft: "0px" }}
                            color="link"
                            onClick={() =>
                              props.history.push(`/admin/orders`, {
                                filtered: "prepared",
                              })
                            }
                          >
                            <i className="ni ni-curved-next" /> See orders
                          </Button>
                        </span>

                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6" xl="3">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Completed orders
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {completedOrders}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                              <i className="ni ni-chart-bar-32" />
                            </div>
                          </Col>
                        </Row>
                        <span className="text-success mr-2">
                          <Button
                            style={{ paddingLeft: "0px" }}
                            color="link"
                            onClick={() =>
                              props.history.push(`/admin/orders`, {
                                filtered: "completed",
                              })
                            }
                          >
                            <i className="ni ni-curved-next" /> See orders
                          </Button>
                        </span>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" xl="3">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Canceled orders
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {canceledOrders}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                              <i className="ni ni-chart-bar-32" />
                            </div>
                          </Col>
                        </Row>
                        <span className="text-success mr-2">
                          <Button
                            style={{ paddingLeft: "0px" }}
                            color="link"
                            onClick={() =>
                              props.history.push(`/admin/orders`, {
                                filtered: "canceled",
                              })
                            }
                          >
                            <i className="ni ni-curved-next" /> See orders
                          </Button>
                        </span>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6" xl="3">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Refunded orders
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {refundedOrders}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                              <i className="ni ni-chart-bar-32" />
                            </div>
                          </Col>
                        </Row>
                        <span className="text-success mr-2">
                          <Button
                            style={{ paddingLeft: "0px" }}
                            color="link"
                            onClick={() =>
                              props.history.push(`/admin/orders`, {
                                filtered: "refunded",
                              })
                            }
                          >
                            <i className="ni ni-curved-next" /> See orders
                          </Button>
                        </span>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </>
            )}
          </div>
        </Container>
      </>
    </>
  );
}

export default Dashboard;

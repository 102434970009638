import Login from "./components/login/Login.js";
import ProviderLogin from "./components/login/ProviderLogin.js";
import Profile from "./components/profile/Profile.js";
import Dashboard from "./components/dashboards/Dashboard.js";
import OrderList from "./components/order/List.js";
import ProductList from "./components/product/List.js";
import ShowProduct from "./components/product/Show.js";
import ShowOrder from "./components/order/Show.js";
import CreateProduct from "./components/product/Create.js";
import UploadXls from "./components/product/UploadXls.js";
import CreateCategory from "./components/category/Create.js";
import CategoryList from "./components/category/List.js";
import CreateManufacturer from "./components/manufacturer/Create.js";
import ManufacturerList from "./components/manufacturer/List.js";
import CreateUser from "./components/user/Create.js";
import UserList from "./components/user/List.js";
import ShowUser from "./components/user/Show.js";
import ProviderList from "./components/provider/List.js";
import CreateProvider from "./components/provider/Create.js";
import TimeReport from "./components/provider/TimeReport.js";
import ShowProvider from "./components/provider/Show.js";
import OfferList from "./components/offer/List.js";
import TagList from "./components/tag/List.js";
import ShowTags from "./components/tag/Show.js";
import CreateTag from "./components/tag/Create.js";
import CreateOffer from "./components/offer/Create.js";
import ShowOffer from "./components/offer/Show.js";
import EditOffer from "./components/offer/Update.js";
import ShowManufacturer from "./components/manufacturer/Show.js";
import ShowCategory from "./components/category/Show.js";
import BannerList from "./components/banners/List.js";
import CreateBanner from "./components/banners/Create.js";
import ShowBanner from "./components/banners/Show.js";
import OrderedProducts from "./components/orderedProducts/OrderedProducts.js";
import BulkEdit from "./components/product/bulkEdit/bulkEdit";
import Payments from "./components/financialReporting/Payments";
import InvoiceList from "./components/invoices/List.js";
import FinancialOrderedProducts from "./components/financialReporting/FinancialOrderedProducts";
import ShowVoucher from "./components/vouchers/Show.js";
import CreateVoucher from "./components/vouchers/Create.js";
import VoucherList from "./components/vouchers/List.js";

const routes = [
  {
    collapse: false,
    icon: "ni ni-chart-bar-32 text-darker",
    state: "dashboardsCollapse",
    path: "/dashboard",
    name: "Dashboard",
    miniName: "D",
    component: Dashboard,
    layout: "/admin",
  },
  {
    collapse: false,
    name: "Orders",
    icon: "ni ni-align-left-2 text-darker",
    state: "tablesCollapse",
    path: "/orders/show/:id",
    miniName: "O",
    component: ShowOrder,
    layout: "/admin",
    redirect: "/orders/show/:id",
  },
  {
    collapse: false,
    name: "All orders",
    icon: "ni ni-bullet-list-67 text-darker",
    state: "tables2Collapse",
    path: "/orders",
    miniName: "O",
    component: OrderList,
    layout: "/admin",
  },

  {
    collapse: false,
    name: "Ordered products",
    icon: "ni ni-collection text-darker",
    state: "orderedProductsCollapse",
    path: "/financialOrderedProducts",
    miniName: "O",
    component: FinancialOrderedProducts,
    layout: "/admin",
  },
  {
    collapse: true,
    name: "Products",
    icon: "ni ni-bag-17 text-darker",
    state: "formsCollapse",
    views: [
      {
        path: "/products/show/editBulk",
        name: "Edit products bulk",
        miniName: "P",
        component: BulkEdit,
        layout: "/admin",
        redirect: "/products/show/editBulk",
      },
      {
        path: "/products/show/:id",
        name: "Product details",
        miniName: "P",
        component: ShowProduct,
        layout: "/admin",
        redirect: "/products/show/:id",
      },

      {
        path: "/products/create",
        name: "Add product",
        miniName: "P",
        component: CreateProduct,
        layout: "/admin",
      },
      {
        path: "/products/upload",
        name: "Upload CSV",
        miniName: "X",
        component: UploadXls,
        layout: "/admin",
      },
      {
        path: "/products/",
        name: "All products",
        miniName: "P",
        component: ProductList,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Offers",
    icon: "ni ni-diamond text-darker",
    state: "offerCollapse",
    views: [
      {
        path: "/offers/show/:id",
        name: "Offer details",
        miniName: "O",
        component: ShowOffer,
        layout: "/admin",
        redirect: "/offers/show/:id",
      },
      {
        path: "/offers/edit/:id",
        name: "Edit offer",
        miniName: "O",
        component: EditOffer,
        layout: "/admin",
        redirect: "/offers/edit/:id",
      },
      {
        path: "/offers/create",
        name: "Add offer",
        miniName: "O",
        component: CreateOffer,
        layout: "/admin",
      },
      {
        path: "/offers/",
        name: "All offers",
        miniName: "O",
        component: OfferList,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Vouchers",
    icon: "ni ni-money-coins text-darker",
    state: "voucherCollapse",
    views: [
      {
        path: "/vouchers/show/:id",
        name: "Voucher details",
        miniName: "O",
        component: ShowVoucher,
        layout: "/admin",
        redirect: "/vouchers/show/:id",
      },
      {
        path: "/vouchers/create",
        name: "Add voucher",
        miniName: "O",
        component: CreateVoucher,
        layout: "/admin",
      },
      {
        path: "/vouchers",
        name: "All vouchers",
        miniName: "O",
        component: VoucherList,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Categories",
    icon: "ni ni-archive-2 text-darker",
    state: "categoriesCollapse",
    views: [
      {
        path: "/categories/show/:id",
        name: "Category details",
        miniName: "C",
        component: ShowCategory,
        layout: "/admin",
        redirect: "/categories/show/:id",
      },
      {
        path: "/categories/create",
        name: "Add category",
        miniName: "C",
        component: CreateCategory,
        layout: "/admin",
      },
      {
        path: "/categories/",
        name: "All categories",
        miniName: "C",
        component: CategoryList,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Financial Overview",
    icon: "ni ni-archive-2 text-darker",
    state: "financialOverviewCollapse",
    views: [
      {
        path: "/payments/",
        name: "Payments details",
        miniName: "C",
        component: Payments,
        layout: "/admin",
      },
      {
        path: "/invoices",
        name: "Invoices",
        miniName: "I",
        component: InvoiceList,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Providers",
    icon: "ni ni-delivery-fast text-darker",
    state: "providerCollapse",
    views: [
      {
        path: "/providers/show/:id",
        name: "Provider details",
        miniName: "P",
        component: ShowProvider,
        layout: "/admin",
        redirect: "/providers/show/:id",
      },
      {
        path: "/providers/timeReport",
        name: "Reports",
        miniName: "P",
        component: TimeReport,
        layout: "/admin",
      },
      {
        path: "/providers/create",
        name: "Add provider",
        miniName: "P",
        component: CreateProvider,
        layout: "/admin",
      },
      {
        path: "/providers/",
        name: "All providers",
        miniName: "P",
        component: ProviderList,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Manufacturers",
    icon: "ni ni-box-2 text-darker",
    state: "manufacturersCollapse",
    views: [
      {
        path: "/manufacturers/show/:id",
        name: "Manufacturers details",
        miniName: "C",
        component: ShowManufacturer,
        layout: "/admin",
        redirect: "/manufacturers/show/:id",
      },
      {
        path: "/manufacturers/create",
        name: "Add manufacturer",
        miniName: "C",
        component: CreateManufacturer,
        layout: "/admin",
      },
      {
        path: "/manufacturers/",
        name: "All manufacturers",
        miniName: "E",
        component: ManufacturerList,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Tags",
    icon: "ni ni-tag text-darker",
    state: "tagsCollapse",
    views: [
      {
        path: "/tags/show/:id",
        name: "Tags details",
        miniName: "C",
        component: ShowTags,
        layout: "/admin",
        redirect: "/tags/show/:id",
      },
      {
        path: "/tags/create",
        name: "Add tag",
        miniName: "C",
        component: CreateTag,
        layout: "/admin",
      },
      {
        path: "/tags/",
        name: "All tags",
        miniName: "E",
        component: TagList,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Banners",
    icon: "ni ni-books text-darker",
    state: "bannersCollapse",
    views: [
      {
        path: "/banners/show/:id",
        name: "Banner details",
        miniName: "B",
        component: ShowBanner,
        layout: "/admin",
        redirect: "/banners/show/:id",
      },
      {
        path: "/banners/create",
        name: "Add banner",
        miniName: "B",
        component: CreateBanner,
        layout: "/admin",
      },
      {
        path: "/banners/",
        name: "All banners",
        miniName: "B",
        component: BannerList,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Users",
    icon: "ni ni-single-02 text-darker",
    state: "usersCollapse",
    views: [
      {
        path: "/users/show/:id",
        name: "User details",
        miniName: "C",
        component: ShowUser,
        layout: "/admin",
        redirect: "/users/show/:id",
      },
      {
        path: "/register/admin",
        name: "Add user",
        miniName: "C",
        component: CreateUser,
        layout: "/admin",
      },
      {
        path: "/users/",
        name: "All users",
        miniName: "E",
        component: UserList,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Others",
    icon: "ni ni-ungroup text-darker",
    state: "examplesCollapse",
    redirect: "/login/admin/",
    views: [
      {
        path: "/login/admin",
        name: "Login",
        miniName: "L",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/profile",
        name: "Profile",
        miniName: "P",
        component: Profile,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Others",
    icon: "ni ni-ungroup text-darker",
    state: "examplesCollapse",
    redirect: "/login/provider",
    views: [
      {
        path: "/login/provider",
        name: "Login",
        miniName: "L",
        component: ProviderLogin,
        layout: "/auth",
      },
    ],
  },
];

export default routes;

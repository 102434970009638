import React, { useState, useEffect } from "react";
import { Card, CardBody, Container, Row, Col } from "reactstrap";
import SimpleHeader from "../../../componentsTheme/Headers/SimpleHeader.js";
import SuccessAlert, { showSaveDialog } from "../../../utils/succesAlert";
import ErrorAlert, { showErrorDialog } from "../../../utils/errorAlert";
import BulkImageLinks from "./bulkImageLinksContainer";
import BulkTagsInputContainer from "./bulkTagsInputContainer";
import { createProducts } from "../../../services/products";
import BulkFormatedDescription from "./bulkDescriptionContainer";
import BulkImgContainer from "./bulkImgContainer";
import Header from "../../../utils/header";
import BulkDelivery from "./bulkDelivery";
import BulkProductDetailsContainer from "./bulkProductDetailsContainer";
import { RequestLoader } from "../../../utils/loader";

function BulkEdit(props) {
  const [productsForEditing, setProductsForEditing] = useState({
    name: "",
    deliveryTime: "24",
    description: "",
    slogan: "",
    manufacturer: "",
    unitOfMeasurement: "",
    brands: [],
    categories: "",
    subcategory: "",
    price: {
      price: "",
      priceCurrency: "",
    },
    link: [],
    externalIds: "",
    sku: "",
    stockCount: 0,
    variable: "",
    visibility: "",
    images: [],
    tags: [],
  });

  const [productList, setProductList] = useState([]);

  const [links, setLinks] = useState([]);
  const [saved, setSaved] = useState(false);
  const [loadingDeliveryOptions, setLoadingDeliveryOptions] = useState(false);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [error, setError] = useState(false);
  const [errorName, setErrorName] = useState(false);
  const [errorDescription, setErrorDescription] = useState(false);
  const [errorUm, setErrorUm] = useState(false);
  const [errorMan, setErrorMan] = useState(false);
  const [errorCateg, setErrorCateg] = useState(false);
  const [errorSubcateg, setErrorSubcateg] = useState(false);
  const [errorSku, setErrorSku] = useState(false);
  const [errorPrice, setErrorPrice] = useState(false);
  const [errorPriceCurrency, setErrorPriceCurrency] = useState(false);
  const [errorText, setErrorText] = useState("");
  const role = localStorage.getItem("role");

  const isFormValid =
    productList.length > 0 ? productList[0].subcategory !== "" : "";

  useEffect(() => {
    let list = props.location.state.product;
    list.map((item) => {
      item.unitOfMeasurement = {
        value: item.unitOfMeasurement,
        label: item.unitOfMeasurement,
      };
    });
    setProductList(list);
  }, []);

  function setErrorBorder() {
    setErrorSubcateg(errorSubcateg || productList[0].subcategory == "");
  }

  async function updateBulkProducts(event) {
    event.preventDefault();
    if (isFormValid) {
      setLoadingRequest(true);
      productList.map((item) => {
        let getTag = [];
        if (item.tags !== "") {
          item.tags.forEach((item) => {
            item.hasOwnProperty("value")
              ? getTag.push(item.value)
              : getTag.push(item["@id"]);
          });
        } else {
          getTag.push({ label: "", value: "" });
        }
        let addPrice = {
          price: Number(item.price.price),
          priceCurrency: item.price.priceCurrency.hasOwnProperty("value")
            ? item.price.priceCurrency.value
            : item.price.priceCurrency,
        };
        let manuf = item.manufacturer.hasOwnProperty("value")
          ? `${item.manufacturer["value"]}`
          : `${item.manufacturer["@id"]}`;
        let categ = item.categories[0]
          ? `${item.categories[0]["@id"]}`
          : `${item.categories["value"]}`;
        let subCateg = item.subcategory.hasOwnProperty("value")
          ? `${item.subcategory["value"]}`
          : `${item.subcategory["@id"]}`;

        let prId = item["@id"];

        let URL = process.env.REACT_APP_API_ENTRYPOINT + prId;
        let res = fetch(URL, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            name: item.name,
            slogan: item.slogan,
            unitOfMeasurement: item.unitOfMeasurement.value,
            manufacturer: manuf,
            categories: [categ],
            subcategory: subCateg,
            sku: item.sku,
            stockCount: Number(item.stockCount),
            price: addPrice,
            description: item.description,
            formattedDescription: item.description,
            images: item.images,
            visibility: item.visibility,
            tags: getTag,
            deliveryTime: item.deliveryTime,
          }),
        })
          .then((res) => {
            if (res.ok) {
              showSaveDialog(setSaved);
              setLoadingRequest(false);
            } else {
              showErrorDialog(setError);
              setLoadingRequest(false);
            }
          })
          .catch((error) => {
            showErrorDialog(setError);
            setLoadingRequest(false);
          });
        return res;
      });
    } else {
      showErrorDialog(setError);
      setErrorBorder();
      setLoadingRequest(false);
      if (productList[0].subcategory == "") {
        setErrorSubcateg(true);
        setErrorText("Please select a subcategory");
      } else {
        setErrorText("Please enter all of the required informations");
      }
    }
  }

  return (
    <>
      <SimpleHeader name="Edit multiple products" parentName="Products" />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="6">
            <div className="card-wrapper">
              <Card>
                <Header
                  title={"Products currently editing"}
                  edit={true}
                  del
                  delText
                  loading={false}
                  showIcons={false}
                />
                <CardBody>
                  <BulkProductDetailsContainer
                    product={productsForEditing}
                    setProduct={setProductsForEditing}
                    productList={productList}
                    setProductList={setProductList}
                    setLoadingDeliveryOptions={setLoadingDeliveryOptions}
                    errorName={errorName}
                    setErrorName={setErrorName}
                    errorUm={errorUm}
                    setErrorUm={setErrorUm}
                    errorSku={errorSku}
                    setErrorSku={setErrorSku}
                    errorPrice={errorPrice}
                    setErrorPrice={setErrorPrice}
                    errorPriceCurrency={errorPriceCurrency}
                    setErrorPriceCurrency={setErrorPriceCurrency}
                    errorMan={errorMan}
                    setErrorMan={setErrorMan}
                    errorCateg={errorCateg}
                    setErrorCateg={setErrorCateg}
                    errorSubcateg={errorSubcateg}
                    setErrorSubcateg={setErrorSubcateg}
                    role={role}
                  />
                </CardBody>
              </Card>
            </div>
          </Col>
          <Col lg="6">
            <div className="card-wrapper">
              <BulkFormatedDescription
                product={productsForEditing}
                setProduct={setProductsForEditing}
                productList={productList}
                setProductList={setProductList}
                errorDescription={errorDescription}
                setErrorDescription={setErrorDescription}
                loading={false}
              />
              <BulkDelivery
                product={productsForEditing}
                setProduct={setProductsForEditing}
                productList={productList}
                setProductList={setProductList}
                loading={loadingDeliveryOptions}
              />
              <BulkTagsInputContainer
                newProduct={productsForEditing}
                setNewProduct={setProductsForEditing}
                productList={productList}
                setProductList={setProductList}
                loading={false}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <Header
                  title={"Images"}
                  edit={true}
                  del
                  delText
                  loading={false}
                  showIcons={false}
                />
                <CardBody>
                  <BulkImageLinks
                    links={links}
                    setLinks={setLinks}
                    newProduct={productsForEditing}
                    setNewProduct={setProductsForEditing}
                    productList={productList}
                    setProductList={setProductList}
                  />
                  <BulkImgContainer
                    links={links}
                    setLinks={setLinks}
                    newProduct={productsForEditing}
                    setNewProduct={setProductsForEditing}
                    productList={productList}
                    setProductList={setProductList}
                  />
                  <button
                    disabled={loadingRequest ? true : false}
                    onClick={(event) => updateBulkProducts(event)}
                    className="btn btn-success"
                  >
                    Update products
                  </button>
                  {loadingRequest && <RequestLoader />}
                </CardBody>
                <Col md="10">
                  {saved && (
                    <SuccessAlert text={"Your products were updated"} />
                  )}
                  {error && <ErrorAlert text={errorText} />}
                </Col>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default BulkEdit;

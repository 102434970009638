import React from "react";
// reactstrap components
import { Row, Col } from "reactstrap";
// core components
import AuthHeader from "../../componentsTheme/Headers/AuthHeader.js";

export default function ErrorPage(props) {
  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100vh",
          backgroundColor: "#172b4d",
          overflow: "hidden",
        }}
      >
        <AuthHeader />

        <Row className="justify-content-center">
          <Col lg="4" md="4" style={{ marginTop: 15, textAlign: "center" }}>
            <h1
              style={{ fontSize: "60px", color: "white", marginBottom: "30px" }}
            >
              404
            </h1>
            <h2
              style={{
                fontSize: "20px",
                color: "white",
                marginBottom: "30px",
              }}
            >
              Page not found :(
            </h2>
            <h4
              style={{ fontSize: "20px", color: "white", marginBottom: "30px" }}
            >
              Ooooups! Looks like you got lost.
            </h4>
            <a href="/">Go home</a>
          </Col>
        </Row>
      </div>
    </>
  );
}

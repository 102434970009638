export const ORDER_STATUS_OPTIONS = [
  { label: "new", value: "new" },
  { label: "prepared", value: "prepared" },
  { label: "completed", value: "completed" },
  { label: "refunded", value: "refunded" },
  { label: "canceled", value: "canceled" },
];

export const DELIVERY_STATUS_OPTIONS = [
  { label: "new", value: "new" },
  { label: "prepared", value: "prepared" },
  { label: "on the way", value: "on_the_way" },
  { label: "completed", value: "completed" },
  { label: "refunded", value: "refunded" },
  { label: "canceled", value: "canceled" },
];

export const ORDER_DELIVERY_TIME_OPTIONS = [
  { value: "12-24", label: "12 - 24 hours" },
  { value: "24-48", label: "24 - 48 hours" },
];

async function doFetch(pageNumber) {
  let url =
    process.env.REACT_APP_API_ENTRYPOINT +
    `/orders?order%5BcreatedAt%5D=desc&page=${pageNumber}`;
  return await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  }).then((response) => response.json());
}

async function fetchProductDetails(orderId) {
  let url = process.env.REACT_APP_API_ENTRYPOINT + `${orderId}`;
  return await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  }).then((response) => response.json());
}

export async function getOrderDetails(orderId) {
  return fetchProductDetails(orderId)
    .then((res) => {
      if (res.code === 401) {
        let url = process.env.REACT_APP_API_ENTRYPOINT + "/login/admin/refresh";

        return fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            refresh_token: `${localStorage.getItem("refresh_token")}`,
          }),
        })
          .then((res) => res.json())
          .then((r) => localStorage.setItem("token", r.token))
          .then(() => fetchProductDetails(orderId));
      }
      return res;
    })
    .then((data1) => {
      return data1;
    });
}

export async function getOrders(pageNumber) {
  return doFetch(pageNumber)
    .then((res) => {
      if (res.code === 401) {
        let url = process.env.REACT_APP_API_ENTRYPOINT + "/login/admin/refresh";
        return fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            refresh_token: `${localStorage.getItem("refresh_token")}`,
          }),
        })
          .then((res) => res.json())
          .then((r) => localStorage.setItem("token", r.token))
          .then(() => doFetch(pageNumber));
      }
      return res;
    })
    .then((data1) => {
      let list = [];
      data1["hydra:member"].forEach((item) => {
        list.push(item);
      });
      return list;
    });
}

export async function getTotalOrders(pageNumber) {
  return doFetch(pageNumber).then((res) => {
    if (res.code === 401) {
      let url = process.env.REACT_APP_API_ENTRYPOINT + "/login/admin/refresh";

      return fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          refresh_token: `${localStorage.getItem("refresh_token")}`,
        }),
      })
        .then((res) => res.json())
        .then((r) => localStorage.setItem("token", r.token))
        .then(() => doFetch(pageNumber));
    }
    return res;
  });
}

export async function getNewOrders() {
  let url = process.env.REACT_APP_API_ENTRYPOINT + `/orders?status=new`;
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  }).then((res) => res.json());
}

export async function getPreparedOrders() {
  let url = process.env.REACT_APP_API_ENTRYPOINT + `/orders?status=prepared`;
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  }).then((res) => res.json());
}

export async function getCanceledOrders() {
  let url = process.env.REACT_APP_API_ENTRYPOINT + `/orders?status=canceled`;
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  }).then((res) => res.json());
}

export async function getCompletedOrders() {
  let url = process.env.REACT_APP_API_ENTRYPOINT + `/orders?status=completed`;
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  }).then((res) => res.json());
}

export async function getRefundedOrders() {
  let url = process.env.REACT_APP_API_ENTRYPOINT + `/orders?status=refunded`;
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  }).then((res) => res.json());
}

export function saveDeliveryInformations(
  deliveryId,
  providerId,
  deliveryStatus,
  additionalInfos,
  orderId,
  orderStatus
) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + deliveryId;
  const res = fetch(URL, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({
      provider: providerId,
      deliveryStatus: deliveryStatus,
      additionalInfos: additionalInfos,
    }),
  }).then((response) => response.json());
  const URL2 = process.env.REACT_APP_API_ENTRYPOINT + orderId;

  fetch(URL2, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({
      status: orderStatus,
      statusReason: null,
    }),
  })
    .then((response) => response.text())
    .then((text) => text);
  return res;
}

export function cancelPlacedOrder(currentOrder, cancelationReason, deliveryId) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + currentOrder;

  const res = fetch(URL, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({
      statusReason: cancelationReason,
      status: "canceled",
    }),
  }).then((response) => response.json());

  const URL2 = process.env.REACT_APP_API_ENTRYPOINT + deliveryId;
  fetch(URL2, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({
      deliveryStatus: "canceled",
    }),
  }).then((response) => response.json());
  return res;
}

export function updateOrderStatus(currentOrder, selectedStatus) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + currentOrder;

  return fetch(URL, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({
      status: selectedStatus,
      statusReason: null,
    }),
  }).then((response) => response.json());
}

export async function getOrderById(id) {
  let url = process.env.REACT_APP_API_ENTRYPOINT + `/orders/${id}`;
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
    .then((res) => res.json())
    .then((data1) => data1);
}

export function getOrderByStatus(status, page) {
  let url =
    process.env.REACT_APP_API_ENTRYPOINT +
    `/orders?status=${status}&order%5BcreatedAt%5D=desc&page=${page}`;
  const res = fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
    .then((res) => res.json())
    .then((data1) => data1);
  return res;
}

export function getOrderByPrefDelivery(pref, page) {
  let url =
    process.env.REACT_APP_API_ENTRYPOINT +
    `/orders?preferredDelivery=${pref}&order%5BcreatedAt%5D=desc&page=${page}`;
  const res = fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
    .then((res) => res.json())
    .then((data1) => data1);
  return res;
}

async function doFetchProvider(url) {
  return await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-User-Auth": `${localStorage.getItem("token")}`,
    },
  });
}

export async function getProviderOrders(url) {
  return doFetchProvider(url)
    .then((res) => {
      if (res.status === 401) {
        let link = process.env.REACT_APP_API_ENTRYPOINT + "/login/refresh";
        return fetch(link, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            refreshToken: `${localStorage.getItem("refresh_token")}`,
          }),
        })
          .then((res) => res.json())
          .then((r) => localStorage.setItem("token", r.token))
          .then(() => doFetchProvider(url));
      }
      return res;
    })
    .then((data1) => {
      return data1;
    });
}

export function updateOrderStatusByProvider(currentOrder, selectedStatus) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + currentOrder;

  return fetch(URL, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({
      deliveryStatus: selectedStatus,

    }),
  }).then((response) => response.json());
}

export function cancelPlacedOrderByProvider(currentOrder, cancelationReason) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + currentOrder;

  return fetch(URL, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({

      deliveryStatus: "canceled",
    }),
  }).then((response) => response.json());
}

export async function deleteOrder(id) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + id;
  let res = await fetch(URL, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return res;
}

export function createOrderProduct(forOrder, product, status, quantity) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + "/order_products";
  return fetch(URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({
      forOrder,
      product,
      status,
      quantity,
    }),
  });
}

export function updateOrderProduct(id, forOrder, product, status, quantity) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + id;
  return fetch(URL, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({
      forOrder,
      product,
      status,
      quantity,
    }),
  });
}

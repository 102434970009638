import React, { useMemo, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#BEBEBE",
  borderStyle: "dashed",
  backgroundColor: "#FFF",
  color: "#000",
  fontSize: 14,
  fontWeight: 500,
  outline: "none",
  transition: "border .24s ease-in-out",
  marginTop: "20px",
  marginLeft: "5%",
  height: "80%",
  width: "80%",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  loadingWraper: {
    textAlign: "center",
    marginTop: 50,
  },
  loader: {
    color: "#FBD400",
  },
  uploadSucces: {
    color: "green",
    fontSize: "50px",
  },
  uploadError: {
    color: "red",
    fontSize: "50px",
  },
  appBarSpacer: theme.mixins.toolbar,
}));

export default function UploadProducts({
  setFile,
  multiple = false,
  accept = null,
}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [showUploadText, setShowUploadText] = useState(true);

  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
    setError(false);
    setSuccess(true);
    setShowUploadText(false);
  }, []);

  const {
    acceptedFiles,
    rejectedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, multiple, accept });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject]
  );

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <div>
      <div className="container">
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />

          {showUploadText && (
            <div>
              <p>Drag and drop your files here</p>
            </div>
          )}

          {loading && (
            <div className={classes.loadingWraper}>
              <CircularProgress className={classes.loader}></CircularProgress>
            </div>
          )}

          {success && (
            <span>
              <CheckCircleIcon className={classes.uploadSucces} />
            </span>
          )}
          {error && (
            <span>
              <HighlightOffIcon className={classes.uploadError} />
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

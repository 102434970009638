import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Container,
  Input,
  Row,
  Col,
  Label,
  FormGroup,
} from "reactstrap";
import SimpleHeader from "../../componentsTheme/Headers/SimpleHeader.js";
import DropzoneContainer from "../../utils/DropzoneContainer";
import Select from "react-select";
import SuccessAlert from "../../utils/succesAlert";
import ErrorAlert from "../../utils/errorAlert";
import Header from "../../utils/header";
import { RequestLoader } from "../../utils/loader";

function UploadXls(props) {
  const [file, setFile] = useState();
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(false);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [selectedColumnIndex, setSelectedColumnIndex] = useState([]);
  const [columnDelimiter, setColumnDelimiter] = useState(",");
  const role = localStorage.getItem('role')

  const columnName = [
    { value: "PRODUCT_SKU", label: "SKU" },
    { value: "PRODUCT_NAME", label: "Name" },
    { value: "PRODUCT_CATEGORY", label: "Category" },
    { value: "PRODUCT_MANUFACTURER", label: "Manufacturer" },
    { value: "PRODUCT_BRAND", label: "Brand" },
    { value: "PRODUCT_DESCRIPTION", label: "Description" },
    { value: "PRODUCT_SUBCATEGORY", label: "Subcategory" },
    { value: "PRODUCT_TAGS", label: "Tags" },
    { value: "PRODUCT_SLOGAN", label: "Slogan" },
    { value: "PRODUCT_IMAGES", label: "Images" },
    { value: "PRODUCT_UNIT_OF_MEASUREMENT", label: "Unit of measurement" },
    { value: "PRODUCT_PRICE", label: "Price" },
    { value: "PRODUCT_PRICE_CURRENCY", label: "Currency" },
    { value: "PRODUCT_VISIBILITY", label: "Visibility" },
    { value: "PRODUCT_STOCK_COUNT", label: "Stock count" },
    { value: "PRODUCT_VAT_INCLUDED", label: "VAT included" },
    { value: "PRODUCT_VAT_PERCENTAGE", label: "VAT percentage" },
  ];

  const [fileColumns, setFileColumns] = useState();

  async function readColumnNames() {
    const fileText = await file.text();
    const firstLine = fileText.split("\n")[0];
    const columns = firstLine.split(columnDelimiter);
    const columnObj = columns.map((c, index) => ({ value: index, label: c }));
    setFileColumns(columnObj);
  }

  const handleChangeRowOption = (index) => (event) => {
    let value = event.value;
    let list = [...selectedColumnIndex];
    list[index] = value;
    setSelectedColumnIndex(list);
  };

  async function saveCsv(event) {
    event.preventDefault();
    setLoadingRequest(true);

    var formData = new FormData();
    formData.append("file", file);
    formData.append("has_header_row", true);
    formData.append("column_mapping", JSON.stringify(selectedColumnIndex));
    let URL = process.env.REACT_APP_API_ENTRYPOINT + "/bulk_upload";

    let res = await fetch(URL, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: formData,
    })
      .then((res) => {
        if (res.ok) {
          setSaved(true);
          setError(false);
          setLoadingRequest(false);
        } else {
          setSaved(false);
          setError(true);
          setLoadingRequest(false);
        }
      })
      .catch((error) => {
        setSaved(false);
        setError(true);
        setLoadingRequest(false);
      });
    return res;
  }

  return (
    <>
      <SimpleHeader name="Upload CSV" parentName="Products" />
      <Container className="mt--6" fluid>
        <Row className="justify-content-center">
          <Col className="card-wrapper" lg="8">
            <div className="card-wrapper">
              <Card>
                <Header
                  title={"Upload CSV"}
                  edit={true}
                  del
                  delText
                  loading={false}
                  showIcons={false}
                />

                <CardBody>
                  <FormGroup className="row">
                    <Col md="12">
                      <DropzoneContainer
                        fileType={"CSV_FILE"}
                        accept=".csv"
                        setFile={setFile}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label"
                      htmlFor="example-text-input"
                      md="3"
                    >
                      What is the delimiter for the columns?
                    </Label>
                    <Col md="9">
                      <Form>
                        <Input
                          value={columnDelimiter}
                          name="name"
                          onChange={(e) => setColumnDelimiter(e.target.value)}
                        />
                      </Form>
                    </Col>
                  </FormGroup>
                  <Button
                    color="success"
                    outline
                    type="button"
                    onClick={readColumnNames}
                  >
                    Read file
                  </Button>
                </CardBody>
              </Card>
            </div>

            <div className="card-wrapper">
              <Card>
                <Header
                  title={"Mapping"}
                  edit={true}
                  del
                  delText
                  loading={false}
                  showIcons={false}
                />

                <CardBody>
                  {fileColumns &&
                    fileColumns.map((pr, index) => {
                      return (
                        <FormGroup className="row" key={index}>
                          <Label
                            className="form-control-label"
                            htmlFor="example-text-input"
                            md="3"
                          >
                            {pr.label}
                          </Label>
                          <Col md="9">
                            <Form>
                              <Select
                                options={columnName}
                                onChange={handleChangeRowOption(index)}
                              />
                            </Form>
                          </Col>
                        </FormGroup>
                      );
                    })}
                  <Button
                    color="success"
                    outline
                    type="button"
                    onClick={(event) => saveCsv(event)}
                  >
                    Save
                  </Button>
                  {loadingRequest && <RequestLoader />}
                  <div style={{ marginTop: 10 }}>
                    {saved && (
                      <SuccessAlert text={"Your products have been added"} />
                    )}
                    {error && (
                      <ErrorAlert text={"There was an error while adding your products, please try again"} />
                    )}
                  </div>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default UploadXls;

import React from "react";
import { Redirect } from "react-router-dom";
import ErrorPage from "../components/error/errorPage";

export default function PrivateRoute(WrappedComponent, allowedRoles) {
  return class WithAuthorization extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        user: {
          role: localStorage.getItem("role"),
        },
      };
    }

    isUserAuthenticated() {
      let token = localStorage.getItem("token");
      let user = typeof token !== "undefined" && token !== null ? true : false;
      return user;
    }

    render() {
      const { role } = this.state.user;
      const isUser = this.isUserAuthenticated();
      if (isUser) {
        if (allowedRoles === role) {
          return <WrappedComponent {...this.props} />;
        } else {
          return <ErrorPage {...this.props} />;
        }
      } else {
        return <Redirect to="/auth/login/" />;
      }
    }
  };
}

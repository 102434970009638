import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  Label,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import Chip from "@material-ui/core/Chip";
import SimpleHeader from "../../componentsTheme/Headers/SimpleHeader.js";
import SuccessAlert from "../../utils/succesAlert";
import ErrorAlert from "../../utils/errorAlert";
import Loader from "../../utils/loader";
import Header from "../../utils/header";
import COLORS from "../../utils/colors";
import { getTagDetails, getProductsFromTag } from "../../services/tags";

function ShowTags(props) {
  const [currentTag, setCurrentTag] = useState("");
  const [productList, setProductsList] = useState("");
  const [pageNumber, setPageNumber] = useState("1");
  const [edit, setEdit] = useState(false);
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    let tag = props.location.state.tag;
    getTagDetails(tag)
      .then((item) => {
        setCurrentTag(item);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  }, [props.location.state.tag]);

  useEffect(() => {
    let currentList = [...productList];
    let tag = props.location.state.tag;
    getProductsFromTag(tag, pageNumber)
      .then((item) => {
        item.forEach((el) => {
          currentList.push(el);
        });
        setProductsList(currentList);
      })
      .catch(() => {
        setError(true);
      });
  }, [pageNumber]);

  function del() {
    let tag = currentTag["@id"];
    const URL = process.env.REACT_APP_API_ENTRYPOINT + "" + tag;

    const res = fetch(URL, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        props.history.push(`/admin/tags/`);
      })
      .catch(() => {
        setError(true);
      });

    return res;
  }

  function changeInfo(event) {
    setCurrentTag({
      ...currentTag,
      [event.target.name]: event.target.value,
    });
  }

  async function updateInfo(event) {
    event.preventDefault();
    let tag = currentTag["@id"];
    let URL = process.env.REACT_APP_API_ENTRYPOINT + tag;

    let res = await fetch(URL, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        name: currentTag.name,
      }),
    })
      .then((response) => {
        setSaved(true);
      })
      .catch(() => {
        setError(true);
      });
    return res;
  }

  function loadMoreProducts() {
    setPageNumber(Number(pageNumber) + 1);
  }

  const handleClick = (data) => {
    let id = [""];
    id.push(data);
    props.history.push(`/admin/products/show/${encodeURIComponent(data)}`, {
      product: id,
    });
  };

  return (
    <>
      <SimpleHeader name="Tag details" parentName="Tags" />
      <Container className="mt--6" fluid>
        <Row>
          {error && (
            <ErrorAlert
              text={
                "There was an error while loading the details, please try again"
              }
            />
          )}
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <Header
                  title={"Tag details"}
                  edit={setEdit}
                  del={del}
                  delText={"You will not be able to recover this tag."}
                  loading={loading}
                  showIcons={true}
                />
                {loading && <Loader />}
                {currentTag !== "" && productList !== "" && (
                  <CardBody>
                    <FormGroup className="row">
                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                        md="2"
                      >
                        Tag
                      </Label>
                      <Col md="6">
                        <Input
                          disabled={edit ? false : true}
                          onChange={(event) => changeInfo(event)}
                          value={currentTag.name}
                          name="name"
                          type="text"
                          placeholder="Enter tag"
                          style={{ backgroundColor: "white" }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="row">
                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                        md="2"
                      >
                        Products
                      </Label>
                      <Col md="10">
                        <FormGroup className="row">
                          {productList.map((data, index) => {
                            return (
                              <div key={data.value}>
                                <Chip
                                  onClick={(event) => handleClick(data.value)}
                                  label={data.label}
                                  style={{
                                    backgroundColor: "#FFD0C0",
                                    color: "#000",
                                    marginLeft: "20px",
                                    marginTop: "10px",
                                    fontFamily: "Poppins",
                                  }}
                                />
                              </div>
                            );
                          })}
                        </FormGroup>
                      </Col>
                    </FormGroup>
                    {productList.length < currentTag.products.length && (
                      <Button
                        onClick={() => loadMoreProducts()}
                        type="button"
                        style={{
                          backgroundColor: COLORS.BLUE,
                          color: COLORS.WHITE,
                          margin: "auto",
                          display: "block",
                        }}
                      >
                        Show more products labeled with this tag
                      </Button>
                    )}
                    {edit && (
                      <button
                        className="btn btn-success"
                        onClick={(event) => updateInfo(event)}
                      >
                        Save
                      </button>
                    )}
                    <div style={{ marginTop: 10 }}>
                      {saved && (
                        <SuccessAlert text={"Your tag has been updated"} />
                      )}
                    </div>
                  </CardBody>
                )}
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ShowTags;

import React, { useState, useEffect } from "react";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table,
  UncontrolledTooltip,
  Modal,
} from "reactstrap";
import Select from "react-select";
import SimpleHeader from "../../componentsTheme/Headers/SimpleHeader.js";
import {
  DELIVERY_STATUS_OPTIONS,
  cancelPlacedOrderByProvider,
  updateOrderStatusByProvider,
} from "../../services/orders";
import SuccessAlert, { showSaveDialog } from "../../utils/succesAlert";
import THEME from "../../utils/colors";
import COLORS from "../../utils/colors";
import Loader from "../../utils/loader";
import ErrorAlert, { showErrorDialog } from "../../utils/errorAlert";

function ShowOrder(props) {
  const [loading, setLoading] = useState(false);
  const [delivery, setDelivery] = useState("");
  const [cancellationDialog, setCancellationDialog] = useState(false);
  const [copied, setCopied] = useState(false);
  const [cancelationReason, setCancelationReason] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [error, setError] = useState(false);
  const [saved, setSaved] = useState(false);

  useEffect(() => {
    setLoading(true);
    setSelectedStatus({
      label:
        props.location.state.order.deliveryStatus === "on_the_way"
          ? "on the way"
          : props.location.state.order.deliveryStatus,
      value: props.location.state.order.deliveryStatus,
    });
    setDelivery(props.location.state.order);
    setLoading(false);
  }, [props.location.state.order]);

  function handleChangeStatus(selectedStatus) {
    setSelectedStatus(selectedStatus);
  }

  function toggleModal() {
    setCancellationDialog(!cancellationDialog);
    setSaved(false);
  }

  function cancelOrder() {
    let currentOrder = delivery["@id"];
    cancelPlacedOrderByProvider(currentOrder, cancelationReason)
      .then((res) => {
        if (res.deliveryStatus === "canceled") {
          setSelectedStatus({
            label: "canceled",
            value: "canceled",
          });
          setDelivery({
            ...delivery,
            deliveryStatus: "canceled",
          });
          setCancellationDialog(false);
          showSaveDialog(setSaved);
        } else {
          showErrorDialog(setError);
        }
      })
      .catch(() => {
        showErrorDialog(setError);
      });
  }

  function updateStatus() {
    let currentOrder = delivery["@id"];
    updateOrderStatusByProvider(currentOrder, selectedStatus.value)
      .then((res) => {
        if (res.deliveryStatus) {
          setDelivery({
            ...delivery,
            deliveryStatus: selectedStatus.value,
            statusReason: null,
          });
          showSaveDialog(setSaved);
        } else {
          showErrorDialog(setError);
        }
      })
      .catch(() => {
        showErrorDialog(setError);
      });
  }

  function copyTable() {
    const getTable = document.querySelector("Table");
    let range, sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(getTable);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(getTable);
        sel.addRange(range);
      }
      document.execCommand("copy");
    }
    sel.removeAllRanges();
    setCopied(true);
  }

  return (
    <>
      <SimpleHeader name="Order details" parentName="Provider orders" />
      <Container className="mt--6" fluid>
        {loading && (
          <Card>
            <Loader />
          </Card>
        )}
        {error && (
          <ErrorAlert
            text={
              "There was an error while loading the transport details, please try again"
            }
          />
        )}
        {delivery !== "" && (
          <>
            <Row>
              <Col lg="12">
                <div className="card-wrapper">
                  <Card>
                    <CardHeader>
                      <h3 className="mb-0">
                        Transport {""}
                        <>
                          {delivery["@id"].slice(12, 20)} from{" "}
                          {new Date(delivery["createdAt"]).toLocaleDateString()}
                          <div style={{ float: "right" }}>
                            <Badge color="" className="badge-dot mr-2">
                              <i
                                className={
                                  delivery["status"] === "new"
                                    ? "bg-success"
                                    : "bg-warning"
                                }
                              />
                              <span style={{ fontWeight: 600, fontSize: 14 }}>
                                {delivery["deliveryStatus"] === "on_the_way"
                                  ? "on the way"
                                  : delivery["deliveryStatus"]}
                              </span>
                            </Badge>
                          </div>
                        </>
                      </h3>
                    </CardHeader>
                    <CardBody>
                      {delivery !== "" && (
                        <Form>
                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="example-text-input"
                              md="2"
                            >
                              Status
                            </Label>
                            <Col md="6">
                              <Select
                                placeholder="Update status"
                                value={selectedStatus}
                                onChange={handleChangeStatus}
                                options={DELIVERY_STATUS_OPTIONS}
                                styles={{
                                  control: (base, state) => ({
                                    ...base,
                                    fontSize: 14,
                                  }),
                                }}
                              />
                            </Col>
                            {delivery.deliveryStatus !==
                              selectedStatus.value && (
                              <Col md="2">
                                <Button
                                  outline
                                  type="button"
                                  onClick={updateStatus}
                                  style={{ color: COLORS.BLUE }}
                                >
                                  Update status
                                </Button>
                              </Col>
                            )}
                          </FormGroup>

                          {delivery.statusReason && (
                            <FormGroup className="row">
                              <Label
                                className="form-control-label"
                                htmlFor="example-search-input"
                                md="2"
                              >
                                Reason
                              </Label>
                              <Col md="6">
                                <Input
                                  value={`${delivery.statusReason}`}
                                  disabled
                                  style={{ backgroundColor: "white" }}
                                  name="customer"
                                  type="text"
                                  placeholder="Customer phone number"
                                />
                              </Col>
                            </FormGroup>
                          )}
                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="example-search-input"
                              md="2"
                            >
                              Delivery time
                            </Label>
                            <Col md="6">
                              <Input
                                value={delivery.deliveryTime + " hours"}
                                disabled
                                style={{ backgroundColor: "white" }}
                                name="customer"
                                type="text"
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="example-search-input"
                              md="2"
                            >
                              Name
                            </Label>
                            <Col md="6">
                              <Input
                                value={`${delivery.orders[0].customer.firstName} ${delivery.orders[0].customer.lastName}`}
                                disabled
                                style={{ backgroundColor: "white" }}
                                name="customer"
                                type="text"
                                placeholder="The customer that has placed this order"
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="example-search-input"
                              md="2"
                            >
                              Email
                            </Label>
                            <Col md="6">
                              <Input
                                value={`${delivery.orders[0].customer.email}`}
                                disabled
                                style={{ backgroundColor: "white" }}
                                name="customer"
                                type="text"
                                placeholder="Customer email address"
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="example-search-input"
                              md="2"
                            >
                              Phone number
                            </Label>
                            <Col md="6">
                              <Input
                                value={`${delivery.orders[0].customer.phoneNumber}`}
                                disabled
                                style={{ backgroundColor: "white" }}
                                name="customer"
                                type="text"
                                placeholder="Customer phone number"
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="example-tel-input"
                              md="2"
                            >
                              Shipping Address
                            </Label>
                            <Col md="6">
                              <Input
                                value={
                                  delivery.orders[0].shippingAddress
                                    .streetAddress +
                                  ", " +
                                  delivery.orders[0].shippingAddress.city +
                                  ", " +
                                  delivery.orders[0].shippingAddress.county +
                                  ", " +
                                  delivery.orders[0].shippingAddress.country
                                }
                                disabled
                                style={{ backgroundColor: "white" }}
                                name="shippingAddress"
                                type="text"
                                placeholder="The address the items should ship to"
                              />
                            </Col>
                          </FormGroup>

                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="example-password-input"
                              md="2"
                            >
                              Customer observations
                            </Label>
                            <Col md="6">
                              <Input
                                value={delivery.orders[0].userObservations}
                                disabled
                                style={{ backgroundColor: "white" }}
                                name="userObservations"
                                type="textarea"
                                rows="3"
                              />
                            </Col>
                          </FormGroup>

                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="example-password-input"
                              md="2"
                            >
                              Admin observations
                            </Label>
                            <Col md="6">
                              <Input
                                value={delivery.additionalInfos}
                                disabled
                                style={{ backgroundColor: "white" }}
                                name="userObservations"
                                type="textarea"
                                rows="3"
                              />
                            </Col>
                          </FormGroup>

                          {delivery.deliveryStatus !== "canceled" && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                marginBottom: "10px",
                              }}
                            >
                              <Button
                                outline
                                type="button"
                                onClick={() => toggleModal("defaultModal")}
                                style={{ color: THEME.DANGER }}
                              >
                                Cancel transport
                              </Button>
                            </div>
                          )}

                          {saved && (
                            <SuccessAlert
                              text={"Your transport has been updated"}
                            />
                          )}
                        </Form>
                      )}
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <div className="card-wrapper">
                  <Card>
                    <CardHeader>
                      <h3 className="mb-0">
                        Products info
                        <div style={{ float: "right" }}>
                          <Button
                            id="tooltip340498904"
                            className="btn-icon-only rounded-circle"
                            color="#00ff00"
                            onClick={() => copyTable()}
                          >
                            <span className="btn-inner--icon">
                              <i className="ni ni-single-copy-04" />
                            </span>
                          </Button>
                          <UncontrolledTooltip
                            delay={0}
                            trigger="hover focus"
                            target="tooltip340498904"
                          >
                            {copied === true ? "Copied" : "Copy To Clipbord"}
                          </UncontrolledTooltip>
                        </div>
                      </h3>
                    </CardHeader>
                    <CardBody>
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th className="sort" scope="col">
                              Product
                            </th>
                            <th className="sort" scope="col">
                              Manufacturer
                            </th>

                            <th className="sort" scope="col">
                              Quantity
                            </th>
                            <th className="sort" scope="col">
                              U.M.
                            </th>
                          </tr>
                        </thead>
                        {delivery && (
                          <tbody className="list">
                            {delivery.orderProducts.map(
                              (orderProduct, index) => {
                                const product = orderProduct.product;
                                return (
                                  <tr key={index}>
                                    <th scope="row">
                                      <span className="name mb-0 text-sm">
                                        {product.name}
                                      </span>
                                    </th>
                                    <td>{product.manufacturer.name}</td>

                                    <td className="budget">
                                      {orderProduct.quantity}
                                    </td>
                                    <td>
                                      <span className="status">
                                        {product.unitOfMeasurement}
                                      </span>
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        )}
                      </Table>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
          </>
        )}
        <Modal
          className="modal-dialog-centered"
          isOpen={cancellationDialog}
          toggle={() => toggleModal("defaultModal")}
        >
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-default">
              Cancel order
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => toggleModal("defaultModal")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <p>Are you sure you want to cancel the tranposrt?</p>
          </div>
          <div className="modal-footer">
            <Button
              onClick={cancelOrder}
              type="button"
              style={{ backgroundColor: COLORS.BLUE, color: COLORS.WHITE }}
            >
              Cancel transport
            </Button>
            <Button
              className="ml-auto"
              style={{ color: COLORS.BLUE }}
              data-dismiss="modal"
              type="button"
              onClick={() => toggleModal("defaultModal")}
            >
              Close
            </Button>
          </div>
        </Modal>
      </Container>
    </>
  );
}

export default ShowOrder;

import React, { useState } from "react";
import classnames from "classnames";
import {
  Button,
  Card,
  CardBody,
  Label,
  Form,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import SimpleHeader from "../../componentsTheme/Headers/SimpleHeader.js";
import Select from "react-select";
import SuccessAlert, { showSaveDialog } from "../../utils/succesAlert";
import ErrorAlert, { showErrorDialog } from "../../utils/errorAlert";
import Header from "../../utils/header";
import { createNewUser, USER_ROLE_OPTIONS } from "../../services/users";
import { RequestLoader } from "../../utils/loader";

function Create(props) {
  const [currentUser, setCurrentUser] = useState({
    email: "",
    password: "",
    roles: [],
  });
  const [selectedOption, setSelectedOption] = useState("");
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorUsername, setErrorUsername] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorRole, setErrorRole] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [loadingRequest, setLoadingRequest] = useState(false);

  const isFormValid =
    currentUser.email.trim().length > 0 &&
    currentUser.password.trim().length > 8 &&
    currentUser.roles.length > 0;

  function handleChange(selectedOption) {
    setCurrentUser({
      ...currentUser,
      roles: [selectedOption.value],
    });
    setErrorRole(false);
  }

  function addInfo(event) {
    setCurrentUser({
      ...currentUser,
      [event.target.name]: event.target.value,
    });
    event.target.name === "email"
      ? setErrorUsername(false)
      : setErrorPassword(false);
  }

  function setErrorBorder() {
    setErrorUsername(errorUsername || currentUser.email.length <= 0);
    setErrorPassword(errorPassword || currentUser.password.trim().length <= 8);
    setErrorRole(errorRole || currentUser.roles.length <= 0);
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  async function createUser(event) {
    event.preventDefault();
    if (isFormValid) {
      setLoadingRequest(true)
      createNewUser(currentUser.email, currentUser.roles, currentUser.password)
        .then((resp) => {
          if (resp.ok) {
            showSaveDialog(setSaved)
            setLoadingRequest(false)
          } else {
            showErrorDialog(setError)
            setErrorText('There was an error while creating the account, please try again')
            setLoadingRequest(false)
          }
        })
        .catch((error) => {
          showErrorDialog(setError);
          setErrorText('There was an error while creating the account, please try again')
          setLoadingRequest(false)
        });
    } else {
      setErrorBorder();
      showErrorDialog(setError);
      setLoadingRequest(false)
      if (currentUser.password.length < 8) {
        setErrorText('Please enter a password with min. 8 characters')
      } else {
        setErrorText('Please enter all of the required informations')
      }
    }
  }

  return (
    <>
      <SimpleHeader name="New user" parentName="Users" />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <Header
                  title={"User details"}
                  edit={true}
                  del
                  delText
                  loading={false}
                  showIcons={false}
                />

                <CardBody>
                  <Form>
                    <FormGroup className="row">
                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                        md="2"
                      >
                        Username
                      </Label>
                      <Col md="6">
                        <Input
                          style={{ borderColor: errorUsername ? "red" : "" }}
                          value={currentUser.email}
                          name="email"
                          type="email"
                          placeholder="E-mail address"
                          onChange={(event) => addInfo(event)}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                        md="2"
                      >
                        Password
                      </Label>
                      <Col md="6">
                        <FormGroup>
                          <InputGroup
                            className={classnames("input-group-merge", {
                              focused: showPassword,
                            })}
                          >
                            <Input
                              style={{
                                borderColor: errorPassword ? "red" : "",
                              }}
                              value={currentUser.password}
                              onChange={(event) => addInfo(event)}
                              name="password"
                              type={showPassword ? "text" : "password"}
                              placeholder="Min. 8 characters password"
                            />
                            <InputGroupAddon
                              addonType="append"
                              onClick={handleClickShowPassword}
                            >
                              <InputGroupText>
                                {showPassword ? (
                                  <i className="fas fa-eye-slash" />
                                ) : (
                                    <i className="fas fa-eye" />
                                  )}
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                        md="2"
                      >
                        Roles
                      </Label>
                      <Col md="6">
                        <Select
                          name="roles"
                          defaultValue={selectedOption}
                          onChange={handleChange}
                          options={USER_ROLE_OPTIONS}
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              backgroundColor: "white",
                              fontSize: 14,
                              border: errorRole
                                ? "1px solid red"
                                : "1px solid lightgray",
                            }),
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <button
                      onClick={(event) => createUser(event)}
                      className="btn btn-success"
                    >
                      Save
                    </button>
                    {loadingRequest && <RequestLoader />}
                  </Form>
                  <div style={{ marginTop: 10 }}>
                    {saved && (
                      <SuccessAlert text={"Your account has been created"} />
                    )}
                    {error && (
                      <ErrorAlert
                        text={
                          errorText
                        }
                      />
                    )}
                  </div>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Create;

import React, { useState } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { Button } from "reactstrap";

export default function QuestionAlert({ text, del }) {
  const [alert, setAlert] = useState(false);
  function questionAlert() {
    setAlert(true);
  }

  function hideAlert() {
    setAlert(null);
  }

  function yesResponse() {
    setAlert(null);
    del();
  }

  return (
    <div>
      <Button
        className="btn-icon-only rounded-circle"
        color="#00ff00"
        onClick={() => questionAlert()}
      >
        <span className="btn-inner--icon" style={{ color: "#000" }}>
          <i className="fas fa-trash-alt fa-lg" />
        </span>
      </Button>

      {alert && (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "10%" }}
          title="Are you sure?"
          showCancel
          onConfirm={() => yesResponse()}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="default"
          confirmBtnText="Ok"
          cancelBtnText="Cancel"
          btnSize=""
        >
          {text}
        </ReactBSAlert>
      )}
    </div>
  );
}

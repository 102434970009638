import React, { useState } from "react";
import { Button, Label, FormGroup, Form, Input, Col, Modal } from "reactstrap";
import {
  cancelPlacedOrder,
  saveDeliveryInformations,
} from "../../services/orders";
import SuccessAlert, { showSaveDialog } from "../../utils/succesAlert";
import ErrorAlert, { showErrorDialog } from "../../utils/errorAlert";
import THEME from "../../utils/colors";
import COLORS from "../../utils/colors";

function ClientInformation({
  order,
  setOrder,
  selectedStatus,
  setSelectedStatus,
}) {
  const [cancellationDialog, setCancellationDialog] = useState(false);
  const [cancelationReason, setCancelationReason] = useState("");
  const [error, setError] = useState(false);
  const [saved, setSaved] = useState(false);

  function toggleModal() {
    setCancellationDialog(!cancellationDialog);
    setSaved(false);
  }

  function cancelOrderText(event) {
    setCancelationReason(event.target.value);
  }

  function cancelOrder() {
    let currentOrder = order["@id"];
    order.deliveries.forEach((item) => {
      cancelPlacedOrder(currentOrder, cancelationReason, item["@id"])
        .then((res) => {
          if (res.status === "canceled") {
            order.statusReason = cancelationReason;
            order.deliveries.forEach((item) => {
              item.deliveryStatus = {
                label: res.status,
                value: res.status,
              };
            });
            setOrder(order);
            setCancellationDialog(false);
            showSaveDialog(setSaved);
          } else {
            showErrorDialog(setError);
          }
        })
        .catch(() => {
          showErrorDialog(setError);
          window.scrollTo(0, 0);
        });
    });
  }

  return (
    <>
      <Form>
        {order.statusReason && (
          <FormGroup className="row">
            <Label
              className="form-control-label"
              htmlFor="example-search-input"
              md="2"
            >
              Cancelation reason
            </Label>
            <Col md="6">
              <Input
                value={`${order.statusReason}`}
                disabled
                style={{ backgroundColor: "white" }}
                name="customer"
                type="text"
                placeholder="Customer phone number"
              />
            </Col>
          </FormGroup>
        )}

        <FormGroup className="row">
          <Label
            className="form-control-label"
            htmlFor="example-search-input"
            md="2"
          >
            Name
          </Label>
          <Col md="6">
            <Input
              value={`${order.customer.firstName} ${order.customer.lastName}`}
              disabled
              style={{ backgroundColor: "white" }}
              name="customer"
              type="text"
              placeholder="The customer that has placed this order"
            />
          </Col>
        </FormGroup>
        <FormGroup className="row">
          <Label
            className="form-control-label"
            htmlFor="example-search-input"
            md="2"
          >
            Email
          </Label>
          <Col md="6">
            <Input
              value={`${order.customer.email}`}
              disabled
              style={{ backgroundColor: "white" }}
              name="customer"
              type="text"
              placeholder="Customer email address"
            />
          </Col>
        </FormGroup>
        <FormGroup className="row">
          <Label
            className="form-control-label"
            htmlFor="example-search-input"
            md="2"
          >
            Phone number
          </Label>
          <Col md="6">
            <Input
              value={`${order.customer.phoneNumber}`}
              disabled
              style={{ backgroundColor: "white" }}
              name="customer"
              type="text"
              placeholder="Customer phone number"
            />
          </Col>
        </FormGroup>
        <FormGroup className="row">
          <Label
            className="form-control-label"
            htmlFor="example-tel-input"
            md="2"
          >
            Shipping Address
          </Label>
          <Col md="6">
            <Input
              value={
                order.shippingAddress.streetAddress +
                ", " +
                order.shippingAddress.city +
                ", " +
                order.shippingAddress.county +
                ", " +
                order.shippingAddress.country
              }
              disabled
              style={{ backgroundColor: "white" }}
              name="shippingAddress"
              type="text"
              placeholder="The address the items should ship to"
            />
          </Col>
        </FormGroup>
        <FormGroup className="row">
          <Label
            className="form-control-label"
            htmlFor="example-password-input"
            md="2"
          >
            Billing Address
          </Label>
          <Col md="6">
            <Input
              value={
                order.billingAddress.streetAddress +
                ", " +
                order.billingAddress.city +
                ", " +
                order.billingAddress.county +
                ", " +
                order.billingAddress.country
              }
              disabled
              style={{ backgroundColor: "white" }}
              name="billingAddress"
              type="text"
              placeholder="The address the customer should be billed on"
            />
          </Col>
        </FormGroup>
        <FormGroup className="row">
          <Label
            className="form-control-label"
            htmlFor="example-password-input"
            md="2"
          >
            Observations
          </Label>
          <Col md="6">
            <Input
              value={order.userObservations}
              disabled
              style={{ backgroundColor: "white" }}
              name="userObservations"
              type="textarea"
              rows="3"
            />
          </Col>
        </FormGroup>

        {order.status !== "canceled" && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "10px",
            }}
          >
            <Button
              outline
              type="button"
              onClick={() => toggleModal("defaultModal")}
              style={{ color: THEME.DANGER }}
            >
              Cancel order
            </Button>
          </div>
        )}
        {saved && <SuccessAlert text={"Your order has been updated"} />}
        {error && (
          <ErrorAlert
            text={
              "There was an error while updating your order, please try again"
            }
          />
        )}
      </Form>

      <Modal
        className="modal-dialog-centered"
        isOpen={cancellationDialog}
        toggle={() => toggleModal("defaultModal")}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Cancel order
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleModal("defaultModal")}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <p>Please add the reasons for your cancelation:</p>
          <Input
            id="exampleFormControlTextarea1"
            rows="3"
            type="textarea"
            required={true}
            value={cancelationReason}
            onChange={(event) => cancelOrderText(event)}
          />
        </div>
        <div className="modal-footer">
          <Button
            onClick={cancelOrder}
            type="button"
            style={{ backgroundColor: COLORS.BLUE, color: COLORS.WHITE }}
          >
            Send
          </Button>
          <Button
            className="ml-auto"
            style={{ color: COLORS.BLUE }}
            data-dismiss="modal"
            type="button"
            onClick={() => toggleModal("defaultModal")}
          >
            Close
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default ClientInformation;

import { getIn } from "formik";
import React from "react";
import { Label, Col, FormGroup, FormFeedback } from "reactstrap";
import COLORS from "../utils/colors";
import AsyncSelect from "react-select/async";
import { getTagDetailsByName } from "../services/tags";
import { getProductByName } from "../services/products";
import { getManufacturerByName } from "../services/manufacturers";
import { getCategoryByName } from "../services/categories";

export default function SelectFieldAsync({ field, form, ...props }) {
  const errorText =
    getIn(form.touched, field.name) && getIn(form.errors, field.name);

  const handleInputChange = async (newValue) => {
    const inputValue = newValue.replace(/\W/g, "");

    if (inputValue.length > 2) {
      switch (props.label) {
        case "Tag Name":
          const tagDetails = await getTagDetailsByName(newValue);
          const returnObj = tagDetails.map((obj) => ({
            value: `/tags/${obj.id}`,
            label: obj.name,
          }));
          return returnObj;

        case "Products Name":
          const prsDetails = await getProductByName(newValue, 1);
          const returnObjPrs = prsDetails.map((obj) => ({
            value: `/product/${obj.id}`,
            label: obj.name + " - " + obj.sku,
          }));
          return returnObjPrs;
        case "Category Name":
          const categoryDetails = await getCategoryByName(newValue);
          const returnObjCat = categoryDetails.map((obj) => ({
            value: `/categories/${Number(obj["@id"].match(/[0-9]+/g)[0])}`,
            label: obj.name,
          }));
          return returnObjCat;
        case "Manufacturer Name":
          const manufDetails = await getManufacturerByName(newValue);
          const parsedDetails = manufDetails["hydra:member"];
          const returnObjManuf = parsedDetails.map((obj) => ({
            value: `/manufacturers/${obj.id}`,
            label: obj.name,
          }));
          return returnObjManuf;
        case "Product Name":
          const prDetails = await getProductByName(newValue, 1);
          const returnObjPr = prDetails.map((obj) => ({
            value: `/product/${obj.id}`,
            label: obj.name + " - " + obj.sku,
          }));
          return returnObjPr;
        default:
          return null;
      }
    }
  };

  return (
    <FormGroup className="row">
      <Label className="form-control-label" htmlFor="example-text-input" md="2">
        {props.label}
      </Label>
      <Col md="6">
        <AsyncSelect
          {...field}
          {...props}
          defaultOptions
          isClearable={true}
          onChange={(option) => form.setFieldValue(field.name, option)}
          onBlur={field.onBlur}
          loadOptions={handleInputChange}
          styles={{
            control: (base, state) => ({
              ...base,
              backgroundColor: COLORS.WHITE,
              fontSize: 14,
              border: !!errorText ? "1px solid red" : "1px solid #dee2e6",
              height: "calc(1.5em + 1.25rem + 5px)",
            }),
          }}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              neutral50: "#8898aa",
            },
          })}
        />
        <FormFeedback>{errorText}</FormFeedback>
      </Col>
    </FormGroup>
  );
}

import React from "react";
import { FormGroup } from "reactstrap";
import Chip from "@material-ui/core/Chip";

export default function SubcategoryChips({
  subcateg,
  setSubcategoryList,
  edit,
  setClickedChip,
}) {
  const handleClick = (data) => {
    setClickedChip(data);
  };

  return (
    <FormGroup className="row">
      <div>
        {subcateg.map((data) => {
          return (
            <Chip
              key={data.value}
              label={data.label}
              onClick={(event) => handleClick(data)}
              style={{
                backgroundColor: "#FFD0C0",
                color: "#000",
                marginLeft: "20px",
                marginTop: "10px",
                fontFamily: "Poppins",
              }}
            />
          );
        })}
      </div>
    </FormGroup>
  );
}

import React, { useState, useEffect } from "react";
import { Badge, Card, Container } from "reactstrap";
import SimpleHeader from "../../componentsTheme/Headers/SimpleHeader.js";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import getOffers from "../../services/offers";
import Loader from "../../utils/loader";
import MUIDataTable from "mui-datatables";
import ErrorAlert from "../../utils/errorAlert";
import IconButton from "@material-ui/core/IconButton";
import Header from "../../utils/header";
import getVouchers from "../../services/vouchers.js";

function List(props) {
  const [vouchers, setVouchers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState("1");
  const [count, setCount] = useState("");
  const [error, setError] = useState(false);

  const THEME = createMuiTheme({
    typography: {
      fontFamily: "Poppins",
    },
  });

  useEffect(() => {
    setLoading(true);
    getVouchers(pageNumber).then((item) => {
      setVouchers(item);
      setLoading(false);
    });
  }, [pageNumber]);

  const options = {
    count: count,
    rowsPerPage: 20,
    selectableRows: "none",
    responsive: "vertical",
    rowsPerPageOptions: [],
    download: false,
    print: false,
  };

  const columns = [
    {
      name: "ID",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value}</>;
        },
      },
    },
    {
      name: "Name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        filterType: "textField",
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value}</>;
        },
      },
    },
    {
      name: "Description",
      label: "Description",
      options: {
        filter: true,
        sort: true,
        filterType: "textField",
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value}</>;
        },
      },
    },
    {
      name: "Code",
      label: "Code",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value}</>;
        },
      },
    },
    {
      name: "StartDate",
      label: "Start Date",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{new Date(value).toLocaleDateString()}</>;
        },
      },
    },
    {
      name: "ExpiredDate",
      label: "Expire Date",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{new Date(value).toLocaleDateString()}</>;
        },
      },
    },
    {
      name: "FreeShipping",
      label: "Free Shipping",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return `${value}`;
        },
      },
    },
    {
      name: "MinimumSpend",
      label: "Minimum Spend",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return `${value}`;
        },
      },
    },
    {
      name: "MaximumSpend",
      label: "Maximum Spend",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return `${value}`;
        },
      },
    },
    {
      name: "VoucherType",
      label: "Voucher Type",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return `${value}`;
        },
      },
    },
    {
      name: "UsageLimit",
      label: "Usage Limit",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return `${value}`;
        },
      },
    },
    {
      name: "UsageLimitPerUser",
      label: "Usage Limit Per User",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return `${value}`;
        },
      },
    },
    {
      name: "Discount",
      label: "Discount Value",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return `${value}`;
        },
      },
    },
    {
      name: "DiscountPriceType",
      label: "Discount Type",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value === "relative" ? "%" : "RON";
        },
      },
    },
    {
      name: "DETAILS",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ width: "30px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IconButton
                  size="small"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={() =>
                    props.history.push(
                      `/admin/vouchers/show/${tableMeta.rowData[0]}`,
                      {
                        voucher: tableMeta.rowData[0],
                      }
                    )
                  }
                >
                  <MoreVertIcon fontSize="small" />
                </IconButton>
              </div>
            </div>
          );
        },
      },
    },
  ];

  return (
    <>
      <SimpleHeader name="All vouchers" parentName="Vouchers" />
      <Container className="mt--6" fluid>
        <Card>
          <Header
            title={"Vouchers list"}
            edit={true}
            del
            delText
            loading={false}
            showIcons={false}
          />
          {loading && <Loader />}
          {error && (
            <ErrorAlert
              text={
                "There was an error while loading the vouchers list, please try again"
              }
            />
          )}
          {!loading && (
            <>
              <MuiThemeProvider theme={THEME}>
                <MUIDataTable
                  title={""}
                  data={vouchers}
                  columns={columns}
                  options={options}
                />
              </MuiThemeProvider>
            </>
          )}
        </Card>
      </Container>
    </>
  );
}

export default List;

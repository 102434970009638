import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Input,
  Media,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Modal,
} from "reactstrap";
import { getManufacturerName } from "../../services/manufacturers";
import { getAdminData } from "../../services/users";
import { updatePassword, getProviderName } from "../../services/providers";
import COLORS from "../../utils/colors";
import ErrorAlert from "../../utils/errorAlert";
import SuccessAlert from "../../utils/succesAlert";

class AdminNavbar extends React.Component {
  state = {
    error: false,
    errorText: "",
    changePasswordDialog: false,
    email: "",
    emailSent: false,
    user: "Welcome",
    userProvider: {
      firstName: "",
      lastName: "",
    },
    loading: true,
    logo: "",
  };
  // function that on mobile devices makes the search open
  openSearch = () => {
    document.body.classList.add("g-navbar-search-showing");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-showing");
      document.body.classList.add("g-navbar-search-show");
    }, 150);
    setTimeout(function () {
      document.body.classList.add("g-navbar-search-shown");
    }, 300);
  };
  // function that on mobile devices makes the search close
  closeSearch = () => {
    document.body.classList.remove("g-navbar-search-shown");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-show");
      document.body.classList.add("g-navbar-search-hiding");
    }, 150);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hiding");
      document.body.classList.add("g-navbar-search-hidden");
    }, 300);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hidden");
    }, 500);
  };

  componentDidMount() {
    let role = localStorage.getItem("role");
    if (role === "admin") {
      getAdminData().then((item) => {
        item.logo = item.photo?.contentUrl;
        item.lastName = item.lastName || "";
        this.setState({
          user: item,
          loading: false,
        });
      });
    }
    if (role === "manufacturer") {
      getManufacturerName().then((item) => {
        item.logo = item.logo.contentUrl;
        this.setState({
          user: item,
        });
      });
    } else if (role === "provider") {
      getProviderName().then((item) => {
        this.setState({
          userProvider: item,
        });
      });
    }
  }

  logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("role");
    this.props.history.push("/auth/login/");
    window.location.reload(true);
  };

  showDialog = () => {
    this.setState({
      changePasswordDialog: true,
    });
  };

  toggleModal = () => {
    this.setState({
      error: false,
      errorText: "",
      changePasswordDialog: !this.state.changePasswordDialog,
      email: "",
      emailSent: false,
    });
  };

  handleChange = (event) => {
    this.setState({
      email: event.target.value,
      error: false,
      errorText: "",
    });
  };

  sendLink = () => {
    var emailFormat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValidEmail = emailFormat.test(
      String(this.state.email.trim()).toLowerCase()
    );
    if (isValidEmail) {
      updatePassword(this.state.email).then((res) => {
        if (res.ok) {
          this.setState({
            error: false,
            emailSent: true,
          });
        } else {
          this.setState({
            error: true,
            errorText:
              "There was an error while sending the link, please try again",
          });
        }
      });
    } else {
      this.setState({
        error: true,
        errorText: "Please enter a valid email address",
      });
    }
  };

  goToProfile = () => {
    this.props.history.push("/admin/profile");
  };

  render() {
    return (
      <>
        <Navbar
          className={classnames("navbar-top navbar-expand")}
          style={{
            backgroundColor: "#39AD4B",
            border: "2px solid #39AD4B",
          }}
        >
          <Container
            fluid
            style={{
              backgroundColor: "#39AD4B",
              border: "2px solid #39AD4B",
            }}
          >
            <Collapse navbar isOpen={true}>
              <Nav className="align-items-center ml-md-auto" navbar>
                <NavItem className="d-xl-none">
                  <div
                    className={classnames(
                      "pr-3 sidenav-toggler",
                      { active: this.props.sidenavOpen },
                      { "sidenav-toggler-dark": this.props.theme === "dark" }
                    )}
                    onClick={this.props.toggleSidenav}
                  >
                    <div className="sidenav-toggler-inner">
                      <i className="sidenav-toggler-line" />
                      <i className="sidenav-toggler-line" />
                      <i className="sidenav-toggler-line" />
                    </div>
                  </div>
                </NavItem>
                <NavItem className="d-sm-none">
                  <NavLink onClick={this.openSearch}>
                    <i className="ni ni-zoom-split-in" />
                  </NavLink>
                </NavItem>
              </Nav>
              <Nav className="align-items-center ml-auto ml-md-0" navbar>
                <UncontrolledDropdown nav>
                  <DropdownToggle className="nav-link pr-0" color="" tag="a">
                    <Media className="align-items-center">
                      <span className="avatar avatar-sm rounded-circle">
                        <img
                          alt="..."
                          src={
                            this.state.user.logo
                              ? this.state.user.logo
                              : require("../../assets/img/theme/default-avatar.png")
                          }
                        />
                      </span>
                      <Media className="ml-2 d-none d-lg-block">
                        <span
                          className="mb-0 text-sm font-weight-bold"
                          style={{ color: "white" }}
                        >
                          {localStorage.getItem("role") === "admin" &&
                            !this.state.loading &&
                            this.state.user.firstName
                            ? `${this.state.user.firstName} ${this.state.user.lastName}`
                            : ""}

                          {localStorage.getItem("role") === "manufacturer" &&
                            this.state.user.name}

                          {localStorage.getItem("role") === "provider" &&
                            `${this.state.userProvider.firstName} ${this.state.userProvider.lastName}`}
                        </span>
                      </Media>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem className="noti-title" header tag="div">
                      <h6 className="text-overflow m-0">Welcome!</h6>
                    </DropdownItem>
                    <DropdownItem divider />
                    {localStorage.getItem("role") === "admin" && (
                      <DropdownItem onClick={this.goToProfile}>
                        <i className="ni ni-single-02" />
                        <span>My profile</span>
                      </DropdownItem>
                    )}
                    {(localStorage.getItem("role") === "provider" ||
                      localStorage.getItem("role") === "manufacturer") && (
                        <DropdownItem onClick={this.showDialog}>
                          <i className="ni ni-settings-gear-65" />
                          <span>Change password</span>
                        </DropdownItem>
                      )}
                    {localStorage.getItem("role") === "admin" && (
                      <DropdownItem>
                        <i className="ni ni-settings-gear-65" />
                        <a
                          href="https://api.approzar.com/reset-password"
                          style={{ color: "black" }}
                        >
                          Change password{" "}
                        </a>
                      </DropdownItem>
                    )}
                    <DropdownItem href="/auth/login/" onClick={this.logout}>
                      <i className="ni ni-user-run" />
                      <span>Logout</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.changePasswordDialog}
          toggle={() => this.toggleModal("defaultModal")}
        >
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-default">
              Change password
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("defaultModal")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <p>Please enter your email address:</p>
            <Input
              id="exampleFormControlTextarea1"
              type="email"
              required={true}
              value={this.state.email}
              onChange={(event) => this.handleChange(event)}
            />
          </div>

          <div className="modal-footer">
            {!this.state.emailSent && (
              <Button
                onClick={() => this.sendLink()}
                type="button"
                style={{ backgroundColor: COLORS.BLUE, color: COLORS.WHITE }}
              >
                Send link to change password
              </Button>
            )}
            <Button
              className="ml-auto"
              style={{ color: COLORS.BLUE }}
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("defaultModal")}
            >
              Close
            </Button>
          </div>

          <div style={{ margin: "10px" }}>
            {this.state.error && <ErrorAlert text={this.state.errorText} />}
          </div>
          <div style={{ margin: "10px" }}>
            {this.state.emailSent && <SuccessAlert text={"Check your email"} />}
          </div>
        </Modal>
      </>
    );
  }
}
AdminNavbar.defaultProps = {
  toggleSidenav: () => { },
  sidenavOpen: false,
  theme: "dark",
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

export default AdminNavbar;

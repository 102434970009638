import React from "react";
import { Card, CardBody, Form } from "reactstrap";
import ReactQuill from "react-quill";
import Loader from "../../utils/loader";
import Header from "../../utils/header";

export default function FormatedDescription({
  product,
  setProduct,
  editProduct,
  errorDescription,
  setErrorDescription,
  loading,
}) {
  function handleChangeDescription(value) {
    setProduct({
      ...product,
      description: value,
    });
    setErrorDescription(false);
  }

  return (
    <Card>
      <Header
        title={"Description"}
        edit={true}
        del
        delText
        loading={false}
        showIcons={false}
      />

      {loading && <Loader />}
      {!loading && (
        <CardBody>
          <Form
            style={{
              border: errorDescription
                ? "1px solid red"
                : "1px solid lightgray",
            }}
          >
            <div data-quill-placeholder="Quill WYSIWYG" data-toggle="quill" />
            <ReactQuill
              readOnly={!editProduct}
              value={product.description}
              onChange={(value) => handleChangeDescription(value)}
              theme="snow"
              modules={{
                toolbar: [
                  ["bold", "italic"],
                  ["link", "blockquote", "code"],
                  [
                    {
                      list: "ordered",
                    },
                    {
                      list: "bullet",
                    },
                  ],
                ],
              }}
            />
          </Form>
        </CardBody>
      )}
    </Card>
  );
}

import React, { useState } from "react";
import { Card, Container } from "reactstrap";
import SimpleHeader from "../../../componentsTheme/Headers/SimpleHeader.js";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { getTotalManufacturerProducts } from "../../../services/manufacturers";
import ErrorAlert from "../../../utils/errorAlert";
import Header from "../../../utils/header";
import CustomToolbarSelect from "../../../utils/customToolbarSelectProductList";
import MaterialTable, { MTableToolbar } from "material-table";

function ProductList(props) {
  const [error, setError] = useState(false);
  const [
    selectedRowsForMultipleEdit,
    setSelectedRowsForMultipleEdit,
  ] = useState([]);
  const tableRef = React.createRef();

  const THEME = createMuiTheme({
    typography: {
      fontFamily: "Poppins",
    },
  });

  const columns = [
    {
      field: "images",
      title: "IMAGE",
      filtering: false,
      render: (rowData) => {
        return (
          <>
            <img
              alt="Product"
              className="avatar rounded-circle mr-1"
              src={
                rowData.images.length > 0
                  ? rowData.images[0].contentUrl
                  : "/no_image.png"
              }
            />
          </>
        );
      },
    },
    {
      field: "id",
      title: "ID",
      render: (rowData) => {
        return <>{Number(rowData["id"])}</>;
      },
    },
    {
      field: "sku",
      title: "SKU",
      render: (rowData) => {
        return <>{rowData["sku"]}</>;
      },
    },
    {
      field: "name",
      title: "NAME",
      render: (rowData) => {
        return <>{rowData["name"]}</>;
      },
    },
    {
      field: "categories",
      title: "CATEGORY",
      render: (rowData) => {
        return <>{rowData["categories"][0].name}</>;
      },
    },
    {
      field: "subcategory",
      title: "SUBCATEGORY",
      render: (rowData) => {
        return <>{rowData["subcategory"].name}</>;
      },
    },
    {
      field: "actualPrice",
      title: "PRICE (RON)",
      filtering: false,
      render: (rowData) => {
        return <>{rowData["actualPrice"] ? rowData["actualPrice"].price : 0}</>;
      },
    },
    {
      field: "visibility",
      title: "VISIBILITY",
      lookup: { visible: "visible", hidden: "hidden", gone: "gone" },
      render: (rowData) => {
        return <>{rowData["visibility"]}</>;
      },
    },
  ];

  function getUrl(query, hasPagination, id) {
    let url = process.env.REACT_APP_API_ENTRYPOINT + `/manufacturers/${id}`;
    url += "&page=" + (query.page + 1);
    url += "&pagination=" + hasPagination;
    if (query.orderBy) {
      switch (query.orderBy.title) {
        case "MANUFACTURER":
          query.orderBy.field = "manufacturer.name";
          break;
        case "CATEGORY":
          query.orderBy.field = "categories.name";
          break;
        case "SUBCATEGORY":
          query.orderBy.field = "subcategory.name";
          break;
        case "PRICE (RON)":
          query.orderBy.field = "price.price";
          break;
        case "EXPRESS DELIVERY":
          query.orderBy.field = "manufacturer.transportExpressExist";
          break;
      }
      url += `&order%5B${query.orderBy.field}%5D=${query.orderDirection}`;
    }

    if (query.filters) {
      query.filters.forEach((filter) => {
        switch (filter.column.title) {
          case "ID":
            url =
              process.env.REACT_APP_API_ENTRYPOINT +
              "/products/" +
              encodeURIComponent(filter.value);
            break;
          case "SKU":
            url += "&sku=" + encodeURIComponent(filter.value);
            break;
          case "NAME":
            url += "&name=" + encodeURIComponent(filter.value);
            break;
          case "MANUFACTURER":
            url += "&manufacturer.name=" + encodeURIComponent(filter.value);
            break;
          case "CATEGORY":
            url += "&categories.name=" + encodeURIComponent(filter.value);
            break;
          case "SUBCATEGORY":
            url += "&subcategory.name=" + encodeURIComponent(filter.value);
            break;
          case "DELIVERY HOURS":
            url += "&deliveryTime=" + encodeURIComponent(filter.value);
            break;
          case "EXPRESS DELIVERY":
            let value = filter.value.includes("y") ? true : false;
            url +=
              "&manufacturer.transportExpressExist=" +
              encodeURIComponent(value);
            break;
          case "VISIBILITY":
            url += "&visibility=" + encodeURIComponent(filter.value);
            break;
          default:
        }
      });
    }
    return url;
  }

  const getNewItems = async (query) => {
    const fetchResult = await getTotalManufacturerProducts(1);
    if (fetchResult.status > 299) {
      setError(true);
      return {
        data: [],
        page: query.page,
        totalCount: 1,
      };
    }

    return {
      data: fetchResult,
      page: query.page,
      totalCount: 1,
    };
  };

  const actions = [
    {
      name: "seeDetails",
      icon: () => <MoreVertIcon />,
      tooltip: "See more details",
      onClick: (event, rowData) =>
        props.history.push(`show/${encodeURIComponent(rowData["@id"])}`, {
          product: rowData,
        }),
      disabled: false,
      position: "row",
    },
  ];

  const selectMultipleRows = (data) => {
    setSelectedRowsForMultipleEdit(data);
  };

  return (
    <>
      <SimpleHeader name="All products" parentName="Products" />
      <Container className="mt--6" fluid>
        <Card>
          <Header
            title={"Product list"}
            edit={true}
            del
            delText
            loading={false}
            showIcons={false}
          />
          {error && (
            <ErrorAlert
              text={
                "There was an error while loading the product list, please try again"
              }
            />
          )}
          <>
            <MuiThemeProvider theme={THEME}>
              <div style={{ maxWidth: "100%" }}>
                <MaterialTable
                  columns={columns}
                  data={getNewItems}
                  title={null}
                  tableRef={tableRef}
                  options={{
                    exportButton: false,
                    selection: true,
                    sorting: true,
                    filtering: true,
                    pageSize: 30,
                    pageSizeOptions: [30],
                    search: false,
                    exportAllData: true,
                    actionsColumnIndex: -1,
                    filtering: false,
                  }}
                  actions={actions}
                  localization={{
                    header: {
                      actions: "",
                    },
                    toolbar: {
                      nRowsSelected: "{0} product(s) selected",
                    },
                    body: {
                      emptyDataSourceMessage: "No records to display",
                      filterRow: {
                        filterTooltip: "Filter",
                      },
                    },
                  }}
                  onSelectionChange={(data) => selectMultipleRows(data)}
                  components={{
                    Toolbar: (propsToolbar) => (
                      <div>
                        <MTableToolbar {...propsToolbar} />
                        <CustomToolbarSelect
                          selectedRows={selectedRowsForMultipleEdit}
                          location={props}
                        />
                      </div>
                    ),
                  }}
                />
              </div>
            </MuiThemeProvider>
          </>
        </Card>
      </Container>
    </>
  );
}

export default ProductList;

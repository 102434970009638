import React from "react";
import { Route } from "react-router-dom";
import { List, Create, Show } from "../components/provider/";

export default [
  <Route path="/providers/create" component={Create} exact key="create" />,
  <Route path="/providers/show/:id" component={Show} exact key="show" />,
  <Route path="/providers/" component={List} exact strict key="list" />,
  <Route path="/providers/:page" component={List} exact strict key="page" />,
  <Route path="/providers" component={List} exact strict key="list" />,
];

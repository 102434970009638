import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Label,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import SimpleHeader from "../../../componentsTheme/Headers/SimpleHeader.js";
import SuccessAlert, { showSaveDialog } from "../../../utils/succesAlert";
import ErrorAlert, { showErrorDialog } from "../../../utils/errorAlert";
import Header from "../../../utils/header";
import ImgContainer from "../../../utils/imgContainer";
import Loader, { RequestLoader } from "../../../utils/loader";
import {
  updateManufacturerProfile,
  MANUFACTURER_LOGO,
  getManufacturerName,
} from "../../../services/manufacturers";

function Profile(props) {
  const [manufacturer, setManufacturer] = useState({
    name: "",
    telephone: "",
    address: {
      city: "",
      county: "",
      country: "",
      streetAddress: "",
      addressAdditionalInfo: "",
      postalCode: "",
    },
    additionalInfo: "",
    slogan: "",
    logo: { contentUrl: "" },
  });

  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [errorName, setErrorName] = useState("");
  const [errorCity, setErrorCity] = useState("");
  const [errorSlogan, setErrorSlogan] = useState("");
  const [errorPhoneNumber, setErrorPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingRequest, setLoadingRequest] = useState(false);

  const isFormValid =
    manufacturer.name.trim().length > 4 &&
    manufacturer.email.trim().length > 4 &&
    manufacturer.address.city.trim().length > 0 &&
    manufacturer.telephone.length > 4 &&
    manufacturer.slogan.length < 255;

  useEffect(() => {
    setLoading(true);
    getManufacturerName()
      .then((item) => {
        let logo = item.logo ? item.logo.contentUrl : item.logo;
        item.logo = {
          contentUrl: logo,
        };
        setManufacturer(item);
        setLoading(false);
      })
      .catch((error) => {
        showErrorDialog(setError);
        setErrorText(
          "There was an error while loading the informations, please try again"
        );
      });
  }, []);

  function addInfo(event) {
    setManufacturer({
      ...manufacturer,
      [event.target.name]: event.target.value,
    });
  }

  function addInfoAddress(event) {
    let newAddress = { ...manufacturer.address };
    newAddress[event.target.name] = event.target.value;
    setManufacturer({
      ...manufacturer,
      address: newAddress,
    });
  }

  function setErrorBorder() {
    manufacturer.name.trim().length < 5
      ? setErrorName("error")
      : setErrorName("");
    manufacturer.address.city.trim().length < 1
      ? setErrorCity("error")
      : setErrorCity("");
    manufacturer.telephone.length < 5
      ? setErrorPhoneNumber("error")
      : setErrorPhoneNumber("");
    manufacturer.slogan.length >= 255
      ? setErrorSlogan("error")
      : setErrorSlogan("");
  }

  async function updateManufacturer(event) {
    event.preventDefault();
    if (isFormValid) {
      setLoadingRequest(true);
      let address = {
        city: manufacturer.address.city,
        county: manufacturer.address.county,
        country: manufacturer.address.country,
        streetAddress: manufacturer.address.streetAddress,
        additionalInfo: manufacturer.address.addressAdditionalInfo,
        postalCode: manufacturer.address.postalCode,
      };
      let image =
        manufacturer.logo.contentUrl !== "" ? manufacturer.logo : null;

      updateManufacturerProfile(
        manufacturer["@id"],
        manufacturer.name,
        manufacturer.telephone,
        address,
        manufacturer.additionalInfo,
        manufacturer.slogan,
        image
      )
        .then((res) => {
          if (res.ok) {
            showSaveDialog(setSaved);
            setSaved(true);
            setErrorName("");
            setErrorCity("");
            setErrorPhoneNumber("");
            setErrorSlogan("");
            setLoadingRequest(false);
            setTimeout(() => {
              window.location.reload();
            }, 500);
          } else {
            showErrorDialog(setError);
            setErrorText("There was an error, please try again");
            setLoadingRequest(false);
          }
        })
        .catch(() => {
          showErrorDialog(setError);
          setLoadingRequest(false);
        });
    } else {
      setErrorBorder();
      showErrorDialog(setError);
      setLoadingRequest(false);
      if (manufacturer.slogan.length >= 255) {
        setErrorText("The slogan must be max. 255 characters long");
      } else if (manufacturer.name.length <= 5) {
        setErrorText(
          "The manufacturer name must be at least 5 characters long"
        );
      } else {
        setErrorText("Please enter all of the required informations");
      }
    }
  }

  function removeImage() {
    setManufacturer({
      ...manufacturer,
      logo: { contentUrl: "" },
    });
  }

  return (
    <>
      <SimpleHeader name="Edit profile" parentName="Profile" />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <Header
                  title={"Edit profile"}
                  edit={true}
                  del
                  delText
                  loading={false}
                  showIcons={false}
                />
                {loading && <Loader />}
                {!loading && (
                  <CardBody>
                    <div style={{ display: "flex", direction: "column" }}>
                      <Col md="8">
                        <Form>
                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="example-text-input"
                              md="3"
                            >
                              Name
                            </Label>
                            <Col md="9">
                              <Input
                                style={{
                                  backgroundColor: "white",
                                  borderColor:
                                    errorName === "error" ? "red" : "",
                                }}
                                value={manufacturer.name || ""}
                                name="name"
                                type="text"
                                placeholder="The manufacturer's name"
                                onChange={(event) => addInfo(event)}
                                autoComplete="off"
                              />
                            </Col>
                          </FormGroup>

                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="example-text-input"
                              md="3"
                            >
                              Phone number
                            </Label>
                            <Col md="9">
                              <Input
                                style={{
                                  backgroundColor: "white",
                                  borderColor:
                                    errorPhoneNumber === "error" ? "red" : "",
                                }}
                                value={manufacturer.telephone}
                                name="telephone"
                                type="text"
                                placeholder="The manufacturer's phone number"
                                onChange={(event) => addInfo(event)}
                                autoComplete="off"
                              />
                            </Col>
                          </FormGroup>

                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="example-text-input"
                              md="3"
                            >
                              Street
                            </Label>
                            <Col md="9">
                              <Input
                                style={{ backgroundColor: "white" }}
                                value={
                                  manufacturer.address
                                    ? manufacturer.address.streetAddress
                                    : ""
                                }
                                name="streetAddress"
                                type="text"
                                placeholder="Street"
                                onChange={(event) => addInfoAddress(event)}
                                autoComplete="off"
                              />
                            </Col>
                          </FormGroup>

                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="example-text-input"
                              md="3"
                            >
                              Postal code
                            </Label>
                            <Col md="9">
                              <Input
                                style={{ backgroundColor: "white" }}
                                value={
                                  manufacturer.address
                                    ? manufacturer.address.postalCode
                                    : ""
                                }
                                name="postalCode"
                                type="number"
                                placeholder="Postal code"
                                onChange={(event) => addInfoAddress(event)}
                                autoComplete="off"
                              />
                            </Col>
                          </FormGroup>

                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="example-text-input"
                              md="3"
                            >
                              City
                            </Label>
                            <Col md="9">
                              <Input
                                style={{
                                  backgroundColor: "white",
                                  borderColor:
                                    errorCity === "error" ? "red" : "",
                                }}
                                value={
                                  manufacturer.address
                                    ? manufacturer.address.city
                                    : ""
                                }
                                name="city"
                                type="text"
                                placeholder="City"
                                onChange={(event) => addInfoAddress(event)}
                                autoComplete="off"
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="example-text-input"
                              md="3"
                            >
                              County
                            </Label>
                            <Col md="9">
                              <Input
                                style={{ backgroundColor: "white" }}
                                value={
                                  manufacturer.address
                                    ? manufacturer.address.county
                                    : ""
                                }
                                name="county"
                                type="text"
                                placeholder="County"
                                onChange={(event) => addInfoAddress(event)}
                                autoComplete="off"
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="example-text-input"
                              md="3"
                            >
                              Country
                            </Label>
                            <Col md="9">
                              <Input
                                style={{ backgroundColor: "white" }}
                                value={
                                  manufacturer.address
                                    ? manufacturer.address.country
                                    : ""
                                }
                                name="country"
                                type="text"
                                placeholder="Country"
                                onChange={(event) => addInfoAddress(event)}
                                autoComplete="off"
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="example-text-input"
                              md="3"
                            >
                              Additional address info
                            </Label>
                            <Col md="9">
                              <Input
                                style={{ backgroundColor: "white" }}
                                value={
                                  manufacturer.address
                                    ? manufacturer.address.addressAdditionalInfo
                                    : ""
                                }
                                name="addressAdditionalInfo"
                                type="text"
                                placeholder=" Additional informations"
                                onChange={(event) => addInfoAddress(event)}
                                autoComplete="off"
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="example-text-input"
                              md="3"
                            >
                              Slogan
                            </Label>
                            <Col md="9">
                              <Input
                                style={{
                                  backgroundColor: "white",
                                  borderColor:
                                    errorSlogan === "error" ? "red" : "",
                                }}
                                rows="3"
                                value={manufacturer.slogan}
                                name="slogan"
                                type="textarea"
                                onChange={(event) => addInfo(event)}
                                autoComplete="off"
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label
                              className="form-control-label"
                              htmlFor="example-text-input"
                              md="3"
                            >
                              Additional informations
                            </Label>
                            <Col md="9">
                              <Input
                                rows="3"
                                style={{ backgroundColor: "white" }}
                                value={manufacturer.additionalInfo}
                                name="additionalInfo"
                                type="textarea"
                                onChange={(event) => addInfo(event)}
                                autoComplete="off"
                              />
                            </Col>
                          </FormGroup>
                        </Form>
                      </Col>

                      <Col md="4">
                        <Col md="12">
                          {manufacturer.logo.contentUrl &&
                            manufacturer.logo.contentUrl !== "" && (
                              <>
                                <span
                                  style={{
                                    position: "absolute",
                                    top: "2px",
                                    right: "100px",
                                    zIndex: "100",
                                    color: "black",
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                  onClick={() => removeImage()}
                                >
                                  &times;
                                </span>
                                <img
                                  alt="..."
                                  style={{
                                    width: "250px",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    display: "block",
                                  }}
                                  src={
                                    manufacturer.logo.contentUrl
                                      ? manufacturer.logo.contentUrl
                                      : ""
                                  }
                                />
                              </>
                            )}
                        </Col>
                        {(manufacturer.logo.contentUrl === "" ||
                          !manufacturer.logo.contentUrl) && (
                          <Col md="12">
                            <ImgContainer
                              links
                              setLinks
                              newProduct={manufacturer}
                              setNewProduct={setManufacturer}
                              type={MANUFACTURER_LOGO}
                            />
                          </Col>
                        )}
                      </Col>
                    </div>

                    <button
                      onClick={(event) => updateManufacturer(event)}
                      className="btn btn-success"
                    >
                      Update
                    </button>
                    {loadingRequest && <RequestLoader />}
                    <div style={{ marginTop: 10 }}>
                      {error && <ErrorAlert text={errorText} />}
                      {saved && (
                        <SuccessAlert text={"Your profile has been updated"} />
                      )}
                    </div>
                  </CardBody>
                )}
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Profile;

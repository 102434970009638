async function doFetch(pageNumber) {
  let url = process.env.REACT_APP_API_ENTRYPOINT + `/tags?page=${pageNumber}`;
  return await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  }).then((response) => response.json());
}

export default async function getTags(pageNumber) {
  return doFetch(pageNumber)
    .then((res) => {
      if (res.code === 401) {
        let url = process.env.REACT_APP_API_ENTRYPOINT + "/login/admin/refresh";

        return fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            refresh_token: `${localStorage.getItem("refresh_token")}`,
          }),
        })
          .then((res) => res.json())
          .then((r) => localStorage.setItem("token", r.token))
          .then(() => doFetch(pageNumber));
      }
      return res;
    })
    .then((data1) => {
      return data1;
    });
}

export async function getTagDetails(id) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + `${id}`;
  const res = fetch(URL, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => response.json())
    .then((data1) => {
      return data1;
    });
  return res;
}

export async function getTagDetailsByName(name) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + `/tags?name=${name}`;
  const res = fetch(URL, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => response.json())
    .then((data1) => {
      let list = [];
      data1["hydra:member"].forEach((item) => {
        list.push(item);
      });
      return list;
    });
  return res;
}

export async function getProductsFromTag(id, pageNumber) {
  const URL =
    process.env.REACT_APP_API_ENTRYPOINT + `${id}/products?page=${pageNumber}`;
  const res = fetch(URL, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => response.json())
    .then((data1) => {
      let list = [];
      data1["hydra:member"].forEach((item) => {
        list.push({
          label: item.name,
          value: item["@id"],
        });
      });
      return list;
    });
  return res;
}

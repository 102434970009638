import React from "react";
import { Route } from "react-router-dom";
import { BrandList, CreateBrand, Update, Show } from "../components/brand/";

export default [
  <Route path="/brands/create" component={CreateBrand} exact key="create" />,
  <Route path="/brands/edit/:id" component={Update} exact key="update" />,
  <Route path="/brands/show/:id" component={Show} exact key="show" />,
  <Route path="/brands/" component={BrandList} exact strict key="list" />,
  <Route path="/brands/:page" component={BrandList} exact strict key="page" />,
  <Route path="/brands" component={BrandList} exact strict key="list" />,
];

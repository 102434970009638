import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Form,
  FormGroup,
  Label,
  Container,
  Row,
  Col,
} from "reactstrap";
import { Pie } from "react-chartjs-2";
import SimpleHeader from "../../componentsTheme/Headers/SimpleHeader.js";
import MaterialTable from "material-table";
import Select from "react-select";
import getProviders from "../../services/providers";
import { getOrders } from "../../services/orders";
import Header from "../../utils/header";

function TimeReport(props) {
  const [providers, setProviders] = useState("");
  const [providerOptions, setProviderOptions] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [providerOrders, setProviderOrders] = useState("");

  useEffect(() => {
    getProviders(1).then((pr) => {
      let list = [];
      let provChart = [];
      let numberOfDeliveries = [];
      let vehicleType = [];
      setProviders(pr);
      pr.forEach((item) => {
        let providerName = `${item["firstName"]} ${item["lastName"]}`;
        numberOfDeliveries.push(item.deliveries.length);
        let selectableList = {};
        selectableList.label = providerName;
        selectableList.value = item["@id"];
        list.push(selectableList);
        provChart.push(providerName);
        vehicleType.push(item.vehicleType);
      });
      setProviderOptions(list);
      let chartD = { ...chartData.data };
      chartD.labels = provChart;
      chartD.datasets[0].data = numberOfDeliveries;
      setCharData({
        ...chartData,
        data: chartD,
      });
      var counts = {};
      vehicleType.forEach(function (x) {
        counts[x] = (counts[x] || 0) + 1;
      });
      let chartVeh = { ...chartVehicleType.data };
      chartVeh.labels = [...new Set(vehicleType)];
      chartVeh.datasets[0].data = Object.values(counts);
      setChartVehicleType({
        ...chartVehicleType,
        data: chartVeh,
      });
    });
  }, []);

  function handleChange(selectedOption) {
    setSelectedOption(selectedOption);
    let prId = selectedOption.value;
    const URL = process.env.REACT_APP_API_ENTRYPOINT + `${prId}/deliveries`;
    const res = fetch(URL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((data1) => {
        let list = [];
        data1["hydra:member"].forEach((item) => {
          list.push(item);
        });
        setProviderOrders(list);
        setProviders(list);
        return list;
      });
    return res;
  }

  var colors = {
    theme: {
      default: "#172b4d",
      primary: "#5e72e4",
      secondary: "#f4f5f7",
      info: "#11cdef",
      success: "#2dce89",
      danger: "#f5365c",
      warning: "#fb6340",
    },
  };

  const [chartData, setCharData] = useState({
    data: {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [
            colors.theme["warning"],
            colors.theme["success"],
            colors.theme["primary"],
            colors.theme["info"],
          ],
          label: "Dataset 1",
        },
      ],
    },
    options: {
      responsive: true,
      legend: {
        position: "right",
      },
      animation: {
        animateScale: true,
        animateRotate: true,
      },
    },
  });

  const [chartVehicleType, setChartVehicleType] = useState({
    data: {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [
            colors.theme["warning"],
            colors.theme["success"],
            colors.theme["primary"],
            colors.theme["info"],
          ],
          label: "Dataset 2",
        },
      ],
    },
    options: {
      responsive: true,
      legend: {
        position: "right",
      },
      animation: {
        animateScale: true,
        animateRotate: true,
      },
    },
  });

  return (
    <>
      <SimpleHeader name="Provider reports" parentName="Providers" />
      <Container className="mt--6" fluid>
        <Card>
          <Header
            title={"Provider delivery time"}
            edit={true}
            del
            delText
            loading={false}
            showIcons={false}
          />

          <CardBody>
            <Row>
              <Col md="4">
                <Card>
                  <CardHeader>
                    <h6 className="surtitle">Total alocated deliveries</h6>
                  </CardHeader>
                  <CardBody>
                    <div className="chart">
                      <Pie
                        data={chartData.data}
                        options={chartData.options}
                        className="chart-canvas"
                        id="chart-pie"
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card>
                  <CardHeader>
                    <h6 className="surtitle">Vehicle type</h6>
                  </CardHeader>
                  <CardBody>
                    <div className="chart">
                      <Pie
                        data={chartVehicleType.data}
                        options={chartVehicleType.options}
                        className="chart-canvas"
                        id="chart-pie2"
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <FormGroup className="row">
                  <Label
                    className="form-control-label"
                    htmlFor="example-text-input"
                    md="6"
                  >
                    Select provider
                  </Label>
                  <Col md="6">
                    <Form>
                      <Select
                        defaultValue={selectedOption}
                        onChange={handleChange}
                        options={providerOptions}
                      />
                    </Form>
                  </Col>
                </FormGroup>
              </Col>
            </Row>

            {selectedOption !== "" && (
              <>
                <Row>
                  <Col md="3">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Total deliveries
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {providerOrders.length}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                              <i className="ni ni-active-40" />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>


                </Row>
                <MaterialTable
                  columns={[
                    {
                      title: "TRANSPORT ID",
                      field: "@id",
                      render: (rowData) => {
                        return <>{rowData["@id"].slice(12, 20)} </>;
                      },
                    },
                    {
                      title: "DATE",
                      field: "createdAt",
                      render: (rowData) => {
                        return (
                          <div>
                            {new Date(rowData.createdAt).toLocaleDateString()}
                          </div>
                        );
                      },
                    },

                    {
                      title: "DELIVERY TIME",
                      field: "deliveryTime",
                      render: (rowData) => {
                        return <div>{rowData.deliveryTime} hours</div>;
                      },
                    },
                    {
                      title: "DELIVERY STATUS",
                      field: "deliveryStatus",
                      render: (rowData) => {
                        return <div>{rowData.deliveryStatus}</div>;
                      },
                    },
                  ]}
                  options={{
                    exportButton: true,
                    search: false,
                    actionsColumnIndex: -1,
                    pageSize: 5,
                    headerStyle: {
                      color: "#8898aa",
                      backgroundColor: "#f6f9fc",
                      borderColor: "#e9ecef",
                      fontSize: 10,
                      fontWeight: 600,
                    },
                  }}
                  data={providers}
                  title=""
                  localization={{
                    header: {
                      actions: "",
                    },
                  }}
                />
              </>
            )}
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default TimeReport;

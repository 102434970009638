import { getIn } from "formik";
import React from "react";
import { Label, Col, FormGroup, Input, FormFeedback } from "reactstrap";

export default function TextFormField({ field, form, ...props }) {
  const errorText =
    getIn(form.touched, field.name) && getIn(form.errors, field.name);

  return (
    <FormGroup className="row">
      <Label className="form-control-label" htmlFor="example-text-input" md="2">
        {props.label}
      </Label>
      <Col md="6">
        <Input
          invalid={!!errorText}
          helperText={errorText}
          {...field}
          {...props}
        />
        <FormFeedback>{errorText}</FormFeedback>
      </Col>
    </FormGroup>
  );
}

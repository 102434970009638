import React, { useState, useEffect } from "react";
import { Button, Card, CardBody, Label, FormGroup, Col } from "reactstrap";
import Select from "react-select";
import Chip from "@material-ui/core/Chip";
import getTags from "../../services/tags";
import { getTotalManufacturerTags } from "../../services/manufacturers";
import COLORS from "../../utils/colors";
import Loader from "../../utils/loader";
import Header from "../../utils/header";

export default function TagsInputContainer({
  newProduct,
  setNewProduct,
  editProduct,
  loading,
}) {
  const [chipValue, setChipValue] = useState("");
  const [chipData, setChipData] = useState([]);
  const [mykey, setmykey] = useState(0);
  const role = localStorage.getItem("role");
  const [tagOptions, setTagOptions] = useState([
    {
      value: "",
      label: "",
    },
  ]);

  useEffect(() => {
    if (role === "admin") {
      getTags(1).then((tag) => {
        let list = [];
        tag["hydra:member"].forEach((item) => {
          let selectableList = {};
          selectableList.label = item["name"];
          selectableList.value = item["@id"];
          list.push(selectableList);
        });
        setTagOptions(list);
      });
    }

    if (role === "manufacturer") {
      getTotalManufacturerTags(1).then((tag) => {
        let list = [];
        tag.forEach((item) => {
          let selectableList = {};
          selectableList.label = item["name"];
          selectableList.value = item["@id"];
          list.push(selectableList);
        });
        setTagOptions(list);
      });
    }
  }, []);

  useEffect(() => {
    setChipData(newProduct.tags);
  }, [newProduct.tags]);

  function changeChipData(selectedOption) {
    setChipValue(selectedOption);
  }

  function addChip() {
    setmykey(mykey + 1);
    setChipData([
      ...chipData,
      { key: mykey, label: chipValue.label, value: chipValue.value },
    ]);

    setNewProduct({
      ...newProduct,
      tags: [
        ...chipData,
        { key: mykey, label: chipValue.label, value: chipValue.value },
      ],
    });
    setChipValue("");
  }

  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) => chips.filter((chip) => chip !== chipToDelete));
    let currentTags = [...newProduct.tags].filter(
      (prod) => prod !== chipToDelete
    );
    setNewProduct({
      ...newProduct,
      tags: currentTags,
    });
  };

  return (
    <Card>
      <Header
        title={"Tags"}
        edit={true}
        del
        delText
        loading={false}
        showIcons={false}
      />

      {loading && <Loader />}
      {!loading && (
        <CardBody>
          <FormGroup className="row">
            {editProduct && (
              <>
                <Label
                  className="form-control-label"
                  htmlFor="example-week-input"
                  md="2"
                >
                  Tags
                </Label>
                <Col md="8">
                  <Select
                    name="tags"
                    value={chipValue}
                    onChange={changeChipData}
                    options={tagOptions}
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        fontSize: "0.875rem",
                      }),
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        neutral50: "#8898aa",
                      },
                    })}
                  />
                </Col>
                <Col md="2">
                  <Button
                    disabled={chipValue ? false : true}
                    onClick={addChip}
                    style={{
                      color: COLORS.BLACK,
                    }}
                  >
                    Add
                  </Button>
                </Col>
              </>
            )}
            {newProduct !== "" && (
              <div>
                {newProduct.tags.map((data, index) => {
                  return (
                    <Chip
                      key={index}
                      label={data.label}
                      onDelete={handleDelete(data)}
                      style={{
                        backgroundColor: "#FFD0C0",
                        color: "#000",
                        marginLeft: "20px",
                        marginTop: "10px",
                        fontFamily: "Poppins",
                      }}
                    />
                  );
                })}
              </div>
            )}
          </FormGroup>
        </CardBody>
      )}
    </Card>
  );
}

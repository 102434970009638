import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Table,
  UncontrolledTooltip,
} from "reactstrap";

function ProductInformations({ order }) {
  const [copied, setCopied] = useState(false);

  function copyTable() {
    const getTable = document.querySelector("Table");
    let range, sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(getTable);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(getTable);
        sel.addRange(range);
      }
      document.execCommand("copy");
    }
    sel.removeAllRanges();
    setCopied(true);
  }

  return (
    <>
      {order !== "" && (
        <>
          <div className="card-wrapper">
            <Card>
              <CardHeader>
                <h3 className="mb-0">
                  Products info
                  <div style={{ float: "right" }}>
                    <Button
                      id="tooltip340498904"
                      className="btn-icon-only rounded-circle"
                      color="#00ff00"
                      onClick={() => copyTable()}
                    >
                      <span className="btn-inner--icon">
                        <i className="ni ni-single-copy-04" />
                      </span>
                    </Button>
                    <UncontrolledTooltip
                      delay={0}
                      trigger="hover focus"
                      target="tooltip340498904"
                    >
                      {copied === true ? "Copied" : "Copy To Clipbord"}
                    </UncontrolledTooltip>
                  </div>
                </h3>
              </CardHeader>
              <CardBody>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th className="sort" scope="col">
                        Product
                      </th>
                      <th className="sort" scope="col">
                        Price/u.m
                      </th>
                      <th className="sort" scope="col">
                        Quantity
                      </th>
                      <th className="sort" scope="col">
                        U.M.
                      </th>
                      <th scope="col">Total</th>
                      <th></th>
                    </tr>
                  </thead>
                  {order && (
                    <tbody className="list">
                      {order.orderProducts.map((orderProduct, index) => {
                        const product = orderProduct.product;
                        if (product.name.includes("Transport")) {
                          // TODO: Make this logic better
                          order.products.sort((a, b) => {
                            var first = a.product.manufacturer.name.toUpperCase();
                            var next = b.product.manufacturer.name.toUpperCase();
                            return first < next ? -1 : first > next ? 1 : 0;
                          });
                          let transportProduct = order.products.filter(
                            (item) => !item.product.name.includes("Transport")
                          );
                          transportProduct.push(orderProduct);
                          order.products = transportProduct;
                        }

                        return (
                          <tr
                            key={index}
                            style={{
                              color:
                                order.orderProducts[index].status ===
                                  "removed" ||
                                order.orderProducts[index].quantity === 0
                                  ? "red"
                                  : "",
                              textDecoration:
                                order.orderProducts[index].status ===
                                  "removed" ||
                                order.orderProducts[index].quantity === 0
                                  ? "line-through"
                                  : "",
                            }}
                          >
                            <th scope="row">
                              <span className="name mb-0 text-sm">
                                {product.name}
                              </span>
                            </th>
                            <td>
                              <span className="status">
                                {product.actualPrice.price}{" "}
                                {product.actualPrice.priceCurrency}
                              </span>
                            </td>
                            <td className="budget">
                              {orderProduct.quantity !== null
                                ? orderProduct.quantity
                                : "1"}
                            </td>
                            <td>
                              <span className="status">
                                {product.unitOfMeasurement}
                              </span>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <span className="completion mr-2">
                                  {orderProduct.quantity *
                                    product.actualPrice.price}{" "}
                                  {order.totalPrice.priceCurrency}
                                </span>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                      <tr className="name mb-0 text-sm">
                        <td></td>
                        <td></td>
                        <td></td>
                        <th>Total products price </th>
                        <td>
                          {order.total} {order.totalPrice.priceCurrency}
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  )}
                </Table>
              </CardBody>
            </Card>
          </div>
        </>
      )}
    </>
  );
}

export default ProductInformations;

export const MANUFACTURER_DELIVERY_TIME = [
  {
    value: "4",
    label: "4 hours",
  },
  {
    value: "12",
    label: "12 hours",
  },
  {
    value: "24",
    label: "24 hours",
  },
  {
    value: "48",
    label: "48 hours",
  },
  {
    value: "72",
    label: "72 hours",
  },
];

export const MANUFACTURER_LOGO = "MANUFACTURER_LOGO ";

export async function doFetch(pageNumber) {
  let url =
    process.env.REACT_APP_API_ENTRYPOINT + `/tenants?page=${pageNumber}`;
  return await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  }).then((response) => response.json());
}

export default async function getManufacturers(pageNumber) {
  return doFetch(pageNumber)
    .then((res) => {
      if (res.code === 401) {
        let url = process.env.REACT_APP_API_ENTRYPOINT + "/login/admin/refresh";

        return fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            refresh_token: `${localStorage.getItem("refresh_token")}`,
          }),
        })
          .then((res) => res.json())
          .then((r) => localStorage.setItem("token", r.token))
          .then(() => doFetch(pageNumber));
      }
      return res;
    })
    .then((data1) => data1);
}

export function getDetails(id) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + `${id}`;
  const res = fetch(URL, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => response.json())
    .then((data1) => {
      return data1;
    });
  return res;
}

export function createManufacturer(
  name,
  legalName,
  telephone,
  email,
  address,
  additionalInfo,
  slogan,
  transportExpressExist,
  deliveryTime,
  deliveryPrice,
  deliveryTimeExpress,
  deliveryPriceExpress,
  vatId,
  regCom,
  bankAccount,
  bankName,
  logo,
  vatPayer,
  isVerified,
  password
) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + `/manufacturers`;
  const res = fetch(URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({
      name,
      legalName,
      telephone,
      email,
      address,
      additionalInfo,
      slogan,
      transportExpressExist,
      deliveryTime,
      deliveryPrice,
      deliveryTimeExpress,
      deliveryPriceExpress,
      vatId,
      regCom,
      bankAccount,
      bankName,
      logo,
      vatPayer,
      isVerified,
      password,
    }),
  });
  return res;
}

export function updateManufacturerInfo(
  id,
  name,
  legalName,
  telephone,
  email,
  address,
  additionalInfo,
  slogan,
  transportExpressExist,
  deliveryTime,
  deliveryPrice,
  deliveryTimeExpress,
  deliveryPriceExpress,
  vatId,
  regCom,
  bankAccount,
  bankName,
  logo,
  vatPayer,
  isVerified
) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + id;
  const res = fetch(URL, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({
      name,
      legalName,
      telephone,
      email,
      address,
      additionalInfo,
      slogan,
      transportExpressExist,
      deliveryTime,
      deliveryPrice,
      deliveryTimeExpress,
      deliveryPriceExpress,
      vatId,
      regCom,
      bankAccount,
      bankName,
      logo,
      vatPayer,
      isVerified,
    }),
  });
  return res;
}

export function deleteManufacturer(id) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + id;
  const res = fetch(URL, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return res;
}

export function manufacturerOfTheWeek(id) {
  const URL_PROD = process.env.REACT_APP_API_ENTRYPOINT + "/settings/11";
  const res = fetch(URL_PROD, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({
      env: "prod",
      values: {
        manufacturerOfTheWeek: id,
        expiresExpressDelivery: 14,
        deliveriesId: ["/products/345", "/products/346", "/products/347"],
        startExpressDelivery: 8,
      },
    }),
  }).then((response) => response.json());
  const URL_DEV = process.env.REACT_APP_API_ENTRYPOINT + "/settings/12";
  fetch(URL_DEV, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({
      env: "dev",
      values: {
        manufacturerOfTheWeek: id,
        expiresExpressDelivery: 14,
        deliveriesId: ["/products/345", "/products/346", "/products/347"],
        startExpressDelivery: 8,
      },
    }),
  });
  return res;
}

export async function fetchManufacturer() {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + "/settings";
  return await fetch(URL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  }).then((response) => response.json());
}

export async function getManufacturerOfTheWeek() {
  return fetchManufacturer()
    .then((res) => {
      if (res.code === 401) {
        let url = process.env.REACT_APP_API_ENTRYPOINT + "/login/admin/refresh";

        return fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            refresh_token: `${localStorage.getItem("refresh_token")}`,
          }),
        })
          .then((res) => res.json())
          .then((r) => localStorage.setItem("token", r.token))
          .then(() => fetchManufacturer());
      }
      return res;
    })
    .then((data1) => data1["hydra:member"]);
}

export async function getManufacturerByName(name) {
  let url =
    process.env.REACT_APP_API_ENTRYPOINT + `/manufacturers?name=${name}`;
  return await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  }).then((response) => response.json());
}

//manufacturer view

async function doFetchManufacturer(url) {
  const urlManufacturerId =
    process.env.REACT_APP_API_ENTRYPOINT + "/customers/me";
  const id = await fetch(urlManufacturerId, {
    method: "GET",
    headers: {
      "X-User-Auth": `${localStorage.getItem("token")}`,
    },
  })
    .then((response) => response.json())
    .then((data1) => data1);

  return await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-User-Auth": `${localStorage.getItem("token")}`,
    },
  });
}

export async function getManufacturerSuborders(url) {
  return doFetchManufacturer(url).then((res) => {
    if (res.code === 401) {
      let url = process.env.REACT_APP_API_ENTRYPOINT + "/login/refresh";
      return fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          refreshToken: `${localStorage.getItem("refresh_token")}`,
        }),
      })
        .then((res) => res.json())
        .then((r) => localStorage.setItem("token", r.token))
        .then(() => doFetchManufacturer(url));
    }
    return res;
  });
}

async function doFetchManufacturerProducts(pageNumber) {
  const urlManufacturerId =
    process.env.REACT_APP_API_ENTRYPOINT + "/customers/me";
  const id = await fetch(urlManufacturerId, {
    method: "GET",
    headers: {
      "X-User-Auth": `${localStorage.getItem("token")}`,
    },
  })
    .then((response) => response.json())
    .then((data1) => data1);

  let url =
    process.env.REACT_APP_API_ENTRYPOINT +
    `/manufacturers/${id}/products?page=${pageNumber}`;

  return await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-User-Auth": `${localStorage.getItem("token")}`,
    },
  }).then((response) => response.json());
}

export async function getTotalManufacturerProducts(pageNumber) {
  return doFetchManufacturerProducts(pageNumber)
    .then((res) => {
      if (res.code === 401) {
        let url = process.env.REACT_APP_API_ENTRYPOINT + "/login/refresh";
        return fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            refreshToken: `${localStorage.getItem("refresh_token")}`,
          }),
        })
          .then((res) => res.json())
          .then((r) => localStorage.setItem("token", r.token))
          .then(() => doFetchManufacturerProducts(pageNumber));
      }
      return res;
    })
    .then((data1) => {
      let list = [];
      data1["hydra:member"].forEach((item) => {
        list.push(item);
      });
      return list;
    });
}

async function doFetchManufacturerTags(pageNumber) {
  const urlManufacturerId =
    process.env.REACT_APP_API_ENTRYPOINT + "/customers/me";
  const id = await fetch(urlManufacturerId, {
    method: "GET",
    headers: {
      "X-User-Auth": `${localStorage.getItem("token")}`,
    },
  })
    .then((response) => response.json())
    .then((data1) => data1);

  let url = process.env.REACT_APP_API_ENTRYPOINT + `/tags?page=${pageNumber}`;

  return await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-User-Auth": `${localStorage.getItem("token")}`,
    },
  }).then((response) => response.json());
}

export async function getTotalManufacturerTags(pageNumber) {
  return doFetchManufacturerTags(pageNumber)
    .then((res) => {
      if (res.code === 401) {
        let url = process.env.REACT_APP_API_ENTRYPOINT + "/login/refresh";
        return fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            refreshToken: `${localStorage.getItem("refresh_token")}`,
          }),
        })
          .then((res) => res.json())
          .then((r) => localStorage.setItem("token", r.token))
          .then(() => doFetchManufacturerTags(pageNumber));
      }
      return res;
    })
    .then((data1) => {
      let list = [];
      data1["hydra:member"].forEach((item) => {
        list.push(item);
      });
      return list;
    });
}

async function doFetchManufacturerName() {
  const urlManufacturerId =
    process.env.REACT_APP_API_ENTRYPOINT + "/customers/me";
  return await fetch(urlManufacturerId, {
    method: "GET",
    headers: {
      "X-User-Auth": `${localStorage.getItem("token")}`,
    },
  });
}

export async function getManufacturerName() {
  return doFetchManufacturerName()
    .then((res) => {
      if (res.status === 401 || res.status === 404) {
        let url = process.env.REACT_APP_API_ENTRYPOINT + "/login/refresh";
        return fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            refreshToken: `${localStorage.getItem("refresh_token")}`,
          }),
        })
          .then((res) => res.json())
          .then((r) => localStorage.setItem("token", r.token))
          .then(() => doFetchManufacturerName());
      }
      return res;
    })
    .then((r) => r.json())
    .then((data1) => {
      let url =
        process.env.REACT_APP_API_ENTRYPOINT + `/manufacturers/${data1}`;
      return fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-User-Auth": `${localStorage.getItem("token")}`,
        },
      }).then((response) => response.json());
    });
}

export async function getManufacturerCategories() {
  let url =
    process.env.REACT_APP_API_ENTRYPOINT + `/categories?exists[parent]=false`;

  return await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-User-Auth": `${localStorage.getItem("token")}`,
    },
  })
    .then((response) => response.json())
    .then((data1) => {
      let list = [];
      data1["hydra:member"].forEach((item) => {
        list.push(item);
      });
      return list;
    });
}

export async function getManufacturerSubcategories(id) {
  let url = process.env.REACT_APP_API_ENTRYPOINT + `${id}/subcategories`;

  return await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-User-Auth": `${localStorage.getItem("token")}`,
    },
  })
    .then((response) => response.json())
    .then((data1) => {
      let list = [];
      data1["hydra:member"].forEach((item) => {
        list.push(item);
      });
      return list;
    });
}

export async function getSubOrderDetails(id) {
  let url = process.env.REACT_APP_API_ENTRYPOINT + `${id}`;
  return await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-User-Auth": `${localStorage.getItem("token")}`,
    },
  }).then((response) => response.json());
}

export function updateManufacturerProfile(
  id,
  name,
  telephone,
  address,
  additionalInfo,
  slogan,
  logo
) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + id;
  const res = fetch(URL, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "X-User-Auth": `${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({
      name,
      telephone,
      address,
      additionalInfo,
      slogan,
      logo,
    }),
  });
  return res;
}

import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Label,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import SimpleHeader from "../../componentsTheme/Headers/SimpleHeader.js";
import Select from "react-select";
import SuccessAlert from "../../utils/succesAlert";
import ErrorAlert from "../../utils/errorAlert";
import Header from "../../utils/header";
import Loader, { RequestLoader } from "../../utils/loader";
import {
  updateProvider,
  deleteProvider,
  PROVIDER_VEHICLE_OPTIONS,
  getProviderDetails,
} from "../../services/providers";

function Show(props) {
  const [provider, setProvider] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    licensePlate: "",
    vehicleType: "",
    additionalInfo: "",
  });
  const [loading, setLoading] = useState(false);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [editProvider, setEditProvider] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(false);
  const [errorFirstName, setErrorFirstName] = useState(false);
  const [errorLastName, setErrorLastName] = useState(false);
  const [errorPhoneNumber, setErrorPhoneNumber] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorVehicleType, setErrorVehicleType] = useState(false);
  const [errorText, setErrorText] = useState('');

  const isFormValid =
    provider.firstName.trim().length > 0 &&
    provider.lastName.trim().length > 0 &&
    provider.phoneNumber.trim().length > 0 &&
    provider.email.trim().length > 0 &&
    provider.vehicleType.value !== "";

  useEffect(() => {
    setLoading(true);
    let pr = props.location.state.provider;
    getProviderDetails(pr).then((item) => {
      setProvider(item);
      setSelectedOption({
        value: item.vehicleType,
        label: item.vehicleType,
      });
      setLoading(false);
    });
  }, [props.location.state.provider]);

  function handleChange(selectedOption) {
    setProvider({
      ...provider,
      vehicleType: selectedOption.value,
    });
    setSelectedOption(selectedOption);
  }

  function addInfo(event) {
    setProvider({
      ...provider,
      [event.target.name]: event.target.value,
    });
  }

  function showSaveDialog() {
    setSaved(true);
    setTimeout(() => {
      setSaved(false);
    }, 5000);
  }

  function showErrorDialog() {
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 3000);
  }

  function setErrorBorder() {
    setErrorFirstName(errorFirstName || provider.firstName.trim().length <= 0);
    setErrorLastName(errorLastName || provider.lastName.trim().length <= 0);
    setErrorPhoneNumber(
      errorPhoneNumber || provider.phoneNumber.trim().length <= 0
    );
    setErrorEmail(errorEmail || provider.email.trim().length <= 0);
    setErrorVehicleType(errorVehicleType || provider.vehicleType.length <= 0);
  }

  function del() {
    let provId = provider["@id"];
    deleteProvider(provId)
      .then((res) => {
        if (res.ok) {
          props.history.push(`/admin/providers/`)
        } else {
          showErrorDialog();
          setErrorText('There was an error while deleting the provider, please try again')
        }
      })
      .catch(() => {
        showErrorDialog();
        setErrorText('There was an error while deleting the provider, please try again')
      });
  }

  async function updateProviderInfo(event) {
    event.preventDefault();
    if (isFormValid) {
      let prId = provider["@id"];
      setLoadingRequest(true)
      updateProvider(
        prId,
        provider.firstName,
        provider.lastName,
        provider.phoneNumber,
        provider.email,
        provider.licensePlate,
        provider.vehicleType,
        provider.additionalInfo
      )
        .then((res) => {
          if (res.ok) {
            showSaveDialog()
            setLoadingRequest(false)
          } else {
            showErrorDialog();
            setErrorText('There was an error while updating the provider, please try again')
            setLoadingRequest(false)
          }
        })
        .catch(() => {
          showErrorDialog();
          setErrorText('There was an error while updating the provider, please try again')
          setLoadingRequest(false)
        });
    } else {
      setErrorBorder();
      showErrorDialog();
      setErrorText('Please enter all of the required informations')
      setLoadingRequest(false)
    }
  }

  return (
    <>
      <SimpleHeader name="Provider details" parentName="Providers" />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <Header
                  title={"Provider details"}
                  edit={setEditProvider}
                  del={del}
                  delText={"You will not be able to recover this account."}
                  loading={loading}
                  showIcons={true}
                />
                {loading && <Loader />}
                {!loading && (
                  <CardBody>
                    <Form>
                      <FormGroup className="row">
                        <Label
                          className="form-control-label"
                          htmlFor="example-text-input"
                          md="2"
                        >
                          First name
                        </Label>
                        <Col md="6">
                          <Input
                            disabled={editProvider ? false : true}
                            style={{
                              backgroundColor: "white",
                              borderColor: errorFirstName ? "red" : "",
                            }}
                            value={provider.firstName || ""}
                            name="firstName"
                            type="text"
                            placeholder="The provider's first name"
                            onChange={(event) => addInfo(event)}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup className="row">
                        <Label
                          className="form-control-label"
                          htmlFor="example-text-input"
                          md="2"
                        >
                          Last name
                        </Label>
                        <Col md="6">
                          <Input
                            disabled={editProvider ? false : true}
                            style={{
                              backgroundColor: "white",
                              borderColor: errorLastName ? "red" : "",
                            }}
                            value={provider.lastName || ""}
                            name="lastName"
                            type="text"
                            placeholder="The provider's last name"
                            onChange={(event) => addInfo(event)}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup className="row">
                        <Label
                          className="form-control-label"
                          htmlFor="example-text-input"
                          md="2"
                        >
                          Phone number
                        </Label>
                        <Col md="6">
                          <Input
                            disabled={editProvider ? false : true}
                            style={{
                              backgroundColor: "white",
                              borderColor: errorPhoneNumber ? "red" : "",
                            }}
                            value={provider.phoneNumber || ""}
                            name="phoneNumber"
                            type="text"
                            placeholder="The provider's phone number"
                            onChange={(event) => addInfo(event)}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup className="row">
                        <Label
                          className="form-control-label"
                          htmlFor="example-text-input"
                          md="2"
                        >
                          Email
                        </Label>
                        <Col md="6">
                          <Input
                            disabled={editProvider ? false : true}
                            style={{
                              backgroundColor: "white",
                              borderColor: errorEmail ? "red" : "",
                            }}
                            value={provider.email}
                            name="email"
                            type="text"
                            placeholder="The provider's email"
                            onChange={(event) => addInfo(event)}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup className="row">
                        <Label
                          className="form-control-label"
                          htmlFor="example-text-input"
                          md="2"
                        >
                          License plate
                        </Label>
                        <Col md="6">
                          <Input
                            disabled={editProvider ? false : true}
                            style={{ backgroundColor: "white" }}
                            value={provider.licensePlate}
                            name="licensePlate"
                            type="text"
                            placeholder="The license plate if the provider owns a vehicle"
                            onChange={(event) => addInfo(event)}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup className="row">
                        <Label
                          className="form-control-label"
                          htmlFor="example-text-input"
                          md="2"
                        >
                          Vehicle Type
                        </Label>
                        <Col md="6">
                          <Select
                            isDisabled={editProvider ? false : true}
                            name="vehicleType"
                            value={selectedOption}
                            onChange={handleChange}
                            options={PROVIDER_VEHICLE_OPTIONS}
                            placeholder="The type of the vehicle the provider uses"
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                backgroundColor: "white",
                                fontSize: 14,
                                border: errorVehicleType
                                  ? "1px solid red"
                                  : "1px solid lightgray",
                              }),
                            }}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label
                          className="form-control-label"
                          htmlFor="example-text-input"
                          md="2"
                        >
                          Additional informations
                        </Label>
                        <Col md="6">
                          <Input
                            rows="3"
                            disabled={editProvider ? false : true}
                            style={{ backgroundColor: "white" }}
                            value={
                              provider.additionalInfo
                                ? provider.additionalInfo
                                : ""
                            }
                            name="additionalInfo"
                            type="textarea"
                            placeholder="Other informations"
                            onChange={(event) => addInfo(event)}
                          />
                        </Col>
                      </FormGroup>
                      {editProvider && (
                        <button
                          type="submit"
                          className="btn btn-success"
                          onClick={(event) => updateProviderInfo(event)}
                        >
                          Save
                        </button>
                      )}
                      {loadingRequest && <RequestLoader />}
                      <div style={{ marginTop: 10 }}>
                        {saved && (
                          <SuccessAlert text={"The account has been updated"} />
                        )}
                        {error && (
                          <ErrorAlert
                            text={
                              errorText
                            }
                          />
                        )}
                      </div>
                    </Form>
                  </CardBody>
                )}
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Show;

import React from "react";
import { Route } from "react-router-dom";
import { CategoryList, CreateCategory, Show } from "../components/category/";

export default [
  <Route
    path="/categories/create"
    component={CreateCategory}
    exact
    key="create"
  />,
  <Route path="/categories/show/:id" component={Show} exact key="show" />,
  <Route
    path="/categories/"
    component={CategoryList}
    exact
    strict
    key="list"
  />,
  <Route
    path="/categories/:page"
    component={CategoryList}
    exact
    strict
    key="page"
  />,
  <Route path="/categories" component={CategoryList} exact strict key="list" />,
];

import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";

export default function Loader(props) {
  return (
    <div style={{ textAlign: "center", marginTop: 50, marginBottom: 50 }}>
      <CircularProgress />
    </div>
  );
}

export function TableListLoader(props) {
  return (
    < div style={{ position: 'absolute', zIndex: 110, top: 35, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
      <CircularProgress />
    </div>
  );
}


export function RequestLoader(props) {
  return (
    <div
      style={{
        marginTop: 30,
        marginLeft: 20,
        marginRight: 20,
        textAlign: "center",
      }}
    >
      <LinearProgress color="primary" />
    </div>
  );
}

import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Input,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import Select from "react-select";
import getProviders from "../../services/providers";
import { saveDeliveryInformations } from "../../services/orders";
import SuccessAlert, { showSaveDialog } from "../../utils/succesAlert";
import ErrorAlert, { showErrorDialog } from "../../utils/errorAlert";
import { DELIVERY_STATUS_OPTIONS } from "../../services/orders";

function DeliveryInformations({
  deliveries,
  order,
  setOrder,
  setSelectedStatus,
}) {
  const [options, setOptions] = useState([]);
  const [error, setError] = useState(false);
  const [saved, setSaved] = useState(false);
  const [currentDeliveries, setCurrentDeliveries] = useState([]);
  const [copiedDeliveryTable, setCopiedDeliveryTable] = useState(false);


  const isFormValid = currentDeliveries.every((item) => item.provider);

  useEffect(() => {
    getProviders(1).then((pr) => {
      let list = [];
      pr.forEach((elem) => {
        let selectableList = {};
        let providerName = `${elem["firstName"]} ${elem["lastName"]}`;
        selectableList.label = providerName;
        selectableList.value = elem["@id"];
        list.push(selectableList);
      });
      setOptions(list);
    });
  }, []);

  useEffect(() => {
    deliveries.forEach((item, index) => {
      if (!item.deliveryStatus.value) {
        item.deliveryStatus = {
          label:
            item.deliveryStatus === "on_the_way"
              ? "on the way"
              : item.deliveryStatus,
          value: item.deliveryStatus,
        };
      }
      if (item.provider && !item.provider.value) {
        item.provider = {
          label: `${item.provider.firstName} ${item.provider.lastName}`,
          value: item.provider["@id"],
        };
      }
    });
    setCurrentDeliveries(deliveries);
  }, [order]);

  function saveDeliveryInfo() {
    if (isFormValid) {
      let orderStatus = null;

      let completedOrder = deliveries.every(
        (item) => item.deliveryStatus.value === "completed"
      );

      let newOrder = deliveries.some(
        (item) => item.deliveryStatus.value === "new"
      );
      let refundedOrder = deliveries.every(
        (item) => item.deliveryStatus.value === "refunded"
      );

      let canceledOrder = deliveries.every(
        (item) => item.deliveryStatus.value === "canceled"
      );

      if (completedOrder) {
        orderStatus = "completed";
      } else if (newOrder) {
        orderStatus = "new";
      } else if (refundedOrder) {
        orderStatus = "refunded";
      } else if (canceledOrder) {
        orderStatus = "canceled";
      } else {
        orderStatus = "prepared";
      }

      let orderId = order["@id"];
      currentDeliveries.forEach((item) => {
        saveDeliveryInformations(
          item["@id"],
          item.provider.value,
          item.deliveryStatus.value,
          item.additionalInfos,
          orderId,
          orderStatus
        )
          .then((res) => {
            if (res.deliveryStatus) {
              setOrder({
                ...order,
                statusReason: null,
              });
              showSaveDialog(setSaved);
            } else {
              showErrorDialog(setError);
            }
          })
          .catch((error) => {
            showErrorDialog(setError);
          });
      });
    } else {
      showErrorDialog(setError);
    }
  }

  const onChangeProvider = (index) => (event) => {
    let deliveryList = [...currentDeliveries];
    deliveryList[index].provider = event;
    deliveryList[index].deliveryStatus = {
      label: "prepared",
      value: "prepared",
    };
    setOrder({
      ...order,
      deliveries: deliveryList,
    });
  };

  const onChangeDeliveryStatus = (index) => (event) => {
    let deliveryList = [...currentDeliveries];
    deliveryList[index].deliveryStatus = event;
    setOrder({
      ...order,
      deliveries: deliveryList,
    });
  };

  function onChangeSearchForProvider(selectedOption) {

  }

  const addObservations = (index) => (event) => {
    let deliveryList = [...currentDeliveries];
    deliveryList[index].additionalInfos = event.target.value;
    setOrder({
      ...order,
      deliveries: deliveryList,
    });
  };

  function copyDeliveryTable() {
    const getDeliveryTable = document.querySelector(".transportTable");
    let range, sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(getDeliveryTable);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(getDeliveryTable);
        sel.addRange(range);
      }
      document.execCommand("copy");
    }
    sel.removeAllRanges();
    setCopiedDeliveryTable(true);
  }

  return (
    <>
      <Card>
        <CardHeader>
          <h3 className="mb-0">
            Delivery info
            <div style={{ float: "right" }}>
              <Button
                id="tooltipDeliveryTable"
                className="btn-icon-only rounded-circle"
                color="#00ff00"
                onClick={() => copyDeliveryTable()}
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-single-copy-04" />
                </span>
              </Button>
              <UncontrolledTooltip
                delay={0}
                trigger="hover focus"
                target="tooltipDeliveryTable"
              >
                {copiedDeliveryTable === true ? "Copied" : "Copy To Clipbord"}
              </UncontrolledTooltip>
            </div>
          </h3>
        </CardHeader>
        <CardBody>
          <Table
            className="align-items-center table-flush transportTable"
            responsive
          >
            <thead className="thead-light">
              <tr>
                <th className="sort" scope="col" style={{ width: "2%" }}>
                  Transport id
                </th>
                <th className="sort" scope="col" style={{ width: "2%" }}>
                  Delivery time
                </th>
                <th className="sort" scope="col" style={{ width: "20%" }}>
                  Products
                </th>
                <th className="sort" scope="col" style={{ width: "15%" }}>
                  Manufacturer
                </th>
                <th className="sort" scope="col" style={{ width: "22%" }}>
                  Provider
                </th>
                <th className="sort" scope="col" style={{ width: "22%" }}>
                  Status
                </th>
                <th className="sort" scope="col" style={{ width: "17%" }}>
                  Observations
                </th>
              </tr>
            </thead>
            {currentDeliveries !== "" && (
              <tbody className="list">
                {currentDeliveries.map((delivery, index) => {
                  delivery.orderProducts.sort((a, b) => {
                    var first = a.product.manufacturer.name.toUpperCase();
                    var next = b.product.manufacturer.name.toUpperCase();
                    return first < next ? -1 : first > next ? 1 : 0;
                  });

                  return (
                    <tr key={index}>
                      <th scope="row">
                        <span className="name mb-0 text-sm">
                          {delivery["@id"].slice(12, 20)}
                        </span>
                      </th>
                      <td>
                        <span className="name mb-0 text-sm">
                          {delivery.deliveryTime} hours
                        </span>
                      </td>
                      <td>
                        <span className="status">
                          {delivery.orderProducts.map((item, index) => {
                            return <div key={index}>{item.product.name}</div>;
                          })}
                        </span>
                      </td>
                      <td>
                        <span className="status">
                          {delivery.orderProducts.map((item, index) => {
                            return (
                              <div key={index}>
                                {item.product.manufacturer.name}
                              </div>
                            );
                          })}
                        </span>
                      </td>
                      <td key={index}>
                        <Select
                          key={index}
                          value={currentDeliveries[index].provider}
                          options={options}
                          onInputChange={onChangeSearchForProvider}
                          onChange={onChangeProvider(index)}
                          placeholder="Select..."
                          styles={{
                            menu: (provided) => ({
                              ...provided,
                              zIndex: 999999,
                              overflow: "visible",
                              position: "relative",
                            }),
                            control: (base, state) => ({
                              ...base,
                              fontSize: 14,
                            }),
                          }}
                        />
                      </td>
                      <td>
                        <Select
                          value={currentDeliveries[index].deliveryStatus}
                          options={DELIVERY_STATUS_OPTIONS}
                          onChange={onChangeDeliveryStatus(index)}
                          placeholder="Select..."
                          styles={{
                            menu: (provided) => ({
                              ...provided,
                              zIndex: 999999,
                              overflow: "visible",
                              position: "relative",
                            }),
                            control: (base, state) => ({
                              ...base,
                              fontSize: 14,
                            }),
                          }}
                        />
                      </td>
                      <td>
                        <Input
                          value={currentDeliveries[index].additionalInfos || ""}
                          type="textarea"
                          onChange={addObservations(index)}
                        ></Input>
                      </td>
                    </tr>
                  );
                })}
                <tr className="name mb-0 text-sm">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            )}
          </Table>
        </CardBody>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "10px",
            marginRight: "20px",
          }}
        >
          <Button
            color="success"
            outline
            type="button"
            onClick={saveDeliveryInfo}
          >
            Save provider allocation
          </Button>
        </div>
        <div style={{ marginTop: 10 }}>
          {saved && (
            <SuccessAlert text={"The allocation was saved successfully!"} />
          )}
          {error && (
            <ErrorAlert
              text={"Please enter providers for every delivery time"}
            />
          )}
        </div>
      </Card>
    </>
  );
}

export default DeliveryInformations;

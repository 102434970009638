async function doFetch(pageNumber) {
  let url =
    process.env.REACT_APP_API_VOUCHER_ENTRYPOINT +
    `vouchers?page=${pageNumber}`;
  return await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  }).then((response) => response.json());
}

export default async function getVouchers(pageNumber) {
  return doFetch(pageNumber)
    .then((res) => {
      if (res.code === 401) {
        let url = process.env.PUBLIC_URL + "/login/admin/refresh";

        return fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            refresh_token: `${localStorage.getItem("refresh_token")}`,
          }),
        })
          .then((res) => res.json())
          .then((r) => localStorage.setItem("token", r.token))
          .then(() => doFetch(pageNumber));
      }
      return res;
    })
    .then((data1) => {
      return data1.data;
    });
}

export async function getVoucherDetails(id) {
  const URL =
    process.env.REACT_APP_API_VOUCHER_ENTRYPOINT + `vouchersById/${id}`;
  const res = fetch(URL, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => response.json())
    .then((data1) => {
      return data1.data;
    });
  return res;
}

export async function editVoucher(id, body) {
  const URL = process.env.REACT_APP_API_VOUCHER_ENTRYPOINT + `vouchers/${id}`;
  const res = fetch(URL, {
    method: "PATCH",
    headers: {
      // body: JSON.stringify({ body }),
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(body),
  });
  return res;
}

export function addVoucher(body) {
  const URL = process.env.REACT_APP_API_VOUCHER_ENTRYPOINT + `vouchers`;
  const res = fetch(URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(body),
  });
  return res;
}

export async function deleteVoucher(id) {
  const URL = process.env.REACT_APP_API_VOUCHER_ENTRYPOINT + `vouchers/${id}`;
  let res = await fetch(URL, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return res;
}

import React, { useState, useEffect } from "react";
import { Button, Card, CardHeader, Container, Row, Col } from "reactstrap";
import SimpleHeader from "../../componentsTheme/Headers/SimpleHeader.js";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import getManufacturers, {
  manufacturerOfTheWeek,
  getManufacturerOfTheWeek, getManufacturerByName
} from "../../services/manufacturers";
import Loader from "../../utils/loader";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import ErrorAlert from "../../utils/errorAlert";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import StarIcon from "@material-ui/icons/Star";

function ManufacturerList(props) {
  const [manufacturers, setManufacturers] = useState([]);
  const [manufacOfTheWeek, setManufacturerOfTheWeek] = useState();
  const [nameOfManufacOfTheWeek, setNameOfManufacturerOfTheWeek] = useState(
    null
  );
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState("1");
  const [requests, setRequests] = useState([1]);
  const [count, setCount] = useState("");
  const [error, setError] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const [filters, setFilters] = useState("");

  const THEME = createMuiTheme({
    typography: {
      fontFamily: "Poppins",
    },
  });

  useEffect(() => {
    if (!filterApplied) {
      setLoading(true);
      let list = [...manufacturers];
      getManufacturers(pageNumber).then((item) => {
        item['hydra:member'].forEach((or) => {
          list.push(or);
        });
        setCount(item['hydra:totalItems'])
        setManufacturers(list);
        setLoading(false);
      });
      getManufacturerOfTheWeek().then((item) => {
        setManufacturerOfTheWeek(item[0].values.manufacturerOfTheWeek);
      });
    }
  }, [pageNumber, filterApplied]);

  useEffect(() => {
    let name = manufacturers.filter(
      (item) => item["@id"] == manufacOfTheWeek
    )[0];
    setNameOfManufacturerOfTheWeek(name);
  }, [manufacturers, manufacOfTheWeek]);

  const columns = [
    {
      name: "@id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value.slice(15, 20)}</>;
        },
      },
    },
    {
      name: "name",
      label: "NAME",
      options: {
        filter: true,
        sort: false,
        filterType: "textField",
      },
    },
    {
      name: "legalName",
      label: "LEGAL NAME",
      options: {
        filter: false,
        sort: false,
        filterType: "textField",
      },
    },
    {
      name: "telephone",
      label: "TELEPHONE",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "email",
      label: "EMAIL",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "STATUS",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ width: "30px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Tooltip
                  title={
                    tableMeta.rowData[0] == manufacOfTheWeek
                      ? "Manufacturer of the week"
                      : "Set manufacturer of the week"
                  }
                >
                  <IconButton
                    style={{
                      color:
                        tableMeta.rowData[0] == manufacOfTheWeek
                          ? "orange"
                          : "",
                    }}
                    size="small"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={() =>
                      selectManufacturerOfTheWeek(tableMeta.rowData[0])
                    }
                  >
                    <StarIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "DETAILS",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ width: "30px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IconButton
                  size="small"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={() =>
                    props.history.push(
                      `show/${encodeURIComponent(tableMeta.rowData[0])}`,
                      {
                        manufacturer: tableMeta.rowData[0],
                      }
                    )
                  }
                >
                  <MoreVertIcon fontSize="small" />
                </IconButton>
              </div>
            </div>
          );
        },
      },
    },
  ];

  function selectManufacturerOfTheWeek(id) {
    manufacturerOfTheWeek(id).then((item) => {
      setManufacturerOfTheWeek(item.values.manufacturerOfTheWeek);
    });
  }


  const options = {
    count: count,
    confirmFilters: true,
    rowsPerPage: 10,
    selectableRows: "none",
    responsive: "vertical",
    rowsPerPageOptions: [],
    download: false,
    print: false,
    search: false,
    textLabels: {
      body: {
        noMatch: loading ? <Loader /> : "Sorry, no matching records found",
      },
    },
    onChangePage: (currentPage) => {
      if (!filterApplied) {
        if (loading) {
          return <Loader />;
        } else {
          let req = [...requests];
          let lastRequest = req.pop();
          let newRequest = lastRequest + 1;
          if (!requests.includes(newRequest)) {
            req.push(newRequest);
            setRequests(req);
            setPageNumber(newRequest);
          }
        }
      } else {
        if (loading) {
          return <Loader />;
        } else {
          setError(false);
          let req = [...requests];
          let lastRequest = req.pop();
          let newRequest = lastRequest + 1;
          if (!requests.includes(newRequest)) {
            req.push(newRequest);
            setRequests(req);
            setPageNumber(newRequest);
          }
          window.scrollTo(0, 0);
        }
      }
    },
    onFilterChipClose: (index, removedFilter, filterList) => {
      setError(false);
      setPageNumber(1);
      setFilterApplied(false);
      setFilters('')
      setRequests([1]);
      setManufacturers([]);
    },
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div style={{ marginTop: "40px" }}>
          <Button
            variant="contained"
            onClick={() => handleFilterSubmit(applyNewFilters)}
          >
            Apply filters
          </Button>
        </div>
      );
    },
  };

  const handleFilterSubmit = (applyNewFilters) => {
    setError(false);
    let filterList = applyNewFilters();
    applyFilters(filterList);
  };

  function applyFilters(filterList) {
    setError(false);
    let list = [];
    setManufacturers([]);
    setLoading(true);
    setFilterApplied(true);
    setFilters(filterList);
    setRequests([1]);
    setPageNumber(1);
    switch (true) {
      case filterList[1].length > 0:
        let searchByName = filterList[1][0];
        getManufacturerByName(searchByName, 1)
          .then((data) => {
            setCount(data["hydra:totalItems"]);
            let list = [];
            data["hydra:member"].forEach((item) => {
              list.push(item);
            });
            setManufacturers(list);
            setLoading(false);
            return list;
          })
          .catch(() => setError(true));
        break;

    }
  }

  return (
    <>
      <SimpleHeader name="All manufacturers" parentName="Manufacturers" />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader className="border-0">
            <h3 className="mb-0">Manufacturer list</h3>
            <div style={{ float: "right" }}>
              <button
                className="btn btn-success"
                onClick={() => {
                  window.open("https://www.approzar.eu/gusturibune", "_blank");
                }}
              >
                Go to Landing Page
              </button>
            </div>
          </CardHeader>
          {error && (
            <ErrorAlert
              text={
                "There was an error while loading the manufacturer list, please try again"
              }
            />
          )}
          <>
            <div style={{ margin: "0px 20px 20px 10px" }}>
              <h3 className="mb-0">
                <IconButton
                  style={{
                    color: "orange",
                  }}
                  size="small"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                >
                  <StarIcon fontSize="small" />
                </IconButton>{" "}
                Current manufacturer of the week: &nbsp;
                  {nameOfManufacOfTheWeek ? nameOfManufacOfTheWeek.name : ""}
              </h3>
            </div>
            <MuiThemeProvider theme={THEME}>
              <MUIDataTable
                title={''}
                data={manufacturers}
                columns={columns}
                options={options}
              />
            </MuiThemeProvider>
          </>
        </Card>
      </Container>
    </>
  );
}

export default ManufacturerList;

import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Label,
  Form,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import SimpleHeader from "../../componentsTheme/Headers/SimpleHeader.js";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import SuccessAlert, { showSaveDialog } from "../../utils/succesAlert";
import ErrorAlert, { showErrorDialog } from "../../utils/errorAlert";
import {
  getProductsByManufacturer,
  getProductByNameAndManufacturer,
} from "../../services/products";
import getManufacturers from "../../services/manufacturers";
import { createOffer } from "../../services/offers";
import Header from "../../utils/header";
import * as moment from "moment";
import COLORS from "../../utils/colors";
import { PRODUCT_CURRENCY_OPTIONS } from "../../services/products";
import useDebounce from "../../services/debounce";

function Create(props) {
  const [product, setProduct] = useState({
    manufacturer: "",
    product: "",
    startDate: moment(new Date()).format("YYYY-MM-DDTHH:mm"),
    endDate: moment().add(7, "d").format("YYYY-MM-DDTHH:mm"),
    newPrice: "",
    priceCurrency: { value: "RON", label: "RON" },
    priority: "",
  });

  const [selectedManufacturer, setSelectedManufacturer] = useState("");
  const [manufacturerOptions, setManufacturerOptions] = useState([]);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [productInput, setProductInput] = useState("");
  const debouncedSearch = useDebounce(productInput, 500);

  const [options, setOptions] = useState([]);
  const [saved, setSaved] = useState(false);

  const [errorProduct, setErrorProduct] = useState(false);
  const [errorMan, setErrorMan] = useState(false);
  const [errorStartDate, setErrorStartDate] = useState(false);
  const [errorEndDate, setErrorEndDate] = useState(false);
  const [errorPrice, setErrorPrice] = useState(false);
  const [errorPriceCurrency, setErrorPriceCurrency] = useState(false);
  const [errorPriority, setErrorPriority] = useState(false);

  const isFormValid =
    product.product !== "" &&
    product.priority !== "" &&
    product.manufacturer !== "" &&
    product.newPrice.length > 0 &&
    product.priceCurrency &&
    product.startDate !== "" &&
    product.endDate !== "";

  useEffect(() => {
    getManufacturers(1).then((man) => {
      let list = [];
      man['hydra:member'].forEach((item) => {
        list.push({ label: item.name, value: item["@id"] });
      });
      setManufacturerOptions(list);
    });
  }, []);

  useEffect(() => {
    if (selectedManufacturer !== "") {
      getProductsByManufacturer(selectedManufacturer.value, 1).then((prod) => {
        let list = [];
        prod["hydra:member"].forEach((item) => {
          list.push({
            label: item.name,
            value: item["@id"],
            price: item.price.price,
            priceCurrency: item.price.priceCurrency,
            visibility: item.visibility,
          });
        });
        setOptions(list);
      });
    }
  }, [selectedManufacturer]);

  useEffect(() => {
    if (debouncedSearch) {
      let manufacturer = product.manufacturer.slice(15, 20);
      getProductByNameAndManufacturer(productInput, manufacturer).then(
        (prod) => {
          let list = [];
          prod.forEach((item) => {
            list.push({
              label: item.name,
              value: item["@id"],
              price: item.price.price,
              priceCurrency: item.price.priceCurrency,
              visibility: item.visibility,
            });
          });
          setOptions(list);
        }
      );
    }
  }, [debouncedSearch]);

  function setErrorBorder() {
    setErrorMan(errorMan || product.manufacturer.length <= 0);
    setErrorProduct(errorProduct || product.product.trim().length <= 0);
    setErrorStartDate(errorStartDate || product.startDate.length <= 0);
    setErrorEndDate(errorEndDate || product.endDate.length <= 0);
    setErrorPrice(errorPrice || product.newPrice.trim().length <= 0);
    setErrorPriceCurrency(
      errorPriceCurrency || product.priceCurrency.length <= 0
    );
    setErrorPriority(errorPriority || product.priority.trim().length <= 0);
  }

  function handleChangeManufacturer(selectedManufacturer) {
    setSelectedManufacturer(selectedManufacturer);
    setSelectedOption("");
    setProduct({
      ...product,
      manufacturer: selectedManufacturer.value,
      product: "",
    });
    setErrorMan(false);
  }

  function addInfo(event) {
    setProduct({
      ...product,
      [event.target.name]: event.target.value,
    });
    let isValid = event.target.value.length > 0;
    if (event.target.name == "newPrice") {
      setErrorPrice(!isValid);
    } else if (event.target.name == "priority") {
      setErrorPriority(!isValid);
    }
  }

  function changePriceCurrency(selectedOption) {
    let newPrice = { ...product };
    newPrice.priceCurrency = selectedOption ? selectedOption : "";
    setProduct(newPrice);
    setErrorPriceCurrency(false);
  }

  function addStartDate(event) {
    setProduct({
      ...product,
      startDate: event.target.value,
    });
    setErrorStartDate(false);
  }

  function addEndDate(event) {
    setProduct({
      ...product,
      endDate: event.target.value,
    });
    setErrorEndDate(false);
  }

  async function newOffer(event) {
    event.preventDefault();
    if (isFormValid) {
      let newOfferDate = new Date(product.startDate);
      let endOfferDate = new Date(product.endDate);
      let toISoNew = newOfferDate.toISOString();
      let toISoEnd = endOfferDate.toISOString();
      let price = {
        price: Number(product.newPrice),
        priceCurrency: product.priceCurrency.value,
      };
      createOffer(
        product.product,
        toISoNew,
        toISoEnd,
        price,
        Number(product.priority),
        new Date().toISOString(),
        new Date().toISOString()
      )
        .then((res) => {
          if (res.ok) {
            showSaveDialog(setSaved)
          } else {
            showErrorDialog(setError);
            setErrorText('There was an error while adding your offer, please try again')
          }
        })
        .catch(() => {
          showErrorDialog(setError);
          setErrorText('There was an error while adding your offer, please try again')
        });
    } else {
      showErrorDialog(setError);
      setErrorBorder();
      setErrorText('Please enter all of the required informations')
    }
  }

  function onChangeProduct(event) {
    setSelectedOption(event);
    setProduct({
      ...product,
      product: event.value,
    });
    setErrorProduct(false);
  }

  function onChangeSearchForProduct(selectedOption) {
    setProductInput(selectedOption);
    setErrorProduct(false);
  }

  return (
    <>
      <SimpleHeader name="New offer" parentName="Offers" />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <Header
                  title={"Offer details"}
                  edit={true}
                  del
                  delText
                  loading={false}
                  showIcons={false}
                />
                <CardBody>
                  <Form>
                    <FormGroup className="row">
                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                        md="2"
                      >
                        Manufacturer
                      </Label>
                      <Col md="6">
                        <Select
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              fontSize: 14,
                              border: errorMan
                                ? "1px solid red"
                                : "1px solid lightgray",
                            }),
                          }}
                          value={selectedManufacturer}
                          onChange={handleChangeManufacturer}
                          options={manufacturerOptions}
                          placeholder="Select manufacturer"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="row">
                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                        md="2"
                      >
                        Product
                      </Label>
                      <Col md="6">
                        <Select
                          value={selectedOption}
                          options={options}
                          onInputChange={onChangeSearchForProduct}
                          onChange={onChangeProduct}
                          placeholder="Select product"
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              fontSize: 14,
                              border: errorProduct
                                ? "1px solid red"
                                : "1px solid lightgray",
                            }),
                          }}
                        />
                      </Col>
                    </FormGroup>

                    {product.product !== "" && (
                      <>
                        <FormGroup className="row">
                          <Label
                            className="form-control-label"
                            htmlFor="example-text-input"
                            md="2"
                          >
                            Visibility
                          </Label>
                          <Col md="6">
                            <Input
                              value={
                                selectedOption !== ""
                                  ? selectedOption.visibility
                                  : ""
                              }
                              disabled
                              style={{ backgroundColor: "white" }}
                              name="visibility"
                              type="text"
                              placeholder="Product visibility"
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup className="row">
                          <Label
                            className="form-control-label"
                            htmlFor="example-text-input"
                            md="2"
                          >
                            Current price
                          </Label>
                          <Col md="6">
                            <Input
                              value={
                                selectedOption !== ""
                                  ? `${selectedOption.price} ${selectedOption.priceCurrency}`
                                  : ""
                              }
                              disabled
                              style={{ backgroundColor: "white" }}
                              name="currentPrice"
                              type="text"
                              placeholder="The current price of the product"
                            />
                          </Col>
                        </FormGroup>
                      </>
                    )}
                    <FormGroup className="row">
                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                        md="2"
                      >
                        Start date
                      </Label>
                      <Col md="6">
                        <Input
                          value={moment(product.startDate).format(
                            "YYYY-MM-DDTHH:mm"
                          )}
                          style={{ borderColor: errorStartDate ? "red" : "" }}
                          name="startDate"
                          type="datetime-local"
                          placeholder="The starting date for the offer"
                          onChange={(event) => addStartDate(event)}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="row">
                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                        md="2"
                      >
                        End date
                      </Label>
                      <Col md="6">
                        <Input
                          value={moment(product.endDate).format(
                            "YYYY-MM-DDTHH:mm"
                          )}
                          style={{ borderColor: errorEndDate ? "red" : "" }}
                          onChange={(event) => addEndDate(event)}
                          name="endDate"
                          type="datetime-local"
                          placeholder="The ending date for the offer"
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                        md="2"
                      >
                        New price
                      </Label>
                      <Col md="6">
                        <Input
                          style={{ borderColor: errorPrice ? "red" : "" }}
                          value={product.newPrice}
                          onChange={(event) => addInfo(event)}
                          name="newPrice"
                          type="text"
                          placeholder="The new price"
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                        md="2"
                      >
                        Price currency
                      </Label>
                      <Col md="6">
                        <CreatableSelect
                          placeholder="Select currency"
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              fontSize: "0.875rem",
                              backgroundColor: COLORS.WHITE,
                              border: errorPriceCurrency
                                ? "1px solid red"
                                : "1px solid lightgray",
                            }),
                          }}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              neutral50: "#8898aa",
                            },
                          })}
                          name="priceCurrency"
                          value={product.priceCurrency}
                          onChange={changePriceCurrency}
                          options={PRODUCT_CURRENCY_OPTIONS}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                        md="2"
                      >
                        Priority
                      </Label>
                      <Col md="6">
                        <Input
                          style={{ borderColor: errorPriority ? "red" : "" }}
                          value={product.priority}
                          onChange={(event) => addInfo(event)}
                          name="priority"
                          type="number"
                          placeholder="Offer priority"
                        />
                      </Col>
                    </FormGroup>

                    <button
                      onClick={(event) => newOffer(event)}
                      className="btn btn-success"
                    >
                      Save
                    </button>
                    <div style={{ marginTop: 10 }}>
                      {saved && (
                        <SuccessAlert text={"The offer has been created"} />
                      )}
                      {error && (
                        <ErrorAlert
                          text={errorText}
                        />
                      )}
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Create;

import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import { GridList, GridListTile } from "@material-ui/core/";
import SimpleHeader from "../../componentsTheme/Headers/SimpleHeader.js";
import { deleteProduct, getProductDetails } from "../../services/products";
import ImageLinks from "./imageLinksContainer";
import TagsInputContainer from "./tagsInputContainer";
import SuccessAlert, { showSaveDialog } from "../../utils/succesAlert";
import ErrorAlert, { showErrorDialog } from "../../utils/errorAlert";
import FormattedDescription from "./formattedDescriptionContainer";
import ImgContainer from "../../utils/imgContainer";
import Loader, { RequestLoader } from "../../utils/loader";
import Header from "../../utils/header";
import COLORS from "../../utils/colors";
import DeliveryOptionsContainer from "./deliveryOptionsContainer";
import ProductDetailsContainer from "./productDetailsContainer";
import {
  IMAGE_PRODUCT,
  IMAGE_CLOUDINARY_UPLOAD,
  IMAGE_CLOUDINARY_UPLOAD_W_H,
} from "../../services/products";

function ShowProduct(props) {
  const [product, setProduct] = useState("");
  const [editProduct, setEditProduct] = useState(false);
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(false);
  const [errorDescription, setErrorDescription] = useState(false);
  const [errorName, setErrorName] = useState(false);
  const [errorUm, setErrorUm] = useState(false);
  const [errorSku, setErrorSku] = useState(false);
  const [errorPrice, setErrorPrice] = useState(false);
  const [errorPriceCurrency, setErrorPriceCurrency] = useState(false);
  const [errorMan, setErrorMan] = useState(false);
  const [errorCateg, setErrorCateg] = useState(false);
  const [errorSubcateg, setErrorSubcateg] = useState(false);
  const [errorTax, setErrorTax] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [loadingDeliveryOptions, setLoadingDeliveryOptions] = useState(false);
  const [links, setLinks] = useState([]);
  const [errorText, setErrorText] = useState("");
  const role = localStorage.getItem("role");

  const isFormValid =
    product !== ""
      ? product.name.length > 2 &&
        product.name.length < 35 &&
        product.formattedDescription.length > 0 &&
        product.unitOfMeasurement &&
        product.manufacturer !== "" &&
        product.categories !== "" &&
        product.subcategory !== "" &&
        product.sku.length > 0 &&
        product.price.price.length !== 0 &&
        product.price.priceCurrency &&
        product.price.vatPercentage
      : "";

  useEffect(() => {
    setLoading(true);
    let id = props.location.state.product["@id"];
    getProductDetails(id, role)
      .then((pr) => {
        pr.link = [];
        let newLink = links;
        pr.images.forEach((img, index) => {
          pr.link[index] = img.contentUrl;
          newLink.push(0);
          if (img.contentUrl.includes(IMAGE_CLOUDINARY_UPLOAD)) {
            img.contentUrl = [
              img.contentUrl.slice(0, IMAGE_CLOUDINARY_UPLOAD.length),
              IMAGE_CLOUDINARY_UPLOAD_W_H,
              img.contentUrl.slice(IMAGE_CLOUDINARY_UPLOAD.length),
            ].join("");
          }
        });
        setLinks(newLink);
        pr.manufacturer.label = pr.manufacturer.name;
        pr.manufacturer.value = pr.manufacturer["@id"];
        pr.unitOfMeasurement = {
          label: pr.unitOfMeasurement,
          value: pr.unitOfMeasurement,
        };
        pr.price.priceCurrency = {
          label: pr.price.priceCurrency,
          value: pr.price.priceCurrency,
        };
        pr.price.vatPercentage = {
          label: pr.price.vatPercentage + "%",
          value: pr.price.vatPercentage,
        };
        pr.categories = {
          label: pr.categories.length > 0 ? pr.categories[0].name : "",
          value: pr.categories.length > 0 ? pr.categories[0]["@id"] : "",
        };
        if (pr.subcategory) {
          pr.subcategory = {
            label: pr.subcategory !== "" ? pr.subcategory.name : "",
            value:
              pr.subcategory["@id"].length > 0 ? pr.subcategory["@id"] : "",
          };
        }
        pr.tags.forEach((item, index) => {
          item.label = pr.tags.length > 0 ? pr.tags[index].name : "";
          item.value = pr.tags.length > 0 ? pr.tags[index]["@id"] : "";
        });
        setProduct(pr);
        setLoading(false);
      })
      .catch(() => showErrorDialog(setError));
  }, []);

  function del() {
    let prod = product["@id"];
    deleteProduct(prod, props, role);
  }

  function setErrorBorder() {
    setErrorName(
      errorName ||
        (product.name.trim().length <= 2 && product.name.trim().length >= 34)
    );
    setErrorDescription(
      errorDescription || product.formattedDescription.trim().length <= 0
    );
    setErrorUm(errorUm || product.unitOfMeasurement.length <= 0);
    setErrorSku(errorSku || product.sku.trim().length <= 0);
    setErrorPrice(errorPrice || product.price.price.length <= 0);
    setErrorMan(errorMan || product.manufacturer.length <= 0);
    setErrorCateg(errorCateg || product.categories.length <= 0);
    setErrorSubcateg(errorSubcateg || product.subcategory.length <= 0);
  }

  async function updateProduct(event) {
    if (isFormValid) {
      setLoadingRequest(true);
      event.preventDefault();
      let getTag = [];
      product.tags.forEach((item) => {
        getTag.push(item.value);
      });
      product.images.forEach((pr, index) => {
        return (pr.contentUrl = product.link[index]);
      });
      let header =
        role === "admin"
          ? {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            }
          : {
              "Content-Type": "application/json",
              "X-User-Auth": `${localStorage.getItem("token")}`,
            };

      let prId = product["@id"];
      let URL = process.env.REACT_APP_API_ENTRYPOINT + prId;
      let res = await fetch(URL, {
        method: "PUT",
        headers: header,
        body: JSON.stringify({
          name: product.name,
          slogan: product.slogan,
          unitOfMeasurement: product.unitOfMeasurement.value,
          manufacturer: `${product.manufacturer.value}`,
          categories: [`${product.categories.value}`],
          subcategory: `${product.subcategory.value}`,
          sku: product.sku,
          stockCount: Number(product.stockCount),
          price: {
            price: parseFloat(product.price.price),
            priceCurrency: product.price.priceCurrency.value,
            vatPercentage: Number(product.price.vatPercentage.value),
          },
          description: product.description,
          formattedDescription: product.description,
          images: product.images,
          visibility: product.visibility,
          tags: getTag,
          deliveryTime: product.deliveryTime,
        }),
      })
        .then((resp) => {
          if (resp.ok) {
            showSaveDialog(setSaved);
            setLoadingRequest(false);
          } else {
            showErrorDialog(setError);
            setErrorText(
              "There was an error while updating your product, please try again"
            );
            setLoadingRequest(false);
          }
        })
        .catch(() => {
          showErrorDialog(setError);
          setErrorText(
            "There was an error while updating your product, please try again"
          );
          setLoadingRequest(false);
        });

      return res;
    } else {
      showErrorDialog(setError);
      setErrorBorder();
      if (product.name.trim().length < 3 || product.name.trim().length >= 34) {
        setErrorText(
          "The product name must be between 3 and 34 charancters long."
        );
      } else {
        setErrorText("Please enter all of the required informations");
      }
    }
  }

  function removeImage(index) {
    let currentProduct = { ...product };
    let selectedImageLink = currentProduct.images[index].contentUrl.replace(
      "w_512,h_512/",
      ""
    );

    let filteredImages = [];
    currentProduct.images.map((img) => {
      if (img.contentUrl !== currentProduct.images[index].contentUrl) {
        filteredImages.push(img);
      }
    });

    let filteredLink = currentProduct.link.filter(
      (item) => item !== selectedImageLink
    );
    currentProduct.images = filteredImages;
    currentProduct.link = filteredLink;
    setProduct(currentProduct);
    links.pop();
  }

  return (
    <>
      <SimpleHeader name="Product details" parentName="Products" />
      <Container className="mt--6" fluid>
        <>
          <Row>
            <Col lg="6">
              <div className="card-wrapper">
                <Card>
                  <Header
                    title={"Product details"}
                    edit={setEditProduct}
                    del={del}
                    delText={"You will not be able to recover this product."}
                    loading={loading}
                    showIcons={true}
                  />
                  {loading && <Loader />}
                  {product !== "" && (
                    <CardBody>
                      <ProductDetailsContainer
                        product={product}
                        setProduct={setProduct}
                        editProduct={editProduct}
                        setLoadingDeliveryOptions={setLoadingDeliveryOptions}
                        errorName={errorName}
                        setErrorName={setErrorName}
                        errorUm={errorUm}
                        setErrorUm={setErrorUm}
                        errorSku={errorSku}
                        setErrorSku={setErrorSku}
                        errorPrice={errorPrice}
                        setErrorPrice={setErrorPrice}
                        errorPriceCurrency={errorPriceCurrency}
                        setErrorPriceCurrency={setErrorPriceCurrency}
                        errorMan={errorMan}
                        setErrorMan={setErrorMan}
                        errorCateg={errorCateg}
                        setErrorCateg={setErrorCateg}
                        errorSubcateg={errorSubcateg}
                        setErrorSubcateg={setErrorSubcateg}
                        errorTax={errorTax}
                        setErrorTax={setErrorText}
                        role={role}
                      />
                    </CardBody>
                  )}
                </Card>
              </div>
            </Col>
            <Col lg="6">
              <div className="card-wrapper">
                <FormattedDescription
                  product={product}
                  setProduct={setProduct}
                  editProduct={editProduct}
                  errorDescription={errorDescription}
                  setErrorDescription={setErrorDescription}
                  loading={loading}
                />
                <DeliveryOptionsContainer
                  product={product}
                  setProduct={setProduct}
                  edit={editProduct}
                  loading={loadingDeliveryOptions}
                />
                <TagsInputContainer
                  newProduct={product}
                  setNewProduct={setProduct}
                  editProduct={editProduct}
                  loading={loading}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              {product !== "" && (
                <div className="card-wrapper">
                  <Card>
                    <CardHeader>
                      <h3 className="mb-0">Gallery</h3>
                    </CardHeader>
                    <CardBody>
                      <FormGroup className="row">
                        <GridList
                          style={{
                            width: "100%",
                            height: "auto",
                          }}
                          cellHeight={200}
                          cols={5}
                        >
                          {product.images.map((tile, index) => (
                            <GridListTile
                              key={index}
                              cols={tile.cols || 1}
                              style={{
                                position: "relative",
                                marginLeft: "5px",
                                marginRight: "10px",
                                width: "200px",
                              }}
                            >
                              {editProduct && (
                                <span
                                  style={{
                                    position: "absolute",
                                    top: "2px",
                                    right: "2px",
                                    zIndex: "100",
                                    color: COLORS.WHITE,
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                  onClick={(e) => removeImage(index)}
                                >
                                  &times;
                                </span>
                              )}
                              <img src={tile.contentUrl} />
                            </GridListTile>
                          ))}
                        </GridList>
                      </FormGroup>
                      {editProduct && (
                        <>
                          <ImageLinks
                            links={links}
                            setLinks={setLinks}
                            newProduct={product}
                            setNewProduct={setProduct}
                          />
                          <ImgContainer
                            links={links}
                            setLinks={setLinks}
                            newProduct={product}
                            setNewProduct={setProduct}
                            type={IMAGE_PRODUCT}
                          />

                          <Button
                            disabled={loadingRequest ? true : false}
                            color="success"
                            outline
                            type="button"
                            onClick={updateProduct}
                          >
                            Save
                          </Button>
                          {loadingRequest && <RequestLoader />}

                          <div style={{ marginTop: 10 }}>
                            {saved && (
                              <SuccessAlert
                                text={"Your product has been updated"}
                              />
                            )}
                            {error && <ErrorAlert text={errorText} />}
                          </div>
                        </>
                      )}
                    </CardBody>
                  </Card>
                </div>
              )}{" "}
            </Col>
          </Row>
        </>
      </Container>
    </>
  );
}

export default ShowProduct;

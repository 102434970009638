import React, { useState, useEffect } from "react";
import { Label, FormGroup, Form, Input, Col } from "reactstrap";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@material-ui/core/";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import getManufacturers, {
  getDetails,
  getManufacturerName,
  getManufacturerCategories,
  getManufacturerSubcategories,
} from "../../../services/manufacturers";
import getCategories, { getSubcategories } from "../../../services/categories";
import COLORS, { THEME } from "../../../utils/colors";
import {
  PRODUCT_VISIBILITY_OPTIONS,
  PRODUCT_UM_OPTIONS,
  PRODUCT_CURRENCY_OPTIONS,
} from "../../../services/products";

function BulkProductDetailsContainer({
  product,
  setProduct,
  productList,
  setProductList,
  setLoadingDeliveryOptions,
  errorName,
  setErrorName,
  errorUm,
  setErrorUm,
  errorSku,
  setErrorSku,
  errorPrice,
  setErrorPrice,
  errorPriceCurrency,
  setErrorPriceCurrency,
  errorMan,
  setErrorMan,
  errorCateg,
  setErrorCateg,
  errorSubcateg,
  setErrorSubcateg,
  role,
}) {
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(false);
  const [manufacturerOptions, setManufacturerOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subcategoryOptions, setSubcategoryOptions] = useState([]);

  useEffect(() => {
    if (role === "admin") {
      getManufacturers(1)
        .then((man) => {
          let list = [];
          man["hydra:member"].forEach((item) => {
            let selectableList = {};
            selectableList.label = item["name"];
            selectableList.value = item["@id"];
            list.push(selectableList);
          });
          setManufacturerOptions(list);
        })
        .catch(() => showErrorAlert());
      getCategories(1)
        .then((categ) => {
          let list = [];
          categ.forEach((item) => {
            let selectableList = {};
            selectableList.label = item["name"];
            selectableList.value = item["@id"];
            list.push(selectableList);
          });
          setCategoryOptions(list);
        })
        .catch(() => showErrorAlert());
      if (product.categories.value) {
        getSubcategories(product.categories.value)
          .then((res) => {
            let list = [];
            res.forEach((item) => {
              let selectableList = {};
              selectableList.label = item["name"];
              selectableList.value = item["@id"];
              list.push(selectableList);
            });
            setSubcategoryOptions(list);
          })
          .catch(() => showErrorAlert());
      }
    }

    if (role === "manufacturer") {
      getManufacturerName().then((item) => {
        let currentManuf = {};
        currentManuf.label = item["name"];
        currentManuf.value = item["@id"];
        setProduct({
          ...product,
          manufacturer: currentManuf,
        });
      });
      getManufacturerCategories().then((categ) => {
        let list = [];
        categ.forEach((item) => {
          let selectableList = {};
          selectableList.label = item["name"];
          selectableList.value = item["@id"];
          list.push(selectableList);
        });
        setCategoryOptions(list);
      });
      if (product.categories.value) {
        getManufacturerSubcategories(product.categories.value)
          .then((res) => {
            let list = [];
            res.forEach((item) => {
              let selectableList = {};
              selectableList.label = item["name"];
              selectableList.value = item["@id"];
              list.push(selectableList);
            });
            setSubcategoryOptions(list);
          })
          .catch(() => showErrorAlert());
      }
    }
  }, []);

  function changePrice(event) {
    let currentInputs = { ...product };
    let newPrice = [...productList];
    newPrice.forEach((pr) => {
      pr.price.price = event.target.value;
    });
    currentInputs.price.price = event.target.value;
    setProductList(newPrice);
    setProduct(currentInputs);
  }

  function changePriceCurrency(selectedOption) {
    let currentInputs = { ...product };
    let newPrice = [...productList];
    newPrice.forEach((pr) => {
      pr.price.priceCurrency = selectedOption ? selectedOption : "";
    });
    currentInputs.price.priceCurrency = selectedOption ? selectedOption : "";
    setProductList(newPrice);
    setProduct(currentInputs);
  }

  function handleChangeManufacturer(selectedOption) {
    let list = [...productList];
    setLoadingDeliveryOptions(true);
    setProduct({
      ...product,
      manufacturer: selectedOption,
    });
    getDetails(selectedOption.value).then((item) => {
      item.label = item.name;
      item.value = item["@id"];
      setProduct({
        ...product,
        manufacturer: item,
      });
      list.forEach((pr) => {
        pr.manufacturer = item;
      });
      setProductList(list);
      setLoadingDeliveryOptions(false);
      setErrorMan(false);
    });
  }

  function handleChangeCategories(selectedOption) {
    let listPr = [...productList];
    listPr.forEach((pr) => {
      pr.categories = selectedOption;
      pr.subcategory = "";
    });
    setProductList(listPr);
    setProduct({
      ...product,
      categories: selectedOption,
      subcategory: "",
    });
    setErrorCateg(false);
    let list = [];
    getSubcategories(selectedOption.value)
      .then((res) => {
        res.forEach((item) => {
          let selectableList = {};
          selectableList.label = item["name"];
          selectableList.value = item["@id"];
          list.push(selectableList);
        });
        setSubcategoryOptions(list);
      })
      .catch(() => showErrorAlert());
  }

  function handleChangeSubcategories(selectedOption) {
    let list = [...productList];
    list.forEach((pr) => {
      pr.subcategory = selectedOption;
    });
    setProductList(list);
    setProduct({
      ...product,
      subcategory: selectedOption,
    });
    setErrorSubcateg(false);
  }

  function handleChangeVisibility(event) {
    let list = [...productList];
    list.forEach((pr) => {
      pr.visibility = event.target.value;
    });
    setProductList(list);
    setProduct({
      ...product,
      visibility: event.target.value,
    });
  }

  function handleChangeUm(selectedOption) {
    let list = [...productList];
    list.forEach((pr) => {
      pr.unitOfMeasurement = selectedOption ? selectedOption : "";
    });
    setProductList(list);
    setProduct({
      ...product,
      unitOfMeasurement: selectedOption ? selectedOption : "",
    });
    setErrorUm(false);
  }

  function showErrorAlert() {
    setError(true);
    setSaved(false);
  }

  function changeInfo(event) {
    let list = [...productList];
    list.forEach((pr) => {
      pr[event.target.name] = event.target.value;
    });
    setProductList(list);
    setProduct({
      ...product,
      [event.target.name]: event.target.value,
    });
  }

  return (
    <>
      {productList && (
        <Form>
          {productList.map((item) => {
            const productEditing = `${item["name"]} / SKU ${item["sku"]}`;
            return (
              <>
                <FormGroup className="row">
                  <Col md="10">
                    <div>{productEditing}</div>
                  </Col>
                </FormGroup>
              </>
            );
          })}

          <FormGroup className="row">
            <Label
              className="form-control-label"
              htmlFor="example-email-input"
              md="2"
            >
              Slogan
            </Label>
            <Col md="10">
              <Input
                onChange={(event) => changeInfo(event)}
                style={{ backgroundColor: COLORS.WHITE }}
                value={product["slogan"]}
                name="slogan"
                type="textarea"
                rows="3"
                placeholder="A slogan or motto associated with the product"
              />
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Label
              className="form-control-label"
              htmlFor="example-tel-input"
              md="2"
            >
              Manufacturer
            </Label>
            <Col md="10">
              <Select
                name="manufacturer"
                styles={{
                  control: (base, state) => ({
                    ...base,
                    backgroundColor: COLORS.WHITE,
                    fontSize: 14,
                    border: errorMan ? "1px solid red" : "1px solid lightgray",
                  }),
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    neutral50: "#8898aa",
                  },
                })}
                value={product.manufacturer}
                onChange={handleChangeManufacturer}
                options={manufacturerOptions}
              />
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Label
              className="form-control-label"
              htmlFor="example-number-input"
              md="2"
            >
              Category
            </Label>
            <Col md="10">
              <Select
                styles={{
                  control: (base, state) => ({
                    ...base,
                    backgroundColor: COLORS.WHITE,
                    fontSize: 14,
                    border: errorCateg
                      ? "1px solid red"
                      : "1px solid lightgray",
                  }),
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    neutral50: "#8898aa",
                  },
                })}
                name="categories"
                value={product.categories}
                onChange={handleChangeCategories}
                options={categoryOptions}
              />
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Label
              className="form-control-label"
              htmlFor="example-text-input"
              md="2"
            >
              Subcategory
            </Label>
            <Col md="10">
              <Select
                styles={{
                  control: (base, state) => ({
                    ...base,
                    backgroundColor: COLORS.WHITE,
                    fontSize: 14,
                    border: errorSubcateg
                      ? "1px solid red"
                      : "1px solid lightgray",
                  }),
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    neutral50: "#8898aa",
                  },
                })}
                name="subcategories"
                value={product.subcategory}
                onChange={handleChangeSubcategories}
                options={subcategoryOptions}
              />
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Label
              className="form-control-label"
              htmlFor="example-week-input"
              md="2"
            >
              Stock count
            </Label>
            <Col md="10">
              <Input
                onChange={(event) => changeInfo(event)}
                style={{ backgroundColor: COLORS.WHITE }}
                value={product["stockCount"]}
                name="stockCount"
                type="number"
                placeholder=""
              />
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Label
              className="form-control-label"
              htmlFor="example-url-input"
              md="2"
            >
              UM
            </Label>
            <Col md="10">
              <CreatableSelect
                placeholder="Select unit of measurement"
                styles={{
                  control: (base, state) => ({
                    ...base,
                    fontSize: "0.875rem",
                    backgroundColor: COLORS.WHITE,
                    border: errorUm ? "1px solid red" : "1px solid lightgray",
                  }),
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    neutral50: "#8898aa",
                  },
                })}
                name="unitOfMeasurement"
                value={product.unitOfMeasurement}
                onChange={handleChangeUm}
                options={PRODUCT_UM_OPTIONS}
              />
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Label
              className="form-control-label"
              htmlFor="example-week-input"
              md="2"
            >
              Price
            </Label>
            <Col md="10">
              <Input
                onChange={(event) => changePrice(event)}
                style={{
                  backgroundColor: COLORS.WHITE,
                  borderColor: errorPrice ? THEME.DANGER : "",
                }}
                value={`${product.price.price}`}
                name="price"
                type="number"
                placeholder=""
              />
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Label
              className="form-control-label"
              htmlFor="example-week-input"
              md="2"
            >
              Price currency
            </Label>
            <Col md="10">
              <CreatableSelect
                placeholder="Select currency"
                styles={{
                  control: (base, state) => ({
                    ...base,
                    fontSize: "0.875rem",
                    backgroundColor: COLORS.WHITE,
                    border: errorPriceCurrency
                      ? "1px solid red"
                      : "1px solid lightgray",
                  }),
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    neutral50: "#8898aa",
                  },
                })}
                name="priceCurrency"
                value={product.price.priceCurrency}
                onChange={changePriceCurrency}
                options={PRODUCT_CURRENCY_OPTIONS}
              />
            </Col>
          </FormGroup>
          <FormGroup className="row">
            <Label
              className="form-control-label"
              htmlFor="example-week-input"
              md="2"
            >
              Visibility
            </Label>
            <Col md="10">
              <FormControl
                component="fieldset"
                style={{ fontFamily: "Poppins" }}
              >
                <RadioGroup
                  row
                  value={product.visibility}
                  onChange={(event) => handleChangeVisibility(event)}
                >
                  {PRODUCT_VISIBILITY_OPTIONS.map((item, index) => {
                    return (
                      <div key={index}>
                        <FormControlLabel
                          value={item.value}
                          control={<Radio color="primary" />}
                          label={item.label}
                        />
                      </div>
                    );
                  })}
                </RadioGroup>
              </FormControl>
            </Col>
          </FormGroup>
        </Form>
      )}{" "}
    </>
  );
}

export default BulkProductDetailsContainer;

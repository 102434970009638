async function doFetchDeliveryProducts(type, role) {
  let header =
    role === "admin"
      ? {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
      : {
          "Content-Type": "application/json",
          "X-User-Auth": `${localStorage.getItem("token")}`,
        };

  const URL = process.env.REACT_APP_API_ENTRYPOINT + "/delivery_summary";
  let res = await fetch(URL, {
    method: "POST",
    headers: header,
    body: JSON.stringify({
      type,
    }),
  }).then((response) => response.json());
  return res;
}

export async function getDeliverySummary(type, role) {
  return doFetchDeliveryProducts(type, role).then((res) => {
    if (res.code === 401) {
      let url = process.env.REACT_APP_API_ENTRYPOINT + "/login/admin/refresh";

      return fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          refresh_token: `${localStorage.getItem("refresh_token")}`,
        }),
      })
        .then((res) => res.json())
        .then((r) => localStorage.setItem("token", r.token))
        .then(() => doFetchDeliveryProducts(type, role));
    }
    return res;
  });
}

export async function doFetch(url) {
  return await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

export async function deliveryProducts(url) {
  return doFetch(url).then((res) => {
    if (res.status === 401) {
      let link = process.env.REACT_APP_API_ENTRYPOINT + "/login/admin/refresh";

      return fetch(link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          refresh_token: `${localStorage.getItem("refresh_token")}`,
        }),
      })
        .then((res) => res.json())
        .then((r) => localStorage.setItem("token", r.token))
        .then(() => doFetch(url));
    }
    return res;
  });
}

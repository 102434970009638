import React, { useState, useEffect } from "react";
import { Card, CardHeader, Container, Row, Col } from "reactstrap";
import SimpleHeader from "../../componentsTheme/Headers/SimpleHeader.js";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import getCategories from "../../services/categories";
import Loader from "../../utils/loader";
import MUIDataTable from "mui-datatables";
import ErrorAlert, { showErrorDialog } from "../../utils/errorAlert";
import IconButton from "@material-ui/core/IconButton";

function CategoryList(props) {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState("1");
  const [count, setCount] = useState("");
  const [error, setError] = useState(false);

  const THEME = createMuiTheme({
    typography: {
      fontFamily: "Poppins",
    },
  });
  useEffect(() => {
    setLoading(true);
    getCategories(pageNumber)
      .then((categ) => {
        setCategories(categ);
        setLoading(false);
      })
      .catch(() => showErrorDialog(setError));
  }, [pageNumber]);

  const options = {
    count: count,
    rowsPerPage: 10,
    selectableRows: "none",
    responsive: "vertical",
    rowsPerPageOptions: [],
    download: false,
    print: false,
    search: false,
  };

  const columns = [
    {
      name: "image",
      label: "ICON",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <img
                alt="..."
                style={{ backgroundColor: "white" }}
                className="avatar rounded-circle mr-1"
                src={value.contentUrl}
              />
            </>
          );
        },
      },
    },
    {
      name: "@id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value.slice(12, 20)}</>;
        },
      },
    },
    {
      name: "name",
      label: "NAME",
      options: {
        filter: true,
        sort: true,
        filterType: "textField",
      },
    },
    {
      name: "DETAILS",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ width: "30px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IconButton
                  size="small"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={() =>
                    props.history.push(
                      `show/${encodeURIComponent(tableMeta.rowData[1])}`,
                      {
                        category: tableMeta.rowData[1],
                      }
                    )
                  }
                >
                  <MoreVertIcon fontSize="small" />
                </IconButton>
              </div>
            </div>
          );
        },
      },
    },
  ];

  return (
    <>
      <SimpleHeader name="All categories" parentName="Categories" />
      <Container className="mt--6" fluid>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Card>
            <CardHeader className="border-0">
              <Row>
                <Col xs="6">
                  <h3 className="mb-0">Category list</h3>
                </Col>
              </Row>
            </CardHeader>
            {loading && <Loader />}
            {error && (
              <ErrorAlert
                text={
                  "There was an error while loading the category list, please try again"
                }
              />
            )}
            {!loading && (
              <>
                <MuiThemeProvider theme={THEME}>
                  <MUIDataTable
                    title={""}
                    data={categories}
                    columns={columns}
                    options={options}
                  />
                </MuiThemeProvider>
              </>
            )}
          </Card>
        </Col>
      </Container>
    </>
  );
}

export default CategoryList;

import React, { useEffect, useState } from "react";
import {
  Card,
  Container,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
} from "reactstrap";
import SimpleHeader from "../../../componentsTheme/Headers/SimpleHeader.js";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import ErrorAlert from "../../../utils/errorAlert";
import Header from "../../../utils/header";
import MaterialTable from "material-table";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import * as moment from "moment";
import ReactDatetime from "react-datetime";
import Loader from "../../../utils/loader";
import {
  getManufacturerSuborders,
  getManufacturerName,
} from "../../../services/manufacturers";

function Payments(props) {
  const [manufacturer, setManufacturer] = useState("");
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const tableRef = React.createRef();
  const [showCustomInputs, setShowCustomInputs] = useState(false);
  const [period, setPeriod] = useState({
    startDate: moment(new Date()),
    endDate: moment().add(7, "d"),
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [month, setMonth] = useState("Current Month");
  const [totalPrice, setTotalPrice] = useState();
  const [loading, setLoading] = useState(false);

  const THEME = createMuiTheme({
    typography: {
      fontFamily: "Poppins",
    },
  });

  const columns = [
    {
      field: "forOrder",
      title: "Order Id",
      render: (rowData) => {
        return Number(rowData.forOrder["@id"].slice(7, 15).match(/[0-9]+/g)[0]);
      },
    },
    {
      field: "createdAt",
      title: "DATE",
      filtering: false,
      render: (rowData) => {
        return `${new Date(rowData.createdAt).toLocaleDateString()}`;
      },
    },
    {
      field: "finalPrice",
      title: "Total Sales",
      filtering: false,
      render: (rowData) => {
        return `${rowData.finalPrice.price} ${rowData.finalPrice.priceCurrency}`;
      },
    },
    {
      field: "appRevenue",
      title: "Tax",
      filtering: false,
      render: (rowData) => {
        return `${(rowData.finalPrice.price * 0.15).toFixed(2)} ${
          rowData.finalPrice.priceCurrency
        }`;
      },
    },
    {
      field: "manufacturerPayment",
      title: "Revenue",
      filtering: false,
      render: (rowData) => {
        return `${(
          rowData.finalPrice.price -
          rowData.finalPrice.price * 0.15
        ).toFixed(2)} ${rowData.finalPrice.priceCurrency}`;
      },
    },
    {
      field: "paymentStatus",
      title: "Status",
      render: (rowData) => {
        return `${rowData.paymentStatus}`;
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    getManufacturerName()
      .then((item) => {
        setManufacturer(item);
        setLoading(false);
      })
      .catch((error) => {
        showErrorDialog(setError);
        setErrorText(
          "There was an error while loading the informations, please try again"
        );
      });
  }, []);

  function showErrorDialog() {
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 3000);
  }

  function addStartDate(date) {
    setPeriod({
      ...period,
      startDate: date["_d"],
    });
  }

  function addEndDate(date) {
    setPeriod({
      ...period,
      endDate: date["_d"],
    });
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function customDateSearch(e) {
    e.preventDefault();
    tableRef.current.onQueryChange();
    setMonth("customPeriod");
  }

  function getUrl(query, hasPagination, filterByDate) {
    let url =
      process.env.REACT_APP_API_ENTRYPOINT +
      `/suborders?manufacturer.id=${manufacturer.id}`;
    url += "&page=" + (query.page + 1);
    url += "&pagination=" + hasPagination;

    if (query.orderBy) {
      url += `&order%5B${query.orderBy.field}%5D=${query.orderDirection}`;
    }

    if (query.filters) {
      query.filters.forEach((filter) => {
        switch (filter.column.field) {
          case "forOrder":
            url += "&forOrder.id=" + encodeURIComponent(filter.value);
            break;
          case "paymentStatus":
            url += "&paymentStatus=" + encodeURIComponent(filter.value);
            break;
          default:
        }
      });
    }

    if (filterByDate) {
      switch (filterByDate) {
        case "Last Month":
          var startMonth = moment(startMonth)
            .subtract(1, "months")
            .startOf("month")
            .format("YYYY-MM-DDTHH:mm");
          var endMonth = moment(endMonth)
            .subtract(1, "months")
            .endOf("month")
            .format("YYYY-MM-DDTHH:mm");
          url += `&createdAt%5Bafter%5D=${startMonth}&createdAt%5Bbefore%5D=${endMonth}`;
          break;
        case "Current Month":
          var startCurrentMonth = moment(startMonth)
            .startOf("month")
            .format("YYYY-MM-DDTHH:mm");
          var endCurrentMonth = moment(endMonth)
            .endOf("month")
            .format("YYYY-MM-DDTHH:mm");
          url += `&createdAt%5Bafter%5D=${startCurrentMonth}&createdAt%5Bbefore%5D=${endCurrentMonth}`;
          break;
        case "Custom Period":
          var startCustomPeriod = moment(period.startDate).format(
            "YYYY-MM-DDTHH:mm"
          );
          var endCustomPeriod = moment(period.endDate).format(
            "YYYY-MM-DDTHH:mm"
          );
          url += `&createdAt%5Bafter%5D=${startCustomPeriod}&createdAt%5Bbefore%5D=${endCustomPeriod}`;
          break;
        default:
      }
    }
    return url;
  }

  const getNewItems = async (query) => {
    let url = getUrl(query, true, month);
    const fetchResult = await getManufacturerSuborders(url);
    if (fetchResult.status > 299) {
      return {
        data: [],
        page: query.page,
        totalCount: 1,
      };
    } else {
      const parsedResponse = await fetchResult.json();
      if (parsedResponse.hasOwnProperty("hydra:member")) {
        let list = [];
        let totalItems = parsedResponse["hydra:totalItems"];
        parsedResponse["hydra:member"].forEach((pr) => {
          list.push(pr);
        });
        let tot = 0;
        list.map((a, key) => {
          tot += a.finalPrice.price;
          return tot;
        });
        setTotalPrice(tot);
        return {
          data: list,
          page: query.page,
          totalCount: totalItems,
        };
      } else {
        let list = [];
        list.push(parsedResponse);
        return {
          data: list,
          page: query.page,
          totalCount: 1,
        };
      }
    }
  };

  async function filterByCurrentMonth() {
    setShowCustomInputs(false);
    setAnchorEl(null);
    tableRef.current.onQueryChange();
    setMonth("Current Month");
  }

  function filterByLastMonth() {
    setShowCustomInputs(false);
    setAnchorEl(null);
    tableRef.current.onQueryChange();
    setMonth("Last Month");
  }

  function showCustomPeriodInputs() {
    setShowCustomInputs(true);
    setAnchorEl(null);
  }

  function customDateSearch(e) {
    e.preventDefault();
    tableRef.current.onQueryChange();
    setMonth("Custom Period");
  }

  return (
    <>
      <SimpleHeader name="All payments" parentName="Payments" />
      <Container className="mt--6" fluid>
        <Card>
          <Header
            title={"Payments list"}
            edit={true}
            del
            delText
            loading={false}
            showIcons={false}
          />
          {error && (
            <ErrorAlert
              text={
                "There was an error while loading the payments page, please try again"
              }
            />
          )}
          <>
            {loading && <Loader />}
            {!loading && (
              <>
                <MuiThemeProvider theme={THEME}>
                  <div style={{ marginTop: 20, marginLeft: 15 }}>
                    <Button
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={handleClick}
                    >
                      {`${month} >`}
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={filterByCurrentMonth}>
                        Current month
                      </MenuItem>
                      <MenuItem onClick={filterByLastMonth}>
                        Last month
                      </MenuItem>
                      <MenuItem onClick={showCustomPeriodInputs}>
                        Custom period
                      </MenuItem>
                    </Menu>
                    {showCustomInputs && (
                      <Form>
                        <FormGroup className="row">
                          <Col md="2"></Col>
                          <Col md="3">
                            <ReactDatetime
                              inputProps={{
                                style: {
                                  color: "black",
                                },
                              }}
                              value={moment(period.startDate).format(
                                "DD-MM-YYYY"
                              )}
                              timeFormat={false}
                              onChange={(date) => addStartDate(date)}
                            />
                          </Col>

                          <Label
                            className="form-control-label"
                            htmlFor="example-text-input"
                            md="1"
                          >
                            to
                          </Label>
                          <Col md="3">
                            <ReactDatetime
                              inputProps={{
                                style: {
                                  color: "black",
                                },
                              }}
                              value={moment(period.endDate).format(
                                "DD-MM-YYYY"
                              )}
                              timeFormat={false}
                              onChange={(date) => addEndDate(date)}
                            />
                          </Col>
                          <Button
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={(e) => customDateSearch(e)}
                          >
                            Apply
                          </Button>
                        </FormGroup>
                      </Form>
                    )}
                  </div>
                  <div>
                    <MaterialTable
                      columns={columns}
                      data={getNewItems}
                      title={null}
                      tableRef={tableRef}
                      options={{
                        sorting: true,
                        pageSize: 30,
                        pageSizeOptions: [30],
                        search: false,
                        exportAllData: true,
                        actionsColumnIndex: -1,
                      }}
                      style={{ borderWidth: 0, boxShadow: "none" }}
                      localization={{
                        header: {
                          actions: "",
                        },
                        body: {
                          emptyDataSourceMessage: "No records to display",
                          filterRow: {
                            filterTooltip: "Filter",
                          },
                        },
                      }}
                    />
                  </div>
                  <div
                    style={{
                      marginLeft: 30,
                      marginRight: 30,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      verticalAlign: "center",
                      alignItems: "center",
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  >
                    <p style={{ marginTop: 15 }}>
                      TOTAL SALES: {totalPrice} RON
                    </p>
                  </div>
                </MuiThemeProvider>
              </>
            )}
          </>
        </Card>
      </Container>
    </>
  );
}

export default Payments;

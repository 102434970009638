import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Container,
  Col,
  Form,
  FormGroup,
  Label,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import SimpleHeader from "../../../componentsTheme/Headers/SimpleHeader.js";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import {
  getManufacturerSuborders,
  getManufacturerName,
} from "../../../services/manufacturers";
import { getManufacturerInvoice } from "../../../services/invoices";
import ErrorAlert from "../../../utils/errorAlert";
import Header from "../../../utils/header";
import Loader from "../../../utils/loader";
import MaterialTable from "material-table";
import ReactDatetime from "react-datetime";
import * as moment from "moment";

function InvoiceList(props) {
  const [manufacturer, setManufacturer] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [loading, setLoading] = useState(false);
  const [showCustomInputs, setShowCustomInputs] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [period, setPeriod] = useState({
    startDate: moment(new Date()),
    endDate: moment().add(7, "d"),
  });
  const [month, setMonth] = useState();
  const tableRef = React.createRef();
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  useEffect(() => {
    setLoading(true);
    getManufacturerName()
      .then((item) => {
        setManufacturer(item);
        setLoading(false);
      })
      .catch((error) => {
        showErrorDialog(setError);
        setErrorText(
          "There was an error while loading the informations, please try again"
        );
      });
  }, []);

  function showErrorDialog() {
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 3000);
  }

  function addStartDate(date) {
    setPeriod({
      ...period,
      startDate: date["_d"],
    });
  }

  function addEndDate(date) {
    setPeriod({
      ...period,
      endDate: date["_d"],
    });
  }

  const THEME = createMuiTheme({
    typography: {
      fontFamily: "Poppins",
    },
  });

  const columns = [
    {
      field: "invoice",
      title: "ID",
      render: (rowData) => {
        return <>{Number(rowData.invoice.slice(10, 15))}</>;
      },
    },
    {
      field: "forOrder",
      title: "CUSTOMER",
      filtering: false,
      render: (rowData) => {
        return (
          <>
            {rowData.forOrder.customer.firstName}{" "}
            {rowData.forOrder.customer.lastName}
          </>
        );
      },
    },
    {
      field: "suborder",
      title: "FOR SUBORDER",
      render: (rowData) => {
        return <>{Number(rowData["@id"].slice(11, 15))}</>;
      },
    },
    {
      field: "createdAt",
      title: "DATE",
      filterComponent: (props) => <CustomDatePicker {...props} />,
      render: (rowData) => {
        return `${new Date(rowData.createdAt).toLocaleDateString()}`;
      },
    },
  ];

  function getUrl(query, hasPagination, filterByDate) {
    let url =
      process.env.REACT_APP_API_ENTRYPOINT +
      `/suborders?manufacturer.id=${manufacturer.id}`;
    url += "&page=" + (query.page + 1);
    url += "&pagination=" + hasPagination;

    if (query.orderBy) {
      url += `&order%5B${query.orderBy.field}%5D=${query.orderDirection}`;
    }

    if (query.filters) {
      query.filters.forEach((filter) => {
        switch (filter.column.field) {
          case "invoice":
            url += "&invoice.id=" + encodeURIComponent(filter.value);
            break;
          case "suborder":
            url =
              process.env.REACT_APP_API_ENTRYPOINT +
              "/suborders/" +
              encodeURIComponent(filter.value);
            break;
          default:
        }
      });
    }

    if (filterByDate) {
      switch (filterByDate) {
        case "lastMonth":
          var startMonth = moment(startMonth)
            .subtract(1, "months")
            .startOf("month")
            .format("YYYY-MM-DDTHH:mm");
          var endMonth = moment(endMonth)
            .subtract(1, "months")
            .endOf("month")
            .format("YYYY-MM-DDTHH:mm");
          url += `&createdAt%5Bafter%5D=${startMonth}&createdAt%5Bbefore%5D=${endMonth}`;
          break;
        case "currentMonth":
          var startCurrentMonth = moment(startMonth)
            .startOf("month")
            .format("YYYY-MM-DDTHH:mm");
          var endCurrentMonth = moment(endMonth)
            .endOf("month")
            .format("YYYY-MM-DDTHH:mm");
          url += `&createdAt%5Bafter%5D=${startCurrentMonth}&createdAt%5Bbefore%5D=${endCurrentMonth}`;
          break;
        case "customPeriod":
          var startCustomPeriod = moment(period.startDate).format(
            "YYYY-MM-DDTHH:mm"
          );
          var endCustomPeriod = moment(period.endDate).format(
            "YYYY-MM-DDTHH:mm"
          );
          url += `&createdAt%5Bafter%5D=${startCustomPeriod}&createdAt%5Bbefore%5D=${endCustomPeriod}`;
          break;
        default:
      }
    }
    return url;
  }

  const getNewItems = async (query) => {
    let url = getUrl(query, true, month);
    const fetchResult = await getManufacturerSuborders(url);
    if (fetchResult.status > 299) {
      return {
        data: [],
        page: query.page,
        totalCount: 1,
      };
    } else {
      const parsedResponse = await fetchResult.json();

      if (parsedResponse.hasOwnProperty("hydra:member")) {
        let list = [];
        parsedResponse["hydra:member"].forEach((pr) => {
          if (pr.hasOwnProperty("invoice")) {
            list.push(pr);
          }
        });
        return {
          data: list,
          page: query.page,
          totalCount: parsedResponse["hydra:totalItems"],
        };
      } else {
        let list = [];
        list.push(parsedResponse);
        return {
          data: list,
          page: query.page,
          totalCount: 1,
        };
      }
    }
  };

  const CustomDatePicker = (props) => {
    return (
      <Dropdown
        isOpen={dropdownOpen}
        toggle={toggle}
        style={{
          textAlign: "left",
          color: "black",
          marginLeft: "0px",
          paddingLeft: "0px",
          fontFamily: "Poppins",
          cursor: "pointer",
        }}
      >
        <DropdownToggle
          tag="a"
          className="nav-link"
          style={{
            width: "100%",
            color: "black",
            marginLeft: "0px",
            paddingLeft: "10px",
            borderRadius: "4px",
            border: "none",
            maxHeight: "100%",
          }}
        >
          Filter by
        </DropdownToggle>
        <DropdownMenu onClick={(e) => handleFilterBy(e.target.value)}>
          <DropdownItem value="currentMonth">Current month</DropdownItem>
          <DropdownItem value="lastMonth">Last month</DropdownItem>
          <DropdownItem value="customPeriod">Custom period</DropdownItem>
          <DropdownItem divider />
          <DropdownItem value="reset">Reset</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  const exportCsv = async (allColumns, allData) => {
    let invoiceList = [];
    allData.forEach((suborder) => {
      invoiceList.push(suborder.invoice);
    });
    invoiceList.forEach((inv) => {
      getManufacturerInvoice(inv).then(async (invoice) => {
        if (invoice.status > 299) {
          setError(true);
        } else {
          const parsedResponse = await invoice.json();
          window.location = parsedResponse["url"];
        }
      });
    });
  };

  async function handleFilterBy(e) {
    switch (e) {
      case "currentMonth":
        setShowCustomInputs(false);
        tableRef.current.onQueryChange();
        setMonth("currentMonth");
        break;
      case "lastMonth":
        setShowCustomInputs(false);
        tableRef.current.onQueryChange();
        setMonth("lastMonth");
        break;
      case "customPeriod":
        setShowCustomInputs(true);
        break;
      case "reset":
        setShowCustomInputs(false);
        tableRef.current.onQueryChange();
        setMonth("");
    }
  }

  function customDateSearch(e) {
    e.preventDefault();
    tableRef.current.onQueryChange();
    setMonth("customPeriod");
  }

  const actions = [
    {
      name: "download",
      icon: () => <CloudDownloadIcon />,
      tooltip: "Download PDF",
      onClick: (event, rowData) =>
        getManufacturerInvoice(rowData.invoice).then(async (invoice) => {
          if (invoice.status > 299) {
            setError(true);
          } else {
            const parsedResponse = await invoice.json();
            window.location = parsedResponse["url"];
          }
        }),
      disabled: false,
      position: "row",
    },
  ];

  return (
    <>
      <SimpleHeader name="All invoices" parentName="Invoices" />
      <Container className="mt--6" fluid>
        <Card>
          <Header
            title={"Invoice list"}
            edit={true}
            del
            delText
            loading={false}
            showIcons={false}
          />
          {error && <ErrorAlert text={errorText} />}
          <>
            <MuiThemeProvider theme={THEME}>
              <div>
                {showCustomInputs && (
                  <Form>
                    <FormGroup className="row">
                      <Col md="2"></Col>
                      <Col md="3">
                        <ReactDatetime
                          inputProps={{
                            style: {
                              color: "black",
                            },
                          }}
                          value={moment(period.startDate).format("DD-MM-YYYY")}
                          timeFormat={false}
                          onChange={(date) => addStartDate(date)}
                        />
                      </Col>

                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                        md="1"
                      >
                        to
                      </Label>
                      <Col md="3">
                        <ReactDatetime
                          inputProps={{
                            style: {
                              color: "black",
                            },
                          }}
                          value={moment(period.endDate).format("DD-MM-YYYY")}
                          timeFormat={false}
                          onChange={(date) => addEndDate(date)}
                        />
                      </Col>
                      <Button
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(e) => customDateSearch(e)}
                      >
                        Apply
                      </Button>
                    </FormGroup>
                  </Form>
                )}
              </div>
              {loading && <Loader />}
              {!loading && (
                <div style={{ maxWidth: "100%" }}>
                  <MaterialTable
                    columns={columns}
                    data={getNewItems}
                    title={null}
                    tableRef={tableRef}
                    options={{
                      exportButton: true,
                      sorting: true,
                      filtering: true,
                      pageSize: 30,
                      pageSizeOptions: [30],
                      search: false,
                      exportAllData: true,
                      actionsColumnIndex: -1,
                      exportCsv,
                    }}
                    localization={{
                      header: {
                        actions: "",
                      },
                      toolbar: {
                        exportName: "Download all",
                      },
                    }}
                    actions={actions}
                  />
                </div>
              )}
            </MuiThemeProvider>
          </>
        </Card>
      </Container>
    </>
  );
}

export default InvoiceList;

import React, { useEffect, useState } from "react";
import {
  Card,
  Container,
  Button,
  Form,
  FormGroup,
  Label,
  Col,
} from "reactstrap";
import SimpleHeader from "../../componentsTheme/Headers/SimpleHeader.js";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { getProducts } from "../../services/products";
import ErrorAlert from "../../utils/errorAlert";
import Header from "../../utils/header";
import MaterialTable from "material-table";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import * as moment from "moment";
import ReactDatetime from "react-datetime";
import doFetch from "../../services/manufacturers";
import { updateSuborders } from "../../services/suborders.js";

function Payments(props) {
  const [error, setError] = useState(false);
  const tableRef = React.createRef();
  const [showCustomInputs, setShowCustomInputs] = useState(false);
  const [period, setPeriod] = useState({
    startDate: moment(new Date()),
    endDate: moment().add(7, "d"),
  });
  const [manufacturerList, setManufacturerList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElManuf, setAnchorElManuf] = useState(null);
  const [selectedManufacturer, setSelectedManufacturer] = useState(2);
  const [month, setMonth] = useState("Current Month");
  const [totalPrice, setTotalPrice] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [totalAmountToPay, setTotalAmountToPay] = useState();
  const [totalAmountToKeep, setTotalAmountToKeep] = useState();

  const THEME = createMuiTheme({
    typography: {
      fontFamily: "Poppins",
    },
  });

  const columns = [
    {
      field: "manufacturerName",
      title: "Manufacturer Name",
      render: (rowData) => {
        return `${
          selectedManufacturer === 2
            ? "Selectia Noastra"
            : selectedManufacturer.name
        }`;
      },
    },
    {
      field: "forOrder",
      title: "Order Id",
      render: (rowData) => {
        return Number(rowData.forOrder["@id"].slice(7, 15).match(/[0-9]+/g)[0]);
      },
    },
    {
      field: "createdAt",
      title: "DATE",
      filtering: false,
      render: (rowData) => {
        return `${new Date(rowData.createdAt).toLocaleDateString()}`;
      },
    },
    {
      field: "totalSales",
      title: "Total Sales",
      filtering: false,
      render: (rowData) => {
        return `${rowData.finalPrice.price} ${rowData.finalPrice.priceCurrency}`;
      },
    },
    {
      field: "appRevenue",
      title: "App Revenue",
      filtering: false,
      render: (rowData) => {
        return `${(rowData.finalPrice.price * 0.15).toFixed(2)} ${
          rowData.finalPrice.priceCurrency
        }`;
      },
    },
    {
      field: "manufacturerPayment",
      title: "Partner revenue",
      filtering: false,
      render: (rowData) => {
        return `${(
          rowData.finalPrice.price -
          rowData.finalPrice.price * 0.15
        ).toFixed(2)} ${rowData.finalPrice.priceCurrency}`;
      },
    },
    {
      field: "paymentStatus",
      title: "Status",
      render: (rowData) => {
        return `${rowData.paymentStatus}`;
      },
    },
  ];

  function addStartDate(date) {
    setPeriod({
      ...period,
      startDate: date["_d"],
    });
  }

  function addEndDate(date) {
    setPeriod({
      ...period,
      endDate: date["_d"],
    });
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickManuf = (event) => {
    setAnchorElManuf(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseManuf = () => {
    setAnchorElManuf(null);
  };

  function customDateSearch(e) {
    e.preventDefault();
    tableRef.current.onQueryChange();
    setMonth("customPeriod");
  }

  useEffect(() => {
    doFetch(1).then((snap) => {
      setManufacturerList(snap["hydra:member"]);
    });
  }, []);

  function getUrl(query, hasPagination, filterByDate) {
    let url =
      process.env.REACT_APP_API_ENTRYPOINT +
      `/suborders?forOrder.status[]=completed&forOrder.status[]=new&forOrder.status[]=prepared&manufacturer.id[]=${
        selectedManufacturer.id ? selectedManufacturer.id : 1
      }`;
    url += "&";
    url += "&page=" + (query.page + 1);
    url += "&pagination=" + hasPagination;

    if (query.orderBy) {
      url += `&order%5B${query.orderBy.field}%5D=${query.orderDirection}`;
    }

    if (query.filters) {
      query.filters.forEach((filter) => {
        switch (filter.column.field) {
          case "forOrder":
            url += "&forOrder.id=" + encodeURIComponent(filter.value);
            break;
          case "paymentStatus":
            url += "&paymentStatus=" + encodeURIComponent(filter.value);
            break;
          default:
        }
      });
    }

    if (filterByDate) {
      switch (filterByDate) {
        case "Last Month":
          var startMonth = moment(startMonth)
            .subtract(1, "months")
            .startOf("month")
            .format("YYYY-MM-DDTHH:mm");
          var endMonth = moment(endMonth)
            .subtract(1, "months")
            .endOf("month")
            .format("YYYY-MM-DDTHH:mm");
          url += `&createdAt%5Bafter%5D=${startMonth}&createdAt%5Bbefore%5D=${endMonth}`;
          break;
        case "Current Month":
          var startCurrentMonth = moment(startMonth)
            .startOf("month")
            .format("YYYY-MM-DDTHH:mm");
          var endCurrentMonth = moment(endMonth)
            .endOf("month")
            .format("YYYY-MM-DDTHH:mm");
          url += `&createdAt%5Bafter%5D=${startCurrentMonth}&createdAt%5Bbefore%5D=${endCurrentMonth}`;
          break;
        case "Custom Period":
          var startCustomPeriod = moment(period.startDate).format(
            "YYYY-MM-DDTHH:mm"
          );
          var endCustomPeriod = moment(period.endDate).format(
            "YYYY-MM-DDTHH:mm"
          );
          url += `&createdAt%5Bafter%5D=${startCustomPeriod}&createdAt%5Bbefore%5D=${endCustomPeriod}`;
          break;
        default:
      }
    }
    return url;
  }

  const getNewItems = async (query) => {
    let url = getUrl(query, true, month);
    const fetchResult = await getProducts(url);
    if (fetchResult.status > 299) {
      return {
        data: [],
        page: query.page,
        totalCount: 1,
      };
    } else {
      const parsedResponse = await fetchResult.json();
      if (parsedResponse.hasOwnProperty("hydra:member")) {
        let list = [];
        let totalItems = parsedResponse["hydra:totalItems"];
        parsedResponse["hydra:member"].forEach((pr) => {
          list.push(pr);
        });
        let tot = 0;
        list.map((a, key) => {
          tot += a.finalPrice.price;
          return tot;
        });

        setTotalPrice(tot);
        return {
          data: list,
          page: query.page,
          totalCount: totalItems,
        };
      } else {
        let list = [];
        list.push(parsedResponse);
        return {
          data: list,
          page: query.page,
          totalCount: 1,
        };
      }
    }
  };

  async function filterByCurrentMonth() {
    setShowCustomInputs(false);
    setAnchorEl(null);
    tableRef.current.onQueryChange();
    setMonth("Current Month");
  }

  function filterByLastMonth() {
    setShowCustomInputs(false);
    setAnchorEl(null);
    tableRef.current.onQueryChange();
    setMonth("Last Month");
  }

  function showCustomPeriodInputs() {
    setShowCustomInputs(true);
    setAnchorEl(null);
  }

  function customDateSearch(e) {
    e.preventDefault();
    tableRef.current.onQueryChange();
    setMonth("Custom Period");
  }

  function manufacturerMenu(data) {
    setSelectedManufacturer(data);
    setAnchorElManuf(null);
    tableRef.current.onQueryChange();
  }

  function checkRows(data) {
    let totalPartnerRevenue = 0;
    let totalAppRevenue = 0;

    data.forEach((a, key) => {
      const totalPrice = a.finalPrice.price;
      const appRevenue = totalPrice * 0.15;
      const partnerRevenue = totalPrice - appRevenue;
      const partnerRevenueFixed = Number(partnerRevenue.toFixed(2));
      const appRevenueFixed = Number(appRevenue.toFixed(2));

      totalPartnerRevenue += partnerRevenueFixed;
      totalAppRevenue += appRevenueFixed;
    });
    setTotalAmountToPay(totalPartnerRevenue);
    setTotalAmountToKeep(totalAppRevenue);

    const mapare = data.map((a, key) => {
      return a["@id"];
    });
    setSelectedRows(mapare);
  }

  function updateStatus() {
    selectedRows.map((snap, key) => {
      updateSuborders(snap);
      tableRef.current.onQueryChange();
      setSelectedRows([]);
    });
  }

  return (
    <>
      <SimpleHeader name="All payments" parentName="Payments" />
      <Container className="mt--6" fluid>
        <Card>
          <Header
            title={"Payments list"}
            edit={true}
            del
            delText
            loading={false}
            showIcons={false}
          />
          {error && (
            <ErrorAlert
              text={
                "There was an error while loading the payments page, please try again"
              }
            />
          )}
          <>
            <MuiThemeProvider theme={THEME}>
              <div style={{ marginTop: 20, marginLeft: 15 }}>
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  {`${month} >`}
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={filterByCurrentMonth}>
                    Current month
                  </MenuItem>
                  <MenuItem onClick={filterByLastMonth}>Last month</MenuItem>
                  <MenuItem onClick={showCustomPeriodInputs}>
                    Custom period
                  </MenuItem>
                </Menu>
                {showCustomInputs && (
                  <Form>
                    <FormGroup className="row">
                      <Col md="2"></Col>
                      <Col md="3">
                        <ReactDatetime
                          inputProps={{
                            style: {
                              color: "black",
                            },
                          }}
                          value={moment(period.startDate).format("DD-MM-YYYY")}
                          timeFormat={false}
                          onChange={(date) => addStartDate(date)}
                        />
                      </Col>

                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                        md="1"
                      >
                        to
                      </Label>
                      <Col md="3">
                        <ReactDatetime
                          inputProps={{
                            style: {
                              color: "black",
                            },
                          }}
                          value={moment(period.endDate).format("DD-MM-YYYY")}
                          timeFormat={false}
                          onChange={(date) => addEndDate(date)}
                        />
                      </Col>
                      <Button
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(e) => customDateSearch(e)}
                      >
                        Apply
                      </Button>
                    </FormGroup>
                  </Form>
                )}
              </div>
              <div style={{ marginTop: 20, marginBottom: 20, marginLeft: 15 }}>
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClickManuf}
                >
                  {selectedManufacturer.name
                    ? `${selectedManufacturer.name} >`
                    : "Selectia Noastra >"}
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorElManuf}
                  keepMounted
                  open={Boolean(anchorElManuf)}
                  onClose={handleCloseManuf}
                >
                  {manufacturerList.length > 0
                    ? manufacturerList.map((snap, key) => {
                        return (
                          <MenuItem
                            value={snap.id}
                            key={key}
                            onClick={manufacturerMenu.bind(this, snap)}
                            selected={snap.name === selectedManufacturer.name}
                          >
                            {snap.name}
                          </MenuItem>
                        );
                      })
                    : null}
                </Menu>
              </div>
              <div>
                <MaterialTable
                  columns={columns}
                  data={getNewItems}
                  title={null}
                  tableRef={tableRef}
                  options={{
                    sorting: true,
                    pageSize: 30,
                    pageSizeOptions: [30],
                    search: false,
                    exportAllData: true,
                    actionsColumnIndex: -1,
                    selection: true,
                    showSelectAllCheckbox: false,
                    selectionProps: (rowData) => ({
                      disabled: rowData.paymentStatus === "paid",
                      color: "primary",
                    }),
                  }}
                  style={{ borderWidth: 0, boxShadow: "none" }}
                  onSelectionChange={(rows) => {
                    checkRows(rows);
                  }}
                  localization={{
                    header: {
                      actions: "",
                    },
                    toolbar: {
                      nRowsSelected: "{0} product(s) selected",
                    },
                    body: {
                      emptyDataSourceMessage: "No records to display",
                      filterRow: {
                        filterTooltip: "Filter",
                      },
                    },
                  }}
                />
              </div>
              <div
                style={{
                  marginLeft: 30,
                  marginRight: 30,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  verticalAlign: "center",
                  alignItems: "center",
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <p style={{ marginTop: 15 }}>TOTAL SALES: {totalPrice} RON</p>
                {selectedRows.length > 0 ? (
                  <div>
                    <p>Amount to be paid: {totalAmountToPay.toFixed(2)}</p>
                    <p>Amount to keep: {totalAmountToKeep.toFixed(2)}</p>
                    <Button
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      style={{ backgroundColor: "green", color: "white" }}
                      onClick={updateStatus}
                    >
                      Edit status to paid for selected item
                    </Button>
                  </div>
                ) : null}
              </div>
            </MuiThemeProvider>
          </>
        </Card>
      </Container>
    </>
  );
}

export default Payments;

import Login from "./components/login/Login.js";
import ProviderLogin from "./components/login/ProviderLogin.js";
import Profile from "./components/provider/profile/Profile.js";
import ShowOrder from "./components/provider/ShowOrder.js";
import OrderList from "./components/provider/OrderList.js";

const providerRoutes = [
  {
    collapse: false,
    name: "Orders",
    icon: "ni ni-align-left-2 text-default",
    state: "tablesCollapse",
    path: "/orders/show/:id",
    miniName: "O",
    component: ShowOrder,
    layout: "/provider",
    redirect: "/orders/show/:id",
  },
  {
    collapse: false,
    name: "My deliveries",
    icon: "ni ni-align-left-2 text-default",
    state: "tables2Collapse",
    path: "/orders",
    miniName: "O",
    component: OrderList,
    layout: "/provider",
  },
  {
    collapse: false,
    name: "My profile",
    icon: "ni ni-single-02 text-default",
    state: "profileCollapse",
    path: "/profile",
    miniName: "O",
    component: Profile,
    layout: "/provider",
  },
  {
    collapse: true,
    name: "Others",
    icon: "ni ni-ungroup text-orange",
    state: "examplesCollapse",
    redirect: "/login/admin/",
    views: [
      {
        path: "/login/admin",
        name: "Login",
        miniName: "L",
        component: Login,
        layout: "/auth",
      },
    ],
  },
  {
    collapse: true,
    name: "Others",
    icon: "ni ni-ungroup text-orange",
    state: "examplesCollapse",
    redirect: "/login/provider",
    views: [
      {
        path: "/login/provider",
        name: "Login",
        miniName: "L",
        component: ProviderLogin,
        layout: "/auth",
      },
    ],
  },
];

export default providerRoutes;

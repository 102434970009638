import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Label,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import SimpleHeader from "../../componentsTheme/Headers/SimpleHeader.js";
import Select from "react-select";
import SuccessAlert, { showSaveDialog } from "../../utils/succesAlert";
import ErrorAlert, { showErrorDialog } from "../../utils/errorAlert";
import Loader from "../../utils/loader";
import Header from "../../utils/header";
import {
  getUserDetails,
  updateUserDetails,
  deleteUser,
  USER_ROLE_OPTIONS,
} from "../../services/users";

function Show(props) {
  const [currentUser, setCurrentUser] = useState("");
  const [edit, setEdit] = useState(false);
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [errorUsername, setErrorUsername] = useState(false);
  const [errorRole, setErrorRole] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [loading, setLoading] = useState(false);

  const isFormValid =
    currentUser !== ""
      ? currentUser.email.trim().length > 0 && currentUser.roles.length > 0
      : "";

  useEffect(() => {
    setLoading(true);
    let user = props.location.state.user;
    getUserDetails(user)
      .then((item) => {
        setCurrentUser(item);
        setSelectedOption({
          value: item.roles[0],
          label: item.roles[0].slice(5, 10),
        });
        setLoading(false);
      })
      .catch(() => {
        setError(true)
        showErrorDialog(setError);
        setErrorText('There was an error while loading the user informations, please try again')
      });
  }, [props.location.state.user]);

  function setErrorBorder() {
    setErrorUsername(errorUsername || currentUser.email.length <= 0);
    setErrorRole(errorRole || currentUser.roles.length <= 0);
  }

  function changeInfo(event) {
    setCurrentUser({
      ...currentUser,
      [event.target.name]: event.target.value,
    });
    setErrorUsername(false);
  }

  function handleChange(selectedOption) {
    setCurrentUser({
      ...currentUser,
      roles: [selectedOption.value],
    });
    setSelectedOption(selectedOption);
    setErrorRole(false);
  }

  async function updateInfo(event) {
    event.preventDefault();
    if (isFormValid) {
      let userId = currentUser["@id"];
      updateUserDetails(userId, currentUser.email, currentUser.roles).then(
        (resp) => {
          if (resp.ok) {
            showSaveDialog(setSaved)
          } else {
            showErrorDialog(setError);
            setErrorText('There was an error while updating the account, please try again')
          }
        }
      );
    } else {
      setErrorBorder();
      showErrorDialog(setError);
      setErrorText('Please enter all of the required informations')
    }
  }

  function del() {
    let user = currentUser["@id"];
    deleteUser(user)
      .then((resp) => {
        if (resp.ok) {
          props.history.push(`/admin/users/`)
        } else {
          showErrorDialog(setError);
          setErrorText('There was an error while deleting the account, please try again')
        }
      })
      .catch(() => {
        showErrorDialog(setError);
        setErrorText('There was an error while deleting the account, please try again')
      });
  }
  return (
    <>
      <SimpleHeader name="User details" parentName="Users" />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <Header
                  title={"User details"}
                  edit={setEdit}
                  del={del}
                  delText={"You will not be able to recover this account."}
                  loading={loading}
                  showIcons={true}
                />

                {loading && <Loader />}

                {currentUser !== "" && (
                  <CardBody>
                    <FormGroup className="row">
                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                        md="2"
                      >
                        Username
                      </Label>
                      <Col md="6">
                        <Input
                          disabled={edit ? false : true}
                          onChange={(event) => changeInfo(event)}
                          value={currentUser.email}
                          name="email"
                          type="text"
                          placeholder="Username"
                          style={{
                            backgroundColor: "white",
                            borderColor: errorUsername ? "red" : "",
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                        md="2"
                      >
                        Roles
                      </Label>
                      <Col md="6">
                        <Select
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              backgroundColor: "white",
                              border: errorRole
                                ? "1px solid red"
                                : "1px solid lightgray",
                            }),
                          }}
                          isDisabled={edit ? false : true}
                          name="roles"
                          value={selectedOption}
                          onChange={handleChange}
                          options={USER_ROLE_OPTIONS}
                          placeholder="Select role"
                        />
                      </Col>
                    </FormGroup>

                    {edit && (
                      <button
                        className="btn btn-success"
                        onClick={(event) => updateInfo(event)}
                      >
                        Save
                      </button>
                    )}
                    <div style={{ marginTop: 10 }}>
                      {error && (
                        <ErrorAlert
                          text={errorText}
                        />
                      )}
                      {saved && (
                        <SuccessAlert text={"Your account has been updated"} />
                      )}
                    </div>
                  </CardBody>
                )}
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Show;

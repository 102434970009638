import React, { useState, useEffect } from "react";
import { Button, Badge, Card, Container } from "reactstrap";
import SimpleHeader from "../../componentsTheme/Headers/SimpleHeader.js";
import { IconButton } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { getOrderByPrefDelivery, getOrders } from "../../services/orders";
import { getTotalOrders } from "../../services/orders";
import { getOrderById } from "../../services/orders";
import { getOrderByStatus } from "../../services/orders";
import getCustomerOrders from "../../services/customers";
import MUIDataTable from "mui-datatables";
import Loader from "../../utils/loader";
import ErrorAlert from "../../utils/errorAlert";
import Header from "../../utils/header";
import moment from "moment";

function OrderList(props) {
  const [order, setOrder] = useState([]);
  const [pageNumber, setPageNumber] = useState("1");
  const [requests, setRequests] = useState([1]);
  const [count, setCount] = useState("");
  const [loading, setLoading] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const [filters, setFilters] = useState("");
  const [error, setError] = useState(false);
  const [downloadFile, setDownloadFile] = useState(true);

  const THEME = createMuiTheme({
    typography: {
      fontFamily: "Poppins",
    },
  });

  const columns = [
    {
      name: "@id",
      label: "ORDER ID",
      options: {
        filter: true,
        sort: true,
        filterType: "textField",
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value.slice(8, 20)}</>;
        },
      },
    },
    {
      name: "customer",
      label: "CUSTOMER ID",
      options: {
        filter: true,
        sort: true,
        filterType: "textField",
        customBodyRender: (value, tableMeta, updateValue) => {
          return `${value["@id"].slice(17, 30)}`;
        },
      },
    },
    {
      name: "customer",
      label: "CUSTOMER",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return `${value.firstName} ${value.lastName}`;
        },
      },
    },
    {
      name: "total",
      label: "TOTAL PRICE",
      options: {
        filter: false,
        sort: false,
        filterType: "dropdown",
        customBodyRender: (value, tableMeta, updateValue) => {
          return `${value}`;
        },
      },
    },
    {
      name: "paymentMethod",
      label: "PAYMENT METHOD",
      options: {
        filter: false,
        sort: false,
        filterType: "dropdown",
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value === "credit_card" ? "Credit card" : value}</>;
        },
      },
    },
    {
      name: "createdAt",
      label: "DATE",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return `${moment(value).format("DD-MM-YYYY HH:mm")}`;
        },
      },
    },
    {
      name: "preferredDelivery",
      label: "PREFER DATE",
      options: {
        filter: true,
        sort: false,
        filterOptions: {
          names: ["ASAP", "21.12.2020", "22.12.2020", "23.12.2020"],
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value) {
            return `${value}`;
          }
        },
      },
    },
    {
      name: "deliveries",
      label: "TRANSPORT ID",
      options: {
        filter: false,
        sort: false,
        filterType: "textField",
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              {value.map((item, index) => (
                <div key={index}>
                  <Badge color="" className="badge-dot mr-2">
                    <i />
                    <span>{item.id}</span>
                  </Badge>
                </div>
              ))}
            </div>
          );
        },
      },
    },
    {
      name: "deliveries",
      label: "TRANSPORT STATUS",
      options: {
        filter: false,
        sort: false,
        filterOptions: {
          names: [
            "new",
            "prepared",
            "on the way",
            "completed",
            "canceled",
            "refunded",
          ],
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return value.map((item, index) => {
            if (item.deliveryStatus === "refunded") {
              return (
                <div key={index}>
                  <Badge color="" className="badge-dot mr-2">
                    <i className={"bg-warning"} />
                    <span>{item.deliveryStatus}</span>
                  </Badge>
                </div>
              );
            } else if (item.deliveryStatus === "prepared") {
              return (
                <div key={index}>
                  <Badge color="" className="badge-dot mr-2">
                    <i className={"bg-warning"} />
                    <span>{item.deliveryStatus}</span>
                  </Badge>
                </div>
              );
            } else if (item.deliveryStatus === "completed") {
              return (
                <div key={index}>
                  <Badge color="" className="badge-dot mr-2">
                    <i className={"bg-info"} />
                    <span>{item.deliveryStatus}</span>
                  </Badge>
                </div>
              );
            } else if (item.deliveryStatus === "canceled") {
              return (
                <div key={index}>
                  <Badge color="" className="badge-dot mr-2">
                    <i className={"bg-warning"} />
                    <span>{item.deliveryStatus}</span>
                  </Badge>
                </div>
              );
            } else if (item.deliveryStatus === "on_the_way") {
              return (
                <div key={index}>
                  <Badge color="" className="badge-dot mr-2">
                    <i className={"bg-info"} />
                    <span>on the way</span>
                  </Badge>
                </div>
              );
            } else {
              return (
                <div key={index}>
                  <Badge color="" className="badge-dot mr-2">
                    <i className={"bg-success"} />
                    <span style={{ fontSize: 14 }}>{item.deliveryStatus}</span>
                  </Badge>
                </div>
              );
            }
          });
        },
      },
    },
    {
      name: "status",
      label: "ORDER STATUS",
      options: {
        filter: true,
        sort: false,
        filterOptions: {
          names: ["new", "prepared", "completed", "canceled", "refunded"],
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value === "refunded") {
            return (
              <div>
                <Badge color="" className="badge-dot mr-2">
                  <i className={"bg-warning"} />
                  <span>{value}</span>
                </Badge>
              </div>
            );
          } else if (value === "prepared") {
            return (
              <div>
                <Badge color="" className="badge-dot mr-2">
                  <i className={"bg-warning"} />
                  <span>{value}</span>
                </Badge>
              </div>
            );
          } else if (value === "completed") {
            return (
              <div>
                <Badge color="" className="badge-dot mr-2">
                  <i className={"bg-info"} />
                  <span>{value}</span>
                </Badge>
              </div>
            );
          } else if (value === "canceled") {
            return (
              <div>
                <Badge color="" className="badge-dot mr-2">
                  <i className={"bg-warning"} />
                  <span>{value}</span>
                </Badge>
              </div>
            );
          } else {
            return (
              <div>
                <Badge color="" className="badge-dot mr-2">
                  <i className={"bg-success"} />
                  <span style={{ fontSize: 14 }}>{value}</span>
                </Badge>
              </div>
            );
          }
        },
      },
    },
    {
      name: "DETAILS",
      options: {
        filter: false,
        sort: false,
        empty: true,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ width: "30px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IconButton
                  size="small"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={() =>
                    props.history.push(
                      `orders/show/${encodeURIComponent(tableMeta.rowData[0])}`,
                      {
                        order: tableMeta.rowData[0],
                      }
                    )
                  }
                >
                  <MoreVertIcon fontSize="small" />
                </IconButton>
              </div>
            </div>
          );
        },
      },
    },
  ];

  useEffect(() => {
    if (!filterApplied && !props.history.location.state) {
      loadOrders();
    }
    if (
      !filterApplied &&
      props.history.location.state &&
      props.history.location.state.hasOwnProperty("user")
    ) {
      loadOrders();
    }
  }, [pageNumber, filterApplied]);

  useEffect(() => {
    if (props.history.location.state && props.history.location.state.filtered) {
      setFilterApplied(true);
      let filterList = [
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [props.history.location.state.filtered],
        [],
      ];
      applyFilters(filterList);
      return;
    }
  }, []);

  function loadOrders() {
    setLoading(true);
    let list = [...order];
    getOrders(pageNumber)
      .then((item) => {
        item.forEach((or) => {
          or.products.forEach((item) => {
            or = updateSum(or);
          });

          list.push(or);
        });
        setOrder(list);
        setLoading(false);
      })
      .catch((error) => {
        setError(true);
        window.scrollTo(0, 0);
      });

    getTotalOrders(1)
      .then((item) => {
        setCount(item["hydra:totalItems"]);
      })
      .catch((error) => {
        setError(true);
        window.scrollTo(0, 0);
      });
  }

  function showAllOrders() {
    setError(false);
    setOrder([]);
    setLoading(true);
    setFilterApplied(false);
    setFilters("");
    setRequests([1]);
    setPageNumber(1);
    props.history.location.state = null;
  }

  function showErrorAlert() {
    setError(true);
    window.scrollTo(0, 0);
  }

  function updateSum(item) {
    let transportPrice = item.products.filter((tr) =>
      tr.product.name.includes("Transport")
    )[0];
    if (transportPrice) {
      item.total =
        item.totalPrice.price +
        transportPrice.product.actualPrice.price +
        " " +
        item.totalPrice.priceCurrency;
    } else {
      item.total = item.totalPrice.price + " " + item.totalPrice.priceCurrency;
    }
    return item;
  }

  function applyFilters(filterList) {
    setError(false);
    let list = [];
    setOrder([]);
    setLoading(true);
    setFilterApplied(true);
    setFilters(filterList);
    setRequests([1]);
    setPageNumber(1);

    // eslint-disable-next-line default-case
    switch (true) {
      case filterList[0].length > 0:
        let searchById = filterList[0][0];
        getOrderById(searchById)
          .then((item) => {
            list.push(item);
            setOrder(list);
            setCount(list.length);
            setLoading(false);
          })
          .catch(() => showErrorAlert());
        break;
      case filterList[1].length > 0:
        let searchByCustomerId = filterList[1][0];
        getCustomerOrders(searchByCustomerId, 1)
          .then((data) => {
            setCount(data["hydra:totalItems"]);
            let list = [];
            data["hydra:member"].forEach((item) => {
              item = updateSum(item);
              list.push(item);
            });
            setOrder(list);
            setLoading(false);
            return list;
          })
          .catch(() => showErrorAlert());
        break;
      case filterList[8].length > 0:
        let searchByStatus = filterList[8][0];
        getOrderByStatus(searchByStatus, 1)
          .then((data) => {
            setCount(data["hydra:totalItems"]);
            let list = [];
            data["hydra:member"].forEach((item) => {
              item = updateSum(item);
              list.push(item);
            });
            setOrder(list);
            setLoading(false);
            return list;
          })
          .catch(() => showErrorAlert());
        break;
      case filterList[6].length > 0:
        let searchByPref = filterList[6][0];
        getOrderByPrefDelivery(searchByPref, 1)
          .then((data) => {
            setCount(data["hydra:totalItems"]);
            let list = [];
            data["hydra:member"].forEach((item) => {
              item = updateSum(item);
              list.push(item);
            });
            setOrder(list);
            setLoading(false);
            return list;
          })
          .catch(() => showErrorAlert());
        break;
    }
  }

  function applyNextPageFilters(page) {
    setLoading(true);
    setError(false);
    let list = [...order];
    let newRequest = pageNumber + 1;
    switch (true) {
      case filters[1].length > 0:
        let searchByCustomerId = filters[1][0];
        getCustomerOrders(searchByCustomerId, newRequest)
          .then((data) => {
            data["hydra:member"].forEach((item) => {
              item = updateSum(item);
              list.push(item);
            });
            setOrder(list);
            setLoading(false);
            return list;
          })
          .catch(() => showErrorAlert());
        break;
      case filters[8].length > 0:
        let searchByStatus = filters[8][0];
        getOrderByStatus(searchByStatus, newRequest)
          .then((data) => {
            data["hydra:member"].forEach((item) => {
              item = updateSum(item);
              list.push(item);
            });
            setOrder(list);
            setLoading(false);
            return list;
          })
          .catch(() => showErrorAlert());
        break;
    }
  }

  const handleFilterSubmit = (applyNewFilters) => {
    setError(false);
    props.history.location.state = null;
    let filterList = applyNewFilters();
    applyFilters(filterList);
  };

  const options = {
    confirmFilters: true,
    search: false,
    textLabels: {
      body: {
        noMatch: loading ? <Loader /> : "Sorry, no matching records found",
      },
    },
    viewColumns: false,
    count: count,
    rowsPerPage: 10,
    selectableRows: "none",
    responsive: "vertical",
    rowsPerPageOptions: [],
    viewColumns: false,
    downloadOptions: {
      filename: "orders.csv",
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: false,
      },
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      if (downloadFile) {
        data.map((item) => {
          let transportList = [];
          let statusList = [];
          item.data[0] = item.data[0].slice(8, 20);
          item.data[1] = item.data[1]["@id"].slice(17, 30);
          item.data[2] = `${item.data[2].firstName}${item.data[2].lastName}`;
          item.data[4] =
            item.data[4] === "credit_card" ? "Credit card" : item.data[4];
          item.data[5] = item.data[5].slice(0, 10);
          item.data[6].map((item) => {
            return transportList.push(item.id);
          });
          item.data[7].map((item) => {
            return statusList.push(item.deliveryStatus);
          });
          item.data[6] = transportList;
          item.data[7] = statusList;
        });
        let val = `${buildHead(columns)}${buildBody(data)}`.trim();
        return val;
      }
      return false;
    },
    onChangePage: (currentPage) => {
      if (!filterApplied) {
        if (loading) {
          return <Loader />;
        } else {
          let req = [...requests];
          let lastRequest = req.pop();
          let newRequest = lastRequest + 1;
          if (!requests.includes(newRequest)) {
            req.push(newRequest);
            setRequests(req);
            setPageNumber(newRequest);
          }
        }
      } else {
        if (loading) {
          return <Loader />;
        } else {
          setError(false);
          let req = [...requests];
          let lastRequest = req.pop();
          let newRequest = lastRequest + 1;
          if (!requests.includes(newRequest)) {
            req.push(newRequest);
            setRequests(req);
            setPageNumber(newRequest);
            applyNextPageFilters(newRequest);
          }
          window.scrollTo(0, 0);
        }
      }
    },
    onFilterChipClose: (index, removedFilter, filterList) => {
      setError(false);
      let filter = filterList.some((item) => item.length > 0);
      if (filter) {
        applyFilters(filterList);
      } else {
        setPageNumber(1);
        setFilterApplied(false);
        setRequests([1]);
        setOrder([]);
        props.history.location.state = null;
      }
    },
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div style={{ marginTop: "40px" }}>
          <Button
            variant="contained"
            onClick={() => handleFilterSubmit(applyNewFilters)}
          >
            Apply filters
          </Button>
        </div>
      );
    },
  };

  return (
    <>
      <SimpleHeader name="All orders" parentName="Orders" />
      <Container className="mt--6" fluid>
        <Card>
          <Header
            title={"Order list"}
            edit={true}
            del
            delText
            loading={false}
            showIcons={false}
          />
          {error && (
            <ErrorAlert
              text={
                "There was an error while loading the product list, please try again"
              }
            />
          )}
          <>
            <MuiThemeProvider theme={THEME}>
              <MUIDataTable
                title={
                  props.history.location.state &&
                  props.history.location.state.filtered ? (
                    <Chip
                      label={props.history.location.state.filtered}
                      onDelete={showAllOrders}
                    />
                  ) : (
                    ""
                  )
                }
                data={order}
                columns={columns}
                options={options}
              />
            </MuiThemeProvider>
          </>
        </Card>
      </Container>
    </>
  );
}

export default OrderList;

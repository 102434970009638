import React from "react";
import { Route } from "react-router-dom";
import {
  ProductList,
  CreateProduct,
  ShowProduct,
} from "../components/product/";

export default [
  <Route
    path="/products/create"
    component={CreateProduct}
    exact
    key="create"
  />,
  <Route path="/products/show/:id" component={ShowProduct} exact key="show" />,
  <Route path="/products/" component={ProductList} exact strict key="list" />,
  <Route
    path="/products/:page"
    component={ProductList}
    exact
    strict
    key="page"
  />,
  <Route path="/products" component={ProductList} exact strict key="list" />,
];

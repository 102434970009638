import React, { useState, useEffect } from "react";
import { Card, CardBody, Label, FormGroup, Input, Col } from "reactstrap";
import CreatableSelect from "react-select/creatable";
import Checkbox from "@material-ui/core/Checkbox";
import COLORS from "../../../utils/colors";
import Loader from "../../../utils/loader";
import Header from "../../../utils/header";
import { MANUFACTURER_DELIVERY_TIME } from "../../../services/manufacturers";

export default function BulkDelivery({
  product,
  setProduct,
  productList,
  setProductList,
  loading,
}) {
  const [selectedDeliveryTime, setSelectedDeliveryTime] = useState("");

  useEffect(() => {
    let time = {
      label: product.deliveryTime,
      value: product.deliveryTime,
    };
    setSelectedDeliveryTime(time);
  }, [product]);

  function handleDeliveryChange(selectedDeliveryOption) {
    let list = [...productList];
    list.map(pr => {
      pr.deliveryTime = selectedDeliveryOption
        ? selectedDeliveryOption.value
        : null
    })
    setProductList(list)
    setSelectedDeliveryTime(selectedDeliveryOption);
    setProduct({
      ...product,
      deliveryTime: selectedDeliveryOption
        ? selectedDeliveryOption.value
        : null,
    });
  }

  return (
    <Card>
      <Header
        title={"Delivery options"}
        edit={true}
        del
        delText
        loading={false}
        showIcons={false}
      />

      {loading && <Loader />}
      {!loading && (
        <CardBody>
          {product.manufacturer && (
            <>
              <FormGroup className="row">
                <Label
                  className="form-control-label"
                  htmlFor="example-text-input"
                  md="4"
                >
                  Standard delivery time (hours)
                </Label>
                <Col md="8">
                  <CreatableSelect
                    placeholder="Select from list or add yourself (hours only)"
                    value={selectedDeliveryTime}
                    onChange={handleDeliveryChange}
                    options={MANUFACTURER_DELIVERY_TIME}
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        fontSize: 14,
                        backgroundColor: "white",
                      }),
                    }}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        neutral50: "#8898aa",
                      },
                    })}
                  />
                </Col>
              </FormGroup>

              <FormGroup className="row">
                <Label
                  className="form-control-label"
                  htmlFor="example-text-input"
                  md="4"
                >
                  Standard delivery price (RON)
                </Label>
                <Col md="8">
                  <Input
                    disabled
                    placeholder="Standard delivery price (RON)"
                    style={{ backgroundColor: "white" }}
                    value={product.manufacturer.deliveryPrice}
                    name="deliveryPrice"
                    type="number"
                  />
                </Col>
              </FormGroup>

              {product.manufacturer.transportExpressExist && (
                <>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label"
                      htmlFor="example-text-input"
                      md="4"
                    >
                      Express delivery (hours)
                    </Label>
                    <Col md="8">
                      <Checkbox
                        disabled
                        checked={product.manufacturer.transportExpressExist}
                        style={{ color: COLORS.BLUE }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label"
                      htmlFor="example-text-input"
                      md="4"
                    >
                      Express delivery time (hours)
                    </Label>
                    <Col md="8">
                      <Input
                        disabled
                        style={{ backgroundColor: "white" }}
                        value={product.manufacturer.deliveryTimeExpress}
                        name="deliveryPriceExpress"
                        type="number"
                        placeholder="Express delivery price (RON)"
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="form-control-label"
                      htmlFor="example-text-input"
                      md="4"
                    >
                      Express delivery price (RON)
                    </Label>
                    <Col md="8">
                      <Input
                        disabled
                        style={{ backgroundColor: "white" }}
                        value={product.manufacturer.deliveryPriceExpress}
                        name="deliveryPriceExpress"
                        type="number"
                        placeholder="Express delivery price (RON)"
                      />
                    </Col>
                  </FormGroup>
                </>
              )}
            </>
          )}
        </CardBody>
      )}
    </Card>
  );
}

import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Label,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import Select from "react-select";
import SimpleHeader from "../../componentsTheme/Headers/SimpleHeader.js";
import SuccessAlert, { showSaveDialog } from "../../utils/succesAlert";
import ErrorAlert, { showErrorDialog } from "../../utils/errorAlert";
import ImgContainer from "../../utils/imgContainer";
import Loader from "../../utils/loader";
import Header from "../../utils/header";
import {
  updateBanner,
  deleteBanner,
  IMAGE_BANNER,
  BANNER_LOCATION,
} from "../../services/banners";
import { getBannerDetails } from "../../services/banners";
import * as moment from "moment";

function ShowBanners(props) {
  const [currentBanner, setCurrentBanner] = useState("");
  const [edit, setEdit] = useState(false);
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorStartDate, setErrorStartDate] = useState("");
  const [errorEndDate, setErrorEndDate] = useState("");
  const [errorLocation, setErrorLocation] = useState("");
  const [errorUrl, setErrorUrl] = useState("");
  const [errorText, setErrorText] = useState("");

  const isFormValid =
    currentBanner !== ""
      ? currentBanner.startDate.length > 0 &&
      currentBanner.endDate.length > 0 &&
      (currentBanner.startDate < currentBanner.endDate) &&
      currentBanner.location.length > 0 &&
      currentBanner.image.contentUrl.trim().length > 0
      : "";

  useEffect(() => {
    setLoading(true);
    let banner = props.location.state.banner;
    getBannerDetails(banner)
      .then((item) => {
        setCurrentBanner(item);
        setSelectedOption({
          value: item.location,
          label: item.location,
        });
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setSaved(false);
        setLoading(false);
      });
  }, [props.location.state.banner]);

  function handleChange(selectedOption) {
    setCurrentBanner({
      ...currentBanner,
      location: selectedOption.value,
    });
  }

  function addImg(event) {
    let banner = { ...currentBanner };
    banner.image = {
      contentUrl: event.target.value,
    };
    setCurrentBanner(banner);
  }

  function addStartDate(event) {
    setCurrentBanner({
      ...currentBanner,
      startDate: event.target.value,
    });
  }

  function addEndDate(event) {
    setCurrentBanner({
      ...currentBanner,
      endDate: event.target.value,
    });
  }

  function setErrorBorder() {
    currentBanner.startDate.trim().length < 1
      ? setErrorStartDate("error")
      : setErrorStartDate("");
    currentBanner.endDate.trim().length < 1
      ? setErrorEndDate("error")
      : setErrorEndDate("");
    currentBanner.location.trim().length < 1
      ? setErrorLocation("error")
      : setErrorLocation("");
    currentBanner.image.contentUrl.trim().length < 1
      ? setErrorUrl("error")
      : setErrorUrl("");
  }

  function del() {
    let banner = currentBanner["@id"];
    deleteBanner(banner)
      .then((resp) => {
        resp.ok ? props.history.push(`/admin/banners/`) : showErrorDialog();
      })
      .catch(() => {
        showErrorDialog();
      });
  }

  async function updateInfo(event) {
    event.preventDefault();
    if (isFormValid) {
      let bannerId = currentBanner["@id"];
      let imageUrl = {
        contentUrl: currentBanner.image.contentUrl,
      };
      updateBanner(
        bannerId,
        currentBanner.startDate,
        currentBanner.endDate,
        currentBanner.location,
        imageUrl
      )
        .then((res) => {
          if (res.ok) {
            showSaveDialog(setSaved)
          } else {
            showErrorDialog(setError)
            setErrorText('There was an error while adding the banner, please try again')
          }

        })
        .catch((error) => {
          showErrorDialog(setError);
          setErrorText('There was an error while adding the banner, please try again')
        });
    } else {
      showErrorDialog(setError);
      setErrorBorder();
      if (currentBanner.image.contentUrl == '') {
        setErrorText('Please enter a link or upload an image')
      } else if (currentBanner.startDate > currentBanner.endDate) {
        setErrorText('End date must be after start date')
      } else {
        setErrorText('Please enter all of the required informations')
      }
    }
  }

  return (
    <>
      <SimpleHeader name="Banner details" parentName="Banners" />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <Header
                  title={"Banner details"}
                  edit={setEdit}
                  del={del}
                  delText={"You will not be able to recover this banner."}
                  loading={loading}
                  showIcons={true}
                />

                {loading && <Loader />}

                {currentBanner !== "" && (
                  <CardBody>
                    <FormGroup className="row">
                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                        md="2"
                      >
                        Start date
                      </Label>
                      <Col md="6">
                        <Input
                          defaultValue={moment
                            .utc(currentBanner.startDate)
                            .format("YYYY-MM-DDTHH:mm")}
                          disabled={edit ? false : true}
                          style={{
                            backgroundColor: "white",
                            borderColor:
                              errorStartDate === "error" ? "red" : "",
                          }}
                          name="startDate"
                          type="datetime-local"
                          placeholder="The starting date for the banner"
                          onChange={(event) => addStartDate(event)}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="row">
                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                        md="2"
                      >
                        End date
                      </Label>
                      <Col md="6">
                        <Input
                          defaultValue={moment
                            .utc(currentBanner.endDate)
                            .format("YYYY-MM-DDTHH:mm")}
                          disabled={edit ? false : true}
                          style={{
                            backgroundColor: "white",
                            borderColor: errorEndDate === "error" ? "red" : "",
                          }}
                          onChange={(event) => addEndDate(event)}
                          name="endDate"
                          type="datetime-local"
                          placeholder="The ending date for the banner"
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                        md="2"
                      >
                        Location
                      </Label>
                      <Col md="6">
                        <Select
                          name="location"
                          value={selectedOption}
                          onChange={handleChange}
                          options={BANNER_LOCATION}
                          isDisabled={edit ? false : true}
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              backgroundColor: "white",
                              fontSize: 14,
                              border:
                                errorLocation === "error"
                                  ? "1px solid red"
                                  : "1px solid lightgray",
                            }),
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                        md="2"
                      >
                        Link
                      </Label>
                      <Col md="6">
                        <Input
                          disabled={edit ? false : true}
                          style={{
                            backgroundColor: "white",
                            borderColor: errorUrl === "error" ? "red" : "",
                          }}
                          value={
                            currentBanner.image.contentUrl.indexOf("base64") ===
                              -1
                              ? currentBanner.image.contentUrl
                              : "Banner uploaded"
                          }
                          onChange={(event) => addImg(event)}
                        />
                      </Col>
                    </FormGroup>
                    {edit && (
                      <ImgContainer
                        links
                        setLinks
                        newProduct={currentBanner}
                        setNewProduct={setCurrentBanner}
                        type={IMAGE_BANNER}
                      />
                    )}
                    {currentBanner.image.contentUrl !== "" && (
                      <FormGroup className="row">
                        <GridList
                          style={{
                            width: "auto",
                            height: "auto",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                          cellHeight={300}
                          cols={1}
                        >
                          <GridListTile
                            cols={1}
                            style={{
                              position: "relative",
                            }}
                          >
                            <img
                              src={currentBanner.image.contentUrl}
                              alt="Banner"
                            />
                          </GridListTile>
                        </GridList>
                      </FormGroup>
                    )}

                    {edit && (
                      <button
                        className="btn btn-success"
                        onClick={(event) => updateInfo(event)}
                      >
                        Save
                      </button>
                    )}
                    <div style={{ marginTop: 10 }}>
                      {error && (
                        <ErrorAlert
                          text={errorText}
                        />
                      )}
                      {saved && (
                        <SuccessAlert text={"Your banner has been updated"} />
                      )}
                    </div>
                  </CardBody>
                )}
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ShowBanners;

import React from "react";
import { Route } from "react-router-dom";
import { OrderList, CreateOrder, ShowOrder } from "../components/order/";

export default [
  <Route path="/orders/create" component={CreateOrder} exact key="create" />,
  <Route path="/orders/show/:id" component={ShowOrder} exact key="show" />,
  <Route path="/orders/" component={OrderList} exact strict key="list" />,
  <Route path="/orders/:page" component={OrderList} exact strict key="page" />,
  <Route path="/orders" component={OrderList} exact strict key="list" />,
];

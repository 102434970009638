export default function getSuborders() {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + `/suborders`;
  const res = fetch(URL, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => response.json())
    .then((data1) => {
      return data1;
    });
  return res;
}

export function updateSuborders(id) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + `${id}`;
  const res = fetch(URL, {
    method: "PUT",
    body: JSON.stringify({
      paymentStatus: "paid",
    }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  }).then((response) => response.json());

  return res;
}

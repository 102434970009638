import React from "react";
import { UncontrolledAlert } from "reactstrap";

export default function ErrorAlert(props) {
  return (
    <div style={{ marginLeft: "10px" }}>
      <UncontrolledAlert color="danger">
        <span className="alert-text ml-1">
          <strong>{props.text}</strong>
        </span>
      </UncontrolledAlert>
    </div>
  );
}

export function showErrorDialog(setError) {
  setError(true);
  setTimeout(() => {
    setError(false);
  }, 3000);
}

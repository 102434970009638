import SelectField from "../../../customComponents/SelectField";
import TextFormField from "../../../customComponents/TextFormField";
import SelectFieldAsync from "../../../customComponents/SelectFieldAsync";

export const formTags = () => {
  return [
    {
      label: "Tag Name",
      name: "IDByType",
      disabled: false,
      component: SelectFieldAsync,
    },
    {
      label: "Discount Value",
      name: "Discount",
      disabled: false,
      component: TextFormField,
    },
    {
      label: "Discount Value Specification",
      name: "DiscountPriceType",
      options: [
        { value: "relative", label: "%" },
        { value: "absolute", label: "RON" },
      ],
      disabled: false,
      component: SelectField,
    },
  ];
};

export const formCategories = () => {
  return [
    {
      label: "Category Name",
      name: "IDByType",
      disabled: false,
      component: SelectFieldAsync,
    },
    {
      label: "Discount Value",
      name: "Discount",
      disabled: false,
      component: TextFormField,
    },
    {
      label: "Discount Value Specification",
      name: "DiscountPriceType",
      options: [
        { value: "relative", label: "%" },
        { value: "absolute", label: "RON" },
      ],
      disabled: false,
      component: SelectField,
    },
  ];
};

export const formManufacturer = () => {
  return [
    {
      label: "Manufacturer Name",
      name: "IDByType",
      disabled: false,
      component: SelectFieldAsync,
    },
    {
      label: "Discount Value",
      name: "Discount",
      disabled: false,
      component: TextFormField,
    },
    {
      label: "Discount Value Specification",
      name: "DiscountPriceType",
      options: [
        { value: "relative", label: "%" },
        { value: "absolute", label: "RON" },
      ],
      disabled: false,
      component: SelectField,
    },
  ];
};

export const formAllProducts = () => {
  return [
    {
      label: "Type",
      placeholder: "all",
      name: "IDByType",
      disabled: true,
      component: TextFormField,
    },
    {
      label: "Discount Value",
      name: "Discount",
      disabled: false,
      component: TextFormField,
    },
    {
      label: "Discount Value Specification",
      name: "DiscountPriceType",
      options: [
        { value: "relative", label: "%" },
        { value: "absolute", label: "RON" },
      ],
      disabled: false,
      component: SelectField,
    },
  ];
};

export const formProducts = () => {
  return [
    {
      label: "Products Name",
      name: "IDByType",
      disabled: false,
      isMulti: true,
      component: SelectFieldAsync,
    },
    {
      label: "Discount Value",
      name: "Discount",
      disabled: false,
      component: TextFormField,
    },
    {
      label: "Discount Value Specification",
      name: "DiscountPriceType",
      options: [
        { value: "relative", label: "%" },
        { value: "absolute", label: "RON" },
      ],
      disabled: false,
      component: SelectField,
    },
  ];
};

export const formPlus = () => {
  return [
    {
      label: "Product Name",
      name: "IDByType",
      disabled: false,
      component: SelectFieldAsync,
    },
    {
      label:
        "Discount Condition (ex: 2+2 - Where first number is minimum quantity required and second is the received quantity)",
      name: "DiscountConditionPlus",
      disabled: false,
      component: TextFormField,
    },
  ];
};

export const formObject = [
  {
    label: "Voucher name",
    name: "Name",
    disabled: false,
    component: TextFormField,
  },
  {
    label: "Voucher description",
    name: "Description",
    disabled: false,
    component: TextFormField,
  },
  {
    label: "Voucher code",
    name: "Code",
    disabled: false,
    component: TextFormField,
  },
  {
    label: "Start Date",
    name: "StartDate",
    type: "datetime-local",
    disabled: false,
    component: TextFormField,
  },
  {
    label: "Expire Date",
    name: "ExpiredDate",
    type: "datetime-local",
    disabled: false,
    component: TextFormField,
  },
  {
    label: "Free Shipping",
    name: "FreeShipping",
    options: [
      { value: true, label: "Free delivery" },
      { value: false, label: "Paid delivery" },
    ],
    disabled: false,
    component: SelectField,
  },
  {
    label: "Minimum Spend",
    name: "MinimumSpend",
    disabled: false,
    component: TextFormField,
  },
  {
    label: "Maximum Spend",
    name: "MaximumSpend",
    disabled: false,
    component: TextFormField,
  },
  {
    label: "Usage Limit",
    name: "UsageLimit",
    disabled: false,
    component: TextFormField,
  },
  {
    label: "Usage Limit Per User",
    name: "UsageLimitPerUser",
    disabled: false,
    component: TextFormField,
  },
  {
    label: "Voucher Type",
    name: "VoucherType",
    options: [
      { value: "tags", label: "Tags Type" },
      { value: "products", label: "Products Type" },
      { value: "categories", label: "Categories Type" },
      { value: "manufacturers", label: "Manufacturers Type" },
      { value: "all", label: "All Products Type" },
      // { value: "plus", label: "Free Products Type" },
    ],
    disabled: false,
    component: SelectField,
  },
];

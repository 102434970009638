import React, { useMemo, useCallback, useState } from "react";
import { Button } from "reactstrap";
import { useDropzone } from "react-dropzone";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { Label, FormGroup, Col } from "reactstrap";
import { IMAGE_BANNER } from "../services/banners";
import { IMAGE_PRODUCT } from "../services/products";
import { IMAGE_CATEGORY } from "../services/categories";
import { MANUFACTURER_LOGO } from "../services/manufacturers";
import { IMAGE_USER_PROFILE } from "../services/users";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px 0px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#BEBEBE",
  borderStyle: "dashed",
  backgroundColor: "#FFF",
  color: "#000",
  fontSize: "14px",
  fontWeight: 500,
  outline: "none",
  transition: "border .24s ease-in-out",
  marginBottom: "20px",
  height: "100%",
  width: "100%",
  fontFamily: "poppins",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const useStyles = makeStyles((theme) => ({
  loadingWraper: {
    textAlign: "center",
    marginTop: 50,
  },
  loader: {
    color: "#FBD400",
  },
  uploadSucces: {
    color: "green",
    fontSize: "50px",
  },
  uploadError: {
    color: "red",
    fontSize: "50px",
  },
  appBarSpacer: theme.mixins.toolbar,
}));

export default function ImgContainer({
  links,
  setLinks,
  newProduct,
  setNewProduct,
  type,
}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [showUploadText, setShowUploadText] = useState(true);

  const uploadProductImage = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      let product = { ...newProduct };
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        product.images.push({
          contentUrl: reader.result,
        });
        product.link.push(reader.result);
        links.push(0);
        setNewProduct(product);
        setError(false);
        setSuccess(true);
        setShowUploadText(false);
      };
      reader.onerror = function (error) {
        setError(true);
        setSuccess(false);
        setShowUploadText(true);
      };
    });
  };

  const uploadBannerImage = (acceptedFiles) => {
    let product = { ...newProduct };
    let file = acceptedFiles[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      product.url = reader.result;
      product.image.contentUrl = reader.result;
      setNewProduct(product);
      setError(false);
      setSuccess(true);
      setShowUploadText(false);
    };
    reader.onerror = function (error) {
      setError(true);
      setSuccess(false);
      setShowUploadText(true);
    };
  };

  const uploadCategoryIcon = (acceptedFiles) => {
    let newCategory = { ...newProduct };
    let file = acceptedFiles[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      newCategory.image.contentUrl = reader.result;
      setNewProduct(newCategory);
      setError(false);
      setSuccess(true);
      setShowUploadText(false);
    };
    reader.onerror = function (error) {
      setError(true);
      setSuccess(false);
      setShowUploadText(true);
    };
  };

  const uploadManufacturerLogo = (acceptedFiles) => {
    let newManufacturer = { ...newProduct };
    let file = acceptedFiles[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      newManufacturer.logo = {};
      newManufacturer.logo.contentUrl = reader.result;
      setNewProduct(newManufacturer);
      setError(false);
      setSuccess(true);
      setShowUploadText(false);
    };
    reader.onerror = function (error) {
      setError(true);
      setSuccess(false);
      setShowUploadText(true);
    };
  };

  const uploadProfilePicture = (acceptedFiles) => {
    let updateUser = { ...newProduct };
    let file = acceptedFiles[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      updateUser.photo = {};
      updateUser.photo.contentUrl = reader.result;
      setNewProduct(updateUser);
      setError(false);
      setSuccess(true);
      setShowUploadText(false);
    };
    reader.onerror = function (error) {
      setError(true);
      setSuccess(false);
      setShowUploadText(true);
    };
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (type === IMAGE_PRODUCT) {
        uploadProductImage(acceptedFiles);
      } else if (type === IMAGE_BANNER) {
        uploadBannerImage(acceptedFiles);
      } else if (type === IMAGE_CATEGORY) {
        uploadCategoryIcon(acceptedFiles);
      } else if (type === MANUFACTURER_LOGO) {
        uploadManufacturerLogo(acceptedFiles);
      } else if (type === IMAGE_USER_PROFILE) {
        uploadProfilePicture(acceptedFiles);
      }
    },
    [newProduct]
  );

  const {
    acceptedFiles,
    rejectedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, accept: "image/jpeg, image/png" });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject]
  );

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <div>
      <FormGroup className="row">
        {type !== IMAGE_CATEGORY &&
          type !== MANUFACTURER_LOGO &&
          type !== IMAGE_USER_PROFILE && (
            <Label
              className="form-control-label"
              htmlFor="example-datetime-local-input"
              md="2"
            >
              Or
            </Label>
          )}

        {type === IMAGE_CATEGORY && (
          <Label
            className="form-control-label"
            htmlFor="example-datetime-local-input"
            md="2"
          >
            Icon
          </Label>
        )}
        {type !== IMAGE_USER_PROFILE && (
          <Col md="6">
            <div className="container">
              <div {...getRootProps({ style })}>
                <input {...getInputProps()} />

                {showUploadText && type === IMAGE_PRODUCT && (
                  <div>Drag and drop your files here</div>
                )}

                {showUploadText && type === IMAGE_BANNER && (
                  <div>Drag and drop your banner here</div>
                )}

                {showUploadText && type === IMAGE_CATEGORY && (
                  <div>Drag and drop your category icon here</div>
                )}

                {showUploadText && type === MANUFACTURER_LOGO && (
                  <div>Add your logo here</div>
                )}

                {showUploadText && type === IMAGE_USER_PROFILE && (
                  <div>Change profile picture</div>
                )}

                {loading && (
                  <div className={classes.loadingWraper}>
                    <CircularProgress
                      className={classes.loader}
                    ></CircularProgress>
                  </div>
                )}

                {success && (
                  <span>
                    <CheckCircleIcon className={classes.uploadSucces} />
                  </span>
                )}
                {error && (
                  <span>
                    <HighlightOffIcon className={classes.uploadError} />
                  </span>
                )}
              </div>
            </div>
          </Col>
        )}
        {type === IMAGE_USER_PROFILE && (
          <Col md="12">
            <div className="container">
              <div
                {...getRootProps({ style })}
                style={{
                  border: "1px solid none",
                  marginTop: "20px",
                }}
              >
                <input {...getInputProps()} />
                <Button color="success" outline>
                  Change photo
                </Button>
              </div>
            </div>
          </Col>
        )}
      </FormGroup>
    </div>
  );
}

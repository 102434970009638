import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Input,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import Select from "react-select";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import SuccessAlert, { showSaveDialog } from "../../utils/succesAlert";
import ErrorAlert, { showErrorDialog } from "../../utils/errorAlert";
import { getProducts } from "../../services/products";
import { createOrderProduct, updateOrderProduct } from "../../services/orders";
import useDebounce from "../../services/debounce";

function ProductInformations({ order, setOrder }) {
  const [copied, setCopied] = useState(false);
  const [addProductInput, setAddProductInput] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectedProductQty, setSelectedProductQty] = useState({});
  const [productOptions, setProductOptions] = useState([]);
  const [productInput, setProductInput] = useState("");
  const [totalProductsPrice, setTotalProductsPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [error, setError] = useState(false);
  const [saved, setSaved] = useState(false);
  const [errorText, setErrorText] = useState("");
  const debouncedSearch = useDebounce(productInput, 500);

  function copyTable() {
    const getTable = document.querySelector("Table");
    let range, sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(getTable);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(getTable);
        sel.addRange(range);
      }
      document.execCommand("copy");
    }
    sel.removeAllRanges();
    setCopied(true);
  }

  function removeProductFromList(e, index) {
    let products = order.products;
    products[index].status =
      products[index].status === "removed" ? null : "removed";

    setOrder({
      ...order,
      products: products,
    });
  }

  function addAnotherProduct() {
    let newProduct = [...addProductInput];
    newProduct.push(0);
    setAddProductInput(newProduct);
  }

  useEffect(() => {
    async function searchProducts() {
      let url =
        process.env.REACT_APP_API_ENTRYPOINT +
        `/products?name=${productInput}&visibility=visible`;
      const fetchResult = await getProducts(url);
      const parsedResponse = await fetchResult.json();
      let list = [];
      parsedResponse["hydra:member"].forEach((item) => {
        item.label = `${item.name} - ${item.manufacturer.name}`;
        item.value = item["@id"];
        list.push(item);
      });
      setProductOptions(list);
    }

    if (debouncedSearch) {
      searchProducts();
    }
  }, [debouncedSearch, productInput]);

  useEffect(() => {
    let addedProductsPrice = 0;
    if (selectedProduct.length > 0) {
      addedProductsPrice = selectedProduct.reduce(
        (sum, obj) =>
          sum + obj.actualPrice.price * selectedProductQty[obj["@id"]],
        0
      );
    }

    let excludeTransport = order.products.filter(
      (item) => !item.product.name.includes("Transport")
    );

    let excludeRemovedProducts = excludeTransport.filter(
      (item) => item.status !== "removed"
    );

    let newPrice = excludeRemovedProducts.reduce(
      (sum, obj) => sum + obj.product.actualPrice.price * obj.quantity,
      0
    );

    let totalPrice = addedProductsPrice + newPrice;
    setTotalProductsPrice(totalPrice);
    let disc = order.totalPrice.price - totalPrice;
    setDiscount(disc);
  }, [order, selectedProduct, selectedProductQty]);

  const onChangeProduct = (index) => (event) => {
    let list = [...selectedProduct];
    let qtyList = { ...selectedProductQty };
    event.status = "added";
    event.qty = "1";
    list[index] = event;
    setSelectedProduct(list);
    qtyList[event["@id"]] = "1";
    setSelectedProductQty(qtyList);
  };

  function onChangeSearchForProduct(selectedOption) {
    setProductInput(selectedOption);
  }

  function removeSelectedProduct(e, index) {
    let productToRemove = selectedProduct[index];
    let list = [...selectedProduct];
    let filterList = list.filter((item) => item !== productToRemove);
    setSelectedProduct(filterList);
    let newProduct = [...addProductInput];
    newProduct.pop();
    setAddProductInput(newProduct);
  }

  function changeQty(event, index) {
    let qtyList = { ...selectedProductQty };
    qtyList[selectedProduct[index]["@id"]] = event.target.value;
    setSelectedProductQty(qtyList);

    let list = [...selectedProduct];
    event.status = "added";
    list[index].qty = event.target.value;
    setSelectedProduct(list);
  }

  function updateOrder() {
    if (selectedProduct.length > 0) {
      selectedProduct.forEach((pr) => {
        createOrderProduct(
          order["@id"],
          pr["@id"],
          pr.status,
          parseFloat(pr.qty)
        ).then((res) => {
          if (res.ok) {
            showSaveDialog(setSaved);
          } else {
            showErrorDialog(setError);
            setErrorText(
              "There was an error while updating your order, please try again"
            );
          }
        });
      });
    }

    let modifiedProducts = order.products.filter((item) => item.status);
    modifiedProducts.forEach((pr) => {
      updateOrderProduct(
        pr["@id"],
        order["@id"],
        pr.product["@id"],
        pr.status,
        parseFloat(pr.quantity)
      ).then((res) => {
        if (res.ok) {
          showSaveDialog(setSaved);
        } else {
          showErrorDialog(setError);
          setErrorText(
            "There was an error while updating your order, please try again"
          );
        }
      });
    });
  }

  return (
    <>
      {order !== "" && (
        <>
          <div className="card-wrapper">
            <Card>
              <CardHeader>
                <h3 className="mb-0">
                  Products info
                  <div style={{ float: "right" }}>
                    <Button
                      id="tooltip340498904"
                      className="btn-icon-only rounded-circle"
                      color="#00ff00"
                      onClick={() => copyTable()}
                    >
                      <span className="btn-inner--icon">
                        <i className="ni ni-single-copy-04" />
                      </span>
                    </Button>
                    <UncontrolledTooltip target="tooltip340498904">
                      {copied === true ? "Copied" : "Copy To Clipbord"}
                    </UncontrolledTooltip>
                    <Button
                      id="tooltip_add_new"
                      className="btn-icon-only rounded-circle"
                      color="#00ff00"
                      onClick={() => addAnotherProduct()}
                    >
                      <span className="btn-inner--icon">
                        <AddIcon fontSize="small" />
                      </span>
                    </Button>
                    <UncontrolledTooltip target="tooltip_add_new">
                      Add new product
                    </UncontrolledTooltip>
                  </div>
                </h3>
              </CardHeader>
              <CardBody>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th className="sort" scope="col">
                        Product
                      </th>
                      <th className="sort" scope="col">
                        Manufacturer
                      </th>
                      <th className="sort" scope="col">
                        Price/u.m
                      </th>
                      <th className="sort" scope="col">
                        Quantity
                      </th>
                      <th className="sort" scope="col">
                        U.M.
                      </th>
                      <th scope="col">Total</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  {order && (
                    <tbody className="list">
                      {order.products.map((orderProduct, index) => {
                        const product = orderProduct.product;
                        if (product.name.includes("Transport")) {
                          order.products.sort((a, b) => {
                            var first = a.product.manufacturer.name.toUpperCase();
                            var next = b.product.manufacturer.name.toUpperCase();
                            return first < next ? -1 : first > next ? 1 : 0;
                          });
                          let transportProduct = order.products.filter(
                            (item) => !item.product.name.includes("Transport")
                          );
                          transportProduct.push(orderProduct);
                          order.products = transportProduct;
                        }

                        let textColor = "";
                        let textDecoration = "";

                        if (
                          orderProduct.status === "removed" ||
                          orderProduct.quantity === 0
                        ) {
                          textColor = "red";
                          textDecoration = "line-through";
                        } else if (orderProduct.status === "added") {
                          textColor = "green";
                        }

                        return (
                          <tr
                            key={index}
                            style={{
                              color: textColor,
                              textDecoration: textDecoration,
                            }}
                          >
                            <th scope="row">
                              <span className="name mb-0 text-sm">
                                {product.name}
                              </span>
                            </th>
                            <td>
                              {product.name.includes("Transport")
                                ? ""
                                : product.manufacturer.name}
                            </td>
                            <td>
                              <span className="status">
                                {product.actualPrice.price}{" "}
                                {product.actualPrice.price !==
                                  product.price.price && (
                                  <span
                                    style={{
                                      color: "red",
                                      textDecoration: "line-through",
                                    }}
                                  >
                                    {product.price.price}{" "}
                                  </span>
                                )}
                                {product.actualPrice.priceCurrency}
                              </span>
                            </td>
                            <td className="budget">
                              {orderProduct.quantity !== null
                                ? orderProduct.quantity
                                : "1"}
                            </td>
                            <td>
                              <span className="status">
                                {product.unitOfMeasurement}
                              </span>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                {orderProduct.price &&
                                orderProduct.price.oldPrice !==
                                  orderProduct.price.price ? (
                                    <div>
                                      <span style={{color:'red', textDecorationLine:"line-through"}}>{product.actualPrice.price *
                                      orderProduct.quantity +
                                      " " +
                                      product.price.priceCurrency}</span>
                                  <span style={{marginLeft:10}}>
                                    {orderProduct.price.price +
                                      " " +
                                      product.price.priceCurrency}
                                  </span>
                                  </div>
                                ) : (
                                  <span className="completion mr-2">
                                    {product.actualPrice.price *
                                      orderProduct.quantity +
                                      " " +
                                      product.price.priceCurrency}

                                    {/* {orderProduct.quantity *
                                    product.actualPrice.price}{" "}
                                  {order.totalPrice.priceCurrency} */}
                                  </span>
                                )}
                              </div>
                            </td>
                            <td>
                              <Button
                                className="btn-icon-only rounded-circle"
                                color="#00ff00"
                                onClick={(e) => removeProductFromList(e, index)}
                              >
                                <span
                                  className="btn-inner--icon"
                                  style={{ color: "#000" }}
                                >
                                  <CloseIcon fontSize="small" />
                                </span>
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                      {addProductInput.map((row, indexRow) => {
                        return (
                          <tr className="name mb-0 text-sm" key={indexRow}>
                            <td>
                              <Select
                                key={indexRow}
                                value={selectedProduct[indexRow]}
                                options={productOptions}
                                onInputChange={onChangeSearchForProduct}
                                onChange={onChangeProduct(indexRow)}
                                placeholder="Search product"
                                styles={{
                                  menu: (provided) => ({
                                    ...provided,
                                    zIndex: 999999,
                                    overflow: "visible",
                                    position: "relative",
                                  }),
                                  control: (base, state) => ({
                                    ...base,
                                    fontSize: 14,
                                  }),
                                }}
                              />
                            </td>
                            <td>
                              {selectedProduct[indexRow] &&
                                selectedProduct[indexRow].manufacturer.name}
                            </td>
                            <td>
                              {selectedProduct[indexRow] && (
                                <span className="status">
                                  {selectedProduct[indexRow].actualPrice.price}{" "}
                                  {selectedProduct[indexRow].actualPrice
                                    .price !==
                                    selectedProduct[indexRow].price.price && (
                                    <span
                                      style={{
                                        color: "red",
                                        textDecoration: "line-through",
                                      }}
                                    >
                                      {selectedProduct[indexRow].price.price}{" "}
                                    </span>
                                  )}
                                  {
                                    selectedProduct[indexRow].actualPrice
                                      .priceCurrency
                                  }
                                </span>
                              )}
                            </td>
                            <td>
                              {selectedProduct[indexRow] && (
                                <Input
                                  onChange={(event) =>
                                    changeQty(event, indexRow)
                                  }
                                  value={`${
                                    selectedProductQty[
                                      selectedProduct[indexRow]["@id"]
                                    ]
                                  }`}
                                  name="qty"
                                  type="number"
                                />
                              )}{" "}
                            </td>
                            <td>
                              {selectedProduct[indexRow] &&
                                selectedProduct[indexRow]
                                  .unitOfMeasurement}{" "}
                            </td>
                            <td>
                              {selectedProduct[indexRow] && (
                                <span className="status">
                                  {selectedProduct[indexRow].actualPrice.price *
                                    selectedProductQty[
                                      selectedProduct[indexRow]["@id"]
                                    ]}{" "}
                                  {selectedProduct[indexRow].actualPrice
                                    .price !==
                                    selectedProduct[indexRow].price.price && (
                                    <span
                                      style={{
                                        color: "red",
                                        textDecoration: "line-through",
                                      }}
                                    >
                                      {selectedProduct[indexRow].price.price *
                                        selectedProductQty[
                                          selectedProduct[indexRow]["@id"]
                                        ]}{" "}
                                    </span>
                                  )}
                                  {
                                    selectedProduct[indexRow].actualPrice
                                      .priceCurrency
                                  }
                                </span>
                              )}{" "}
                            </td>
                            <td>
                              <Button
                                className="btn-icon-only rounded-circle"
                                color="#00ff00"
                                onClick={(e) =>
                                  removeSelectedProduct(e, indexRow)
                                }
                              >
                                <span
                                  className="btn-inner--icon"
                                  style={{ color: "#000" }}
                                >
                                  <CloseIcon fontSize="small" />
                                </span>
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                      <tr className="name mb-0 text-sm">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <th>Total products price </th>
                        <td>
                          {totalProductsPrice} {order.totalPrice.priceCurrency}
                        </td>
                      </tr>
                      <tr className="name mb-0 text-sm">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <th>Total delivery price </th>
                        <td>
                          {order.products[
                            order.products.length - 1
                          ].product.name.includes("Transport")
                            ? order.products[order.products.length - 1].product
                                .price.price
                            : order.shippingPrice.price}{" "}
                          {
                            order.products[order.products.length - 1].product
                              .price.priceCurrency
                          }
                        </td>
                      </tr>
                      {discount !== 0 && (
                        <tr className="name mb-0 text-sm">
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <th>
                            {discount.toFixed(2) > 0 ? "Refund" : "Discount"}
                          </th>
                          <td style={{ color: "red" }}>
                            {discount.toFixed(2)}{" "}
                            {order.totalPrice.priceCurrency}
                          </td>
                        </tr>
                      )}
                      <tr className="name mb-0 text-sm">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <th>Total order</th>
                        <td>
                          {order.products[
                            order.products.length - 1
                          ].product.name.includes("Transport")
                            ? Number(
                                order.totalPrice.price +
                                  order.products[order.products.length - 1]
                                    .product.price.price
                              ).toFixed(2)
                            : Number(
                                order.totalPrice.price +
                                  order.shippingPrice.price
                              ).toFixed(2)}{" "}
                          {order.totalPrice.priceCurrency}
                        </td>
                      </tr>
                      <tr className="name mb-0 text-sm">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <th>Payment method</th>
                        <td>
                          {order.paymentMethod === "credit_card"
                            ? "Credit card"
                            : ""}
                        </td>
                      </tr>
                    </tbody>
                  )}
                </Table>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: "10px",
                  }}
                >
                  <Button
                    outline
                    type="button"
                    onClick={() => updateOrder()}
                    color="success"
                  >
                    Update order
                  </Button>
                </div>
                <div style={{ marginTop: 10 }}>
                  {saved && (
                    <SuccessAlert
                      text={"The order was updated successfully!"}
                    />
                  )}
                  {error && <ErrorAlert text={errorText} />}
                </div>
              </CardBody>
            </Card>
          </div>
        </>
      )}
    </>
  );
}

export default ProductInformations;

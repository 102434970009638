import React from "react";
import { Button, Label, FormGroup, Input, Col } from "reactstrap";
import COLORS from "../../utils/colors";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

export default function ImageLinks({
  links,
  setLinks,
  newProduct,
  setNewProduct,
}) {
  function addDisp() {
    let newLink = [...links];
    newLink.push(0);
    setLinks(newLink);
  }

  function removeDisp() {
    let newLink = [...links];
    let imagesList = [...newProduct.images];
    let imagesLinks = [...newProduct.link];
    let deletedInputIndex = newLink.length - 1;
    newLink.pop();
    setLinks(newLink);
    if (imagesList[deletedInputIndex]) {
      let filter = imagesList.filter(
        (pr) => pr !== imagesList[deletedInputIndex]
      );
      let filterLinks = imagesLinks.filter(
        (pr) => pr !== imagesList[deletedInputIndex].contentUrl
      );
      setNewProduct({
        ...newProduct,
        images: filter,
        link: filterLinks,
      });
    }
  }

  function addImg(event, index) {
    let pr = [...newProduct.link];
    let imagesList = [...newProduct.images];
    if (event.target.value === "") {
      pr[index] = 0;
    } else {
      pr[index] = event.target.value;
      imagesList[index] = { contentUrl: event.target.value.trim() };
    }
    setNewProduct({ ...newProduct, link: pr, images: imagesList });
  }

  return (
    <div>
      <FormGroup className="row">
        <Label
          className="form-control-label"
          htmlFor="example-datetime-local-input"
          md="2"
        >
          Link
        </Label>

        <Col md="8">
          {links.map((value, index) => {
            return (
              <div key={index}>
                <Label
                  className="form-control-label"
                  htmlFor="example-datetime-local-input"
                  md="4"
                >
                  Picture {index + 1}
                </Label>
                <Input
                  key={index}
                  type="text"
                  id={"links" + index}
                  label={"Link" + (index + 1)}
                  value={
                    newProduct.link[index]
                      ? newProduct.link[index].indexOf("base64") === -1
                        ? newProduct.link[index]
                        : "Image uploaded"
                      : ""
                  }
                  onChange={(event) => addImg(event, index)}
                />
              </div>
            );
          })}
        </Col>

        <Col md="2">
          <Button
            className="btn-icon-only rounded-circle"
            type="submit"
            onClick={() => addDisp()}
            style={{
              color: "black",
              backgroundColor: COLORS.BLUE,
            }}
          >
            <span className="btn-inner--icon" style={{ color: COLORS.WHITE }}>
              <AddIcon fontSize="small" />
            </span>
          </Button>
          <Button
            className="btn-icon-only rounded-circle"
            type="submit"
            onClick={() => removeDisp()}
            style={{ color: "black", backgroundColor: COLORS.DANGER }}
          >
            <span className="btn-inner--icon" style={{ color: COLORS.WHITE }}>
              <RemoveIcon fontSize="small" />
            </span>
          </Button>
        </Col>
      </FormGroup>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import SimpleHeader from "../../componentsTheme/Headers/SimpleHeader.js";
import SuccessAlert, { showSaveDialog } from "../../utils/succesAlert";
import ErrorAlert, { showErrorDialog } from "../../utils/errorAlert";
import { RequestLoader } from "../../utils/loader";
import QuestionAlert from "../../utils/questionAlert";
import SubcategoryChips from "../../utils/subcategoryChips";
import ImgContainer from "../../utils/imgContainer";
import Loader from "../../utils/loader";
import { getSubcategories } from "../../services/categories";
import {
  getDetails,
  deleteCategory,
  createSubcategory,
  deleteSubcategory,
  updateCategory,
  updateSubcategory,
  IMAGE_CATEGORY,
} from "../../services/categories";

function ShowCategory(props) {
  const [currentCategory, setCurrentCategory] = useState({
    name: "",
    subcategory: "",
    image: "",
  });
  const [loading, setLoading] = useState(false);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [edit, setEdit] = useState(false);
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [subcategoryList, setSubcategoryList] = useState([]);
  const [clickedChip, setClickedChip] = useState("");

  useEffect(() => {
    setLoading(true);
    getDetails(props.location.state.category).then((item) => {
      setCurrentCategory(item);
      setLoading(false);
    });
  }, [props.location.state.category]);

  useEffect(() => {
    let list = [];
    getSubcategories(props.location.state.category).then((res) => {
      res.forEach((item) => {
        let selectableList = {};
        selectableList.label = item["name"];
        selectableList.value = item["@id"];
        list.push(selectableList);
      });
      setSubcategoryList(list);
    });
  }, [props.location.state.category, saved]);

  function changeInfo(event) {
    setCurrentCategory({
      ...currentCategory,
      [event.target.name]: event.target.value,
    });
  }

  async function updateCategoryInfo(event) {
    event.preventDefault();
    let categId = currentCategory["@id"];
    let isValid = currentCategory.name.length > 0
    if (isValid) {
      setLoadingRequest(true)
      updateCategory(categId, currentCategory.name, currentCategory.image)
        .then((res) => {
          if (res.ok) {
            showSaveDialog(setSaved)
            setLoadingRequest(false)
          } else {
            showErrorDialog(setError)
            setLoadingRequest(false)
            setErrorText('There was an error while updating the category informations, please try again')
          };
        })
        .catch(() => {
          showErrorDialog(setError);
          setLoadingRequest(false)
          setErrorText('There was an error while updating the category informations, please try again')
        });
    } else {
      showErrorDialog(setError);
      setErrorText('Please add a category name')
    }

  }

  function del() {
    let categId = currentCategory["@id"];
    let hasSubcategories = subcategoryList.length > 0

    if (hasSubcategories) {
      showErrorDialog(setError);
      setErrorText('Please delete the subcategories first')
    } else {

      deleteCategory(categId)
        .then((res) => {
          if (res.ok) {
            props.history.push(`/admin/categories/`)
          } else {
            showErrorDialog(setError);
            setErrorText('There was an error while deleting the category, please try again')
          }
        })
        .catch(() => {
          showErrorDialog(setError);
          setErrorText('There was an error while deleting the category, please try again')
        });
    }

  }

  function delSubcategory() {
    let categ = clickedChip.value;
    deleteSubcategory(categ)
      .then((res) => {
        if (res.ok) {
          setClickedChip("");
          const refreshList = subcategoryList.filter(
            (item) => item.value !== categ
          );
          setSubcategoryList(refreshList);
          showSaveDialog(setSaved);
        } else {
          showErrorDialog(setError);
          setErrorText('There was an error while deleting the subcategory, please try again')
        }
      })
      .catch((error) => {
        showErrorDialog(setError);
        setErrorText('There was an error while deleting the subcategory, please try again')
      });
  }

  function changeSubcategInfo(event) {
    setClickedChip({
      ...clickedChip,
      label: event.target.value,
    });
  }

  async function editSubcategory(event) {
    event.preventDefault();
    let categId = clickedChip.value;
    let isValid = clickedChip.label.length > 0
    if (isValid) {
      updateSubcategory(categId, clickedChip.label)
        .then((res) => {
          if (res.ok) {
            showSaveDialog(setSaved)
          } else {
            showErrorDialog(setError);
            setErrorText('There was an error while updating the subcategory, please try again')
          }
        })
        .catch(() => {
          showErrorDialog(setError);
          setErrorText('There was an error while updating the subcategory, please try again')
        });
    } else {
      showErrorDialog(setError);
      setErrorText('Please enter a subcategory name')
    }
  }

  async function addSubcategory(event) {
    event.preventDefault();
    let isValid = currentCategory.subcategory && currentCategory.subcategory.length > 0
    if (isValid) {
      createSubcategory(currentCategory.subcategory, `${currentCategory["@id"]}`)
        .then((res) => {
          if (res.ok) {
            showSaveDialog(setSaved);
            setCurrentCategory({
              ...currentCategory,
              subcategory: "",
            });
          } else {
            showErrorDialog(setError);
            setErrorText('There was an error while adding your subcategory, please try again')
          }
        })
        .catch(() => {
          showErrorDialog(setError);
          setErrorText('There was an error while adding your subcategory, please try again')
        });
    } else {
      showErrorDialog(setError);
      setErrorText('Please enter a subcategory name')
    }
  }

  return (
    <>
      <SimpleHeader name="Category details" parentName="Categories" />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">
                    Category details
                    <>
                      {currentCategory.name !== "" && (
                        <div style={{ float: "right" }}>
                          <Button
                            className="btn-icon-only rounded-circle"
                            color="#00ff00"
                            type="submit"
                            onClick={() => setEdit(true)}
                          >
                            <span
                              className="btn-inner--icon"
                              style={{ color: "#000" }}
                            >
                              <i className="fas fa-edit fa-lg" />
                            </span>
                          </Button>

                          <QuestionAlert
                            text={
                              "You will not be able to recover this category."
                            }
                            del={del}
                          />
                        </div>
                      )}
                    </>
                  </h3>
                </CardHeader>

                {loading && <Loader />}
                {!loading && (
                  <CardBody>
                    <FormGroup className="row">
                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                        md="2"
                      >
                        Category name
                      </Label>
                      <Col md="6">
                        <Input
                          disabled={edit ? false : true}
                          onChange={(event) => changeInfo(event)}
                          value={currentCategory.name}
                          name="name"
                          type="text"
                          placeholder="Name"
                          style={{ backgroundColor: "white" }}
                        />
                      </Col>

                      {edit && (
                        <Col md="4">
                          <Button
                            color="success"
                            outline
                            type="button"
                            onClick={(event) => updateCategoryInfo(event)}
                          >
                            Update category
                          </Button>
                        </Col>
                      )}
                    </FormGroup>

                    <FormGroup className="row">
                      {edit && (
                        <Col md="12">
                          <ImgContainer
                            links
                            setLinks
                            newProduct={currentCategory}
                            setNewProduct={setCurrentCategory}
                            type={IMAGE_CATEGORY}
                          />
                        </Col>
                      )}
                    </FormGroup>

                    <FormGroup className="row" style={{ marginTop: 20 }}>
                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                        md="2"
                      >
                        Subcategories
                      </Label>
                      <Col md="8">
                        <SubcategoryChips
                          subcateg={subcategoryList}
                          setSubcategoryList={setSubcategoryList}
                          edit={edit}
                          clickedChip={clickedChip}
                          setClickedChip={setClickedChip}
                        />
                      </Col>
                    </FormGroup>

                    {edit && (
                      <>
                        {clickedChip !== "" && (
                          <>
                            <FormGroup className="row">
                              <Label
                                className="form-control-label"
                                htmlFor="example-text-input"
                                md="2"
                              >
                                Edit selected subcategory
                              </Label>
                              <Col md="8">
                                <Input
                                  onChange={(event) =>
                                    changeSubcategInfo(event)
                                  }
                                  value={clickedChip.label}
                                  name="subcategory"
                                  type="text"
                                  placeholder="Subcategory"
                                  style={{ backgroundColor: "white" }}
                                />
                              </Col>

                              <Col md="1">
                                <Button
                                  id="updateName"
                                  className="btn-icon-only rounded-circle"
                                  color="#00ff00"
                                  onClick={(event) => editSubcategory(event)}
                                >
                                  <span
                                    className="btn-inner--icon"
                                    style={{ color: "#000" }}
                                  >
                                    <i className="fas fa-save fa-lg" />
                                  </span>
                                </Button>
                              </Col>
                              <Col md="1">
                                <QuestionAlert
                                  text={
                                    "You will not be able to recover this category."
                                  }
                                  del={delSubcategory}
                                />
                              </Col>
                            </FormGroup>
                          </>
                        )}
                        <FormGroup className="row">
                          <Label
                            className="form-control-label"
                            htmlFor="example-text-input"
                            md="2"
                          >
                            Add new subcategory
                          </Label>
                          <Col md="6">
                            <Input
                              onChange={(event) => changeInfo(event)}
                              value={currentCategory.subcategory || ""}
                              name="subcategory"
                              type="text"
                              placeholder="Name"
                              style={{ backgroundColor: "white" }}
                            />
                          </Col>
                          <Col md="4">
                            <Button
                              color="success"
                              outline
                              type="button"
                              onClick={(event) => addSubcategory(event)}
                            >
                              Save subcategory
                            </Button>
                          </Col>
                        </FormGroup>
                      </>
                    )}
                    {loadingRequest && <RequestLoader />}
                    <div style={{ marginTop: 10 }}>
                      {error && (
                        <ErrorAlert text={errorText} />
                      )}
                      {saved && (
                        <SuccessAlert text={"Your category has been updated"} />
                      )}
                    </div>
                  </CardBody>
                )}
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ShowCategory;

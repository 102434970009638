import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Label,
  Form,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import SimpleHeader from "../../componentsTheme/Headers/SimpleHeader.js";
import SuccessAlert from "../../utils/succesAlert";
import ErrorAlert from "../../utils/errorAlert";
import ImgContainer from "../../utils/imgContainer";
import Header from "../../utils/header";
import { addCategory, IMAGE_CATEGORY } from "../../services/categories";

function CreateCategory(props) {
  const [currentCategory, setCurrentCategory] = useState({
    name: "",
    image: {},
  });

  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState(false);

  function addInfo(event) {
    setCurrentCategory({
      ...currentCategory,
      [event.target.name]: event.target.value,
    });
  }
  const isValid =
    currentCategory.name.length > 0 && currentCategory.image.hasOwnProperty('contentUrl')

  function showSaveDialog() {
    setSaved(true);
    setTimeout(() => {
      setSaved(false);
    }, 5000);
  }

  function showErrorDialog() {
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 3000);
  }

  async function createCategory(event) {
    event.preventDefault();
    if (isValid) {
      addCategory(currentCategory.name, currentCategory.image)
        .then((res) => {
          if (res.ok) {
            showSaveDialog()
          } else {
            showErrorDialog();
            setErrorText('There was an error while adding your offer, please try again')
          }
        })
        .catch(() => {
          showErrorDialog();
          setErrorText('There was an error while adding the category, please try again')
        });
    } else {
      showErrorDialog();
      setErrorText('Please add a category name and upload an icon')
    }
  }

  return (
    <>
      <SimpleHeader name="New category" parentName="Categories" />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <Header
                  title={"Category details"}
                  edit={true}
                  del
                  delText
                  loading={false}
                  showIcons={false}
                />

                <CardBody>
                  <Form>
                    <FormGroup className="row">
                      <Label
                        className="form-control-label"
                        htmlFor="example-text-input"
                        md="2"
                      >
                        Name
                      </Label>
                      <Col md="6">
                        <Input
                          value={currentCategory.name}
                          name="name"
                          type="text"
                          placeholder="Category name"
                          onChange={(event) => addInfo(event)}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="row">
                      <Col md="10">
                        <ImgContainer
                          links
                          setLinks
                          newProduct={currentCategory}
                          setNewProduct={setCurrentCategory}
                          type={IMAGE_CATEGORY}
                        />
                      </Col>
                    </FormGroup>

                    <button
                      onClick={(event) => createCategory(event)}
                      className="btn btn-success"
                    >
                      Save
                    </button>
                  </Form>
                  <div style={{ marginTop: 10 }}>
                    {error && (
                      <ErrorAlert
                        text={
                          errorText
                        }
                      />
                    )}
                    {saved && (
                      <SuccessAlert text={"Your category has been created"} />
                    )}
                  </div>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CreateCategory;

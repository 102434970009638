import React, { useState, useEffect } from "react";
import { Card, CardHeader, Container, Row, Col } from "reactstrap";
import SimpleHeader from "../../componentsTheme/Headers/SimpleHeader.js";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { getProviderOrders } from "../../services/orders";
import Loader from "../../utils/loader";
import ErrorAlert from "../../utils/errorAlert";
import MaterialTable from "material-table";
import { getProviderName } from "../../services/providers";

function OrderList(props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const tableRef = React.createRef();
  const [provider, setProvider] = useState({});

  useEffect(() => {
    setLoading(true);
    getProviderName()
      .then((item) => {
        setProvider(item);
        setLoading(false);
      })
      .catch((error) => {
        showErrorDialog(setError);
        setErrorText(
          "There was an error while loading the informations, please try again"
        );
      });
  }, []);

  function showErrorDialog() {
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 3000);
  }

  const THEME = createMuiTheme({
    typography: {
      fontFamily: "Poppins",
    },
  });

  const columns = [
    {
      field: "@id",
      title: "TRANSPORT ID",
      render: (rowData) => {
        return <>{Number(rowData["id"])}</>;
      },
    },
    {
      field: "createdAt",
      title: "DATE",
      filtering: false,
      render: (rowData) => {
        return `${new Date(rowData.createdAt).toLocaleDateString()}`;
      },
    },
    {
      field: "deliveryTime",
      title: "DELIVERY TIME",
      render: (rowData) => {
        return <>{rowData.deliveryTime} hours</>;
      },
    },
    {
      field: "products",
      title: "PRODUCTS",
      filtering: false,
      render: (rowData) => {
        return <>{rowData.orderProducts.length}</>;
      },
    },
    {
      field: "deliveryStatus",
      title: "STATUS",
      render: (rowData) => {
        return <>{rowData.deliveryStatus}</>;
      },
    },
  ];

  const actions = [
    {
      name: "seeDetails",
      icon: () => <MoreVertIcon />,
      tooltip: "See more details",

      onClick: (event, rowData) =>
        props.history.push(
          `/provider/orders/show/${encodeURIComponent(rowData["@id"])}`,
          {
            order: rowData,
          }
        ),
      disabled: false,
      position: "row",
    },
  ];

  function getUrl(query) {
    let url =
      process.env.REACT_APP_API_ENTRYPOINT + `${provider["@id"]}/deliveries?`;
    url += "&page=" + (query.page + 1);
    if (query.orderBy) {
      url += `&order%5B${query.orderBy.field}%5D=${query.orderDirection}`;
    }

    if (query.filters) {
      query.filters.forEach((filter) => {
        switch (filter.column.field) {
          case "@id":
            url =
              process.env.REACT_APP_API_ENTRYPOINT +
              `/deliveries/` +
              encodeURIComponent(filter.value);
            break;
          case "deliveryTime":
            url += "&deliveryTime=" + encodeURIComponent(filter.value);
            break;
          case "deliveryStatus":
            let valueStatus = filter.value.includes("co")
              ? "completed"
              : filter.value.includes("pr")
              ? "prepared"
              : filter.value.includes("ne")
              ? "new"
              : filter.value.includes("ca")
              ? "canceled"
              : filter.value.includes("re")
              ? "refunded"
              : filter.value.includes("on")
              ? "on_the_way"
              : "";
            url += "&deliveryStatus=" + encodeURIComponent(valueStatus);
            break;

          default:
        }
      });
    }
    return url;
  }

  const getNewItems = async (query) => {
    let url = getUrl(query);
    const fetchResult = await getProviderOrders(url);
    if (fetchResult.status > 299) {
      return {
        data: [],
        page: query.page,
        totalCount: 1,
      };
    }

    const parsedResponse = await fetchResult.json();

    if (parsedResponse.hasOwnProperty("hydra:member")) {
      let list = [];
      parsedResponse["hydra:member"].forEach((pr) => {
        list.push(pr);
      });
      return {
        data: list,
        page: query.page,
        totalCount: 1,
      };
    } else {
      let list = [];
      list.push(parsedResponse);
      return {
        data: list,
        page: query.page,
        totalCount: 1,
      };
    }
  };

  return (
    <>
      <SimpleHeader name="My deliveries" parentName="Deliveries" />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Delivery list</h3>
              </Col>
            </Row>
          </CardHeader>
          {loading && <Loader />}
          {error && <ErrorAlert text={errorText} />}
          {!loading && (
            <>
              <MuiThemeProvider theme={THEME}>
                <div style={{ maxWidth: "100%" }}>
                  <MaterialTable
                    columns={columns}
                    data={getNewItems}
                    title={null}
                    tableRef={tableRef}
                    options={{
                      exportButton: false,
                      selection: false,
                      sorting: true,
                      filtering: true,
                      pageSize: 30,
                      pageSizeOptions: [30],
                      search: false,
                      exportAllData: true,
                      actionsColumnIndex: -1,
                    }}
                    actions={actions}
                    localization={{
                      header: {
                        actions: "",
                      },
                      toolbar: {
                        nRowsSelected: "{0} product(s) selected",
                      },
                      body: {
                        emptyDataSourceMessage: "No records to display",
                        filterRow: {
                          filterTooltip: "Filter",
                        },
                      },
                    }}
                  />
                </div>
              </MuiThemeProvider>
            </>
          )}
        </Card>
      </Container>
    </>
  );
}

export default OrderList;

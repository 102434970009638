export const IMAGE_USER_PROFILE = "USER_PROFILE_PICTURE";
export const USER_ROLE_OPTIONS = [
  {
    value: "ROLE_USER",
    label: "User",
  },
  { value: "ROLE_ADMIN", label: "Admin" },
];

async function doFetch(pageNumber) {
  let url = process.env.REACT_APP_API_ENTRYPOINT + `/users?page=${pageNumber}`;
  return await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  }).then((response) => response.json());
}

export default async function getUsers(pageNumber) {
  return doFetch(pageNumber)
    .then((res) => {
      if (res.code === 401) {
        let url = process.env.REACT_APP_API_ENTRYPOINT + "/login/admin/refresh";

        return fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            refresh_token: `${localStorage.getItem("refresh_token")}`,
          }),
        })
          .then((res) => res.json())
          .then((r) => localStorage.setItem("token", r.token))
          .then(() => doFetch(pageNumber));
      }
      return res;
    })
    .then((data1) => {
      let list = [];
      data1["hydra:member"].forEach((item) => {
        list.push(item);
      });
      return list;
    });
}

export async function getUserDetails(id) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + `${id}`;
  const res = fetch(URL, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => response.json())
    .then((data1) => {
      return data1;
    });
  return res;
}

export async function createNewUser(email, roles, password) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + "/register/admin";
  let res = await fetch(URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({
      email: email,
      roles: roles,
      password: password,
    }),
  });
  return res;
}

export async function updateUserDetails(userId, email, roles) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + "" + userId;
  let res = await fetch(URL, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({
      email: email,
      roles: roles,
    }),
  });
  return res;
}

export async function deleteUser(userId) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + "" + userId;
  let res = await fetch(URL, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return res;
}

async function doFetchAdminId() {
  const adminId = process.env.REACT_APP_API_ENTRYPOINT + "/customers/me";
  return await fetch(adminId, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  }).then((response) => response.json());
}

export async function getAdminData() {
  return doFetchAdminId()
    .then((res) => {
      if (res.code === 401) {
        let url = process.env.REACT_APP_API_ENTRYPOINT + "/login/admin/refresh";
        return fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            refresh_token: `${localStorage.getItem("refresh_token")}`,
          }),
        })
          .then((res) => res.json())
          .then((r) => localStorage.setItem("token", r.token))
          .then(() => doFetchAdminId());
      }
      return res;
    })
    .then((data1) => {
      let url = process.env.REACT_APP_API_ENTRYPOINT + `/users/${data1}`;
      return fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }).then((response) => response.json());
    });
}

export async function updateAdminDetails(
  userId,
  email,
  firstName,
  lastName,
  photo
) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + "" + userId;
  let res = await fetch(URL, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({
      email,
      firstName,
      lastName,
      photo,
    }),
  });
  return res;
}

export const IMAGE_BANNER = "BANNER";
export const BANNER_LOCATION = [
  {
    value: "home",
    label: "home",
  },
];

async function doFetch(pageNumber) {
  let url =
    process.env.REACT_APP_API_ENTRYPOINT + `/banners?page=${pageNumber}`;
  return await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  }).then((response) => response.json());
}

export default async function getBanners(pageNumber) {
  return doFetch(pageNumber)
    .then((res) => {
      if (res.code === 401) {
        let url = process.env.REACT_APP_API_ENTRYPOINT + "/login/admin/refresh";

        return fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            refresh_token: `${localStorage.getItem("refresh_token")}`,
          }),
        })
          .then((res) => res.json())
          .then((r) => localStorage.setItem("token", r.token))
          .then(() => doFetch(pageNumber));
      }
      return res;
    })
    .then((data1) => {
      let list = [];
      data1["hydra:member"].forEach((item) => {
        list.push(item);
      });
      return list;
    });
}

export async function getBannerDetails(id) {
  let url = process.env.REACT_APP_API_ENTRYPOINT + `${id}`;
  return await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => response.json())
    .then((data1) => data1);
}

export async function createNewBanner(startDate, endDate, location, image) {
  let url = process.env.REACT_APP_API_ENTRYPOINT + "/banners";
  return await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({
      startDate: startDate,
      endDate: endDate,
      location: location,
      image: image,
    }),
  });
}

export async function deleteBanner(bannerId) {
  let url = process.env.REACT_APP_API_ENTRYPOINT + "" + bannerId;
  return await fetch(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
}

export async function updateBanner(
  bannerId,
  startDate,
  endDate,
  location,
  image
) {
  let url = process.env.REACT_APP_API_ENTRYPOINT + "" + bannerId;
  return await fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({
      startDate,
      endDate,
      location,
      image,
    }),
  });
}

import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, Container, Row, Col } from "reactstrap";
import SimpleHeader from "../../../componentsTheme/Headers/SimpleHeader.js";
import { getOrderDetails, deleteOrder } from "../../../services/orders";
import { getSubOrderDetails } from "../../../services/manufacturers";
import QuestionAlert from "../../../utils/questionAlert";
import Loader from "../../../utils/loader";
import ErrorAlert, { showErrorDialog } from "../../../utils/errorAlert";
import ProductInformations from "./productInfo";
import ClientInformations from "./clientInfo";

function ShowOrder(props) {
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState("");
  const [error, setError] = useState(false);
  const role = localStorage.getItem("role");

  useEffect(() => {
    setLoading(true);
    getSubOrderDetails(props.location.state.order["@id"])
      .then((item) => {
        let orderedProd = item.orderProducts.filter(
          (el) => el.status !== "removed"
        );
        let newPrice = orderedProd.reduce(
          (sum, obj) => sum + obj.product.actualPrice.price * obj.quantity,
          0
        );
        item.total = newPrice;
        setOrder(item);
        setLoading(false);
      })
      .catch(() => {
        showErrorDialog(setError);
      });
  }, []);

  function del() {
    let orderId = order["@id"];
    deleteOrder(orderId, role)
      .then((res) => {
        res.ok
          ? props.history.push(`/admin/orders`)
          : showErrorDialog(setError);
      })
      .catch(() => {
        showErrorDialog(setError);
      });
  }

  return (
    <>
      <SimpleHeader name="Order details" parentName="Orders" />
      <Container className="mt--6" fluid>
        {loading && (
          <Card>
            <Loader />
          </Card>
        )}
        {error && (
          <ErrorAlert
            text={
              "There was an error while loading the order details, please try again"
            }
          />
        )}
        {order !== "" && (
          <>
            <Row>
              <Col lg="12">
                <div className="card-wrapper">
                  <Card>
                    <CardHeader>
                      <h3 className="mb-0">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          Suborder {""}
                          <>
                            {order["@id"].slice(11, 15)} of order{" "}
                            {order.forOrder["@id"].slice(8, 15)} from{" "}
                            {new Date(order["createdAt"]).toLocaleDateString()}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <QuestionAlert
                                text={
                                  "You will not be able to recover this order."
                                }
                                del={del}
                              />
                            </div>
                          </>
                        </div>
                      </h3>
                    </CardHeader>
                  </Card>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <ProductInformations order={order} />
              </Col>
            </Row>
          </>
        )}
      </Container>
    </>
  );
}

export default ShowOrder;

export const IMAGE_CATEGORY = "CATEGORY";

async function doFetch(pageNumber) {
  let url =
    process.env.REACT_APP_API_ENTRYPOINT + `/categories?exists[parent]=false`;
  return await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  }).then((response) => response.json());
}

export default async function getCategories(pageNumber) {
  return doFetch(pageNumber)
    .then((res) => {
      if (res.code === 401) {
        let url = process.env.REACT_APP_API_ENTRYPOINT + "/login/admin/refresh";

        return fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            refresh_token: `${localStorage.getItem("refresh_token")}`,
          }),
        })
          .then((res) => res.json())
          .then((r) => localStorage.setItem("token", r.token))
          .then(() => doFetch(pageNumber));
      }
      return res;
    })
    .then((data1) => {
      let list = [];
      data1["hydra:member"].forEach((item) => {
        list.push(item);
      });
      return list;
    });
}

export async function getSubcategories(id) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + `${id}/subcategories`;
  const res = fetch(URL, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => response.json())
    .then((data1) => {
      let list = [];
      data1["hydra:member"].forEach((item) => {
        list.push(item);
      });
      return list;
    });
  return res;
}

export function getDetails(categ) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + `${categ}`;
  const res = fetch(URL, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => response.json())
    .then((data1) => {
      return data1;
    });
  return res;
}

export function addCategory(name, image) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + "/categories";
  const res = fetch(URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({
      name,
      image,
    }),
  });
  return res;
}

export function getCategoryByName(name) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + `/categories?name=${name}`;
  const res = fetch(URL, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => response.json())
    .then((data1) => {
      return data1["hydra:member"];
    });
  return res;
}

export function createSubcategory(name, parent) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + "/categories";
  const res = fetch(URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({
      name,
      parent,
    }),
  });
  return res;
}

export function deleteCategory(id) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + "" + id;
  const res = fetch(URL, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return res;
}

export function deleteSubcategory(id) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + "" + id;
  const res = fetch(URL, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return res;
}

export function updateCategory(id, name, image) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + id;
  const res = fetch(URL, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({
      name,
      image,
    }),
  });
  return res;
}

export function updateSubcategory(id, name) {
  const URL = process.env.REACT_APP_API_ENTRYPOINT + id;
  const res = fetch(URL, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({
      name,
    }),
  });
  return res;
}

import React from 'react';
import { Route } from 'react-router-dom';
import { List, Create, Update, Show } from '../components/bulkuploadrequest/';

export default [
  <Route path="/bulk_upload_requests/create" component={Create} exact key="create" />,
  <Route path="/bulk_upload_requests/edit/:id" component={Update} exact key="update" />,
  <Route path="/bulk_upload_requests/show/:id" component={Show} exact key="show" />,
  <Route path="/bulk_upload_requests/" component={List} exact strict key="list" />,
  <Route path="/bulk_upload_requests/:page" component={List} exact strict key="page" />,
  <Route path="/bulk_upload_requests" component={List} exact strict key="list" />
];
